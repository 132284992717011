import React, { Fragment, useState } from "react"
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import theme from "../../../theme";
import { TourServicesWhiteButton } from "../../../components/styled/Buttons";
import Sride from "./Sride";
import { withTranslation } from "react-i18next";

const TourSrides = (props) => {

  const [showMore, setShowMore] = useState(false);
  const { destinationsStore: { sRides }, t } = props;
  const MoreThan4Rides = sRides.length > 4;
  
  const ShowFirst4Srides = () => {

    if (MoreThan4Rides && !showMore) {
      return [sRides[0], sRides[1], sRides[2], sRides[3]];
    }
    return sRides;
  };

  const handleMoreRidesClick = () => {
    setShowMore(true);
  }

  return (
    <Fragment>
      <StyledContentContainer>
        {
          ShowFirst4Srides().map(sRide =>
            <Sride key={sRide.id} sRide={sRide}/>
            // <ExplorePageCard sRide={sRide} key={sRide.id} />
          )
        }
      </StyledContentContainer>
      {MoreThan4Rides &&
        !showMore &&
        <MoreRidesButton onClick={handleMoreRidesClick}>{t('explore.more_rides')}</MoreRidesButton>}
    </Fragment>
  )
}

const StyledContentContainer = styled.div`
  margin: 40px 0 40px 0;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 45% 45%;
  grid-gap: 32px;
  justify-content: center;

  @media (max-width: ${theme.breakpoints[0]}px){
    grid-template-columns: 95%;
  }
`;

const MoreRidesButton = styled(TourServicesWhiteButton)`
  margin-top:25px;
  max-width:300px;
  min-height:48px;
  margin:0 auto;
  display:block;
`;


export default withTranslation()(inject("destinationsStore")(observer(TourSrides)));

import React, { Fragment, useEffect } from "react"
import { observer, inject } from "mobx-react";
import { addScript } from "../../components/common/helpers";
import styled from "styled-components";
import { TourServicesWhiteButton } from "../../components/styled/Buttons";
import { withTranslation } from "react-i18next";

// const { explore: { moreAttractions } } = GonProvider.i18nTranslations.travelerApp;

const Tiqets = (props) => {

  useEffect(() => {
    const { destinationsStore: { widgets } } = props;
    const codeParts = widgets[0].code.split("<script");
    addScript(codeParts[1].split("\"")[1]);
  }, [props])

  const { destinationsStore: { widgets }, t } = props;

  return (
    <Fragment>
      <StyledContentContainer dangerouslySetInnerHTML={{ __html: widgets[0].code.split("<script")[0] }} />
      {widgets[0].viewMoreUrl && <MoreRidesButton onClick={() => window.location.href = widgets[0].viewMoreUrl}>{t('explore.more_attractions')}</MoreRidesButton>}
    </Fragment>
  )
}

const StyledContentContainer = styled.div`

  margin-top:40px;
  margin-bottom:40px;

`;

const MoreRidesButton = styled(TourServicesWhiteButton)`
  margin-top:25px;
  max-width:300px;
  min-height:48px;
  margin:0 auto;
  display:block;
`;


export default withTranslation()(inject("destinationsStore")(observer(Tiqets)));

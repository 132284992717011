import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { StyledCard, StyledContent, StyledPage } from "../../../components/styled/IonElements";
import { Heading3 } from "../../../components/styled/Typography";
import StickyHeader from "../../../components/common/StickyHeader";
import SingleHistoryTrip from "./SingleHistoryTrip";
import SkeletonList from "../../../components/common/ScarySkeletons/SkeletonList";
import LoggedInUser from "../LoggedInUser";
import { DesktopFlexContainer, DesktopList, DesktopRightContainer } from "../../../components/styled/Wrappers";
import { withTranslation } from "react-i18next";

class TripHistory extends Component {
    onChangeTrip = (trip) => {
        const { history } = this.props;
        history.push(`/trip/${trip.id}`);
    }

    componentDidMount() {
        const { travelerAppStore: { trips, getTrips } } = this.props;

        if (trips.length <= 0) {
            getTrips(false, false);
        }
    }

    render() {
        const { history, travelerAppStore: { loading, trips }, t } = this.props;

        return (
            <StyledPage>
                <StyledContent>
                    <DesktopFlexContainer align="flex-start">
                        <DesktopList padding="0">
                            <LoggedInUser isSide/>
                        </DesktopList>
                        {window.innerWidth < 768 && <StickyHeader goBack={() => history.push("/profile")} title={t('profile.trip_history')} subtitle="" />}
                        <DesktopRightContainer >
                            <StyledCard margin="10px" padding="60px 20px">
                                <TripHistoryComponentContainer >
                                    <Heading3 style={{ marginBottom: "45px" }}>{t('profile.trip_history')}</Heading3>
                                    {loading.get()
                                        ? <SkeletonList history />
                                        : trips.map(trip => (
                                            <SingleHistoryTrip
                                                timeZone={trip.timeZone}
                                                key={trip.id}
                                                location={trip.city}
                                                dateFrom={trip.startsOn}
                                                dateTo={trip.endsOn}
                                                onClicklFunc={() => this.onChangeTrip(trip)}
                                            />
                                        ))}
                                </TripHistoryComponentContainer>
                            </StyledCard>
                        </DesktopRightContainer>
                    </DesktopFlexContainer>
                </StyledContent>
            </StyledPage>
        )
    }
}

const TripHistoryComponentContainer = ({ children }) => { return children; }


export default withTranslation()(withRouter(inject("travelerAppStore", "destinationsStore")(observer(TripHistory))));

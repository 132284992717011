import { format, parseISO, isSameMonth, isSameYear, isSameDay } from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import dateLocales from "../../components/common/dateLocales";

const toTitleCase = (phrase) => {
    return phrase
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

/**
 * @param timeZone
 * @param date
 * @param formatStr
 */
function dateLocale(date, timeZone, formatStr = "PP") {
    let locale;

    switch (window.__dateLocaleId__) {
        case "zh-hans":
            locale = "zhCN";
            break;
        case "pt-pt":
            locale = "pt";
            break;
        default:
            locale = window.__dateLocaleId__;
    }

    return toTitleCase(
        format(convertToTimeZone(parseISO(date), { timeZone: timeZone || "Europe/Berlin" }), formatStr, {
            locale: dateLocales[locale],
        })
    );
}


const notSameMonthSameYear = (startDate, endDate) => {
    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);
    return !isSameMonth(parsedStartDate, parsedEndDate) && isSameYear(parsedStartDate, parsedEndDate)
}

const isSameDate = (startDate, endDate) => {
    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);
    return isSameMonth(parsedStartDate, parsedEndDate) && isSameDay(parsedStartDate, parsedEndDate);

}

function displayDates(locale, timeZone, startsOn = "", endsOnParam = "") {
    const endsOn = endsOnParam ? dateLocale(endsOnParam, timeZone, "d MMM yyyy") : "";


    if (notSameMonthSameYear(startsOn, endsOn)) {
        return `${dateLocale(startsOn, timeZone, "d MMM", locale)}-${endsOn}`;
    }
    if (!isSameYear(parseISO(startsOn), parseISO(endsOn))) {
        return `${dateLocale(startsOn, timeZone, "d MMM yyyy", locale)}-${endsOn}`
    }
    if (isSameDate(startsOn, endsOn)) {
        return `${dateLocale(startsOn, timeZone, "d MMM yyyy", locale)}`
    }
    return `${dateLocale(startsOn, timeZone, "d", locale)}-${endsOn}`
}

/**
 * @param find
 * @param replace
 * @param string
 */
function replaceLast(find, replace, string) {
    const lastIndex = string.lastIndexOf(find);

    if (lastIndex === -1) {
        return string;
    }

    const beginString = string.substring(0, lastIndex);
    const endString = string.substring(lastIndex + find.length);

    return beginString + replace + endString;
}

/**
* @param string
*/
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const externalLinkRedirection = (link) => {
    // trackEvent("traveler app", "external link", link);
    window.location.href = link;
};

export {
    displayDates,
    replaceLast,
    capitalizeFirstLetter,
    externalLinkRedirection
}
import React from "react";
import { withTranslation } from "react-i18next";
import TermsAndConditionsEnglish from "./TermsAndConditionsEnglish";
import TermsConditionsGreek from "./TermsAndConditionsGreek";

const TermsAndConditions = ({i18n}) => {
    // TODO: add partner id for greek translations
    return (
        i18n.language === "el" ? < TermsConditionsGreek /> : < TermsAndConditionsEnglish  />
    )
}

export default withTranslation()(TermsAndConditions);
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import StickyHeader from "../../../components/common/StickyHeader";
import { IonList } from "@ionic/react";
import { Heading3, Text1 } from "../../../components/styled/Typography";
import theme from "../../../theme";
import { StyledCard, StyledContent, StyledItem, StyledLoading, StyledPage } from "../../../components/styled/IonElements";
import SuccessDownloadModal from "./SuccessDownloadModal";
import DeleteAccountMessagesModal from "./DeleteAccountMessagesModal";
import FeedbackModal from "./FeedbackModal";
import { DesktopFlexContainer, DesktopList, DesktopRightContainer } from "../../../components/styled/Wrappers";
import LoggedInUser from "../LoggedInUser";
import { withTranslation } from "react-i18next";

class AccountAndData extends Component {
    state = {
        isDeleteModalOpen: false,
        isFaildDelitingModalOpen: false,
        deleteModalMessage: "",
        toBeDeleted: false,
        cancelAction: this.toggleDeleteModal,
        deleteAction: this.onAccept,
        feedBackModal: false
    }

    toggleDeleteModal = () => {
        this.setState(state => ({
            isDeleteModalOpen: !state.isDeleteModalOpen
        }));
    }

    willBeDeleted = () => {
        this.setState({
            deleteModalMessage: "Delete your account and all the data?",
            toBeDeleted: true,
            cancelAction: this.toggleDeleteModal,
            deleteAction: this.onAccept
        });
        this.toggleDeleteModal();
    }

    closeModal = (callback) => {
        const { travelerAppStore: { deletingAccountResponse } } = this.props;
        deletingAccountResponse.set({});
        callback();
    }

    onDownload = () => {
        const { travelerAppStore: { downloadAccountData } } = this.props;
        downloadAccountData();
    }

    goToLogin = () => {
        const { history } = this.props;

        this.setState({
            feedBackModal: false
        })
        history.push("/login")
    }

    onAccept = () => {
        const { history, travelerAppStore: { deleteAccount, deletingAccountResponse } } = this.props;
        this.toggleDeleteModal();
        deleteAccount()
            .then(() => {
                if (!!deletingAccountResponse.get().error) {
                    this.setState({
                        deleteModalMessage: "Person has an unprocessed request or already erased",
                        toBeDeleted: false,
                        cancelAction: this.toggleDeleteModal,
                        deleteAction: () => history.push("/trip")
                    });
                    this.toggleDeleteModal()
                }
                else if (!!deletingAccountResponse.get().success) {
                    this.setState({
                        feedBackModal: true
                    })
                }
            });
    }

    render() {
        const { history, travelerAppStore: { accountOperationProcessing, accountDownloadDataResponse, setAccountDownloadDataResponse }, t } = this.props;
        const { deleteModalMessage, feedBackModal, isDeleteModalOpen, toBeDeleted, cancelAction, deleteAction } = this.state;

        return (
            <StyledPage>
                {window.innerWidth < 768 && <StickyHeader goBack={() => history.push("/profile")} title={t('profile.account_and_data')} subtitle="" />}
                <StyledContent>
                    <DesktopFlexContainer align="flex-start">
                        <DesktopList padding="0">
                            <LoggedInUser isSide />
                        </DesktopList>
                        <DesktopRightContainer >
                            <StyledCard margin="10px" padding="50px 20px" height={window.innerWidth < 768 && "100%"}>
                                <StyledLoading
                                    isOpen={accountOperationProcessing.get()}
                                    message={t('please_wait')}
                                    duration={5000}
                                />

                                <SuccessDownloadModal accountDownloadDataResponse={accountDownloadDataResponse.get()} onClose={() => setAccountDownloadDataResponse(false)} ionBackButton={() => setAccountDownloadDataResponse(false)} />
                                <DeleteAccountMessagesModal isOpen={isDeleteModalOpen} message={deleteModalMessage} toBeDeleted={toBeDeleted} cancelAction={cancelAction} action={deleteAction} ionBackButton={cancelAction} />
                                <FeedbackModal isOpen={feedBackModal} onClose={this.goToLogin} />

                                <Heading3>{t('profile.account_and_data')}</Heading3>

                                <IonList>
                                    <StyledItem onClick={this.onDownload} padding="0" margin="0">
                                        <Text1 weight="500">{t('data.download_account_data')}</Text1>
                                    </StyledItem>
                                    <StyledItem onClick={this.willBeDeleted} lines="none" padding="0" margin="0">
                                        <Text1 weight="500" color={theme.colors.accent.red}>{t('data.delete_account_button')}</Text1>
                                    </StyledItem>
                                </IonList>
                            </StyledCard>
                        </DesktopRightContainer>
                    </DesktopFlexContainer>
                </StyledContent>
            </StyledPage>
        )
    }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(AccountAndData))));

import Login from ".";
import VerificationCodeInputPageEmail from "./VerificationCodeInputPageEmail";
import VerificationCodeInputPageSMS from "./VerificationCodeInputPageSMS";
import VerificationCodeInputPagePhoneOnly from "./VerificationCodeInputPagePhoneOnly";
import TransferDetails from "../Trip/TransferDetails";
// import LoginPageComponents from ".";

export default [
	{
		path: "/login",
		exact: true,
		component: Login,
	},
	{
		path: "/login/verification_code",
		exact: true,
		component: VerificationCodeInputPageEmail,
	},
	{
		path: "/login/verification_code_sms",
		exact: true,
		component: VerificationCodeInputPageSMS,
	}
	,
	{
		path: "/login/verification_code_phone_only",
		exact: true,
		component: VerificationCodeInputPagePhoneOnly,
	},
	{
		path: "/trip/:tripToken/transfer/:transferToken/live",
		exact: true,
		component: TransferDetails,
	}
];
// TODO: remove unused route for live transfer

import React, { Component } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import theme from "../../theme";
import { trackEvent } from "../../tracking";
import Header from "../../components/common/Modals/Header";
import { withTranslation } from "react-i18next";
// import ModalHeader from "./common/ModalHeader";

// const { destination: { destination } } = GonProvider.i18nTranslations.travelerApp;
class DestinationsList extends Component {

  addShadow = (e) => {
    const list = e.target;
    if (list.scrollTop > 0) {
      document.getElementById("modal-header").setAttribute("style", `box-shadow: 0 2px 4px 0 ${theme.colors.greys.grey06};`)
    } else {
      document.getElementById("modal-header").setAttribute("style", "box-shadow: unset;")
    }
  }

  onSwipe = () => {
    if (document.getElementById("dest-list").getBoundingClientRect().top < 60) {
      document.getElementById("modal-header").setAttribute("style", `box-shadow: 0 2px 4px 0 ${theme.colors.greys.grey06};`)
    } else {
      document.getElementById("modal-header").setAttribute("style", "box-shadow: unset;")
    }
  }

  redirectionAction = ({ name, id, plainName }) => {
    const { fromExplorer, onChooseCity } = this.props;

    // const { extraTransferOtherCityLink } = startNewTrip ? GonProvider : currentTrip;
    if (!fromExplorer) {
      trackEvent("traveler app", "destinations dropdown", `extra transfer link ${plainName}`);
    }
    onChooseCity({
        id,
        name
      })

    // return fromExplorer ?
    //   onChooseCity({
    //     id,
    //     name
    //   }) : externalLinkRedirection(extraTransferOtherCityLink.replace("city_plain_name", plainName));
  }

  render() {
    const { fromExplorer, fromUpcoming, travelerAppStore: { currentTrip },
      destinationsStore: { destinations },
      onClose, t } = this.props;

    const destinationsList = (fromExplorer || fromUpcoming) ? destinations : destinations.filter(({ name }) => name !== currentTrip.city)

    return (
      <StyledContainer>
        <Header title={t('explore.all_cities')} onClose={() => onClose()} ionBackButton={() => onClose()}/>
        <ul
          className="destinations-list"
          onScroll={this.addShadow}
          id="dest-list"
          onTouchMove={this.onSwipe}
        >
          {destinationsList.map(({ name, id, plainName }) =>
            (
              <li
                key={id}
                className="destination-list-item"
                onClick={() => this.redirectionAction({
                  name,
                  id,
                  plainName
                })}>
                {name}
              </li>
            )
          )
          }
        </ul>
      </StyledContainer>
    )
  }
}

const StyledContainer = styled.div`
  overflow-y: scroll;
  
  .header {
    padding: 20px 40px;
  }

  .destinations-list {
    list-style: none;
    width: 100%;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 0;
    padding: 0px 40px 16px 40px;
    overflow-y: auto;
    cursor: pointer;

    .destination-list-item {
      display: flex;
      align-items: center;
      padding: 13px 0;
      color: ${theme.colors.base.graphite};

      :first-of-type {
        padding-top: 2px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints[0]}px){
    max-height: unset;
    min-width: unset;
    .header {
      min-height: unset;
      height: 80px;
      position: sticky;
      top: 0px;
      background-color: #FFF;
      padding: 20px;
      margin: 0;
      min-width: 320px;
      max-height: 60px;
    }

    .destinations-list {
      list-style: none;
      width: 100%;
      padding: 0;
      margin-top: 0px;
      padding: 0 20px 0px 20px;
      overflow-y: auto;

    .destination-list-item {
      display: flex;
      align-items: center;
      padding: 13px 0;

      :first-of-type {
        padding-top: 13px;
        margin-top: 8px;
      }
    }
  }
  }
`;

export default withTranslation()(inject("travelerAppStore", "destinationsStore")(observer(DestinationsList)));

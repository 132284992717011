import { IonModal } from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { ButtonBase, ButtonDiscard, TourServicesWhiteButton } from "../../../components/styled/Buttons";
import { Heading4, Text2 } from "../../../components/styled/Typography";
import { FlexContainer } from "../../../components/styled/Wrappers";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/useStores";

const DeleteAccountMessagesModal = ({ isOpen, message, toBeDeleted, cancelAction, action, t }) => {
   const {brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: { mainColor }}}} = useStores();
    return (
        <StyledModal isOpen={isOpen} toBeDeleted={toBeDeleted}>
            <div style={{ padding: "25px" }}>
                {!toBeDeleted && <Heading4 style={{marginBottom: "20px", marginTop: "0"}}>{t('data.send_feedback')}</Heading4>}
                <Text2 weight="500">{message}</Text2>
                <FlexContainer align="flex-end" justify="space-between">
                    <CancelButton onClick={cancelAction}><span>{toBeDeleted ? t('data.cancel') : t('data.close')}</span></CancelButton>
                    {toBeDeleted
                        ? <ButtonDiscard onClick={action}>{t('data.delete')}</ButtonDiscard>
                        : <ButtonBase bgcolor={mainColor} onClick={action} style={{width: "120px"}}><span>{t('data.to_my_trip')}</span></ButtonBase>
                    }
                </FlexContainer>
            </div>
        </StyledModal>
    )
}

const StyledModal = styled(IonModal)`
    --height: ${props => props.toBeDeleted ? "170px" : "260px"};
    --width: 300px;
    --border-radius: 4px;
`;

const CancelButton = styled(TourServicesWhiteButton)`
    width: 96px;
    height: 48px;
    margin-top: 32px;
`;

export default withTranslation()(observer(DeleteAccountMessagesModal));
import React from "react";
import styled from "styled-components";
import closeIcon from "../../../assets/icons/close-delete-icon.svg";
import theme from "../../../theme";
import { FlexContainer } from "../../styled/Wrappers";

const ModalHeaderLayout = styled(FlexContainer)`
  width: 100%;
`;

const ModalTitle = styled.span`
  font-weight: 700;
  color: ${theme.colors.base.graphite};
  font-size: 23px;
  line-height: 26px;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${theme.colors.greys.grey05};
`;

const CloseIcon = styled.div`
  background-image: url(${closeIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`;

const Header = ({ title, onClose }) => (
  <ModalHeaderLayout className="header" id="modal-header" align="center" justify="space-between">
    <ModalTitle>{title}</ModalTitle>
    <CloseIconWrapper className="close-icon">
      <CloseIcon role="button" onClick={onClose} ionBackButton={onClose}/>
    </CloseIconWrapper>
  </ModalHeaderLayout>
);

export default Header;

import React from "react";
import styled from "styled-components";
import theme from "../../../../theme";
import { IonModal } from "@ionic/react";
import { withTranslation } from "react-i18next";
import { Heading4, Paragraph } from "../../../../components/styled/Typography";
import { ButtonBase, ButtonTabber } from "../../../../components/styled/Buttons";
import { useStores } from "../../../../stores/useStores";
import { observer } from "mobx-react";

const CurrentLocationSharing = ({ isOpen, onClose, t }) => {
    const { travelerAppStore: { setShareCurrentLocation }, brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: { mainColor } }} } = useStores();

    const onAllowSharing = () => {
        setShareCurrentLocation(true);
        onClose();
    }

    const onRejectSharing = () => {
        setShareCurrentLocation(true);
        onClose();
    }

    return (
        <CurrentLocationSharingContainer onClose={onClose} isOpen={isOpen} ionBackButton={onClose}>
            <div style={{ padding: "0 30px" }}>
                <Header>{t('live.share_your_location')}</Header>
                <Paragraph color={theme.colors.base.graphite} align="left">{t('live.share_location_description')}</Paragraph>
                <div style={{marginTop: "32px"}}>
                    <ShareButton bgcolor={mainColor} onClick={() => onAllowSharing()}><span>{t('live.share_my_location')}</span></ShareButton>
                    <CancelButton onClick={() => onRejectSharing()}>{t('data.cancel')}</CancelButton>
                </div>
            </div>
        </CurrentLocationSharingContainer>
    )
}

const Header = styled(Heading4)`
    margin: 24px 0 12px;
`;

const CurrentLocationSharingContainer = styled(IonModal)`
    --height: 322px;
    --width: 295px;
    --border-radius: 4px;
`;

const ShareButton = styled(ButtonBase)`
    display: block;
    margin-left: auto;
`;

const CancelButton = styled(ButtonTabber)`
    height: 48px;
    width: 117px;
    border: 1px solid rgba(45,59,78,0.15);
    border-radius: 4px;
    display: block;
    margin-left: auto;
    margin-top: 15px;
`;

export default withTranslation()(observer(CurrentLocationSharing));

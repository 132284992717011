import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import theme from "../../theme";
import { trackEvent } from "../../tracking";
import { IonModal, IonRefresher, IonRefresherContent, IonSkeletonText, IonToolbar } from "@ionic/react";
import { StyledCard, StyledContent, StyledPage } from "../../components/styled/IonElements";
import Section from "./Section";
import ArrowDown from "../../components/svgs/ArrowDown";
import DestinationsList from "./DestinationsList";
import { Heading2, Paragraph } from "../../components/styled/Typography";
import TourSRides from "./TourSrides";
import nothingToDoIcon from "../../assets/icons/no_s_rides.svg";
import { TourServicesWhiteButton } from "../../components/styled/Buttons"
import Tickets from "./Tickets";
import { withTranslation } from "react-i18next";
import SkeletonGrid from "../../components/common/ScarySkeletons/SkeletonGrid";

const svgStyles = {
    display: "inline-block",
    width: "20px",
    height: "20px"
};

class Explore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinationModalVisibility: false,
            color: theme.colors.base.graphite,
            noSridesButtonClicked: false
        }
    }

    onModalChangeVisibilty = (modalName) => {
        this.setState((state) => ({ [modalName]: !state[modalName] }))
    }

    componentDidMount() {
        const { destinationsStore: { destinations, fetchDestinations, getSRides } } = this.props;

        if (!destinations.length) {
            fetchDestinations().then(() => {
                getSRides(this.getCurrentCityId())
            });
            return;
        }

        getSRides(this.getCurrentCityId());
    }

    getCurrentCityId = () => {
        const { match: { params: { destinationToken } },
            travelerAppStore: { currentTrip, setCurrentCity },
            destinationsStore: { destinations } } = this.props;

        const currentCity = destinations.find((destination) => {

            if (destinationToken) {
                return destinationToken === destination.id;
            }

            return destination.name === currentTrip.city;
        });

        setCurrentCity(currentCity || destinations[0]);

        return currentCity ? currentCity.id : destinations[0].id;
    }

    onCityClick = ({ id, name }) => {
        const { travelerAppStore: { setCurrentCity },
            destinationsStore: { getSRides } } = this.props;

        this.onModalChangeVisibilty("destinationModalVisibility");
        trackEvent("traveler app", "explore", `click city ${name}`);

        setCurrentCity({
            id,
            name
        });
        getSRides(id);
        this.setState({
            color: theme.colors.base.graphite,
            noSridesButtonClicked: false
        })
    }

    styledButtonAction = (e) => {
        const { noSridesButtonClicked } = this.state;
        const { travelerAppStore: { currentCity } } = this.props;
        trackEvent("traveler app", "Unavailable Sride", currentCity.get().name);

        if (!noSridesButtonClicked) {
            window.location.href = "mailto:localexpert@welcomepickups.com";
        }

        this.setState({
            color: theme.colors.greys.grey3,
            noSridesButtonClicked: true
        });

    }

    doRefresh = (event) => {
        const { travelerAppStore: { currentCity }, destinationsStore: { getSRides } } = this.props;

        getSRides(currentCity.get().id);
    }

    render() {
        const { destinationModalVisibility, color } = this.state;
        const { travelerAppStore: { currentCity },
            destinationsStore: { destinations, destinationsloading, sRidesLoading, sRides, widgets }, t } = this.props;
        
        return (
            <StyledPage>
                <StyledContent>
                    <IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
                        <IonRefresherContent pullingIcon="lines"></IonRefresherContent>
                    </IonRefresher>

                    <IonModal
                        isOpen={destinationModalVisibility}
                        onClose={() => this.onModalChangeVisibilty("destinationModalVisibility")}
                        ionBackButton={() => this.onModalChangeVisibilty("destinationModalVisibility")}
                    >
                        <DestinationsList
                            fromExplorer
                            destinations={destinations}
                            onClose={() => this.onModalChangeVisibilty("destinationModalVisibility")}
                            onChooseCity={this.onCityClick}
                        />
                    </IonModal>

                    <StyledCard padding="15px 20px" margin="20px">
                        <StyledExplorePage>
                            <Section>
                                <div style={{ cursor: "pointer" }} onClick={() => {
                                    trackEvent("traveler app", "explore", "open dropdown");
                                    this.onModalChangeVisibilty("destinationModalVisibility");
                                }}>
                                    <Heading2>{t('explore.explore_things')}</Heading2>
                                    {destinationsloading.get ()
                                        ? <IonSkeletonText animated style={{ width: '70px' }} />
                                        : <CitiesListText>{currentCity.get().name}
                                            <ArrowDown styles={svgStyles} opacity="0.5" fill={theme.colors.base.graphite} />
                                        </CitiesListText>
                                    }
                                    
                                </div>
                            </Section>
                            {sRidesLoading.get()
                                ? <SkeletonGrid />
                                : <Fragment>
                                    {(sRides && sRides.length > 0) && <Section
                                        title={t('explore.sightseeing_rides')}
                                        subtitle={t('explore.sightseeing_subtitle')}
                                    >
                                        <TourSRides />
                                    </Section>
                                    }
                                    {widgets.length > 0 && <Section
                                        title={t('explore.attraction_ticket')}
                                    >
                                        <Tickets />
                                    </Section>}
                                    {(sRides === null || sRides.length <= 0) &&
                                        <NoRidesMessageComponent >
                                            <img alt="No Explore options" src={nothingToDoIcon} />
                                            <NothingToDoMessage  >{t('explore.no_srides_header', { city_name: currentCity.get().name})}</NothingToDoMessage>
                                            <QuestionArea>{t('explore.no_srides_text_2')}</QuestionArea>
                                            <SoundsInterestedStyledButton onClick={this.styledButtonAction}>
                                                <StyledText color={color}>{t('explore.no_srides_button_2')}</StyledText>
                                            </SoundsInterestedStyledButton>
                                        </NoRidesMessageComponent>
                                    }
                                </Fragment>
                            }
                        </StyledExplorePage>
                    </StyledCard>

                </StyledContent>
            </StyledPage>
        )
    }
}

const CitiesListText = styled(Paragraph)`
    text-align: left;
    display:flex;
`;

const StyledText = styled.span`
  font-weight:600;
  font-size: 14px;
  color:${(props) => props.color};
`;

const SoundsInterestedStyledButton = styled(TourServicesWhiteButton)`
  margin-top:25px;
  max-width:240px;
  min-height:48px;
  display:block;
`;


const NoRidesMessageComponent = styled.div`
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  font-weight: 700;
  margin-top:64px;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-bottom:34px;
  @media (max-width: ${theme.breakpoints[0]}px){
    margin-top: 10px;
  }
`;


const NothingToDoMessage = styled.div`
	min-height: 62px;
  width: 240px;
	color: ${theme.colors.greys.grey5};
	font-size: 24px;
	line-height: 31px;
	text-align: center;
  word-break:break-word;
  margin-top:40px;

  @media (max-width: ${theme.breakpoints[0]}px){
    width: 100%;
  }
`;


const QuestionArea = styled.div`
  margin-top:25px;
  min-height: 63px;
  width: 240px;
  color: ${theme.colors.greys.grey5};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  word-break:break-word;

  @media (max-width: ${theme.breakpoints[0]}px){
    width: 100%;
  }
`;

const StyledExplorePage = styled(IonToolbar)`
  
  
 `;

export default withTranslation()(inject("travelerAppStore", "destinationsStore")(observer(Explore)));

import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";
import Edit from "../../components/svgs/Edit";
import { Heading3 } from "../../components/styled/Typography";
import { useRouteMatch } from "react-router-dom";

import AutoCheckErrors from "../../components/common/autoCheckErrors";
import Avatar from "../../components/common/Avatar";
const svgStyles = {
	display: "inline-block",
	marginLeft: "8px",
	marginBottom: "-2px",
	height: "20px",
	width: "20px"
};

const ShortProfileInfo = (props) => {
	const { travelerAppStore: { profileInformation,travelerAutocheckErrors,removeTravelerError }, isExpanded, history } = props;
	const { photoUrl, phoneOrigin, firstName, lastName, contactEmail } = profileInformation;
  const isDetailsPage = useRouteMatch("/profile/edit");
  const sidebarCheck = isDetailsPage && isDetailsPage.isExact ? true : false

	return (
    <Fragment>
		<StyledAccount onClick={() => history.push("/profile/edit")}>
			{photoUrl && (
				<Avatar
					src={photoUrl}
					alt="userAvatar"
					margin="0 auto"
				/>
			)}

			<Heading3>
				{`${firstName} ${lastName}`}

				{!isExpanded && (
					<Edit
						styles={svgStyles}
						fill={theme.colors.base.graphite}
						opacity="1" />
				)}
			</Heading3>

			<p>{`${phoneOrigin}`}</p>

			<p>{contactEmail}</p>

		</StyledAccount>
     <AutoCheckErrors
          autocheckErrorList={travelerAutocheckErrors}
          alertsAreShownIn="Profile"
          removeError={removeTravelerError}
          isDetailsPage={sidebarCheck}
          errors={true}
    /></Fragment>
	)
}

const StyledAccount = styled.div`
	padding: 20px;

	h3 {
		font-size: 24px;
		font-weight: 700;
		margin: 10px 0 10px;
		color: ${theme.colors.base.graphite};
		text-align: center;
	}

	p {
		color: ${theme.colors.greys.grey5};
		margin: 0;
		font-size: 14px;
		line-height: 21px;
		word-break: break-word;
		text-align: center;
	}
`;

export default withRouter(inject("travelerAppStore")(observer(ShortProfileInfo)));

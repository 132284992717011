import { observable } from "mobx";
import TravelerApi from "../API";
import { set as setStorage, get as getStorage} from "../services/storage.ts";

class DestinationsStore {
  constructor(){
    // Read destinations count from localStorage
    if (!this.destinationsCount.get()) {
      getStorage('destinationsCount').then((count) => this.setDestinationsCount(count));
    }
  }

  destinationsloading = observable.box(false);

  sRidesLoading = observable.box(false);

  sRides = observable([]);

  widgets = observable([]);

  destinations = observable([]);

  destinationsCount = observable.box(null);

  setSRidesLoading = (status) => this.sRidesLoading.set(status);

  setDestinationsLoading = (status) => this.destinationsloading.set(status);

  setDestinationsCount = (destinationsCount) => {
    this.destinationsCount.set(destinationsCount)
  }

  async setDestinations(destinations) {
    this.destinations = destinations;
    await setStorage('destinationsCount', destinations.length);
    this.setDestinationsCount(destinations.length);
  };

  fetchDestinations = () => {
    this.setDestinationsLoading(true);
    return TravelerApi.getDestinations()
      .then((destinationsRes) => {
        this.setDestinations(destinationsRes);
        this.setDestinationsLoading(false);
      })
      .catch((err) => {
        this.setDestinationsLoading(false);
        console.error(err);
        throw err;
      })
  }

  getSRides = (cityId) => {
    this.setSRidesLoading(true);
    return TravelerApi.getExploreSuggestions(cityId)
      .then((result) => {
        this.sRides.replace(result.services);
        this.widgets.replace(result.widgets);
        this.setSRidesLoading(false);
      })
      .catch((err) => {
        this.setSRidesLoading(false);
        console.error(err);
        throw err;
      });
  }

}
const destinationsStore = new DestinationsStore();

export default destinationsStore;

import React from "react";

const ArrowDown = ({ fill, opacity, styles }) => (
  <svg style={styles} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>See More</title>
    <defs>
      <filter x="-40.0%" y="-40.0%" width="180.0%" height="180.0%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={opacity}>
      <g id="01.11.01.D.Trip" transform="translate(-387.000000, -256.000000)">
        <g id="Main_card" transform="translate(300.000000, 84.000000)">
          <g id="My_trip.Hero.Section-/-Mobile">
            <g id="Trip_selector">
              <g transform="translate(40.000000, 136.000000)">
                <g id="Icon" filter="url(#filter-1)" transform="translate(47.000000, 36.000000)">
                  <g id="Arrow.Minimal.Down_Icon">
                    <rect id="Bounds" x="0" y="0" width="20" height="20" />
                    <path d="M9.9888851,10.6620437 L6.16544034,7.25354897 C5.75318682,6.88603641 5.12106152,6.92230613 4.75354897,7.33455966 C4.38603641,7.74681318 4.42230613,8.37893848 4.83455966,8.74645103 L9.32151955,12.746451 C9.69992111,13.0837855 10.2708785,13.0846071 10.6502493,12.748363 L15.1632894,8.74836296 C15.5765989,8.38203832 15.6146876,7.75002001 15.248363,7.33671056 C14.8820383,6.92340111 14.25002,6.8853124 13.8367106,7.25163704 L9.9888851,10.6620437 Z" id="Fill" fill={fill} />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowDown;

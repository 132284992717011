/* eslint-disable max-statements */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import OtpInput from 'react-otp-input';

const CODE_LENGTH = 6;

class VerificationCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
  }

  handleChange = (value) => {
    const { value: oldValue } = this.state;
    const { travelerAppStore: { setLoginError } } = this.props;
    setLoginError({});
    
    if (oldValue.length > CODE_LENGTH) {
      return;
    }

    // If user selects another input from the one they are supposed to, the focus will return to the right one.
    // It works only when user types and not when deletes
    if (value.length > oldValue.length && value.length < CODE_LENGTH) {
      const currentOtpInput = document.getElementsByClassName('otp-input')[value.length];
      currentOtpInput.focus();
    }

    this.setState({
      value
    }, () => 
      {
        if (value.length === CODE_LENGTH) {
          this.onLogin();
        }
      });
  };

  onLogin = () => {
    const { login } = this.props;
    const { value } = this.state;
    
    return value.length === CODE_LENGTH && login(value);
  }

  render() {
    const { value } = this.state;

    return (
        <OtpInput
          value={value}
          onChange={this.handleChange}
          numInputs={CODE_LENGTH}
          inputStyle="otp-input"
          focusStyle={{
            zIndex: 100,
          }}
          containerStyle={{ justifyContent: 'center' }}
          isInputNum={true}
          shouldAutoFocus={true}
        />
    )
  }
}

export default inject("travelerAppStore")(observer(VerificationCodeInput));
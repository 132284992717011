import styled from "styled-components";
import theme from "../../theme";

const CustomInput = styled.input`
  padding: 15px 16px;
  border: 1px solid ${theme.colors.greys.grey15};
  border-radius: 4px;
  color: ${theme.colors.base.graphite};
  appearance:none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  outline: none;
   
  border: ${props => props.error ? "2px solid rgba(242,116,112,1)" : `1px solid ${theme.colors.greys.grey15}`};
  color: ${props => props.error ? "rgba(242,116,112,1)" : `${theme.colors.base.graphite}`};
  
  ::placeholder {
    font-size: 16px;
    color: ${theme.colors.greys.grey5};
    display:flex;
    flex-direction:column;
  }
`;

export {
    CustomInput
}
import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import SkeletonDetails from "../../../components/common/ScarySkeletons/SkeletonDetails";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import LiveLocation from "./LiveLocation";
import StaticDetails from "./StaticDetails";
import { isPlatform } from "@ionic/react";

const isMobile = isPlatform('mobile') || isPlatform('mobileweb');

class TransferDetails extends PureComponent {
  // TODO: remove unused reference
  transferDetailsContainer = React.createRef();

  state = {
    driverInfoModalVisibility: false,
    meetingPointModalVisibility: false,
    showHeader: false
  }
  // TODO: add loading?

  componentDidMount() {
    const {
      travelerAppStore: { getTrips, chosenTransfer, setChosenTransfer, setLoadingState },
      match: { params: { tripToken, transferToken } },
      location
    } = this.props;

    const { history } = this.props;
    const oldTripOrNoChosenTransfer = !chosenTransfer.id || !transferToken;
    // Add param to enforce fetching of trips/transfer
    const fetch = location?.state?.fetch;

    if (oldTripOrNoChosenTransfer || fetch) {

      getTrips(tripToken).then((transfers) => {
        setLoadingState(true);
        const transfer = transferToken ? transfers.find((chosenTransfer) => {
          return chosenTransfer.id === transferToken
        }) : transfers[0];
        if (!transfer) { history.push(`/trip/${tripToken}`); } else {
          setChosenTransfer(transfer);
          setLoadingState(false);
        }
      }).catch(() => {
        history.push(`/trip/${tripToken}`);
      });
    }
  }

  render() {
    const { travelerAppStore: { loading, chosenTransfer }} = this.props;
    const showLiveView = chosenTransfer.showLiveView && chosenTransfer.operatorConfirmedDriver;

    const DetailsPage = showLiveView && isMobile ? <LiveLocation/> : <StaticDetails/>;

    return (
      loading.get()
        ? <SkeletonDetails />
        : DetailsPage
    )
  }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(TransferDetails))));

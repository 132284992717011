import React from "react";
import Map from "../../../../components/common/Map";

const MapHeader = ({transfer: { id, ...otherProps }, transferDetailsContainer, children}) => (
    <Map
        parentRef={transferDetailsContainer}
        id={`map${id}`}
        {...otherProps}
    >
			{ children }
    </Map>
)

export default MapHeader;
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import defaultAvatar from "../../../assets/icons/avatar.svg";
import styled from "styled-components";
import theme from "../../../theme";
import { IonActionSheet, IonCheckbox, IonItem, IonLoading, IonToast } from "@ionic/react";
import { StyledCard, StyledContent, StyledInput, StyledPage } from "../../../components/styled/IonElements";
import { Heading3, Paragraph, Text2, ErrorMessage } from "../../../components/styled/Typography";
import PhoneInput from "react-phone-input-2";
import { ButtonBase } from "../../../components/styled/Buttons";
import { DesktopFlexContainer, DesktopList, DesktopRightContainer, FlexContainer } from "../../../components/styled/Wrappers";
import StickyHeader from "../../../components/common/StickyHeader";
import LoggedInUser from ".";
import { withTranslation } from "react-i18next";
import { Plugins, CameraResultType } from '@capacitor/core';
import Avatar from '../../../components/common/Avatar';
import { isMobileAppPlatform, isIosMobileApp } from "../../../components/common/helpers";
import { Camera as CordovaCamera } from '@awesome-cordova-plugins/camera';

const { Camera } = Plugins;

// const { personalInfo: { changesSaved, personalInformation, firstName: fn, lastName: ln, phoneNumber, email, emailUpdates, emailOffers, saveChanges } } = GonProvider.i18nTranslations.travelerApp;

class Edit extends Component {
    constructor(props) {
        super(props);
        const { travelerAppStore: { profileInformation: { firstName, lastName, phoneOrigin, contactEmail, consentBookingEmails, consentPromotionsEmails, photoUrl } } } = this.props;

        const errors = {};

        this.state = {
            changesToast: "",
            firstName,
            lastName,
            phoneOrigin,
            contactEmail,
            consentBookingEmails,
            consentPromotionsEmails,
            photoUrl,
            type: "personal_info",
            errors,
            showPhotoOptions: false
        }
    }

    componentDidMount() {
        const { travelerAppStore: { profileInformation, fetchProfileInformation } } = this.props;

        if (profileInformation.firstName === undefined) {
            fetchProfileInformation()
                .then(() => {
                    const { firstName, lastName, phoneOrigin, contactEmail, consentBookingEmails, consentPromotionsEmails } = profileInformation;

                    this.setState({
                        firstName,
                        lastName,
                        phoneOrigin,
                        contactEmail,
                        consentBookingEmails,
                        consentPromotionsEmails
                    })
                });


        }
    }

    onInputChange = (event) => {

        const { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    onCheckboxBookingEmails = (value) => {
        this.setState({
            consentBookingEmails: value
        });
    }

    onCheckboxPromotionsEmails = (value) => {
        this.setState({
            consentPromotionsEmails: value
        });
    }

    handleValidation = () => {

        let tempErrorsObject = {};
        let errorsFound = false;

        // TODO:Re-code handle validation.

        /**
   * @param key
   * @param message
   */
        function markError(key, message) {
            tempErrorsObject = {
                ...tempErrorsObject,
                [key]: message
            }
            errorsFound = true;
        }


        const { firstName, lastName, phoneOrigin, contactEmail } = this.state;

        Object.entries({ firstName, lastName, phoneOrigin, contactEmail }).forEach(([key, val]) => {

            if (val === "") {
                markError(key, "Required Field");
            }
        })


        if (phoneOrigin === "+" || phoneOrigin.legth < 6) {
            markError("phoneOrigin", "Wrong phone number");
        }
        this.setState({ errors: tempErrorsObject });

        return errorsFound;
    }

    async convertImageDataToFile(imageUrl) {
        const { travelerAppStore: { profileInformation } } = this.props;
        try {
            const response = await fetch(imageUrl);
            const blobPhoto = await response.blob();
            const photo = new File([blobPhoto], `${profileInformation.id}.jpeg`, {
                type: blobPhoto.type
            });

            return Promise.resolve(photo);
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    selectPhoto = async (sourceType) => {
        const { travelerAppStore: { updateProfilePictureAction } } = this.props;

        try{
            if (isMobileAppPlatform()) {
                // On mobile devices use the cordova plugin for camera
                this.setState({showPhotoOptions: false});
                const cameraOptions = {
                    quality: 90,
                    destinationType: CordovaCamera.DestinationType.DATA_URL,
                    encodingType: CordovaCamera.EncodingType.JPEG,
                    correctOrientation: true,
                    sourceType: sourceType || 0
                }
                
                const imageUrl = await CordovaCamera.getPicture(cameraOptions);

                const photo = await this.convertImageDataToFile(`data:image/jpeg;base64,${imageUrl}`);
                await updateProfilePictureAction(photo);

                this.setState({ changesToast: 'Profile picture saved successfully'})
            } else {
                // On desktop devices use the capacitor plugin for camera.
                // This can also work for mobile devices but we had some problems in android
                const image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: isIosMobileApp() ? true : false,
                    resultType: CameraResultType.DataUrl
                });
    
                const photo = await this.convertImageDataToFile(image.dataUrl);
                await updateProfilePictureAction(photo);
    
                this.setState({ changesToast: 'Profile picture saved successfully'});
            }
        } catch(error) {
            console.log(error)
        }
    }

    onSubmit = () => {

        if (this.handleValidation()) return;

        const { travelerAppStore: { updateProfileDataAction } } = this.props;

        let formData = {};

        const { firstName, lastName, phoneOrigin, contactEmail, consentBookingEmails, consentPromotionsEmails} = this.state;

        Object.entries({ firstName, lastName, phoneOrigin, contactEmail, consentBookingEmails, consentPromotionsEmails }).forEach(([key, val]) => {
            formData = {
                ...formData,
                [key]: key === "phoneOrigin" ? val.replace(/[- ]/g, "") : val
            }
        })

        updateProfileDataAction(formData)
            .then(() => {
                this.setState({
                    changesToast: "Saved successfully"
                })
            })
            .catch((e) => {

                this.setState({
                    ...this.state,
                    errors: {
                        ...this.errors,
                        phoneOrigin: e.response.data.errors[0]
                    }
                })
            });
    }

    // createImageInputRef = (imageInput) => { this.imageInput = imageInput; }

    onPhoneInputChange = (phoneOrigin) => {
        this.setState({
            phoneOrigin: `${`+${phoneOrigin}`}`
        });
    }

    render() {
        const { history, travelerAppStore: { profileInfoLoading, setProfileInfoLoading, profileInformation }, t, brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: { mainColor } } } } = this.props;
        const { changesToast, firstName, lastName, phoneOrigin, contactEmail, consentBookingEmails, consentPromotionsEmails, errors } = this.state;

        const image = profileInformation.photoUrl || defaultAvatar;

        return (
            <StyledPage>
                {window.innerWidth < 768 && <StickyHeader goBack={() => history.push("/profile")} title={t('personal_info.personal_information')} subtitle="" />}
                <StyledContent>
                    <DesktopFlexContainer align="flex-start">
                        <DesktopList padding="0">
                            <LoggedInUser isSide />
                        </DesktopList>
                        <DesktopRightContainer >
                            <StyledCard margin="10px" padding="38px 40px" mobileMarginTop="inherit">
                                <IonToast
                                    isOpen={changesToast !== ""}
                                    onDidDismiss={() => this.setState({ changesToast: "" })}
                                    message={changesToast}
                                    duration={3000}
                                    cssClass="styled-toast"
                                />
                                <IonLoading
                                    cssClass='loading-modal'
                                    isOpen={profileInfoLoading.get()}
                                    onDidDismiss={() => setProfileInfoLoading(false)}
                                    message={t('please_wait')}
                                    duration={5000}
                                />
                                <Heading3 style={{ textAlign: 'center' }}>{t('personal_info.personal_information')}</Heading3>
                                <Avatar
                                    size="100px"
                                    margin="0px auto"
                                    src={image}
                                    alt="user"
                                    onClick={() => isMobileAppPlatform() ? this.setState({showPhotoOptions: true}) : this.selectPhoto()}
                                />
                                <Text2
                                    style={{
                                        textAlign: 'center',
                                        margin: '4px 0 32px 0',
                                        cursor:'pointer'
                                    }}
                                    onClick={this.selectPhoto}
                                >
                                    {t('alert.edit_button')}
                                </Text2>

                                <Paragraph margin="20px 0 0 0" weight="700" align="left">{t('personal_info.first_name')}</Paragraph>
                                <StyledInput value={firstName} name="firstName" onIonChange={this.onInputChange} error={errors.firstName}></StyledInput>
                                {errors.firstName && <AlertContent>{errors.firstName}</AlertContent>}

                                <Paragraph margin="20px 0 0 0" weight="700" align="left">{t('personal_info.last_name')}</Paragraph>
                                <StyledInput value={lastName} name="lastName" onIonChange={this.onInputChange} error={errors.lastName}></StyledInput>
                                {errors.lastName && <AlertContent>{errors.lastName}</AlertContent>}

                                <Paragraph margin="20px 0 0 0" weight="700" align="left">{t('personal_info.phone_number')}</Paragraph>
                                <PhoneInput country="gr"
                                    onChange={this.onPhoneInputChange}
                                    value={phoneOrigin}
                                    onKeyDown={this.onKeyDown}
                                    error={errors.phoneOrigin}
                                    autoFormat={false}
                                    type="phoneOrigin"
                                    inputStyle={{
                                        height: "50px",
                                        border: errors.phone ? "1px solid rgba(242,116,112,1)" : `1px solid ${theme.colors.greys.grey15}`,
                                        color: "#2D3B4E",
                                        width: "100%"
                                    }}
                                    buttonStyle={{
                                        background: "white"
                                    }}
                                    pattern="\d*"
                                />
                                {errors.phoneOrigin && <AlertContent>{errors.phoneOrigin}</AlertContent>}

                                <Paragraph margin="20px 0 0 0" weight="700" align="left">{t('personal_info.email')}</Paragraph>
                                <StyledInput value={contactEmail} name="contactEmail" onIonChange={this.onInputChange} error={errors.contactEmail}></StyledInput>

                                <CheckboxContainers>
                                    <StyledItem lines="none">
                                        <Text2>{t('personal_info.email_updates')}</Text2>
                                        <Checkbox color={mainColor} name="consentBookingEmails" slot="start" onClick={() => this.onCheckboxBookingEmails(!consentBookingEmails)} checked={consentBookingEmails} />
                                    </StyledItem>
                                    <StyledItem lines="none">
                                        <Text2>{t('personal_info.email_offers')}</Text2>
                                        <Checkbox color={mainColor} name="consentPromotionsEmails" slot="start" onClick={() => this.onCheckboxPromotionsEmails(!consentPromotionsEmails)} checked={consentPromotionsEmails} />
                                    </StyledItem>
                                </CheckboxContainers>

                                <FlexContainer justify="flex-end">
                                    <SubmitButton bgcolor={mainColor} onClick={() => this.onSubmit()}><span>{t('personal_info.save_changes')}</span></SubmitButton>
                                </FlexContainer>
                            </StyledCard>
                        </DesktopRightContainer>
                    </DesktopFlexContainer>
                </StyledContent>

            {
                isMobileAppPlatform() && this.state.showPhotoOptions && (
                    <IonActionSheet
                        isOpen={this.state.showPhotoOptions}
                        mode="ios"
                        onDidDismiss={() => this.setState({ showPhotoOptions: false })}
                        buttons={[
                            {
                                text: 'Take new photo',
                                handler: () => this.selectPhoto(CordovaCamera.PictureSourceType.CAMERA),
                            },
                            {
                                text: 'Select from gallery',
                                handler: () => this.selectPhoto(CordovaCamera.PictureSourceType.SAVEDPHOTOALBUM),
                            },
                            {
                                text: 'Dismiss',
                                role: 'cancel'
                            },
                        ]}
                    />
                )
            }
            </StyledPage>
        )
    }
}

const StyledItem = styled(IonItem)`
    --ripple-color: transparent;
    --background-hover: transparent;

    --item-native {
        padding: 0;
        margin: 0;
    }

    @media (max-width: ${theme.breakpoints[0]}px){
        margin: auto;
    }
`;

const Checkbox = styled(IonCheckbox)`
    --background: transparent;
    --border-color-checked: transparent;
    --border-color: ${theme.colors.greys.grey15};
    --background-checked: ${props => props.color};
    --checkmark-color: ${theme.colors.base.white};
    --transition: none;
    --border-radius: 4px;
    margin: 0;
    margin-right: 10px;
    --ripple-color: transparent;
    --background-hover: transparent;
`;

const SubmitButton = styled(ButtonBase)`
    margin: 20px 0;
`;

const AlertContent = styled(ErrorMessage)`
	margin: 10px 0px 15px 0px;
`;

const CheckboxContainers = styled.div`
    margin: 15px -14px;
`;


export default withTranslation()(withRouter(inject("travelerAppStore", "brandedSettingsStore")(observer(Edit))));

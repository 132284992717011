import React from "react";
import styled from "styled-components";
import { FlexContainer } from "../../../../components/styled/Wrappers";
import theme from "../../../../theme";

const Bullets = ({ photos }) => (
    <Container align="center" justify="center">
        {(photos).map((photo, index) => (
            <Bullet key={index}> </Bullet>
        ))}
    </Container>
)

export default Bullets;

const Container = styled(FlexContainer)`
    position: absolute;
    width: 100%;
    bottom: 10px;
`;

const Bullet = styled.div`
    width: 7px;
    height: 7px;
    background: ${theme.colors.base.white5};
    border-radius: 5px;
    margin: 0 2px;
`;
import React from "react";
import theme from "../../theme";
import { FullHeightOverlay, HeaderBottomFlexContainer } from "../../components/styled/Wrappers";
import bookImage from "../../assets/book-page.jpg";
import poweredByWhite from "../../assets/branding/poweredByLight.svg";
import bookImage2x from "../../assets/book-page2x.jpg";
import { HeaderLogo, PoweredByWhiteImg } from "../../components/styled/Images";
import { StyledIonToolbar } from "../../components/styled/IonElements";
import { Heading2, Paragraph } from "../../components/styled/Typography";
import { withTranslation } from "react-i18next";
import HeaderRightContainer from "../../components/common/HeaderRightContainer";
import { useStores } from "../../stores/useStores";
import { observer } from "mobx-react";

const Header = ({ t }) => {
    const { destinationsStore: { destinationsCount }, brandedSettingsStore: { isPartner, brandedSettings: { brandLogoForTravelerAppUrl } } } = useStores();
    const showPoweredBy = isPartner && window.innerWidth < 768;

    return (
        <StyledIonToolbar image={window.innerWidth > 768 ? bookImage2x : bookImage} height={window.innerWidth < 768 && "218px"}>
            <FullHeightOverlay />
            <HeaderLogo src={brandLogoForTravelerAppUrl} maxWidth={showPoweredBy ? "70px" : "100%"}/>
            <HeaderRightContainer />
            <HeaderBottomFlexContainer align="flex-end" justify={showPoweredBy ? "space-around" : "flex-start"}>
                <div>
                    <Heading2 color={theme.colors.base.white}>{t('book.book_a_transfer')}</Heading2>
                    {
                        destinationsCount.get() && (
                            <Paragraph align="left" color={theme.colors.base.white}>
                                {t('book.in_destinations', { number_of_cities: destinationsCount.get() })}
                            </Paragraph>
                        )
                    }
                </div>
                {(showPoweredBy) && <PoweredByWhiteImg src={poweredByWhite} alt="powered by Welcome Pickups"/>}
            </HeaderBottomFlexContainer>
        </StyledIonToolbar>
    )
}

export default withTranslation()(observer(Header));
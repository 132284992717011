import React, { useEffect, useState } from "react";
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterOutlet, IonContent } from '@ionic/react';
import luggageIco from "../../../assets/menu-tabs/luggage_icon.svg";
import luggageIcoBlack from "../../../assets/menu-tabs/luggage_icon_black.svg";
import exploreIcoBlack from "../../../assets/menu-tabs/explore_icon_black.svg";
import personIcoBlack from "../../../assets/menu-tabs/person_icon_black.svg";
import transferIcoBlack from "../../../assets/menu-tabs/transfer_icon_black.svg";
import messagesIconBlack from "../../../assets/menu-tabs/messages_icon_black.svg";
import personIco from "../../../assets/menu-tabs/person_icon.svg";
import exploreIco from "../../../assets/menu-tabs/explore_icon.svg";
import transferIco from "../../../assets/menu-tabs/transfer_icon.svg";
import messagesIcon from "../../../assets/menu-tabs/messages_icon.svg";
import messagesIconRed from "../../../assets/menu-tabs/messages_icon_red.svg";
import styled from "styled-components";
import theme from "../../../theme";
import { Route, withRouter } from "react-router-dom";
import Routes from "../../../routes";
import { useStores } from "../../../stores/useStores";
import { observer } from "mobx-react";
import { Text2 } from "../../styled/Typography";
import loginRoutes from "../../../pages/Login/routes";
import LanguageSelector from "../LanguageSelector";
import { withTranslation } from "react-i18next";
import PoweredBy from "../../svgs/PoweredBy";
import { PoweredByLogoWrapper } from "../../styled/Wrappers";
import { MenuLogo } from "../../styled/Images";
import "./styles.scss";

const hiddenMenuRoutes = loginRoutes.map(route => route.path);
let hideTabTimeout = null;

const Menu = ({ location: { pathname }, t }) => {
    const {
      travelerAppStore: { showExploreBadge, shouldHideTabs, isAuthorised },
      messagesStore: { showNewMessagesBadge },
      brandedSettingsStore: { getCoBrandedHelpCenterUrl, isPartner, brandedSettings: { brandLogoUrl, partnerId, coBrandingThemeProperties: { mainColor } } }
    } = useStores();
    const authorised = isAuthorised.get();
    const pathnameArray = pathname.split('/');
    const redirectLogoUrl = partnerId === 7580 && "https://layover.aegeanair.com";
    const [hideTabs, setHideTabs] = useState(false);
    const willHideTabs = shouldHideTabs && pathnameArray[pathnameArray.length-2]==="transfer";

    const newMessage = showNewMessagesBadge.get() && !(pathnameArray[1] === "messages");
    
    useEffect(() => {
        if(willHideTabs) {
            hideTabTimeout = setTimeout(() => {
                setHideTabs(true);
            }, 3000)
        }
        else {
            setHideTabs(false);
            clearTimeout(hideTabTimeout);
        }

    }, [pathname, willHideTabs])

    return (
        <IonContent key={showExploreBadge.get()}>
            {
                loginRoutes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                ))
            }
            { (!hiddenMenuRoutes.includes(pathname) && pathnameArray[pathnameArray.length-1] !== "live") && (
                <CustomTabs bordercolor={mainColor} >
                    <IonRouterOutlet>
                        <Routes />
                    </IonRouterOutlet>
                    <IonTabBar slot={window.innerWidth < theme.breakpoints[1] ? "bottom" : "top"} className={willHideTabs ? "hide-tabs" : ""} style={{display: hideTabs && "none"}}>
                        {window.innerWidth > theme.breakpoints[1] && <IonTabButton style={{ position: "absolute", left: "20px", width: "200px", maxWidth: "300px" }} href={redirectLogoUrl}>
                            <MenuLogo alt="logo" src={brandLogoUrl} />
                        </IonTabButton>}
                        <IonTabButton tab="book" href="/book" style={{ maxWidth: "90px" }}>
                            <IonIcon icon={pathnameArray[1] === "book" ? transferIcoBlack : transferIco} />
                            <MenuLabel isActive={pathnameArray[1] === "book"}>{t('book.menu_button')}</MenuLabel>
                        </IonTabButton>

                        <IonTabButton tab="trip" href="/trip" style={{ maxWidth: "90px" }}>
                            <IonIcon icon={pathnameArray[1] === "trip" ? luggageIcoBlack : luggageIco} />
                            <MenuLabel isActive={pathnameArray[1] === "trip  "}>{t('my_trip.trip')}</MenuLabel>
                        </IonTabButton>

                        <IonTabButton tab="explore" href="/explore" style={{ maxWidth: "90px" }}>
                            <IonIcon icon={pathnameArray[1] === "explore" ? exploreIcoBlack : exploreIco} />
                            { showExploreBadge.get() && <SmallNotificationBadge/>}
                            <MenuLabel isActive={pathnameArray[1] === "explore"}>{t('my_trip.explore')}</MenuLabel>
                        </IonTabButton>

                        { authorised &&
                          <IonTabButton tab="messages" href="/messages" style={{ maxWidth: "90px" }}>
                            <IonIcon icon={pathnameArray[1] === "messages" ? messagesIconBlack : newMessage ? messagesIconRed : messagesIcon} />
                            { showNewMessagesBadge.get() && !(pathnameArray[1] === "messages") && <SmallNotificationBadge/>}
                            <MenuLabel isActive={pathnameArray[1] === "messages"} hasNotification={newMessage} >{t('messages.menu_button')}</MenuLabel>
                          </IonTabButton>
                        }

                        <IonTabButton tab="profile" href="/profile" style={{ maxWidth: "80px" }}>
                            <IonIcon icon={pathnameArray[1] === "profile" ? personIcoBlack : personIco} />
                            <MenuLabel isActive={pathnameArray[1] === "profile"}>{t('my_trip.profile')}</MenuLabel>
                        </IonTabButton>


                        {window.innerWidth > theme.breakpoints[1] &&
                            <IonTabButton style={{ position: "absolute", right: isPartner ? "260px" : "70px", width: "auto" }} >
                                <LanguageSelector />
                            </IonTabButton>}
                        {window.innerWidth > theme.breakpoints[1] &&
                            <IonTabButton style={{ position: "absolute", right: isPartner ? "210px" : "20px", width: "200px", maxWidth: "55px" }} >
                                <Text2><a href={getCoBrandedHelpCenterUrl} target="_blank" rel="noopener noreferrer">{t('my_trip.help')}</a></Text2>
                            </IonTabButton>}
                        {window.innerWidth > theme.breakpoints[1] && isPartner &&
                            <IonTabButton style={{ position: "absolute", right: "20px", maxWidth: "177px", top: "-7px" }} >
                                <PoweredByLogoWrapper align="center" justify="flex-end" style={{ marginTop: 0 }}> <PoweredBy fill={theme.colors.base.graphite} /></PoweredByLogoWrapper>
                            </IonTabButton>}
                    </IonTabBar>
                </CustomTabs>
            )}
        </IonContent>
    );
}

export default withTranslation()(withRouter(observer(Menu)));

/*const NotificationBadge = styled(IonBadge)`
    height: 12px;
    width: 10px;
    margin-left: 5px;
    margin-top: -5px;
`;*/

const SmallNotificationBadge = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #F27470;
  position: absolute;
  margin-bottom: 35px;
  margin-left: 28px;
`;

const CustomTabs = styled(IonTabs)`
    padding: 5px 0;
    ion-tab-button {
        border-bottom: 3px solid transparent;
        &.tab-selected {
            color: ${theme.colors.greys.grey5};
            border-bottom: 3px solid ${props => props.bordercolor};
        }
        --color-selected: ${theme.colors.base.graphite};

        a:hover {
            color: red !important;
        }
    }
`;

const MenuLabel = styled(IonLabel)`
    color: ${props => props.isActive && theme.colors.base.graphite};
    color: ${props => !props.isActive && props.hasNotification && '#F27470'};
`;

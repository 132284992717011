import axios from "axios";
import {deserialize, serialize} from "./ApiSerializer";

const apiUrl = process.env.REACT_APP_API_URL;

const convertToFormData = (serializedObject) => {
  const formData = new FormData();
  Object.entries(serializedObject).forEach(([key, val]) => {
    formData.append(key, val);
  });
  return formData;
};

const baseAPI = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

baseAPI.interceptors.response.use(
  ({data}) => deserialize(data)
  // manageErrors
);

class TravelerApi {
  getPersonalInfo = () => baseAPI.get("/v1/traveler/personal_info");

  updatePersonalInfo = (updatedInfo) => {
    return baseAPI.patch(
      "/v1/traveler/personal_info",
      convertToFormData(serialize(updatedInfo).data.attributes),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/vnd.api+json",
        },
      }
    );
  };

  updateProfilePicture = (photo) => {
    return baseAPI.patch(
      "/v1/traveler/personal_info/photo",
      convertToFormData({photo}),
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/vnd.api+json",
        },
      }
    )
  };

  setAuthenticationHeaders = (token) => {
    baseAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  resetAuthenticationHeaders = () => {
    baseAPI.defaults.headers.common["Authorization"] = null;
  };

  getTrips = () => baseAPI.get("/v1/traveler/trips/");

  getTripById = (tripId) => baseAPI.get(`/v1/traveler/trips/${tripId}`);

  getTransferById = (transferId) =>
    baseAPI.get(`/v1/traveler/transfers/${transferId}`);

  getTourById = (tourId) => baseAPI.get(`/v1/traveler/tours/${tourId}`);

  getDestinations = () => baseAPI.get("/v1/destinations");

  getExploreSuggestions = (destinationId) => {
    // TODO: get locale
    return baseAPI.get(`/v1/destinations/${destinationId}/explore?locale=en`);
  };

  loginWithToken = (token) => {
    const formData = new FormData();
    formData.append("token", token);

    return baseAPI.post("/v1/traveler/auth/traveler", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  deleteAccount = () => baseAPI.post("/v1/traveler/erase_data_request");

  downloadAccountData = () =>
    baseAPI.post("/v1/traveler/download_data_request");

  codeRequest = (emailOrPhone) =>
    baseAPI.post("/v1/traveler/auth/code", emailOrPhone, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

  login = (loginData) => baseAPI.post("/v1/traveler/auth/login", loginData);

  changeLocale = (locale) => {
    const formData = new FormData();
    formData.append("locale", locale);
    return baseAPI.patch("/v1/traveler/locales", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  resolveTravelerAutochekError = (errorId) => {
    return baseAPI.patch(`/v1/traveler/autocheck_errors/${errorId}/resolve`);
  };

  resolveAutochekError = (errorId, transferId) => {
    const formData = new FormData();
    formData.append("autocheck_error_id", errorId);
    return baseAPI.patch(
      `/v1/traveler/transfers/${transferId}/resolve_autocheck_error`,
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  logout = () => baseAPI.post("/v1/traveler/auth/logout");

  getBrandedSettings = () => baseAPI.get("/v1/branded_settings");

  registerDevice = (uid, fcm_token, device_type) => {
    const formData = new FormData();
    formData.append("uid", uid);
    if (fcm_token) {
      formData.append("fcm_token", fcm_token);
    }
    formData.append("device_type", device_type);
    return baseAPI.post("/v1/traveler/devices", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  createChatUser = () => {
    return baseAPI.post("/v1/traveler/chats/users/new");
  };

  createChat = (transfer) => {
    const formData = new FormData();

    const resourceType =
      typeof transfer.transferType === "undefined"
        ? "TourTransfer"
        : "Transfer";

    formData.append("resource_id", transfer.id);
    formData.append("resource_type", resourceType);

    return baseAPI.post("/v1/traveler/chats/new", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };

  trackEvent = (eventData) => {
    const formData = convertToFormData(serialize(eventData).data.attributes);
    return baseAPI.post(
      "/v1/traveler/traveler_app_events",
      formData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
      }
    );
  }
}

export default new TravelerApi();

export const getSRides = () => {
  // return sRides;
};


import { IonCard, IonContent, IonInput, IonItem, IonLoading, IonPage, IonToolbar } from '@ionic/react';
import styled from 'styled-components';
import theme from '../../theme';

const StyledCard = styled(IonCard)`
  max-width: 600px;
  margin: ${props => props.margin ? `${props.margin} auto` : "0 auto"};
  padding: ${props => props.padding || "20px"};
  height: ${props => props.height || "auto"};

  @media (max-width: 768px) {
    margin-top: ${props => props.mobileMarginTop || 0};
    height: ${props => props.height || "auto"};
  }
`;

const StyledPage = styled(IonPage)`
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
`;

const StyledContent = styled(IonContent)`
  --background: #F4F5F6;
  height: 100%;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
`;

const StyledIonToolbar = styled(IonToolbar)`
  --background: url('${props => props.image}') no-repeat center/cover !important;
  --min-height: 218px;
  max-width: 600px;
  max-height: 218px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: ${props => props.marginBottom || "-20px"};
  z-index: 0;
  height: ${props => props.height || "240px"};

  @media (max-width: 768px) {
    margin-top: 0;
    border-radius: 0;
  }
`;

const StyledItem = styled(IonItem)`
  margin: ${props => props.margin ? props.margin : "20px 0"};
  padding: ${props => props.padding ? props.margin : "0 20px"};
  --padding-start: 0;
  --min-height: ${props => props.isMinHeight && "21px"};
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-color: ${theme.colors.greys.grey05};
  
  ${props => props.showBorder && `
        :after {
        content: "";
        background: ${theme.colors.greys.grey2};
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 93%;
        margin: 0 3%;
    }`
    }
`;

const StyledInput = styled(IonInput)`
  padding: 15px 16px;
  border: 1px solid ${theme.colors.greys.grey15};
  border-radius: 4px;
  color: ${theme.colors.base.graphite};
  appearance: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  outline: none;
  margin-top: 10px;
  z-index:0;
`;

const StyledLoading = styled(IonLoading)`
`;

export {
    StyledCard,
    StyledContent,
    StyledIonToolbar,
    StyledItem,
    StyledInput,
    StyledLoading,
    StyledPage
}
import React from "react";

const Tick = ({ fill="#fff"}) => (
<svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-332.000000, -630.000000)">
            <g id="Small" transform="translate(24.000000, 471.000000)">
                <g id="Normal" transform="translate(258.000000, 159.000000)">
                    <g id="Tick.Icon.20" transform="translate(50.000000, 0.000000)">
                        <rect id="Bounds" x="0" y="0" width="20" height="20"></rect>
                        <path d="M16.2926892,4.29309725 C16.6831009,3.90246031 17.3162658,3.90227764 17.7069028,4.29268925 C18.0674907,4.6530692 18.0953839,5.22029223 17.7904722,5.61267142 L17.7073108,5.70690275 L8.00781452,15.4119972 L2.76956372,10.2095258 C2.37770343,9.8203421 2.37553364,9.18718084 2.76471735,8.79532055 C3.12396386,8.43360337 3.69109656,8.40393015 4.08443075,8.70760891 L4.17892261,8.79047419 L8.002,12.588 L16.2926892,4.29309725 Z" id="Fill" fill={fill}></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Tick;

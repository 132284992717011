import React from "react";

const ArrowRight = ({ fill }) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01.11.01.D.Trip" transform="translate(-840.000000, -562.000000)">
        <g id="Main_card" transform="translate(300.000000, 84.000000)">
          <g id="Transfer.Block" transform="translate(40.000000, 475.000000)">
            <g id="Icon" transform="translate(500.000000, 3.000000)">
              <g id="Arrow.Minimal.Right_Icon">
                <rect id="Bounds" x="0" y="0" width="20" height="20" />
                <path d="M11.1620549,10.0111261 L7.75164827,6.16330067 C7.38532363,5.74999122 7.42341235,5.11797292 7.8367218,4.75164827 C8.25003125,4.38532363 8.88204955,4.42341235 9.24837419,4.8367218 L13.2483742,9.3497619 C13.5846183,9.72913269 13.5837967,10.3000901 13.2464623,10.6784917 L9.24646227,15.1654516 C8.87894972,15.5777051 8.24682441,15.6139748 7.83457089,15.2464623 C7.42231737,14.8789497 7.38604765,14.2468244 7.7535602,13.8345709 L11.1620549,10.0111261 Z" id="Fill" fill={fill} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowRight;

import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import SightSeeingImage from "./SightSeeingHeader";
import Driver from "./Driver";
import Section from "./Section";
import MeetingPoint from "./MeetingPoint";
import InRideInfo from "./MeetingPoint/InRideInfo";
import Products from "./Products";
import GreyFooter from "../../../components/common/GreyFooter";
import GreyFooterItem from "../../../components/common/GreyFooter/GreyFooterItem";
import Edit from "../../../components/svgs/Edit";
import Close from "../../../components/svgs/Close";
import theme from "../../../theme";
import { withRouter } from "react-router-dom";
import { StyledCard, StyledItem } from "../../../components/styled/IonElements";
import { IonModal } from "@ionic/react";
import DriverDetailsModal from "./Driver/DriverDetailsModal";
import { withTranslation } from "react-i18next";
import { onExternalLinkClick } from "../../../components/common/ExternalLink/ExternalLink";
import { externalLinkRedirection } from "../helpers";
import MapHeader from "./StaticDetails/MapHeader";
import TransferCard from "../List/TransferCard";
import { ButtonDiscard } from "../../../components/styled/Buttons";
import TimelineModal from "./Timeline/TimelineModal";

const isSightSeeingRide = ({ transferType }) => typeof transferType === "undefined";

class CommonDetails extends PureComponent {
    transferDetailsContainer = React.createRef();
    firstImageRef = React.createRef();

    state = {
        driverInfoModalVisibility: false,
        meetingPointModalVisibility: false,
        timelineModalVisibility: false,
        showHeader: false,
    }

    componentDidMount() {
      if(this.props.forwardedRef) {
        this.props.forwardedRef(this);
      }
    }

    onTransferAction = async (makeRedirection, link, event) => {
        event.preventDefault();
        if (makeRedirection) {
            // trackEvent("traveler app", "grey footer link", link);
            const isWebLink = await onExternalLinkClick(event, link);
            if (isWebLink) {
                externalLinkRedirection(link);
            }
        }
    }

    onChangeModalVisibility = (modalStateName) => {
        this.setState(state => {
            return {
                [modalStateName]: !state[modalStateName],
            }
        });
    }

    hideTimelineModal = () => {
      this.setState({ timelineModalVisibility: false })
    }

    onMeetingPointClick = () => {
      this.firstImageRef.current.click();
    }

    render() {
        const { hideMap = false, travelerAppStore: { chosenTransfer }, t } = this.props;
        const { driverInfoModalVisibility, timelineModalVisibility } = this.state;
        const isSRide = isSightSeeingRide(chosenTransfer);
        const isTns = chosenTransfer.isTns;
        const meetingPointWithPhotos = chosenTransfer.meetingPoint && chosenTransfer.meetingPoint?.photos?.length > 0

        return (
            <div>
                {!hideMap && (isSRide ?
                  <SightSeeingImage />
                  :
                  <MapHeader transfer={chosenTransfer} transferDetailsContainer={this.transferDetailsContainer} >
                    { isTns &&
                        <RideTnsButton onClick={() => { this.onChangeModalVisibility("timelineModalVisibility")}}>
                          <span style={{ fontWeight: 500, color: 'inherit', paddingRight: '10px'}}>{t('live.transfer_terminated')}</span> <span style={{ fontWeight: 700, color: 'inherit'}}>{t('trip_details.details')}</span>
                        </RideTnsButton>
                    }
                    { isTns && <TimelineModal isOpen={timelineModalVisibility} onClose={this.hideTimelineModal}/>}
                  </MapHeader>
                  )
                }
                <StyledCard padding="0">
                    <TransferCard
                      errors
                      handleClick={() => { }}
                      transfer={chosenTransfer}
                      arrowVisible={false}
                      showErrorsInDetails={true}
                      isTourDetailsButtonVisible={isSRide}
                    />
                    <StyledItem padding="0" margin="0" >
                        <Driver onChangeModalVisibility={this.onChangeModalVisibility} />
                        <DriverModal isOpen={driverInfoModalVisibility} >
                            <DriverDetailsModal onChangeModalVisibility={this.onChangeModalVisibility} />
                        </DriverModal>
                    </StyledItem>
                    <StyledItem padding="0">
                        <Section handleClick={this.onMeetingPointClick} drilldown={meetingPointWithPhotos} title={t('trip_details.meeting_point')} >
                            <MeetingPoint forwardedRef={this.firstImageRef} chosenTransfer={chosenTransfer} />
                        </Section>
                    </StyledItem>
                    <StyledItem lines="none" padding="0">
                        <Section title={t('my_trip.details')}>
                            <InRideInfo chosenTransfer={chosenTransfer} isSRide={isSRide} />
                        </Section>
                    </StyledItem>
                    {(!isSRide &&
                        chosenTransfer.products.length) ?
                        (<Section title={t('trip_details.products_ordered')}>
                            <Products products={chosenTransfer.products} />
                        </Section>)
                        : null
                    }
                </StyledCard>
                {((!isSRide && !chosenTransfer.isFinished && (chosenTransfer.isEditable || chosenTransfer.isCancelable)) &&
                    < GreyFooterContainer >
                        < GreyFooter >
                            {chosenTransfer.isEditable &&
                                <StyledLink
                                    disabled={!chosenTransfer.isEditable}
                                    href={chosenTransfer.isEditable ? chosenTransfer.editLink : "#"}
                                    onClick={(e) => this.onTransferAction(chosenTransfer.isEditable, chosenTransfer.editLink, e)}
                                >
                                    <GreyFooterItem
                                        text={chosenTransfer.isEditable ? t('trip_details.edit_this_transfer') : t('trip_details.late_edit')}
                                        ico={Edit}
                                        disabled={!chosenTransfer.isEditable}
                                    />
                                </StyledLink>}
                            {chosenTransfer.isCancelable &&
                                <StyledLink
                                    href={chosenTransfer.cancelLink}
                                    onClick={(e) => this.onTransferAction(true, chosenTransfer.cancelLink, e)}
                                >
                                    <GreyFooterItem
                                        text={t('trip_details.cancel_this_transfer')}
                                        ico={Close}
                                    />
                                </StyledLink>}
                        </GreyFooter>
                    </GreyFooterContainer>
                )
                }
            </div>
        )
    }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(CommonDetails))));

const GreyFooterContainer = styled.div`
  height:128px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  z-index: 0;

	@media (max-width: ${theme.breakpoints[0]}px){
    background-color: #E9EBED;
    padding: 20px 0px 20px 0px;
  }
`;

const DriverModal = styled(IonModal)`
  --max-width: 340px;
  --height: 100%;
  --max-height: 610px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    --max-height: 610px;
    --border-radius: 5px;
  }
`;

const StyledLink = styled.a`
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  color: ${props => props.disabled ? theme.colors.greys.grey3 : theme.colors.greys.grey5};

  :visited {
    pointer-events:  ${props => props.disabled ? "none" : "auto"};
    color: ${props => props.disabled ? theme.colors.greys.grey3 : theme.colors.greys.grey5};
  }
`;

const RideTnsButton = styled(ButtonDiscard)`
    height: 38px;
    padding: 0 10px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, 0);
`;

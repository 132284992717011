import {action, decorate, observable, runInAction, set} from "mobx";

import TravelerApi from "../API";
import {trackEvent} from "../tracking";
import {
  filterSplittedTransfer,
  getActiveTrip,
  getActiveTrips,
  sortTransfersByDate,
} from "./helpers";
import i18n from "../services/i18n.js";
import {set as setStorage} from "../services/storage.ts";
import brandedSettingsStore from "./brandedSettingsStore";
import messagesStore from "./messagesStore";
import {isPlatform} from "@ionic/react";
// import { setCookie, externalLinkRedirection, GonProvider } from "../../../helpers";
// configure({ enforceActions: "observed" });
/* eslint-disable max-statements */
// import { sortTransfersByDate, filterSplittedTransfer, getActiveTrips, getActiveTrip } from "./utilsForActions";

class TravelerAppStore {
  /* OBSERVABLE PROPERTIES */

  trips = observable.array([]);

  activeTrips = observable([]);

  transfers = observable.array([]);

  transfersAutocheckErrors = observable.object({});
  travelerAutocheckErrors = observable.array([]);

  loading = observable.box(false);

  profileInfoLoading = observable.box(false);

  openMeetingPoint = observable.box(false);

  error = null;

  chosenTransfer = observable.object({});

  destinations = [];

  currentTrip = observable({});

  cityImage = null;

  showExploreBadge = observable.box(false);

  promoVisiblePopupMyTrip = observable.box(false);

  // Traveler info
  isAuthorised = observable.box(false);

  travelerToken = observable.box("");

  travelerInfo = observable.object({});

  /*
		id
		access_token
		locale
		chat_user
		app_store_links: {
			{
				promo_android_store_link
				promo_apple_store_link
				popup_android_store_link
				popup_apple_store_link
			}
		}
	*/

  profileData = {};

  locale = observable.box("");

  firebaseToken = observable.box("");

  rollbar = null;

  setLocale = async (locale) => {
    this.locale.set(locale);
    i18n.changeLanguage(locale);
    await setStorage("locale", locale);
    // If traveler changed his locale save it in API
    if (this.isAuthorised.get() && this.travelerInfo.locale !== locale) {
      TravelerApi.changeLocale(locale).then(async (data) => {
        this.travelerInfo.locale = locale;
        await setStorage("travelerInfo", this.travelerInfo);
      });
    }
  };

  profileInformation = observable.object({});

  shareCurrentLocation = observable.box(null);

  currentCity = observable.box({});

  // siblingTransfers = observable.object({});

  accountOperationProcessing = observable.box(false);

  accountDownloadDataResponse = observable.box(false);

  deletingAccountResponse = observable.box({});

  codeRequestError = observable.box({});

  loginError = observable.box({});

  authEndpointProcessing = observable.box(false);

  /* NON OBSERVABLE PROPERTIES */

  secondsUntilResend = observable({
    value: 0,
    timer: null,
  });

  timerCompleted = observable.box(true);

  /* SETTERS */
  setTravelerInfo = (travelerInfo) => {
    this.isAuthorised.set(true);
    this.travelerToken.set(travelerInfo.accessToken);
    this.travelerInfo = travelerInfo;
    this.setLocale(travelerInfo.locale);
    this.setTravelerInfoToRollbar(travelerInfo);
    TravelerApi.setAuthenticationHeaders(travelerInfo.accessToken);
  };

  resetTravelerInfo = () => {
    this.isAuthorised.set(false);
    this.travelerToken.set("");

    TravelerApi.resetAuthenticationHeaders();

    this.travelerInfo = {};
    this.resetTravelerInfoToRollbar();
    this.resetTravelerData();
  };

  resetTravelerData = () => {
    this.trips.clear();
    this.activeTrips.clear();
    this.resetCurrentTrip();
    this.profileInformation = {};
  };

  setSiblingTransfers = (siblingTransfers) => {
    this.siblingTransfers = siblingTransfers;
  };

  setCurrentCity = (currentCity) => this.currentCity.set(currentCity);

  setCurrentTrip = (trip) => {
    runInAction(() => {
      // TODO: we merge trip summary with full trip when we fetch full trip
      // Shouldn't we replace the trip?

      const currentTripFromTrips = this.trips.find(item => item.id === trip.id) || {};
      // currentTrip = trip from the trip list + new trip extra info
      set(this.currentTrip, {...currentTripFromTrips, ...trip});
      if (trip.branded) {
        brandedSettingsStore.setBrandedSettings(trip.brandedSettings);
      } else {
        brandedSettingsStore.resetBrandedSettings();
      }
      if (trip.travelerAutocheckErrors) {
        this.setTravelerAutocheckErrors(trip.travelerAutocheckErrors);
      } else {
        this.setTravelerAutocheckErrors([]);
      }
    });
  };

  resetCurrentTrip = () => {
    runInAction(() => {
      // TODO: check if current trip is reset correctly
      if (this.currentTrip.branded) {
        brandedSettingsStore.resetBrandedSettings();
      }
      this.currentTrip = {};
      this.setTravelerAutocheckErrors([]);
    });
  };

  setTransfersAutocheckErrors = (transfersAutocheckErrors) => {
    this.transfersAutocheckErrors = transfersAutocheckErrors;
  };

  setTravelerAutocheckErrors = (travelerAutocheckErrors) => {
    this.travelerAutocheckErrors.replace(travelerAutocheckErrors);
  };

  removeTravelerError = (id) => {

    const filteredTravelerAutocheckErrors = this.travelerAutocheckErrors.filter(item => item.id !== id);
    this.setTravelerAutocheckErrors(filteredTravelerAutocheckErrors);
  };
  removeTransferError = (id, transferId) => {

    const newTransferErrors = this.transfersAutocheckErrors[transferId].filter(item => item.id !== id);

    this.transfersAutocheckErrors[transferId] = newTransferErrors;
   };

  setShowExploreBadge = (state) => {
    this.showExploreBadge.set(state);
  };

  setPromoVisiblePopupMyTrip = (state) => {
    this.promoVisiblePopupMyTrip.set(state);
  };

  setOpenMeetingPoint = (state) => {
    this.openMeetingPoint.set(state);
  };

  setProfileInfoLoading = (status) => this.profileInfoLoading.set(status);

  setChosenTransfer = (chosenTransfer) => {
    this.chosenTransfer = chosenTransfer;
  };

  setProfileInformation = (props) => {
    if (props.autocheckErrors) {
      this.setTravelerAutocheckErrors(props.autocheckErrors);
    } else {
      this.setTravelerAutocheckErrors([]);
    }
    set(this.profileInformation, props);
  };
  setTrips(receivedTrips) {
    this.trips.replace(receivedTrips);
  }

  setShareCurrentLocation = (state) => {
    this.shareCurrentLocation.set(state);
    setStorage("shareCurrentLocation", state);
  };

  setTransfers(receivedTransfers) {
    this.transfers.replace(receivedTransfers);

    //Check for Autocheck errors

    const errors = {};
    if (receivedTransfers) {
      receivedTransfers.forEach((transfer) => {
        let errorList = [];

        transfer.autocheckErrors &&
          transfer.autocheckErrors.forEach((errorNode, index) => {
            if (errorNode.appliesTo === "transfer") {
              errorList.push(errorNode);
            }
          });
        errors[transfer.id] = errorList;
      });
      this.setTransfersAutocheckErrors(errors);
    }
  }

  // setHeaderTab = (chosenTab) => { this.headerChosenTab = chosenTab; }

  setAccountOperationProcessingStatus = (status) =>
    this.accountOperationProcessing.set(status);

  setAccountDownloadDataResponse = (status) =>
    this.accountDownloadDataResponse.set(status);

  setCodeRequestError = (status) => {
    this.codeRequestError.set(status);
  };

  setLoginError = (status) => {
    this.loginError.set(status);
  };

  setAuthEndpointProcessing = (status) => {
    this.authEndpointProcessing.set(status);
  };

  // switchHeaderTab = (headerTabIndex) => {
  //  this.headerChosenTab = headerTabIndex;
  // }

  // setExplorePopupState = (value) => { this.showExplorePopupState.set(value) }

  // setBadgeState = (value) => { this.showBadgeState.set(value) }

  setActiveTrips = (activeTrips) => {
    this.activeTrips.replace(activeTrips);
  };

  /* METHODS */

  setLoadingState = (loadingState) => {
    this.loading.set(loadingState);
  };

  getTripTransfers = (tripId) => {
    // this.setLoadingState(true);
    return TravelerApi.getTripById(tripId)
      .then((trip) => {
        const {transfers, tourTransfers, cityPhotoUrls} = trip;
        this.setCurrentTrip(trip);

        // FIXME: Here we have all the required data to show 'at least' something. Should we defer the next promises?
        // TODO: Add the photo based on the x2 x3 etc.
        this.cityImage = cityPhotoUrls.view3X;
        const transferFetchOperations = Object.keys(
          transfers
        ).map((transferIndex) =>
          TravelerApi.getTransferById(transfers[transferIndex].id)
        );
        const toursFetchOperations = Object.keys(
          tourTransfers
        ).map((transferIndex) =>
          TravelerApi.getTourById(tourTransfers[transferIndex].id)
        );
        const allTypeTransfersFetchOperations = transferFetchOperations.concat(
          toursFetchOperations
        );
        return Promise.all(allTypeTransfersFetchOperations);
      })
      .then((tripTransfers) => {
        const filteredSplittedTransfers = filterSplittedTransfer(
          tripTransfers,
          this.setSiblingTransfers
        );
        const sortedTransfers = sortTransfersByDate(filteredSplittedTransfers);
        this.setTransfers(sortedTransfers);

        this.setLoadingState(false);
        return tripTransfers;
      })
      .catch((err) => {
        // TODO: reset traveler info
        this.isAuthorised.set(false);
        this.setLoadingState(false);
        throw err;
      });
  };

  // Get trips needs to be revised and implemented differently.
  // Transfers logic needs to be seperated from get trips and only be executed inside of each component that needs transfers.
  getTrips = (tripToken, downLoadTransfers = true) => {
    this.setLoadingState(true);
    return TravelerApi.getTrips()
      .then((trips) => {
        this.setTrips(trips);
        const activeTrips = getActiveTrips(trips);
        this.setActiveTrips(activeTrips);

        if (!downLoadTransfers) {
          // throw new Error("");
        }

        const activeTrip = getActiveTrip(activeTrips);
        if (activeTrip) {
          this.setCurrentTrip(activeTrip);
        }

        const currentTrip = tripToken
          ? trips.find((trip) => {
              return trip.id === tripToken;
            })
          : activeTrip;

        if (!currentTrip && tripToken) {
          return activeTrip.id;
        }

        if (currentTrip) {
          if (!activeTrip) {
            this.setCurrentTrip(currentTrip);
          }
          return currentTrip.id;
        }

        return null;
      })
      .then((currentTripId) => {
        if(!currentTripId) {
          this.setLoadingState(false);
          return [];
        }

        return this.getTripTransfers(currentTripId);
      })
      .catch((err) => {
        this.setLoadingState(false);
        throw err;
      });
  };

  getTripsActiveNumber = () => {
    this.setLoadingState(true);

    return TravelerApi.getTrips()
      .then((trips) => {
        return getActiveTrips(trips).length;
      })
      .catch((err) => {
        this.setLoadingState(false);
        throw err;
      });
  };

  setTimelineData = (transferId, timelineData) => {
    if (this.chosenTransfer.id === transferId) {
      this.chosenTransfer.timelineData = timelineData;
    }
    const transfer = this.transfers.find(
      (transfer) => transfer.id === transferId
    );
    if (transfer) {
      transfer.timelineData = timelineData;
    }
  };

  fetchProfileInformation = () => {
    // TODO: Add loading state
    this.setProfileInfoLoading(true);
    return TravelerApi.getPersonalInfo()
      .then((data) => {
        this.setProfileInformation(data);
      })
      .catch((error) => {
        throw error;
      })
      .then(() => {
        this.setProfileInfoLoading(false);
      });
  };

  updateProfileDataAction = (profileData) => {
    // TODO: Add loading state
    this.setProfileInfoLoading(true);
    return TravelerApi.updatePersonalInfo(profileData)
      .then((travelerProfileInfo) => {
        this.setProfileInfoLoading(false);
        this.setProfileInformation(travelerProfileInfo);

        trackEvent("traveler app", "profile", "update success");
      })
      .catch((error) => {
        this.setProfileInfoLoading(false);
        trackEvent("traveler app", "profile", "update error");
        throw error;
      });
  };

  updateProfilePictureAction = async (picture) => {
    this.setProfileInfoLoading(true);
    return TravelerApi.updateProfilePicture(picture)
      .then((data) => {
        const { photoUrl } = data;

        if (photoUrl) {
          this.setProfileInformation({...this.profileInformation, photoUrl});
        }
      })
      .catch((error) => {
        console.log('error', error)
        trackEvent("traveler app", "profile", "update error");
        throw error;
      })
      .finally(() => this.setProfileInfoLoading(false));
  }

  deleteAccount = () => {
    this.setAccountOperationProcessingStatus(true);
    return TravelerApi.deleteAccount()
      .then((result) => {
        this.setAccountOperationProcessingStatus(false);
        this.deletingAccountResponse.set({success: result});
        trackEvent("traveler app", "delete account", "success");
      })
      .catch((err) => {
        this.setAccountOperationProcessingStatus(false);
        this.deletingAccountResponse.set({error: err.response.data.error});
        trackEvent("traveler app", "delete account", "error");
      });
  };

  downloadAccountData = () => {
    this.setAccountOperationProcessingStatus(true);
    TravelerApi.downloadAccountData()
      .then((result) => {
        this.setAccountOperationProcessingStatus(false);
        this.setAccountDownloadDataResponse(result.success);
        trackEvent("traveler app", "download account data", "success");
      })
      .catch((err) => {
        this.setAccountDownloadDataResponse({error: err.response.data.error});
        this.setAccountOperationProcessingStatus(false);
        trackEvent("traveler app", "download account data", "error");
        throw err;
      });
  };

  codeRequest = (emailOrPhone) => {
    this.setAuthEndpointProcessing(true);
    return TravelerApi.codeRequest(emailOrPhone)
      .then(({hasValidPhone, phoneNumber}) => {
        this.setCodeRequestError({});
        this.setAuthEndpointProcessing(false);
        return {
          hasValidPhone,
          phoneNumber,
        };
      })
      .catch((error) => {
        this.setCodeRequestError({error: error.response.data.error});
        this.setAuthEndpointProcessing(false);
        throw error;
      });
  };

  logout = () => {
    return TravelerApi.logout()
      .then((data) => {
        // const { fromMobileApp } = GonProvider;
        // const suffix = fromMobileApp ? "?logout_callback=true" : ""
        // externalLinkRedirection(`/traveler_app/login${suffix}`);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  login = (loginData) => {
    return TravelerApi.login(loginData)
      .then((data) => {
        // TODO: handle these cases in web/mobileweb cases
        // if (window.gon.external_domain) {
        //  setCookie("travelers_access_token", accessToken, 30, window.gon.external_domain);
        // }
        this.clearTimer();
        this.setLoginError({});
        return data;
      })
      .catch((error) => {
        this.setLoginError({error: error.response.data.error});
        trackEvent("traveler app", "login error", error.response.data.error);
        throw error;
      });
  };

  createChatForTransfer = async (transfer) => {
    let getChatUser = null;
    if (this.travelerInfo.chatUser) {
      getChatUser = Promise.resolve(this.travelerInfo.chatUser);
    } else {
      getChatUser = TravelerApi.createChatUser().then(async (chatUser) => {
        this.travelerInfo.chatUser = chatUser;
        await messagesStore.connect(chatUser);

        return chatUser;
      });
    }

    return getChatUser.then((chatUser) => {
      return TravelerApi.createChat(transfer).then((chat) => {
        const foundTransfer = this.transfers.find(
          (trans) => trans.id === transfer.id
        );
        if (foundTransfer) {
          foundTransfer.channelToken = chat.channelToken;
        }
        if (this.chosenTransfer.id === transfer.id) {
          this.chosenTransfer.channelToken = chat.channelToken;
        }
        return chat;
      });
    });
  };

  keepTrackOfCountDown = (seconds) => {
    this.clearTimer();
    this.timerCompleted.set(false);
    this.secondsUntilResend.value = seconds;
    this.secondsUntilResend.timer = setInterval(() => {
      if (this.secondsUntilResend.value > 0) {
        this.secondsUntilResend.value = this.secondsUntilResend.value - 1;
      } else {
        this.clearTimer();
        this.timerCompleted.set(true);
      }
    }, 1000);
  };

  clearTimer = () => {
    clearInterval(this.secondsUntilResend.timer);
    this.secondsUntilResend.value = 0;
  };

  setRollbar = (rollbar) => {
    this.rollbar = rollbar;
  };

  setTravelerInfoToRollbar = (traveler) => {
    if (this.rollbar) {
      this.rollbar.configure({
        payload: {
          person: {
            id: traveler.id,
          },
        },
      });
    }
  };

  resetTravelerInfoToRollbar = (traveler) => {
    if (this.rollbar) {
      this.rollbar.configure({
        payload: {
          person: {
            id: null,
          },
        },
      });
    }
  };

  // changeLocale = (code) => {
  //  const { isCurrentTravelerAuthorised } = GonProvider;
  //  if (isCurrentTravelerAuthorised) {
  //    const form = new FormData();
  //    form.append("locale", code);
  //    return TravelerApi.changeLocale(form).then((response) => {

  //      if (response && response.success) {
  //        externalLinkRedirection(window.location.href);
  //      }

  //    })
  //  }

  //    setCookie("locale", code, 1000, "")
  //    window.location.reload()

  // }
  // TODO: replace with store variable that the transfer details component will set/unset
  get shouldHideTabs() {
    return (
      this.chosenTransfer?.showLiveView &&
      this.chosenTransfer?.operatorConfirmedDriver &&
      (isPlatform("mobile") || isPlatform("mobileweb"))
    );
  }
}

decorate(TravelerAppStore, {
  cityImage: observable,
  transfersAutocheckErrors:observable,
  travelerAutocheckErrors: observable,
  currentTrip: observable,
  showExploreBadge: observable,
  trips: observable,
  transfers: observable,
  loading: observable,
  profileInfoLoading: observable,
  error: observable,
  promoVisiblePopupMyTrip: observable,
  chosenTransfer: observable,
  profileInformation: observable,
  setProfileInformation: action,
  setChosenTransfer: action,
  profileData: observable,
});

const travelerAppStore = new TravelerAppStore();
export default travelerAppStore;

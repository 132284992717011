import React, {Fragment, useState} from "react";
import {withRouter} from "react-router-dom";
import {observer, inject} from "mobx-react";
import {IonSpinner, IonItem} from "@ionic/react";
import "./styles.scss";
import styled from "styled-components";
import theme from "../../../theme";
import TravelerApi from "../../../API";
import Tick from "../../../components/svgs/Tick";
import EditOutline from "../../../components/svgs/EditOutline";
import {FlexContainer} from "../../styled/Wrappers";
import {trackEvent} from "../../../tracking";
import {onExternalLinkClick} from "../ExternalLink/ExternalLink";
import {withTranslation} from "react-i18next";
import history from "../history";

const ActionButton = styled(IonItem)`
  background: ${(props) =>
    props.kind === "primary"
      ? theme.colors.cta.primary
      : theme.colors.base.white};
  cursor: pointer;
  font-weight: bold;
  opacity: 1;
  padding:0px 8px;
  text-align: center;
  box-sizing: border-box;
  flex: 0 0 50%;
  justify-content: center!important;;
  max-width: calc(50% - 6px);
  --color-hover: #fff;
  --background: none;
  --inner-border-width: 0;
  --opacity-hover: 0.8;
  --padding-top:0;

  border-radius: 4px;
  display: flex;
  border:1px solid ${(props) =>
    props.kind === "primary"
      ? theme.colors.cta.primary
      : theme.colors.base.graphite};
  flex-flow:row wrap;


  @media (max-width: 767px) {
    max-width:100%;
    flex:0 0 100%;

    padding:0px 4px;
    &:first-child{ margin:0; margin-bottom:12px;}
  }
`;

const ActionButtonContainer = styled(FlexContainer)`
  flex-flow:row wrap;
  margin-top:18px;
  justify-content:space-between;
  width: 100%;
`;
const IconWraper = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  top: 0;
  right: ${(props) => (props.status === true ? "15px" : "auto")};
  margin: auto;
  max-width: 20px;
  height: auto;
  flex:0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

`;
const ItalicText = styled.em`
  display: block;
  margin-top: 10px;
  font-style:normal;
  font-size:12px;
  color: ${theme.colors.accent.base};
`;
const ActionText = styled.span`
  text-align: center;
  color: ${(props) => props.kind === "primary" ? theme.colors.base.white : theme.colors.base.graphite};
  width:100%;
  font-size: 14px;
  line-height:18px;
  display: ${(props) => (props.hide ? "none" : "block")};
  @media (max-width: 767px) {
    opacity: 1;
    font-size: 16px;
    line-height:18px;
  }
`;


const AlertComponent = ({
  editText,
  alertsAreShownIn,
  link,
  validateText,
  splittedMessageAndValue,
  callback = () => {},
  onValidatePress,
  isLoading,
  order,
  isDetailsPage,
  points,
  subtitleMessage,
}) => {
  if (link) {
    return (
      <div color="danger" className="message">
        <p className="error-message">
          {splittedMessageAndValue[0]}
          {splittedMessageAndValue[1] && (
            <span className="wrong-data">{`: ${splittedMessageAndValue[1]}`}</span>
          )}
          {subtitleMessage && <ItalicText>{subtitleMessage}</ItalicText>}
        </p>
        {link && (
          <ActionButtonContainer>
            <ActionButton
              className="ion-justify-content-center"
              onClick={onValidatePress}
              kind="primary"
              position="fixed"
            >
              {isLoading.open === true && isLoading.key === order ? (
                <IonSpinner className="white-spinner" name="crescent" />
              ) : (
                <Fragment>
                  <IconWraper status={isDetailsPage}>
                    <Tick fill="#fff" />
                  </IconWraper>

                  <ActionText kind="primary" hide={isDetailsPage}>{validateText}</ActionText>
                </Fragment>
              )}
            </ActionButton>

            {points === "internal" ? (
              <ActionButton
                className="ion-justify-content-center"
                position="floating"
                onClick={(e) => {
                  history.push("/profile/edit")
                }}
                kind="secondary"
                detail="false"
              >
                {" "}
                <IconWraper status={isDetailsPage}>
                  <EditOutline />
                </IconWraper>
                <ActionText hide={isDetailsPage}>{editText}</ActionText>
              </ActionButton>
            ) : (
              <ActionButton
                className="ion-justify-content-center"
                href={link}
                onClick={(e) => {
                  trackEvent("traveler app", "external link", link);
                  if (callback) {
                    callback(e);
                  }
                  onExternalLinkClick(e, link);
                }}
                kind="secondary"
                detail="false"
              >
                {" "}
                <IconWraper status={isDetailsPage}>
                  <EditOutline fill="#2d3b4e" />
                </IconWraper>
                <ActionText hide={isDetailsPage}>{editText}</ActionText>
              </ActionButton>
            )}
          </ActionButtonContainer>
        )}
      </div>
    );
  }
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
      }}
      className="message"
    >
      <p className="error-message">
        {splittedMessageAndValue[0]}
        {splittedMessageAndValue[1] && (
          <span className="wrong-data">{`: ${splittedMessageAndValue[1]}`}</span>
        )}
      </p>
    </button>
  );
};

const InvalidTravelerDataBlock = ({
  t,
  alertsAreShownIn,
  errors,
  autocheckFailAlerts,
  classNameForWrapper,
  transferId,
  editUrl,
  removeError,
  showToast,
  isDetailsPage,
  travelerAppStore: {loading},
}) => {
  const isLoading = loading.get();
  const [loadingIdicator, initLoading] = useState({open: false, key: null});

  if (!autocheckFailAlerts && isLoading && errors) {
    return null;
  }

  switch (alertsAreShownIn) {
    case "Profile":
    case "Trip":
      return (
        autocheckFailAlerts &&
        autocheckFailAlerts.map((alert, index) => {
          const {
            messages: {
              forTraveler: {title, subtitle},
            },
            id,
          } = alert;
          const splittedMessageAndValue = title.split(":");
          return (
            <AlertComponent
              isDetailsPage={isDetailsPage}
              key={index}
              order={id}
              validateText={t("alert.validate_button")}
              editText={t("alert.correction_button")}
              isLoading={loadingIdicator}
              onValidatePress={() => {
                initLoading({open: true, key: id});
                TravelerApi.resolveTravelerAutochekError(id)
                  .then((result) => {
                    initLoading({open: false, key: id});
                    removeError(id);
                    showToast({toastMessage: t("alert.error_resolved")});
                  })
                  .catch((err) => {
                    initLoading({open: false, key: id});

                    showToast({
                      toastMessage: t("sign_in.error"),
                    });
                  });
              }}
              alertsAreShownIn={alertsAreShownIn}
              classNameForWrapper={classNameForWrapper}
              link="/profile/edit"
              points="internal"
              splittedMessageAndValue={splittedMessageAndValue}
              subtitleMessage={subtitle}
            />
          );
        })
      );

    case "Transfers":
      return (
        autocheckFailAlerts &&
        autocheckFailAlerts.map((alert, index) => {
          const {
            messages: {
              forTraveler: {title, subtitle},
            },
            id,
          } = alert;
          const splittedMessageAndValue = title.split(":");
          return (
            <AlertComponent
              isDetailsPage={isDetailsPage}
              key={index}
              order={id}
              validateText={t("alert.validate_button")}
              editText={t("alert.correction_button")}
              callback={(e) => {
                e.stopPropagation();
              }}
              onValidatePress={(e) => {
                initLoading({open: true, key: id});
                e.stopPropagation();
                TravelerApi.resolveAutochekError(id, transferId)
                  .then((result) => {
                    initLoading({open: false, key: id});

                    removeError(id, transferId);
                    showToast({toastMessage: t("alert.error_resolved")});
                  })
                  .catch((err) => {
                    initLoading({open: false, key: id});

                    showToast({
                      toastMessage: t("sign_in.error"),
                    });
                  });
              }}
              isLoading={loadingIdicator}
              alertsAreShownIn={alertsAreShownIn}
              classNameForWrapper={classNameForWrapper}
              link={editUrl}
              points="external"
              splittedMessageAndValue={splittedMessageAndValue}
              subtitleMessage={subtitle}
            />
          );
        })
      );
    default:
      return <Fragment />;
  }
};

export default withTranslation()(
  withRouter(inject("travelerAppStore")(observer(InvalidTravelerDataBlock)))
);

import React, { useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text2 } from "../../../../components/styled/Typography";
import { FlexContainer } from "../../../../components/styled/Wrappers";
import { useStores } from "../../../../stores/useStores";
import theme from "../../../../theme";
import Call from "../../../../assets/social-icons/call-icon.svg";
import MessageIconRound from "../../../../assets/social-icons/message-icon-round.svg";
import { IonModal, IonLabel, IonToast, IonLoading } from "@ionic/react";
import DriverDetailsModal from "../Driver/DriverDetailsModal";
import CommonDetails from "../CommonDetails";
import { StyledCard, StyledItem } from "../../../../components/styled/IonElements";
import { withRouter } from "react-router-dom";

let commonDetailsRef = React.createRef();

const ContactDriver = ({ t, i18n, noDriverLocation, history }) => {
    const { travelerAppStore: { chosenTransfer, setOpenMeetingPoint, createChatForTransfer } } = useStores();
    const { operatorConfirmedDriver, confirmedDriver, meetingPoint } = chosenTransfer;
    const driverData = !operatorConfirmedDriver ? confirmedDriver : operatorConfirmedDriver;
    const isGuestDriver = driverData.status === "guest_driver";
    const [showDriverInfo, setShowDriverInfo] = useState(false);
    const isDropoff = chosenTransfer?.transferType === "airport_dropoff";
    const [showToast, setShowToast] = useState(false);
    const [toastMessage] = useState(t('sign_in.error'));
    const [showLoading, setShowLoading] = useState(false);

    const openGallery = () => {
      setOpenMeetingPoint(true);
      if(commonDetailsRef.current){
        commonDetailsRef.current.onMeetingPointClick();
      }
    }

    const driverCarLabel = () => {
      const { vehicleColor, vehicleBrand, vehicleModel, vehiclePlateNumber } = driverData;
      const labelElements = [vehicleColor, vehicleBrand, vehicleModel, vehiclePlateNumber];
      const labelString = labelElements.filter(item => item).join(" ");
      return labelString;
    }

    const onMessageClick = useCallback(() => {
      if(chosenTransfer.channelToken) {
        history.push("/messages", { selectedChannelToken: chosenTransfer.channelToken });
      }
      else {
        setShowLoading(true);
        createChatForTransfer(chosenTransfer)
          .then((chat) => {
            setShowLoading(false);
            history.push("/messages", { selectedChannelToken: chat.channelToken });
          })
          .catch((error) => {
            setShowLoading(false);
            setShowToast(true)
          });
      }
    }, [chosenTransfer, createChatForTransfer, history]);

    // Hide meeting point if transfer is a dropoff
    const contactDetails = [
        {
            title: t('trip_details.driver'),
            image: driverData.professionalPhotoUrl,
            action: () => { setShowDriverInfo(!showDriverInfo) },
            link: null
        },
        {
            title: t('trip_details.call'),
            image: Call,
            action: () => { },
            link: `tel:${driverData.phoneNumber}`
        }
    ];

    if(!isGuestDriver) {
      const messageButton = {
        title: t('trip_details.message'),
        image: MessageIconRound,
        action: onMessageClick,
        link: null
      }

      contactDetails.unshift(messageButton);
    }

    if(meetingPoint && meetingPoint.photos.length > 0) {
      const meetingPointDetails = {
        title: t('trip_details.meeting_point'),
        image: meetingPoint?.photos[0]?.imageUrls?.view,
        action: () => openGallery(),
        link: null
      }
      contactDetails.unshift(meetingPointDetails);
    }

    const isGreek = i18n.language === "el";
    const showDriverLocation = noDriverLocation;
    const showDriverPlates = !showDriverLocation && isDropoff;

    return (
        <Drawer padding="0">
            <GreyToggler />
            <ContactWrapper justify="space-evenly">
                {contactDetails.map(({ title, image, link, action }) => (
                    <ContactBox key={title} href={link} onClick={action}>
                        <CircleImage image={image} />
                        <Text2 style={{ fontSize: isGreek && "13px" }}>{title}</Text2>
                    </ContactBox>
                ))}
                <IonToast
                  isOpen={showToast}
                  onDidDismiss={() => setShowToast(false)}
                  message={toastMessage}
                  duration={3000}
                  color={"danger"}
                  cssClass="styled-toast-error"
                />
                <IonLoading
                  cssClass='loading-modal'
                  isOpen={showLoading}
                  onDidDismiss={() => setShowLoading(false)}
                  message={t('please_wait')}
                />
            </ContactWrapper>
            { showDriverLocation &&
              <StyledItem padding="0" margin="0" lines="full" class="ion-text-center" style={{ "--background": theme.colors.base.graphiteDark }}>
                {/*Use translation*/}
                <IonLabel className="ion-text-wrap" style={{ color: theme.colors.base.white }}>{t('live.no_driver_location')}</IonLabel>
              </StyledItem>
            }
            { showDriverPlates &&
              <StyledItem padding="0" margin="0" class="ion-text-center" style={{"borderTop": "1px solid rgba(45,59,78,0.08)"}}>
                <IonLabel style={{ color: theme.colors.greys.grey5}}>{driverCarLabel()}</IonLabel>
              </StyledItem>
            }
            {/*<StyledItem padding="0" margin="0" >
              </StyledItem>*/}
            <CommonDetails forwardedRef={(element) => { commonDetailsRef.current = element;}} hideMap/>
            <DriverModal isOpen={showDriverInfo} >
                <DriverDetailsModal onChangeModalVisibility={() => setShowDriverInfo(!showDriverInfo)} />
            </DriverModal>
        </Drawer>
    )

}

export default withTranslation()(withRouter(ContactDriver));

const Drawer = styled(StyledCard)`
    z-index: 100;
    width: 100%;
`;

const GreyToggler = styled.div`
    height: 4px;
    width: 30px;
    border-radius: 3px;
    background-color: ${theme.colors.greys.grey1};
    margin: 8px auto;
`;

const ContactWrapper = styled(FlexContainer)`
    padding: 12px 0 10px;
    width: 100%;
    height: 136px;
`;

const ContactBox = styled.a`
    width: 80px;
    text-align: center;
    display: block;
    cursor: pointer;
`;

const CircleImage = styled.div`
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    border-radius: 50px;
    width: 56px;
    height: 56px;
    margin: 8px auto;
`;

const DriverModal = styled(IonModal)`
  --max-width: 340px;
  --height: 100%;
  --max-height: 610px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    --max-height: 610px;
    --border-radius: 5px;
  }
`;

import React from "react";

const ArrowTriangle = ({ fill, styles }) => (
  <svg style={styles} width="6px" height="5px" viewBox="0 0 6 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>To destination</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01.11.02.M.Trip.Cropped" transform="translate(-27.000000, -537.000000)" fill={fill}>
        <g id="Transfer.Block" transform="translate(20.000000, 435.000000)">
          <g id="Arrow.Dotted" transform="translate(7.000000, 77.000000)">
            <g transform="translate(0.000000, 1.000000)" id="Arrow.Arrow.Green">
              <polygon points="3 28.5 5.5 24 0.5 24" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowTriangle;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import allTranslations from '../locales/allTranslations.json';

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      prefix: "%{",
      suffix: "}"
    },
    keySeparator: false,
    resources: allTranslations,
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed'
    }
  });

export default i18n;
import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { StyledContent, StyledPage } from "../../../../components/styled/IonElements";
import { withTranslation } from "react-i18next";
import { onExternalLinkClick } from "../../../../components/common/ExternalLink/ExternalLink";
import { externalLinkRedirection } from "../../helpers";
import TrackTravelerAppEventsService from '../../../../services/trackTravelerAppEvents.js';
import ContactDriver from "./ContactDriver";
import ProgressBar from "./ProgressBar";
import MapComponent from "./MapComponent";
import { BackButton } from "../../../../components/styled/Buttons";
import styled from "styled-components";
import CurrentLocationSharingModal from "./CurrentLocationSharingModal";
import { get as getStorage } from "../../../../services/storage.ts";
import { Capacitor } from '@capacitor/core';
import debounce from "../../../../helpers/debounce";

class LiveLocation extends PureComponent {
  constructor(){
    super();

    this.trackLiveTransfePageVisitedEventDebounced = debounce(
      TrackTravelerAppEventsService.trackLiveTransfePageVisitedEvent,
      3000
    );
  }

  // TODO: remove unused reference
  transferDetailsContainer = React.createRef();

  state = {
    driverInfoModalVisibility: false,
    meetingPointModalVisibility: false,
    showHeader: false,
    showSharingModal: false,
    noDriverLocation: false
  }

  componentWillUnmount() {
    this.trackLiveTransfePageVisitedEventDebounced.stop();
  }

  async componentDidMount() {
    const { travelerAppStore: { shareCurrentLocation, setShareCurrentLocation, currentTrip } } = this.props;

    // Read shareCurrentLocation from storage if is not set by the traveler.
    if(shareCurrentLocation.get() == null) {
      // Always ask for permission if storage is not available
      const isStorageAvailable = Capacitor.isPluginAvailable('Storage');
      if(!isStorageAvailable) {
        this.showSharingModalWithDelay();
        return;
      }

      const shareCurrentLocationFromStorage = await getStorage('shareCurrentLocation');
      if(shareCurrentLocationFromStorage !== null) {
        setShareCurrentLocation(shareCurrentLocationFromStorage);
      }
      else { // Show sharing location modal if traveler has not set it yet.
        this.showSharingModalWithDelay();
      }
    }
    if(currentTrip?.id) {
      this.trackLiveTransfePageVisitedEventDebounced.init(currentTrip);
    }
  }

  setNoDriverLocation = (status) => {
    this.setState({ noDriverLocation: status });
  }

  showSharingModalWithDelay = () => {
    // Give the user some time to see a pick of the page before having the popup appear
    setTimeout(() => {
      this.setState({
        showSharingModal: true
      })
    }, 3000);
  }

  // TODO: remove unused method
  onTransferAction = async (makeRedirection, link, event) => {
    event.preventDefault();
    if (makeRedirection) {
      // trackEvent("traveler app", "grey footer link", link);
      const isWebLink = await onExternalLinkClick(event, link);
      if (isWebLink) {
        externalLinkRedirection(link);
      }
    }
  }

  onChangeModalVisibility = (modalStateName) => {
    this.setState(state => {
      return {
        [modalStateName]: !state[modalStateName],
      }
    });
  }

  goBack = () => {
    this.props.history.push(`/trip/${this.props.travelerAppStore.currentTrip.id}`);
  }

  togglePopups = () => {
		const { showSharingModal } = this.state;
		this.setState({
			showSharingModal: !showSharingModal
		})
	}

  render() {
    const { travelerAppStore: { chosenTransfer, shareCurrentLocation } } = this.props;
    const { showSharingModal, noDriverLocation } = this.state;
    const { operatorConfirmedDriver, confirmedDriver } = chosenTransfer;
    const driverData = !operatorConfirmedDriver ? confirmedDriver : operatorConfirmedDriver;
    // TODO: find a better way to define margin of progressbar
    const increasedMargin = noDriverLocation || (chosenTransfer.transferType === "airport_dropoff");

    return (
      <StyledPage>
        <StyledContent>
          {showSharingModal && <CurrentLocationSharingModal isOpen={showSharingModal} onClose={this.togglePopups} />}
          <div style={{ position: "fixed", width: "100%" }}>
            <CustomBackButton onClick={this.goBack} ionBackButton={this.goBack} />
            {chosenTransfer.fromLatitude && <MapComponent transfer={chosenTransfer} shareCurrentLocation={shareCurrentLocation.get()} setNoDriverLocation={this.setNoDriverLocation} />}
          </div>
          <ProgressBar increasedMargin={increasedMargin}/>
          {/*TODO: show when transfer has no driver*/}
          {driverData && <ContactDriver noDriverLocation={noDriverLocation} />}
        </StyledContent>
      </StyledPage>
    )
  }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(LiveLocation))));

const CustomBackButton = styled(BackButton)`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  background-size: 25px 25px;
  z-index: 100;
`;


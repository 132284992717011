import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import theme from "../../../theme";
import GreenArrow from "../GreenArrow";
import { IonCardContent, IonItemDivider } from "@ionic/react";
import { Text1 } from "../../../components/styled/Typography";
import SkeletonProfile from "../../../components/common/ScarySkeletons/SkeletonProfile";
import { StyledItem } from "../../../components/styled/IonElements";
import ShortProfileInfo from "../ShortProfileInfo";
import LogoutModal from "./LogoutModal";
import AuthenticationService from '../../../services/authentication.js';
import { withTranslation } from "react-i18next";

class LoggedInUser extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showLogoutPopup: false
		}

	}

	componentDidMount() {
		const { travelerAppStore: { fetchProfileInformation, profileInformation, travelerInfo: { id: currentTravelerId } } } = this.props;

		if (profileInformation.id === undefined || profileInformation.id !== currentTravelerId) {
			fetchProfileInformation();
		}
	}

	openLogoutModal = () => {
		this.setState({ showLogoutPopup: true })
	}

	onLogout = () => {
		const { travelerAppStore: { logout } } = this.props;
		
		this.props.history.push("/book");

		logout()
			.then((data) => {
				this.onLogoutSuccess(data);
			})
			.catch(() => { });
	}

	onLogoutSuccess = async (data) => {
		await AuthenticationService.onLogoutSuccess();
	}

	togglePopups = () => {
		const { showLogoutPopup } = this.state;
		this.setState({
			showLogoutPopup: !showLogoutPopup
		})
	}

	render() {
		const { isExpanded, travelerAppStore: { profileInfoLoading }, history, isSide, location, t, brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: {mainColor}} } } = this.props;
		const { showLogoutPopup } = this.state;

		return (
			profileInfoLoading.get()
				? <SkeletonProfile />
				: <ProfileContainer isExpanded={isExpanded}>
					<LogoutModal isOpen={showLogoutPopup} onClose={this.togglePopups} logout={() => this.onLogout()} />

					<ShortProfileInfo isExpanded={isExpanded} />

					<IonItemDivider mode="md" style={{ margin: "0 auto", width: "90%" }} />

					<SubpagesContainer>
						<CustomItem color={mainColor} lines="none" showBorder padding="0" margin="0" onClick={() => history.push("/profile/trip_history")} isSide={isSide} isCurrent={location.pathname === "/profile/trip_history"}>
							<Text1 weight="500">{t('profile.trip_history')}</Text1>
							<GreenArrow />
						</CustomItem>
						<CustomItem color={mainColor} lines="none" showBorder padding="0" margin="0" onClick={() => history.push("/profile/terms_and_conditions")} isSide={isSide} isCurrent={location.pathname === "/profile/terms_and_conditions"}>
							<Text1 weight="500">{t('profile.legal_information')}</Text1>
							<GreenArrow />
						</CustomItem>
						<CustomItem color={mainColor} lines="none" showBorder padding="0" margin="0" onClick={() => history.push("/profile/account_and_data")} isSide={isSide} isCurrent={location.pathname === "/profile/account_and_data"}>
							<Text1 weight="500">{t('profile.account_and_data')}</Text1>
							<GreenArrow />
						</CustomItem>
					</SubpagesContainer>
					<Text1 weight="500" color={theme.colors.greys.grey6} onClick={this.openLogoutModal} style={{ paddingLeft: "20px", paddingBottom: "32px", cursor: "pointer", width: "fit-content" }}>
						{t('profile.logout')}
					</Text1>
				</ProfileContainer>
		)
	}
}


const ProfileContainer = styled(IonCardContent)`
	padding: 0;
`;

const SubpagesContainer = styled.div`
	margin: 32px auto;
`;

const CustomItem = styled(StyledItem)`
	border-left: ${props => (props.isSide && props.isCurrent) && `3px solid ${props => props.color}`};
	padding: 0 20px;
	cursor: pointer;
`;

export default withTranslation()(withRouter(inject("travelerAppStore", "brandedSettingsStore")(observer(LoggedInUser))));

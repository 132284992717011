import React from "react";
import StickyHeader from "../../../components/common/StickyHeader";
import { StyledCard, StyledContent, StyledPage } from "../../../components/styled/IonElements";
import { DesktopFlexContainer, DesktopList, DesktopRightContainer } from "../../../components/styled/Wrappers";
import { useStores } from "../../../stores/useStores";
import GuestUser from "../GuestUser";
import LoggedInUser from "../LoggedInUser";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const TermsConditionsGreek = ({ partnerId, history, t }) => {
    const { travelerAppStore: { isAuthorised } } = useStores();

    if (partnerId === 7580) {
        return (
            <StyledPage>
                {window.innerWidth < 768 && <StickyHeader goBack={() => history.push(window.location.href.split("/terms_and_conditions")[0].replace(window.location.origin, ""))} title={t('legal.terms')} subtitle="" />}
                <StyledContent>
                    <DesktopFlexContainer align="flex-start">
                        <DesktopList padding="0">
                            {isAuthorised.get() ? <LoggedInUser isSide /> : <GuestUser isSide />}
                        </DesktopList>
                        <DesktopRightContainer >
                            <StyledCard margin="10px" padding="20px 15px">
                                <h1 style={{marginTop: "40px"}}>
                                    ΓΕΝΙΚΟΙ ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΚΑΙ ΤΗΣ ΥΠΗΡΕΣΙΑΣ ΜΕ ΤΗΝ ΕΜΠΟΡΙΚΗ ΟΝΟΜΑΣΙΑ «WELCOME»
                                </h1>

                                <p className="simple-text">
                                    <strong>ΠΕΡΙΕΧΟΜΕΝΟ</strong>
                                </p>

                                <ul>
                                    <li>
                                        I. <strong>ΓΕΝΙΚΑ</strong>
                                    </li>
                                </ul>

                                <ul>
                                    <li>
                                        <strong>II. ΟΡΟΙ ΧΡΗΣΗΣ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ</strong>
                                    </li>
                                </ul>

                                <ol>
                                    <li>Περιγραφή Υπηρεσιών Μεταφοράς&nbsp;</li>
                                    <li>Νομική υπόσταση της Εταιρείας&nbsp;</li>
                                    <li>Πολιτική για τις Υπηρεσίες Μεταφοράς&nbsp;</li>
                                    <li>Δικαιώματα και Υποχρεώσεις Ταξιδιωτών- Δηλώσεις-Εγγυήσεις</li>
                                    <li>Δικαιώματα Εταιρείας&nbsp; &#8211; Περιορισμός Ευθύνης&nbsp;</li>
                                    <li>Χρεώσεις και Τιμολογιακή Πολιτική&nbsp;</li>
                                    <li>Γνώμες Ταξιδιωτών&nbsp;</li>
                                    <li>Δήλωση αποποίησης</li>
                                </ol>

                                <p className="simple-text">
                                    <strong>Ι. ΓΕΝΙΚΑ</strong>
                                </p>

                                <p className="simple-text">
                                    Οι ακόλουθοι Γενικοί Όροι και Προϋποθέσεις της Χρήσης της Εφαρμογής και της Υπηρεσίας (εφεξής οι{" "}
                                    <strong>«Όροι Χρήσης» </strong>ή <strong>«Γενικοί Όροι Χρήσης»</strong>) έχουν καθορισθεί από την Εταιρεία
          Περιορισμένης Ευθύνης &nbsp;με την επωνυμία “<strong>WELCOME TRAVEL TECHNOLOGIES HOLDINGS LTD</strong>”, με
          αριθμό μητρώου 10879236, με έδρα c/o Harrison Beale &amp; Owen Seven Stars House, 1 Wheler Road, Coventry CV3
          4LB, Ηνωμένο Βασίλειο και με ΑΦΜ: 289 0786 46, email{" "}
                                    <a href="mailto:hello@welcomepickups.com">hello@welcomepickups.com</a> (εφεξής η <strong>«Εταιρεία» </strong>ή
          <strong> «Εμείς» </strong>ή<strong> «Μας» </strong>ή<strong> «Ημών»</strong>), η οποία νομίμως λειτουργεί και
          διαχειρίζεται την Εφαρμογή για κινητές συσκευές (Mobile App) με την εμπορική ονομασία “Welcome Pickups&nbsp;
          (εφεξής η <strong>«Εφαρμογή»</strong>), μέσω της οποίας οι τουρίστες/ταξιδιώτες (εφεξής οι{" "}
                                    <strong>«Ταξιδιώτες» </strong>ή<strong> «Εσείς» </strong>ή<strong> «Εσάς» </strong>ή<strong> «Σας» </strong>ή
          <strong> «Υμών» </strong>ή<strong> «Χρήστες»</strong>)<strong> </strong>κάνουν κράτηση για παροχή υπηρεσίας
          μεταφοράς&nbsp; (εφεξής <strong>«Υπηρεσία Μεταφοράς»</strong>)&nbsp; εκτελούμενη από Οδηγούς Ιδιωτικών και
          Δημοσίας Χρήσης Οχημάτων, οι οποίοι έχουν δημιουργήσει Λογαριασμό στη Πλατφόρμα (εφεξής οι{" "}
                                    <strong>«Οδηγοί»</strong>) μέσω της οποίας λαμβάνουν από Εμάς, που λειτουργούμε ως διαμεσολαβητές μεταξύ των
          Ταξιδιωτών και των Οδηγών, ειδοποιήσεις και αιτήματα για παροχή υπηρεσίας μεταφοράς προς τους Ταξιδιώτες
          (εφεξής η <strong>«Υπηρεσία»</strong>).
        </p>

                                <p className="simple-text">
                                    Οι ακόλουθοι Γενικοί Όροι Χρήσης της Εφαρμογής και της Υπηρεσίας (εφεξής οι <strong>«Όροι Χρήσης» </strong>ή{" "}
                                    <strong>«Γενικοί Όροι Χρήσης»</strong>), αποτελούν τους νομικά δεσμευτικούς όρους και προϋποθέσεις που
          εφαρμόζονται για την απόκτηση πρόσβασης και τη χρήση της Εφαρμογής και της Υπηρεσίας από τους Ταξιδιώτες,
          συμπεριλαμβανομένων και οποιονδήποτε προγραμμάτων “SOFTWARE”, υπηρεσιών, χαρακτηριστικών, λειτουργιών,
          περιεχομένου ιστοσελίδων και υπέρ-συνδέσμων ή/και άλλων εφαρμογών που παρέχονται κατά καιρούς από την Εταιρεία
          σε συνδυασμό με την Εφαρμογή και την Υπηρεσία.&nbsp;
        </p>

                                <p className="simple-text">
                                    Πριν κάνετε χρήση της Εφαρμογής ή/και της Υπηρεσίας, Σας καλούμε να συμβουλευτείτε τους παρόντες Όρους Χρήσης
                                    και να βεβαιωθείτε ότι συμφωνείτε με αυτούς. Σε περίπτωση διαφωνίας Σας με οποιονδήποτε από τους παρόντες
                                    Όρους Χρήσης, μη κάνετε χρήση της Εφαρμογής και της Υπηρεσίας. Σε περίπτωση ωστόσο που επιθυμείτε κάποια
                                    διευκρίνιση ή πληροφορία αναφορικά με τους Όρους Χρήσης ή έχετε κάποια επιφύλαξη ή απορία σχετική, μπορείτε να
          επικοινωνήσετε με το Τμήμα Operations της Εταιρείας, στο τηλέφωνο που βρίσκεται στην Ιστοσελίδα&nbsp;{" "}
                                    <a href="http://www.welcomepickups.com">www.welcomepickups.com</a>&nbsp; ή στο email hello@welcomepickups.com
          πριν τη διενέργεια οποιασδήποτε πράξης Σας στην Εφαρμογή. Με την οποιαδήποτε ωστόσο ενέργειά Σας στην
          Εφαρμογή, όπως ενδεικτικά την αποδοχή μιας υπηρεσίας μεταφοράς, βεβαιώνετε ότι έχετε διαβάσει, κατανοήσει και
          ανεπιφύλακτα αποδεχτεί τους Όρους Χρήσης.
        </p>

                                <p className="simple-text">
                                    Η Εταιρεία Μας διατηρεί το δικαίωμα να τροποποιεί, ανανεώνει, διαγράφει, προσθέτει, περιορίζει μονομερώς α)
                                    τους παρόντες Όρους Χρήσης, στο σύνολό τους ή εν μέρει, β) τις Πολιτικές της, γ) την Υπηρεσία, δ) τις τεχνικές
          προδιαγραφές της Εφαρμογής (εφεξής οι <strong>«Αλλαγές»</strong>) κατά την απόλυτη διακριτική της ευχέρεια
          ή/και όταν η οποιαδήποτε αλλαγή κατά τα ανωτέρω επιβάλλεται από το νόμο, ενδεχομένως και χωρίς προηγούμενη
          ειδοποίηση Σας ή συγκατάθεσή Σας, πάντα ωστόσο στα πλαίσια των συναλλακτικών ηθών και των ορίων που θέτει ο
          νόμος. Η Εταιρεία αναλαμβάνει την υποχρέωση να Σας ενημερώνει για τις τυχόν αλλαγές σύμφωνα με τα ανωτέρω,
          μέσω της Εφαρμογής με σχετική ειδοποίηση κατά την είσοδό Σας σε αυτή, οι οποίες και θα ισχύουν από την
          ημερομηνία ανάρτησης τους. Διευκρινίζεται ότι τυχόν αλλαγή των παρόντων Όρων Χρήσης, δεν καταλαμβάνει
          υπηρεσίες μεταφοράς ή άλλες συναλλαγές και χρήσεις της Εφαρμογής τις οποίες έχετε ήδη διενεργήσει πριν την
          έναρξη ισχύος των Αλλαγών σύμφωνα με τα ανωτέρω και η Εταιρεία Μας έχει αποδεχτεί. Σε περίπτωση ωστόσο που
          επιθυμείτε κάποια διευκρίνιση ή πληροφορία αναφορικά με τις Αλλαγές, ή έχετε κάποια διαφωνία, ή απορία σχετική
          με αυτές (αλλαγές), μπορείτε να επικοινωνήσετε με το Τμήμα Operations της Εταιρείας, πριν τη διενέργεια
          οποιασδήποτε πράξης Σας στην Εφαρμογή. Οποιαδήποτε ενέργεια Σας, χρήση ή συναλλαγή σας στην Εφαρμογή μετά τις
          σύμφωνα με τα ανωτέρω Αλλαγές, λογίζεται ως ανεπιφύλακτη αποδοχή αυτών.
        </p>

                                <p className="simple-text">
                                    Να σημειώσουμε ότι οποιαδήποτε πληροφορία/ διευκρίνιση Σας χορηγηθεί σύμφωνα με τα ανωτέρω από το Τμήμα
                                    Operations αναφορικά με τους Όρους Χρήσης, δε συνιστά αντικατάσταση, υποκατάσταση ή τυχόν τροποποίηση των
                                    παρόντων Όρων Χρήσης όπως ισχύουν κάθε φορά, καθόσον παρέχονται αποκλειστικά για την παροχή βοήθειας στους
                                    Χρήστες, οι δε Όροι Χρήσης αποτελούν τη μόνη και αποκλειστική μας συμφωνία.
        </p>

                                <p className="simple-text">
                                    Η Εταιρεία διατηρεί το δικαίωμα οποτεδήποτε, αναιτιολόγητα και χωρίς προηγούμενη προειδοποίηση προς τον Χρήστη
                                    να ματαιώσει, αναστείλει ή τερματίσει τη λειτουργία της Υπηρεσίας, να ανακαλέσει ή να προσαρμόσει τη Πλατφόρμα
                                    ή βασικά χαρακτηριστικά της Πλατφόρμας και της Υπηρεσίας.&nbsp;
        </p>

                                <p className="simple-text">
                                    Η χρήση της Εφαρμογής από Εσάς και οποιαδήποτε συναλλαγή Σας με την Εφαρμογή, γίνεται με δική Σας αποκλειστικά
                                    ευθύνη.
        </p>

                                <p className="simple-text">
                                    Αναγνωρίζετε και κατανοείτε ότι θα πρέπει να τηρείτε τους όρους χρήσης του παρόχου που συνεργάζεται με τη
          συσκευή Σας (οι <strong>«Όροι Χρήσης Παρόχου»</strong>). Οι Όροι Χρήσης του Παρόχου θεωρούνται ενσωματωμένοι
          στο παρόν. Αποτελεί αποκλειστικά δική Σας ευθύνη και υποχρέωση να προσδιορίσετε τους Όρους Χρήσης Παρόχου που
          εφαρμόζονται στη Συσκευή Σας.
        </p>

                                <p className="simple-text">
                                    <strong>
                                        ΜΕ ΤΗΝ ΑΠΟΔΟΧΗ ΤΩΝ ΠΑΡΟΝΤΩΝ ΟΡΩΝ ΣΥΜΦΩΝΑ ΜΕ ΤΑ ΑΝΩΤΕΡΩ ΟΙ ΧΡΗΣΤΕΣ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΚΑΙ TΡΙΤΩΝ ΠΟΥ
                                        ΕΝΕΡΓΟΥΝ ΜΕ ΕΞΟΥΣΙΟΔΟΤΗΣΗ ΤΟΥΣ (ΕΦΕΞΗΣ ΟΙ “ΕΞΟΥΣΙΟΔΟΤΗΜΕΝΟΙ ΧΡΗΣΤΕΣ”) ΔΗΛΩΝΟΥΝ ΡΗΤΑ ΚΑΙ ΑΝΕΠΙΦΥΛΑΚΤΑ ΟΤΙ
                                        ΕΧΟΥΝ ΤΗ ΝΟΜΙΜΗ ΗΛΙΚΙΑ ΠΟΥ ΤΟΥΣ ΕΠΙΤΡΕΠΕΙ ΤΗ ΔΕΣΜΕΥΣΗ ΤΟΥ ΑΠΟ ΤΟΥΣ ΠΑΡΟΝΤΕΣ ΓΕΝΙΚΟΥΣ ΟΡΟΥΣ ΑΝΑΦΟΡΙΚΑ ΜΕ ΤΗ
                                        ΧΡΗΣΗ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΚΑΙ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΚΑΙ ΤΗΝ ΠΑΡΟΧΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ.&nbsp;
          </strong>
                                </p>

                                <p className="simple-text">
                                    <strong>1. ΠΕΡΙΓΡΑΦΗ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ ΤΩΝ ΤΑΞΙΔΙΩΤΩΝ</strong>
                                </p>

                                <p className="simple-text">
                                    1.1 Κάνοντας κράτηση της Υπηρεσίας Μεταφοράς μέσω Ημών σημαίνει ότι ο Οδηγός θα είναι το πρόσωπο που θα Σας
                                    υποδεχτεί και καλωσορίσει στη πόλη του και/ή θα Σας μεταφέρει εντός αυτής κατά τη διάρκεια της διαμονής
                                    Σας.&nbsp;
        </p>

                                <p className="simple-text">
                                    Ειδικότερα, οι υπηρεσίες μεταφοράς που έχετε τη δυνατότητα να κάνετε κράτηση μέσω της Πλατφόρμας (εφεξής οι{" "}
                                    <strong>«Υπηρεσίες Μεταφοράς» ή «Υπηρεσία Μεταφοράς» ή «Μεταφορά» </strong>είναι οι ακόλουθες:
        </p>

                                <ol>
                                    <li>Μεταφορά των Ταξιδιωτών από και προς αεροδρόμια.&nbsp;</li>
                                    <li>Μεταφορά Ταξιδιωτών από και προς σημεία τουριστικού ενδιαφέροντος(αξιοθέατα).</li>
                                    <li>
                                        Μεταφορά Ταξιδιωτών από και προς σημείο ενδιαφέροντος στην πόλη με στάσεις (για φαγητό, δραστηριότητα,
                                        φωτογραφίες κτλ.).
          </li>
                                    <li>
                                        Μεταφορά Ταξιδιωτών στα πλαίσια περιοδείας και μεταφορά από και προς σημεία δραστηριοτήτων βασισμένα στα
                                        ενδιαφέροντά τους.&nbsp;
          </li>
                                    <li>
                                        Στα πλαίσια του καλωσορίσματος Σας από τους Οδηγούς, ενδέχεται να λάβετε κατά την παραλαβή Σας από το σταθμό
                                        άφιξης στη χώρα Μας, προϊόντα που Εσείς είτε έχετε αγοράσει από ή μέσω της Εταιρείας, είτε προϊόντα που η
                                        Εταιρεία διανέμει δωρεάν (αναμνηστικά, τοπικά εδέσματα, χάρτες της πόλης, κτλ.).&nbsp;
          </li>
                                    <li>
                                        Για την παροχή της Υπηρεσίας Μεταφοράς, η Εταιρεία Μας θα Σας διαβιβάσει το όνομα, το επίθετο και τον αριθμό
                                        του κινητού τηλεφώνου και εφόσον Μας έχει χορηγηθεί και τη φωτογραφία του.
          </li>
                                </ol>

                                <p className="simple-text">
                                    <strong>
                                        Δεν είμαστε αδειοδοτημένη ιδιωτική εταιρεία ενοικίασης ούτε μεταφορέας και ΔΕΝ ΠΑΡΕΧΟΥΜΕ Υπηρεσίες
                                        Μεταφοράς. Η δράση Μας είναι αποκλειστικά η διαμεσολάβηση μεταξύ των Ταξιδιωτών και του Οδηγού. Κάνοντας
                                        κράτηση για τη παραλαβή Σας μέσω της Εφαρμογής, δεσμεύεστε νομικά και απευθείας με τον Οδηγό που αποδέχτηκε
            την Παραλαβή Σας.{" "}
                                    </strong>
          Η Εταιρεία παρέχει πληροφορίες και μεθοδολογία προς διευκόλυνση των Υπηρεσιών Μεταφοράς όχι όμως με σκοπό τη
          παροχή Υπηρεσιών Μεταφοράς ή τη δράση της ως μεταφορέας με δεδομένο ότι δεν φέρει καμία ευθύνη ή υποχρέωση για
          οποιαδήποτε Υπηρεσία Μεταφοράς παρεχόμενη από τους Οδηγούς προς Εσάς.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>2. ΝΟΜΙΚΗ ΥΠΟΣΤΑΣΗ ΤΗΣ ΕΤΑΙΡΕΙΑΣ</strong>
                                </p>

                                <p className="simple-text">
                                    2.1 Η Εταιρεία Μας παρέχει υπηρεσία ηλεκτρονικής ή τηλεφωνικής διαμεσολάβησης για τη μεταφορά των Ταξιδιωτών.
                                    Ως φορέας ηλεκτρονικής διαμεσολάβησης στις Υπηρεσίες Μεταφοράς που οι Οδηγοί εκτελούν, δρούμε αποκλειστικά ως
                                    μεσάζοντες μεταξύ των Υμών και των Οδηγών. Μέσω της Πλατφόρμας της η Εταιρεία παρέχει υπηρεσίες διαμεσολάβησης
                                    για τη παροχή Υπηρεσιών Μεταφοράς από τους Οδηγούς στους Ταξιδιώτες. Ουσιαστικά η Εταιρεία δεν παρέχει η ίδια
                                    ούτε δύναται να θεωρηθεί ότι παρέχει η ίδια τις Υπηρεσίες Μεταφοράς, καθόσον δεν τυγχάνει αδειοδοτημένος
                                    φορέας μεταφοράς. Σε περίπτωση που κάνετε κράτηση για Υπηρεσία Μεταφοράς μέσω της Εφαρμογής, αναγνωρίζετε ότι
                                    συμβάλλεστε αποκλειστικά και μόνο με τον Οδηγό για τη παροχή της Υπηρεσίας Μεταφοράς και ότι ο Οδηγός είναι ο
                                    μοναδικός και αποκλειστικός υπεύθυνος για την εκπλήρωση της παροχής της Υπηρεσίας.
        </p>

                                <p className="simple-text">
                                    2.2&nbsp; Η Εταιρεία δεν παρέχει υπηρεσίες μεταφοράς, ούτε εμπλέκεται στη σχέση μεταξύ Υμών και του εκάστοτε
                                    Οδηγού που θα Σας παραλάβει καθόσον η συμβατική σχέση για την παροχή υπηρεσίας μεταφοράς δημιουργείται
                                    αποκλειστικά μεταξύ Υμών και του Οδηγού, ο οποίος αναλαμβάνει να Σας παρέχει τις Υπηρεσίες Μεταφοράς. Η
                                    Εταιρεία σε καμία απολύτως περίπτωση δεν συμμετέχει ως συμβαλλόμενο μέρος στις συμβάσεις αυτές ούτε και αποκτά
                                    δικαιώματα ή υποχρεώσεις που να εκπορεύονται από αυτές. Η Εταιρεία δε φέρει καμία ευθύνη ως προς τη μεταφορά
                                    των Ταξιδιωτών, για την οποία οι Οδηγοί είναι αποκλειστικά υπεύθυνοι. Ως εκ τούτου η Εταιρεία, οι εκπρόσωποι,
                                    οι εργαζόμενοι και οι βοηθοί της δεν ευθύνονται με κανέναν τρόπο για τους κινδύνους που συνδέονται με τη
                                    σύναψη, την εκτέλεση και την ολοκλήρωση των συναλλαγών μεταξύ Ταξιδιωτών και Οδηγών και δεν ευθύνονται με
                                    κανέναν τρόπο για τυχόν ζημίες που απορρέουν από τις συναλλαγές αυτές. Εξάλλου, η επιλογή για το αν θα κάνετε
                                    κράτηση για τη παραλαβή Σας μέσω της Εφαρμογής εναπόκειται στη διακριτική Σας ευχέρεια. Εφόσον όμως την
                                    αποδεχτείτε δεσμεύεστε και εγγυάστε ότι θα τηρείτε όλες τις υποχρεώσεις που απορρέουν από τους παρόντες όρους.
        </p>

                                <p className="simple-text">
                                    2.3 Η Εταιρεία δεν εκπροσωπεί ούτε Εσάς ούτε τον Οδηγό, επομένως δεν παρέχει εγγυήσεις για την ποιότητα και
                                    την ομαλή διεκπεραίωση των Υπηρεσιών Μεταφοράς και αντίστοιχα δεν ευθύνεται για τη συμπεριφορά των Ταξιδιωτών
                                    και των Οδηγών. Επιπλέον η Εταιρεία δεν εκπροσωπεί ή αντιπροσωπεύει καμία εταιρεία μεταφοράς και δεν παρέχει
                                    ουδεμία υπηρεσία μεταφοράς.&nbsp;&nbsp;&nbsp;
        </p>

                                <p className="simple-text">
                                    2.4 Επισημαίνεται ότι οι Οδηγοί δεν τελούν σε εργασιακή σχέση με την εταιρεία, ούτε με τους συνεργάτες της. Η
                                    Εφαρμογή αποτελεί μια μέθοδο σύνδεσης των Οδηγών με Ταξιδιώτες. Επιπροσθέτως, η Εταιρεία δεν παρέχει ασφάλιση
                                    στους Οδηγούς, ούτε στα οχήματα, ούτε ευθύνεται σε περίπτωση απώλειας προσωπικών αντικειμένων που απομένουν
                                    στο όχημα από Εσάς.
        </p>

                                <p className="simple-text">
                                    2.5 Η Εταιρεία μπορεί να παραπέμψει Οδηγούς σε Σας για τη παροχή Υπηρεσιών Μεταφοράς. Δεν θα αξιολογήσει την
                                    καταλληλόλητα, τη νομιμότητα ή την ικανότητα του κάθε Οδηγού και παραιτείστε ρητώς και απαλλάσσετε την
                                    Εταιρεία από οποιαδήποτε και από όλες τις απαιτήσεις, ευθύνες ή ζημιές που προκύπτουν από ή σχετίζονται με
                                    οποιοδήποτε τρόπο με τον Οδηγό. Η Εταιρεία δεν θα αποτελεί μέρος των τυχόν διαφορών ανάμεσα σε Εσάς και τους
                                    Οδηγούς. Κάνοντας κράτηση&nbsp; της παροχής των υπηρεσιών μεταφοράς, αναγνωρίζετε ότι η Εταιρεία δεν έχει
                                    καμία ανάμειξη στη συμβατική σχέση μεταξύ του Οδηγού και Υμών, ωστόσο κάθε πράξη μεταφοράς (κούρσα) διέπεται
                                    από τους παρόντες Γενικούς Όρους.
        </p>

                                <p className="simple-text">
                                    2.6 Οι υπηρεσίες διαμεσολάβησης στην υπηρεσία της μεταφοράς διατίθενται μόνο για προσωπική, μη εμπορική χρήση.
                                    Ως εκ τούτου, δεν επιτρέπεται η μεταπώληση, η διάθεση μέσω deep-link, η χρήση, η αντιγραφή, η παρακολούθηση
                                    (π.χ. μέσω spider, scrape), η εμφάνιση, η λήψη, ή η αναπαραγωγή οποιουδήποτε περιεχομένου ή πληροφορίας,
                                    λογισμικού, προϊόντων ή υπηρεσιών που είναι διαθέσιμες στην&nbsp; Εφαρμογή, για οποιαδήποτε εμπορική ή
                                    ανταγωνιστική δραστηριότητα ή σκοπό.
        </p>

                                <p className="simple-text">
                                    <strong>3. ΠΟΛΙΤΙΚΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ</strong>
                                </p>

                                <p className="simple-text">
                                    <strong>3.1 ΠΟΛΙΤΙΚΗ ΚΡΑΤΗΣΕΩΝ</strong>
                                </p>

                                <p className="simple-text">
                                    Για να κάνετε κράτηση για τη μεταφορά Σας, χρειάζεται να Μας δώσετε κάποιες πληροφορίες όπως προσωπικά
                                    στοιχεία και στοιχεία επικοινωνίας(e-mail, αριθμό κινητού τηλεφώνου), ημερομηνία και ώρα, αριθμό πτήσης, ώστε
                                    σε περίπτωση που υπάρξει καθυστέρηση στην ώρα άφιξης να είμαστε σε θέση να το ρυθμίσουμε, τον αριθμό των
                                    επιβατών και τον αριθμό των αποσκευών Σας ώστε να επιλέξουμε το κατάλληλο Όχημα για την παραλαβή Σας και
                                    επίσης στοιχεία τραπεζικού λογαριασμού. Εάν επιθυμείτε να κάνετε κράτηση για τη μεταφορά Σας μέσω Ημών, θα
                                    πρέπει να αποδεχτείτε τους Όρους Χρήσης και τη Πολιτική Προστασίας Πολιτικών Δεδομένων.
        </p>

                                <p className="simple-text">
                                    Όταν μια κράτηση για μεταφορά πραγματοποιείται με Εμάς, θα λαμβάνετε με e-mail επιβεβαίωση ότι λάβαμε τη
                                    κράτησή Σας, δίνοντάς Σας Κωδικό Κράτησης και το σημείο συνάντησης για τη παραλαβή Σας. Θα εξετάζουμε εάν
                                    μπορούμε να εξυπηρετήσουμε τη μεταφορά στο σύνολο ή εάν σύμφωνα με τους παρόντες Γενικούς Όρους Χρήσης έχουμε
                                    λόγους να την απορρίψουμε. Εφόσον ανατεθεί σε έναν Οδηγό της Εταιρείας να εκτελέσει τη μεταφορά Σας, θα Σας
                                    γνωστοποιούμε το όνομά του. Σημαντική Νομική Παρατήρηση: Παρακαλώ σημειώστε ότι η ολοκλήρωση της διαδικασίας
                                    ηλεκτρονικής κράτησης όπως επίσης και το ηλεκτρονικό μήνυμα επιβεβαίωσης που Σας στέλνουμε, δεν αποτελούν
                                    αποδοχή της πρότασης Σας να κάνετε κράτηση μέσω Ημών ακόμα και αν στο ηλεκτρονικό μήνυμα επιβεβαίωσης
                                    περιλαμβάνεται ο Κωδικός Κράτησης. Η Εταιρεία θα αποδεχτεί τη κράτησή Σας για τη μεταφορά Σας μέσω της
                                    Εφαρμογής τότε και μόνο τότε όταν θα λάβει τη πληρωμή από Εσάς, πιο συγκεκριμένα όταν η κάρτα Σας ή ο
                                    λογαριασμός PayPal χρεωθεί. Διατηρούμε το δικαίωμα να αρνηθούμε τη εντολή κράτησης για οποιοδήποτε λόγο,
                                    συμπεριλαμβανομένων νομικών και ρυθμιστικών κανόνων που πηγάζουν από την έννομή σχέση. Σε περίπτωση που δεν
                                    μπορέσουμε να Σας παρέχουμε την Υπηρεσία Μεταφοράς, θα Σας ενημερώσουμε το συντομότερο δυνατό και δεν θα
                                    προχωρήσουμε με την εντολή Σας. Σε περίπτωση που έχετε ήδη πληρώσει για την Υπηρεσία που τελικά δεν μπορούμε
                                    να Σας παρέχουμε, θα Σας επιστρέψουμε τα χρήματα πλήρως σε εύλογο χρονικό διάστημα.&nbsp;
        </p>

                                <p className="simple-text">
                                    Είστε αποκλειστικά υπεύθυνοι για τη υποβολή προς Εμάς των πραγματικών Σας προσωπικών δεδομένων όταν κάνετε
                                    κράτηση για τη παραλαβή Σας μέσω Ημών. Επίσης εγγυάστε και δηλώνετε ότι είστε ο νόμιμος κάτοχος ή έχετε τα
                                    νόμιμα δικαιώματα των καρτών/λογαριασμών των οποίων τα στοιχεία Μας παρέχετε&nbsp; και ότι τα χρήματα που
                                    υπάρχουν, επαρκούν για να καλυφθεί το κόστος της Υπηρεσίας Μεταφοράς. Δυνάμεθα να προχωρήσουμε (παρόλο που δεν
                                    είμαστε υποχρεωμένοι) σε διαδικασία ελέγχου των στοιχείων πληρωμής που Μας παρέχετε όταν ως μέθοδος πληρωμής
                                    έχει επιλεγεί η χρήση πιστωτικής κάρτας ή μέσω Διαδικτυακών Παρόχων Πληρωμών. Σε περίπτωση λάθους ή άλλου
                                    προβλήματος είναι στη διακριτική Μας ευχέρεια να απορρίψουμε την Εντολή Σας.&nbsp;
        </p>

                                <p className="simple-text">
                                    Είστε αποκλειστικά υπεύθυνοι για τη κατάλληλη επιλογή της Υπηρεσίας Μεταφοράς σύμφωνα με τον χρόνο άφιξης και
                                    αναχώρησης της πτήσης Σας. Η Εταιρεία δεν έχει καμία ευθύνη σε περίπτωση που Εσείς χάσετε τη πτήση Σας με
                                    ανταπόκριση εξαιτίας της Υπηρεσίας Μεταφοράς που εσείς είχατε επιλέξει.&nbsp;
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση που επιθυμείτε να κάνετε κράτηση Υπηρεσία Μεταφοράς με Εμάς, Σας προτείνουμε η ελάχιστη διάρκεια
                                    μεταξύ των πτήσεων να είναι έξι (6) ώρες.&nbsp;
        </p>

                                <p className="simple-text">
                                    Μπορείτε να κάνετε κράτηση μέσω διαδικτύου για Υπηρεσία Μεταφοράς μέχρι δέκα (10) ώρες πριν τη
                                    προγραμματισμένη ώρα άφιξης στον Διεθνή Αερολιμένα Αθηνών.&nbsp;
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση που ταξιδεύετε από/προς χώρες που είναι εκτός Σένγκεν, θα πρέπει να έχετε έγκυρη visa Σενγκεν για
                                    να φύγετε από το αεροδρόμιο και να γυρίσετε πίσω.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>3.2 ΠΟΛΙΤΙΚΗ ΑΙΤΗΜΑΤΟΣ ΑΚΥΡΩΣΗΣ ΑΠΟ ΤΟΥΣ ΤΑΞΙΔΙΩΤΕΣ</strong>
                                </p>

                                <p className="simple-text">
                                    Έχετε το δικαίωμα να ακυρώσετε μια Μεταφορά που ήδη έχετε κάνει κράτηση οποιαδήποτε στιγμή επικοινωνώντας με
                                    την Εταιρεία είτε μέσω τηλεφώνου στον αριθμό που υπάρχει στο ηλεκτρονικό μήνυμα επιβεβαίωσης ή στην ιστοσελίδα
          ή στέλνοντας ηλεκτρονικό μήνυμα στη διεύθυνση{" "}
                                    <a href="mailto:hello@welcomepickups.com">hello@welcomepickups.com</a>.
        </p>

                                <p className="simple-text">
                                    Εάν το αίτημα ακύρωσης ληφθεί από Εμάς έως και 48 ώρες πριν το προγραμματισμένο χρόνο παραλαβής, θα Σας
                                    επιστρέψουμε τα χρήματα πλήρως.&nbsp;
        </p>

                                <p className="simple-text">
                                    Εάν το αίτημα ακύρωσης ληφθεί από την Εταιρεία μεταξύ 48 ωρών και 24 ωρών πριν από τη προγραμματισμένη ώρα
                                    παραλαβής, θα Σας χρεώσουμε με επιβάρυνση ακύρωσης ίση με το 9% της αξίας της Μεταφοράς και θα Σας
                                    επιστρέψουμε το υπόλοιπο ποσό το συντομότερο δυνατό.&nbsp;
        </p>

                                <p className="simple-text">
                                    Εάν το αίτημα ακύρωσης ληφθεί από την Εταιρεία μέσα σε λιγότερο από 24 ώρες πριν τη προγραμματισμένη ώρα
                                    παραλαβής, δεν θα Σας επιστρέψουμε τα χρήματά Σας επειδή η ακύρωση έγινε τη τελευταία στιγμή και χωρίς τη
                                    δυνατότητα επιστροφής των χρημάτων.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>3.3 ΠΟΛΙΤΙΚΗ ΑΙΤΗΜΑΤΟΣ ΑΛΛΑΓΗΣ ΑΠΟ ΤΟΥΣ ΤΑΞΙΔΙΩΤΕΣ</strong>&nbsp;
        </p>

                                <p className="simple-text">
                                    Παράταση της διάρκειας του χρόνου της Υπηρεσίας Μεταφοράς, επιπρόσθετες υπηρεσίες ή τροποποιήσεις δεν
                                    επιτρέπεται να γίνουν.&nbsp;
        </p>

                                <p className="simple-text">
                                    Δεν επιτρέπεται να αλλάξουν η ώρα και η τοποθεσία της παραλαβής και αποβίβασης.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>3.4 ΠΟΛΙΤΙΚΗ&nbsp; ΧΡΟΝΟΥ ΑΝΑΜΟΝΗΣ&nbsp;</strong>
                                </p>

                                <p className="simple-text">
                                    Ο Οδηγός θα Σας περιμένει στο σημείο παραλαβής τη προγραμματισμένη ώρα σύμφωνα με την Εντολή Κράτησης της
                                    Μεταφοράς που υπόκειται στη Πολιτική Χρόνου Αναμονής, για αυτό θα πρέπει να είστε στο σημείο παραλαβής στην
                                    ώρα Σας ώστε να αποφευχθεί το ενδεχόμενο να χαρακτηριστείτε ως «Μη Εμφανίσας» όπου σε αυτή τη περίπτωση
                                    εφαρμόζεται η Πολιτική της Μη Εμφάνισης των Ταξιδιωτών.&nbsp;
        </p>

                                <p className="simple-text">
                                    Η Εταιρεία προσφέρει τις ακόλουθες πρόσθετες ώρες αναμονής που εξαρτώνται από το είδος της Μεταφοράς που
                                    κάνατε κράτηση:
        </p>

                                <ul>
                                    <li>Αεροδρόμιο: 15 λεπτά</li>
                                </ul>

                                <p className="simple-text">Ο δωρεάν χρόνος αναμονής αρχίζει από τη προγραμματισμένη ώρα παραλαβής. </p>

                                <p className="simple-text">
                                    Για παραλαβή από αεροδρόμιο όπου η καθορισμένη ώρα παραλαβής είναι η ώρα άφιξης του αεροπλάνου και το
                                    αεροπλάνο αφιχθεί νωρίτερα από την προγραμματισμένη ώρα άφιξης του, η καθορισμένη ώρα παραλαβής παραμένει η
                                    αρχική καθορισμένη ώρα παραλαβής.
        </p>

                                <p className="simple-text">
                                    Για παραλαβή από αεροδρόμιο, σε περίπτωση που οι Ταξιδιώτες προσγειώνονται με διαφορετικές πτήσεις, η Εταιρεία
                                    θεωρεί πως η προγραμματισμένη ώρα παραλαβής είναι αυτή που δηλώθηκε από τον Ταξιδιώτη στην Εφαρμογή κατά την
                                    κράτηση της Υπηρεσίας Μεταφοράς ή όποια άλλη εν τέλει δηλώθηκε σε περίπτωση που αυτή (καθορισμένη ώρα
                                    παραλαβής) άλλαξε σύμφωνα με την Πολιτική Αλλαγών εκ μέρους του Ταξιδιώτη.
        </p>

                                <p className="simple-text">
                                    Οποιεσδήποτε σημειώσεις γίνονται από τους Ταξιδιώτες κατά τη διάρκεια της κράτησης που αναφέρονται σε
                                    διαφορετικές πτήσεις δεν αποτελούν προσαρμογή στη προγραμματισμένη ώρα παραλαβής. Σε περίπτωση όπου ένα γκρουπ
                                    από Ταξιδιώτες φτάνουν με διαφορετικές πτήσεις σε διαφορετικές ώρες και έχουν κάνει κράτηση για μια Μεταφορά,
                                    θα πρέπει να προσθέσουν την ώρα της τελευταίας πτήσης στα στοιχεία της πτήσης.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>3.5 ΠΟΛΙΤΙΚΗ ΜΗ ΕΜΦΑΝΙΣΗΣ ΤΟΥ ΤΑΞΙΔΙΩΤΗ</strong>
                                </p>

                                <p className="simple-text">
                                    Ταξιδιώτης που δεν εμφανίστηκε στο σημείο παραλαβής ορίζεται ως ο Ταξιδιώτης που δεν βρίσκεται στο σημείο
                                    παραλαβής πριν το τέλος του χρόνου αναμονής όπως ορίζεται στη Πολιτική Χρόνου Αναμονής..&nbsp;
        </p>

                                <p className="simple-text">
                                    Σημείο παραλαβής ορίζεται ως το μέρος όπου ο Ταξιδιώτης έχει ζητήσει να γίνει η παραλαβή του.&nbsp;
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση παραλαβής από αεροδρόμιο, η Εταιρεία Σας ενημερώνει μέσω του&nbsp; επιβεβαιωτικού ηλεκτρονικού
                                    μηνύματος κράτησης για την ακριβή τοποθεσία όπου ο Οδηγός θα Σας περιμένει, όπως Χώρος Άφιξης, Χώρος Αναμονής.
        </p>

                                <p className="simple-text">
                                    Στις περιπτώσεις όπου οι Ταξιδιώτες δεν εμφανιστούν στη τοποθεσία παραλαβής μέχρι το τέλος του
                                    προγραμματισμένου χρόνου αναμονή τους, η Υπηρεσία Μεταφοράς χαρακτηρίζεται ως «Μη Εμφάνιση Ταξιδιώτη». Σε αυτή
                                    τη περίπτωση δεν επιστρέφεται κανένα ποσό στον Ταξιδιώτη.&nbsp;
        </p>

                                <p className="simple-text">
                                    Εάν δεν βρίσκεστε στο σημείο παραλαβής κατά τη προγραμματισμένη ώρα παραλαβής ο Οδηγός που του έχει ανατεθεί η
                                    Μεταφορά θα προσπαθήσει να επικοινωνήσει μαζί Σας μέσω μηνύματος/Whatsup/Viber ή θα προσπαθήσει να Σας καλέσει
                                    στο κινητό Σας τηλέφωνο. Κάνοντας κράτηση για Μεταφορά συναινείτε στο να γνωστοποιήσουμε τον αριθμό του
                                    κινητού Σας τηλεφώνου στον Οδηγό Σας.&nbsp;
        </p>

                                <p className="simple-text">
                                    Περιπτώσεις “Μη εμφάνισης του Ταξιδιώτη” που θα αναφερθούν στην Εταιρεία από τον Οδηγός Σας είναι οι
                                    ακόλουθες:
        </p>

                                <ul>
                                    <li>
                                        Σε περίπτωση που καθυστερήσει να εμφανιστεί ο Ταξιδιώτης στο σημείο παραλαβής στο αεροδρόμιο για 15 λεπτά
                                        από τη προγραμματισμένη ώρα παραλαβής και κατόπιν επισήμανσης του Οδηγού για πιθανή περίπτωση “Μη εμφάνισης
                                        του Ταξιδιώτη”, η Εταιρεία θα προσπαθήσει να επικοινωνήσει μαζί Σας (μέσω κλήσης, και/ή μηνύματος/What’s
                                        up/Viber και/ή email) σε μια προσπάθεια να Σας εντοπίσει. Η Εταιρεία θα προσπαθεί να επικοινωνήσει μέχρι τη
                                        λήξη του δωρεάν χρόνου αναμονής όπως αυτός περιγράφεται στους Όρους Χρήσης.&nbsp;
          </li>
                                </ul>

                                <p className="simple-text">Μόλις τελειώσει ο δωρεάν χρόνος αναμονής Σας, θα συμβούν τα παρακάτω:</p>

                                <ul>
                                    <li>
                                        Εάν δεν έχετε εντοπιστεί, δεν έχετε απαντήσει, πρέπει να αποδεσμεύσουμε τον Οδηγό. Σε αυτή τη περίπτωση, η
                                        Υπηρεσία Μεταφοράς χαρακτηρίζεται ως “Μη εμφάνιση του Ταξιδιώτη”.
          </li>
                                    <li>
                                        Εάν έχετε εντοπιστεί και έχετε επικοινωνήσει ότι θα βρίσκεστε στο σημείο παραλαβής έως το τέλος του δωρεάν
                                        χρόνου αναμονής, πρέπει να ενημερώσουμε τον Οδηγό να περιμένει.&nbsp;
          </li>
                                    <li>
                                        Εάν δεν καταφέρετε να εμφανιστείτε έως το τέλος του δωρεάν χρόνου αναμονής, θα προσπαθήσουμε ακόμα μια
                                        τελευταία φορά να σας εντοπισουμε.&nbsp;
          </li>
                                </ul>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    Έχετε 24 ώρες για να αμφισβητήσετε το χαρακτηρισμό της Κράτησης ως «Μη Εμφάνιση Ταξιδιώτη» επικοινωνώντας με
                                    το Τμήμα Εξυπηρέτησης Πελατών.&nbsp; Στη περίπτωση που διαφωνείτε με το χαρακτηρισμό «Μη Εμφάνιση Ταξιδιώτη»
                                    αποτελεί επαρκή απόδειξη ότι ο Οδηγός βρισκόταν στο σημείο παραλαβής όπως ορίζουν οι Πολιτικές της Εταιρείας,
                                    εάν ο Οδηγός βρισκόταν στο σημείο παραλαβής στο χρονικό διάστημα ανάμεσα στη προγραμματισμένη ώρα παραλαβής
                                    και στο τέλος του δωρεάν Χρόνου Αναμονής. Εάν προκύπτει επαρκής απόδειξη στον ισχυρισμό ότι και Εσείς και ο
                                    Οδηγός βρισκόσασταν στο σημείο παραλαβής αλλά δεν καταφέρατε να συναντηθείτε και η Κράτηση Μεταφοράς δεν
                                    πραγματοποιήθηκε, τότε θα Σας επιστρέψουμε το 80% της τιμής της Κράτησης Μεταφοράς, αλλά το 20% θα αποδοθεί
                                    από Εμάς στον Οδηγό ως αμοιβή του Οδηγού, από τη στιγμή που η Εταιρεία είναι υπεύθυνη για την αμοιβή των
                                    Οδηγών.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>3.6 ΠΟΛΙΤΙΚΗ ΜΗ ΕΜΦΑΝΙΣΗΣ ΟΔΗΓΟΥ</strong>
                                </p>

                                <p className="simple-text">
                                    Ο Οδηγός πρέπει να βρίσκεται στο σημείο παραλαβής κατά την καθορισμένη ώρα παραλαβής. Εάν ο Οδηγός πρόκειται
                                    να καθυστερήσει έως 10 λεπτά, θα πρέπει να επικοινωνήσει και να ενημερώσει τον Ταξιδιώτη (μέσω τηλεφώνου και
                                    μήνυμα (sms)/whatsup) το συντομότερο δυνατό, και το αργότερο μέχρι την καθορισμένη ώρα παραλαβής. Εάν ο Οδηγός
                                    πρόκειται να καθυστερήσει περισσότερο από 10 λεπτά, θα πρέπει να επικοινωνήσει και να ενημερώσει το Τμήμα
                                    Εξυπηρέτησης Πελατών όσο το δυνατόν νωρίτερα, και το αργότερο πριν από την καθορισμένη ώρα παραλαβής. Το Τμήμα
                                    Εξυπηρέτησης Πελατών θα πρέπει να επικοινωνήσει με τον Ταξιδιώτη και να τον ενημερώσει για την καθυστέρηση του
                                    Οδηγού.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>3.7 ΠΟΛΙΤΙΚΗ ΑΠΟΣΚΕΥΩΝ</strong>
                                </p>

                                <p className="simple-text">
                                    Οι Ταξιδιώτες πρέπει να φροντίσουν οι ίδιοι για το που θα αποθηκεύσουν τις αποσκευές τους για το χρονικό
                                    διάστημα που μεσολαβεί μεταξύ των πτήσεων με ανταπόκριση, πριν αυτοί κάνουν χρήση της Υπηρεσίας Μεταφοράς.
                                    Στον Διεθνή Αερολιμένα Αθηνών υπάρχει η υπηρεσία αποθήκευσης των αποσκευών&nbsp; η οποία παρέχει ασφαλή φύλαξη
                                    με χρέωση. Για περισσότερες πληροφορίες μπορείτε να καλέσετε τον εξής τηλεφωνικό αριθμό (+30) 2103530352, (
          <a href="https://www.aia.gr/traveler/airport-information/baggage-services/">
                                        https://www.aia.gr/traveler/airport-information/baggage-services/
          </a>
          ).
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση πτήσεων με ανταπόκριση της εταιρείας Aegean Airlines, μπορείτε να έχετε τις χειραποσκευές μαζί
                                    σας στο αυτοκίνητο.&nbsp;
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση που οι επιβάτες επιθυμούν να έχουν μαζί τους περισσότερες αποσκευές στην πτήση τους με
                                    ανταπόκριση, παρακαλούμε σημειώστε ότι οι επιπλέον αποσκευές χρεώνονται σύμφωνα με τη Πολιτική Τιμών της
          εταιρείας Aegean Airlines. Για περισσότερες πληροφορίες παρακαλώ πατήστε εδώ:{" "}
                                    <a href="https://en.aegeanair.com/travel-information/baggage/">
                                        https://en.aegeanair.com/travel-information/baggage/
          </a>{" "}
          .
        </p>

                                <p className="simple-text">
                                    <strong>3.8 ΠΟΛΙΤΙΚΗ ΕΠΙΣΤΡΟΦΗΣ ΧΡΗΜΑΤΩΝ ΣΤΟΝ ΤΑΞΙΔΙΩΤΗ</strong>
                                </p>

                                <p className="simple-text">
                                    Το κόστος της Υπηρεσίας Μεταφοράς που έχει πληρώσει ο Ταξιδιώτης του επιστρέφεται στο σύνολό του στις
                                    ακόλουθες περιπτώσεις:
        </p>

                                <ul>
                                    <li>Εάν η κράτηση Μεταφοράς ακυρωθεί από την Εταιρεία για λόγους λειτουργικής ικανότητας</li>
                                    <li>
                                        Εάν η κράτηση Μεταφοράς ακυρώθηκε για λόγους ανωτέρας βίας (σεισμό, διαδήλωση, φυσικές καταστροφές, ατύχημα
                                        κ.τ.λ.)
          </li>
                                    <li>Εάν η Υπηρεσία Μεταφοράς δεν μπορεί να διεκπεραιωθεί εξαιτίας ακύρωσης της πτήσης</li>
                                    <li>
                                        Εάν η Υπηρεσία Μεταφοράς ακυρώθηκε εξαιτίας καθυστέρηση της πτήσης πάνω από μια (1) ώρα σύμφωνα με τη
                                        Πολιτική του Δωρεάν Χρόνου Αναμονής.
          </li>
                                    <li>
                                        Εάν η Εταιρεία λάβει αίτημα ακύρωσης από τον Ταξιδιώτη μέχρι 48 ώρες πριν από τη προγραμματισμένη ώρα
                                        παραλαβής σύμφωνα με τη Πολιτική Ακυρώσεων του Ταξιδιώτη.
          </li>
                                </ul>

                                <p className="simple-text">
                                    Σε περίπτωση που η Εταιρεία λάβει Αίτημα για Ακύρωση από τον Ταξιδιώτη μεταξύ 48 ωρών και 24 ωρών πριν τη
                                    προγραμματισμένη ώρα παραλαβής, η Εταιρεία επιβάλλει Χρέωση Ακύρωσης ίση με το 9% της τιμής κράτησης της
                                    Μεταφοράς. Το υπολειπόμενο ποσό της χρέωσης για τη κράτηση της Μεταφοράς επιστρέφεται στον Ταξιδιώτη σύμφωνα
                                    με τη Πολιτική Ακύρωσης από τον Ταξιδιώτη.&nbsp;
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση που ένας Ταξιδιώτης διαφωνεί με το χαρακτηρισμό «Μη Εμφάνιση Ταξιδιώτη», εάν προκύπτει επαρκής
                                    απόδειξη ότι και ο Ταξιδιώτης και ο Οδηγός ήταν στο σημείο παραλαβής αλλά δεν κατάφεραν να συναντηθούν με
                                    αποτέλεσμα η Μεταφορά να μην πραγματοποιηθεί, θα επιστραφεί στον Ταξιδιώτη το 80% της κράτησης Μεταφοράς
                                    σύμφωνα με τη Πολιτική της Μη Εμφάνισης Ταξιδιώτη.
        </p>

                                <p className="simple-text">
                                    <strong>3.9 ΣΥΜΒΟΥΛΕΣ ΠΡΟΣ ΤΑΞΙΔΙΩΤΕΣ ΠΟΥ ΤΑΞΙΔΕΥΟΥΝ ΑΠΟ/ΠΡΟΣ ΧΩΡΕΣ ΕΚΤΟΣ SCHENGEN</strong>
                                </p>

                                <p className="simple-text">
                                    Ο Διεθνής Αερολιμένας Αθηνών έχει θέσει τους παρακάτω συγκεκριμένους περιορισμούς για τους επιβάτες που
                                    εισέρχονται στην Ελλάδα από οποιαδήποτε χώρα εκτός Σένγκεν, όπως Ελβετία, Λιχτενστάιν, Ισλανδία και Νορβηγία:
        </p>

                                <ul>
                                    <li>
                                        Σε περίπτωση που η πτήση Σας είναι από και προς αεροδρόμια εκτός Σένγκεν, παρακαλούμε όπως σημειώσετε ότι θα
                                        πρέπει να υποβληθείτε σε έλεγχο διαβατηρίου πριν την επιβίβασή Σας στην επόμενη πτήση.&nbsp;
          </li>
                                    <li>
                                        Σε περίπτωση που η πτήση Σας προέρχεται από αεροδρόμιο χώρας που είναι εντός Σένγκεν με προορισμό αεροδρόμιο
                                        χώρας εκτός Σένγκεν, παρακαλώ όπως σημειώσετε ότι θα πρέπει να υποβληθείτε σε έλεγχο διαβατηρίου και
                                        ασφάλειας.&nbsp;
          </li>
                                    <li>
                                        Σε περίπτωση που η πτήση σας είναι από αεροδρόμιο χώρας εκτός Σένγκεν με προορισμό αεροδρόμιο χώρας εντός
                                        Σένγκεν, παρακαλώ όπως σημειώσετε ότι θα πρέπει να υποβληθείτε σε έλεγχο διαβατηρίου, ασφάλειας και
                                        παράδοσης των αποσκευών σας πριν από την επόμενη πτήση Σας.&nbsp;
          </li>
                                    <li>
                                        Σύμφωνα με τα παραπάνω, αναμένουμε οι επιβάτες Μας να είναι έτοιμοι για επιβίβαση για την Υπηρεσία Μεταφοράς
                                        και να περάσουν τον έλεγχο του διαβατηρίου και ασφαλείας τουλάχιστον 1,5 ώρα πριν τη προγραμματισμένη ώρα
                                        αναχώρησης.&nbsp; Για παράδειγμα, αν υποθέσουμε ότι η πτήση αναχώρησης είναι στις 17:00, θα πρέπει η
                                        προγραμματισμένη Υπηρεσία Μεταφοράς να έχει τελειώσει στις 14:30.&nbsp;
          </li>
                                </ul>

                                <p className="simple-text">
                                    Για περισσότερες πληροφορίες σχετικά με τους επιβάτες με πτήσεις με ανταπόκριση, παρακαλώ όπως συνδεθείτει
                                    στον παρακάτω σύνδεσμο:
          <a href="https://en.aegeanair.com/travel-information/at-the-airport/connecting-passengers/">
                                        https://en.aegeanair.com/travel-information/at-the-airport/connecting-passengers/
          </a>
          &nbsp;
        </p>

                                <p className="simple-text">
                                    Σε περίπτωση που οι επιβάτες ταξιδεύουν μαζί με τα κατοικίδιά τους, παρακαλώ όπως έχετε τα κατοικίδια σε
          μεταφορείς κατοικίδιων. Για περισσότερες πληροφορίες παρακαλώ όπως συνδεθείτε στον παρακάτω σύνδεσμο:{" "}
                                    <a href="https://en.aegeanair.com/travel-information/special-assistance/traveling-with-pet/">
                                        https://en.aegeanair.com/travel-information/special-assistance/traveling-with-pet/
          </a>
          &nbsp;
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>4. ΔΙΚΑΙΩΜΑΤΑ ΚΑΙ ΥΠΟΧΡΕΩΣΕΙΣ ΤΑΞΙΔΙΩΤΩΝ-ΔΗΛΩΣΕΙΣ-ΕΓΓΥΗΣΕΙΣ</strong>
                                </p>

                                <p className="simple-text">
                                    <strong>4.1 ΔΗΛΩΣΕΙΣ ΚΑΙ ΕΓΓΥΗΣΕΙΣ ΤΑΞΙΔΙΩΤΩΝ: </strong>Χρησιμοποιώντας την Εφαρμογή, συμφωνείτε τα ακόλουθα:
        </p>

                                <ul>
                                    <li>
                                        Να παρέχετε αληθινές, ακριβείς, πλήρεις και ενημερωμένες πληροφορίες για Εσάς στη φόρμα κράτησης και να τις
                                        ανανεώνετε κάθε φορά που χρειάζεται. Η Εταιρεία δεν είναι υποχρεωμένη να παρακολουθεί ή να ελέγχει την
                                        ακρίβεια των πληροφοριών που Εσείς παρέχετε. Εάν οποιαδήποτε πληροφορία που Μας παρέχετε δεν είναι σύμφωνη
                                        με τις συγκεκριμένες διατάξεις ή εάν η Εταιρεία έχει βάσιμους λόγους να υποψιαστεί ότι κάποιες πληροφορίες
                                        δεν συμφωνούν με τα όσα έχετε δηλώσει, έχει το δικαίωμα να αρνηθεί την εγγραφή Σας και να αναστείλει ή να
                                        διακόψει αμέσως, χωρίς προειδοποίηση, τη λειτουργία του Λογαριασμού Σας, αρνούμενη οποιαδήποτε και κάθε
                                        χρήση, παρούσα ή μελλοντική, των Υπηρεσιών στις οποίες απαιτείται εγγραφή. Σε αυτή τη περίπτωση δεν
                                        δικαιούστε καμία εγγύηση ή αποζημίωση για την απόρριψη, αναστολή ή ακύρωση της εγγραφή Σας.&nbsp;
          </li>
                                </ul>

                                <ul>
                                    <li>
                                        Δεν θα χρησιμοποιείτε την Εφαρμογή για μη νόμιμο σκοπό όπως να&nbsp; εμποδίζει, καταστρέφει, βλάπτει ή κάνει
                                        την Εταιρεία λιγότερο αποδοτική με οποιοδήποτε τρόπο·&nbsp; όπως η μεταφορά φακέλων που περιέχουν ιούς,
                                        Δούρειους Ίππους ή άλλα κακόβουλα προγράμματα· η πρόσβαση ή η προσπάθεια πρόσβασης σε λογαριασμούς άλλων
                                        χρηστών ή η παραβίαση ή η προσπάθεια παραβίασης οποιουδήποτε μέτρου ασφαλείας· όπως η διάδοση οποιασδήποτε
                                        δήλωσης με συκοφαντικό, χυδαίο περιεχόμενο ή που καταλήγει να είναι σεξιστική, υβριστική ή απειλητική και να
                                        αφορά σε διάκριση λόγω θρησκείας, φύλου, σεξουαλικής προτίμησης, φυλής, εθνικότητας, ηλικίας, αναπηρίας ή
                                        οτιδήποτε άλλο· η διαφήμιση ή η προώθηση τρίτου μέρους ή δικών Σας προϊόντων ή υπηρεσιών.&nbsp;
          </li>
                                </ul>

                                <ul>
                                    <li>Να μην κάνετε κάτι που θεωρούμε εύλογα ότι είναι κακόφημο ή ικανό να βλάψει την φήμη Μας.</li>
                                </ul>

                                <ul>
                                    <li>
                                        Να αναγνωρίζετε ότι έχουμε περιορισμένο έλεγχο στη φύση και στο περιεχόμενο των πληροφοριών που λαμβάνουμε
                                        από Εσάς ή από άλλους χρήστες στην Εφαρμογή. Αν και έχουμε το δικαίωμα να το κάνουμε, δεν ελέγχουμε το
                                        περιεχόμενο αυτό κατά τη διάρκεια της συνηθισμένης πορείας των εργασιών της Εταιρείας και δεν μπορούμε να
                                        είμαστε υπεύθυνοι για τέτοιου είδους περιεχόμενο. Εάν έχετε παράπονο για άλλο Χρήστη, παρακαλώ όπως
                                        επικοινωνήσετε μαζί Μας μέσω της Εφαρμογής.
          </li>
                                </ul>

                                <ul>
                                    <li>
                                        Να συμμορφώνεστε με τους εφαρμοστέους νόμους του έθνους, χώρας, κράτους και πόλης στην οποία βρίσκεστε ενώ
                                        χρησιμοποιείτε το λογισμικό ή την Υπηρεσία.
          </li>
                                </ul>

                                <ul>
                                    <li>
                                        Να συμπεριφέρεστε στους Οδηγούς που σας συστήνονται μέσω της Εφαρμογής με σεβασμό και να μην προκαλείτε
                                        φθορές στο Όχημά τους.
          </li>
                                </ul>

                                <ul>
                                    <li>
                                        Nα Μας αποζημιώνετε και να Μας υπερασπίζεστε πλήρως ενάντια σε οποιονδήποτε ισχυρισμό ή νομικών διαδικασιών
                                        που στρέφονται εναντίον μας από οποιοδήποτε άλλο πρόσωπο ως αποτέλεσμα της αθέτησής Σας ως προς αυτό το
                                        συμβόλαιο.
          </li>
                                </ul>

                                <ul>
                                    <li>
                                        Η διεύθυνση ηλεκτρονικού ταχυδρομείου (όπως <a href="mailto:xxx@yahoo.com">xxx@yahoo.com</a>) που Εσείς
            έχετε για να κάνετε εγγραφή στην Εφαρμογή δεν θα πρέπει να παραπέμπει στο όνομα της Εταιρείας. Επίσης, αν οι
            κωδικοί πρόσβασης για είσοδο στην Πλατφόρμα θεωρηθούν προσβλητικοί, έχουμε δικαίωμα να τα διαγράψουμε καθώς
            επίσης διεύθυνση ιστοσελίδας ή διεύθυνση ηλεκτρονικού ταχυδρομείου.
          </li>
                                </ul>

                                <ul>
                                    <li>Δεν θα χρησιμοποιείτε την Υπηρεσία ή το Λογισμικό για να προκαλέσετε ελαφριά ή σοβαρή ενόχληση.</li>
                                </ul>

                                <ul>
                                    <li>
                                        Σε περίπτωση πρόσβασης και χρήσης της Εφαρμογής μέσω κινητού τηλεφώνου ή οποιασδήποτε κινητής συσκευής
                                        επικοινωνίας, ή χρήσης συγκεκριμένων δυνατοτήτων απομακρυσμένης πρόσβασης, μπορεί να υποστείτε χρεώσεις από
            τον τηλεπικοινωνιακό Σας πάροχο (<strong>«Πάροχος»</strong>) και Εσείς είστε αποκλειστικά υπεύθυνοι για την
            αποπληρωμή των χρεώσεων αυτών.
          </li>
                                </ul>

                                <p className="simple-text">
                                    <strong>4.2</strong> Αναγνωρίζετε και συμφωνείτε ότι Εσείς πρέπει να αποφεύγετε κάθε πράξη που μπορεί να
          προκαλέσει προβλήματα σχετικά με την κυκλοφοριακή ασφάλεια. Συγκεκριμένα, Εσείς εγγυάστε και δηλώνετε ότι:
        </p>

                                <ul>
                                    <li>
                                        Με κανέναν τρόπο δεν θα εμποδίζετε τον Οδηγό από το να οδηγεί με ασφάλεια κατά τη διάρκεια της Μεταφοράς Σας
          </li>
                                    <li>Δεν θα προσπαθήσετε να αποβιβαστείτε/επιβιβαστείτε από/στο όχημα όταν αυτό κινείται</li>
                                    <li>Δεν θα καπνίζετε κατά τη διάρκεια της Μεταφοράς Σας</li>
                                    <li>Δεν θα ανοίγετε τα παράθυρα και τις πόρτες χωρίς την συγκατάθεση του Οδηγού</li>
                                    <li>
                                        Θα είστε κόσμιοι, ευγενικοί και δε θα επιδείξετε σε καμία περίπτωση ακατάλληλη, υβριστική, προκλητική ή
                                        απειλητική συμπεριφορά προς τον Οδηγό
          </li>
                                    <li>Δεν θα προβείτε σε πράξεις που δύνανται να προκαλέσουν βλάβη στο Όχημα του Οδηγού</li>
                                    <li>
                                        Θα προσέχετε και θα ελέγχετε ότι τα παιδιά, τα ζώα και τα αντικείμενα που μεταφέρονται, μεταφέρονται με
                                        υπευθυνότητα
          </li>
                                    <li>Δεν θα πετάτε αντικείμενα μέσα ή έξω από το Όχημα και δεν θα το μολύνετε με κανένα τρόπο</li>
                                    <li>
                                        Θα διαβάσετε προσεκτικά και θα εφαρμόσετε τις οδηγίες ταξιδιού με μικρά παιδιά καθώς και τις οδηγίες για
                                        μεταφορά βρεφικών και παιδικών καροτσιών, αποσκευών, ποδηλάτων και κατοικίδιων
          </li>
                                    <li>Δεν θα καταναλώνετε φαγητά ή ποτά καθ’ όλη τη διάρκεια της Μεταφοράς Σας, μέχρι και το τέλος αυτής</li>
                                    <li>Δεν θα επιβιβάζεστε σε Όχημα με βρώμικο ή μολυσμένο ρουχισμό&nbsp;</li>
                                </ul>

                                <p className="simple-text">
                                    <strong>4.3</strong> Σε περιπτώσεις όπου μεταφέρετε ζώα ή/και αντικείμενα κατά τη διάρκεια της Μεταφοράς Σας
          και το Όχημα υποστεί βλάβη/καταστραφεί ή λερωθεί καθ’ οιοδήποτε τρόπο εξαιτίας αυτών, Εσείς αναλαμβάνετε
          πλήρως την υποχρέωση να πληρώσετε για όλα τα παραπάνω
        </p>

                                <p className="simple-text">
                                    <strong>4.4 </strong>Εγγυάστε ότι Εσείς έχετε διαβάσει στο σύνολό τους και έχετε κατανοήσει πλήρως τους Όρους
          Χρήσης, την Πολιτική Ορθής Χρήσης, την Πολιτική Προστασίας Πνευματικών Δικαιωμάτων και αποδέχεστε όλα τα
          παραπάνω πλήρως και άνευ όρων, καθώς και ότι Εσείς πληροίτε όλα τα νομικά προαπαιτούμενα για την αποδοχή των
          Όρων αυτών
        </p>

                                <p className="simple-text">
                                    <strong>4.5</strong> Εγγυάστε και δηλώνετε ότι Εσείς με δική Σας ευθύνη και κόστος, θα λάβετε άμεσα
          οποιαδήποτε απαραίτητα μέτρα και σε κάθε περίπτωση θα αποζημιώσετε και θα προστατέψετε την Εταιρεία, τις
          Θυγατρικές της, τους Εταίρους της καθώς και κάθε άλλη οντότητα στην οποία απορρέουν δικαιώματα από αυτήν
          (εταίροι, εργαζόμενοι, διαχειριστές, προμηθευτές, πράκτορες, αντιπρόσωποι, καθώς και συνεργάτες όλων αυτών)
          για κάθε απαίτηση, απώλεια, καταστροφή, κόστος, έξοδο, υποχρέωση, απόκλιση πορείας, ποινή, πρόστιμο, τέλος
          νομικών συμβουλών που προήλθε από:
        </p>

                                <p className="simple-text">
                                    α) μία/κάθε παραβίαση από Εσάς των Όρων αυτών ή/και της Πολιτικής Αποδεκτών Χρήσεων, της δήλωσης και εγγύησής
                                    Σας που αφορά το παρόν Συμφωνητικό, και της πνευματικής ιδιοκτησίας ή άλλων δικαιωμάτων της Εταιρείας
        </p>

                                <p className="simple-text">β) κάθε παράνομη πράξη ή νομικά κολάσιμη παράβλεψη κατά τη χρήση της Υπηρεσίας</p>

                                <p className="simple-text">γ) παραβίαση των εφαρμόσιμων νόμων από μέρους Σας</p>

                                <p className="simple-text">
                                    <strong>5. ΔΙΚΑΙΩΜΑΤΑ ΕΤΑΙΡΕΙΑΣ</strong>
                                </p>

                                <p className="simple-text">
                                    <strong>5.1 Οριστική Απενεργοποίηση (διαγραφή) της Εφαρμογής:</strong> Η Εταιρεία ρητά επιφυλάσσεται του
          δικαιώματός της να απενεργοποιήσει οριστικά την Εφαρμογή και την Υπηρεσία αντίστοιχα, σε περίπτωση που η
          Εταιρεία κατά την απόλυτη διακριτική της ευχέρεια κρίνει ότι ο Ταξιδιώτης (Εσείς) (α) παραβιάζει την Πολιτική
          Αποδεκτών Χρήσεων της Εφαρμογής, ή/και (β) παραβιάζει οποιονδήποτε όρο ή διάταξη των παρόντων Όρων Χρήσης και
          δη των παρεχόμενων από αυτόν εγγυήσεων και η παράβαση αυτή δύναται κατά την απόλυτη κρίση της Εταιρείας και σε
          αντίθεση ενδεχομένως με άλλες διατάξεις του παρόντος, να προκαλέσει άμεσο κίνδυνο-ζημία στην Εταιρεία ή/και σε
          οποιονδήποτε άλλο χρήστης της Εφαρμογής, ή/και (γ) έχει διαπράξει κατά την κρίση της Εταιρείας ποινικό ή άλλο
          αδίκημα κατά την χρήση της Εφαρμογής και της παρεχόμενης μέσω αυτής Υπηρεσίας ή/και την παροχή της Υπηρεσίας
          Μεταφοράς ή/και (δ) για οποιονδήποτε άλλο σπουδαίο λόγο μη αναφερόμενο στο παρόν.
        </p>

                                <p className="simple-text">
                                    <strong>5.2</strong> Στις ως άνω περιπτώσεις η απενεργοποίηση της Εφαρμογής/Υπηρεσίας ενδέχεται να είναι άμεση
          και αυτόματη, χωρίς η Εταιρεία να δίνει δικαίωμα στον Ταξιδιώτη να άρει τυχόν παραβίαση, η Εταιρεία ρητά δε
          επιφυλάσσεται παντός νομίμου δικαιώματός της συμπεριλαμβανομένου και του δικαιώματος για αποκατάσταση
          οποιασδήποτε ζημίας έχει τυχόν υποστεί από τις πράξεις ή/και παραλείψεις του Χρήστη σύμφωνα με τα ανωτέρω.
        </p>

                                <p className="simple-text">
                                    <strong>5.3</strong> <strong>Προσωρινή Απενεργοποίηση της Εφαρμογής</strong>: Εκτός των ως άνω περιπτώσεων ή
          και άλλων διατάξεων του παρόντος, η παραβίαση των οποίων ενδεχομένως να συνεπάγεται και προσωρινή
          απενεργοποίηση της Εφαρμογής/Υπηρεσίας, η Εταιρεία ρητά επιφυλάσσεται του δικαιώματός της κατά την απόλυτη
          διακριτική της ευχέρεια και σε αντίθεση ενδεχομένως με άλλες διατάξεις του παρόντος, να απενεργοποιήσει
          προσωρινά την Εφαρμογή/Υπηρεσία για τον Ταξιδιώτη, άμεσα και αζημίως, σε περίπτωση που αυτή κρίνει ότι ο
          Ταξιδιώτης έχει παραβιάσει οποιονδήποτε όρο του παρόντος και μέχρις ότου άρει την παράβαση εντός διαστήματος
          που η Εταιρεία κατά την απόλυτη διακριτική της ευχέρεια που έχει τάξει. Σε περίπτωση μη συμμόρφωσης του, η
          Εταιρεία δύναται να απενεργοποιήσει οριστικά την Εφαρμογή για τον Ταξιδιώτη αυτόν, επιφυλασσόμενης ρητά παντός
          νομίμου δικαιώματός της συμπεριλαμβανομένου και του δικαιώματος για αποκατάστασης οποιασδήποτε ζημίας έχει
          τυχόν υποστεί από τις πράξεις ή/και παραλείψεις του Χρήστη σύμφωνα με τα ανωτέρω.
        </p>

                                <p className="simple-text">
                                    <strong>5.4</strong> Ρητά συμφωνείται ότι ο Χρήστης δε δύναται για όσες περιπτώσεις των άρθρων 5.1 και
          5.2&nbsp; η Εταιρεία βάσισε τις αποφάσεις σε αυτοματοποιημένα μέσα, να αξιώσει την ανόρθωση οποιαδήποτε ζημίας
          του από την απενεργοποίηση της Εφαρμογής/Υπηρεσίας σύμφωνα με τις ως άνω διατάξεις, ακόμη και σε περίπτωση που
          οι ενδείξεις στις οποίες βασίστηκε η Εταιρεία για την απενεργοποίηση της ήταν εσφαλμένες, καθόσον αναγνωρίζει
          και αποδέχεται ότι τα αυτοματοποιημένα μέσα που χρησιμοποιεί η Εφαρμογή για την εξεύρεση τυχόν παράνομων ή
          αντισυμβατικών δραστηριοτήτων των χρηστών βασίζονται είτε σε γενικές τυποποιημένες/αυτοματοποιημένες εντολές
          ενός προγράμματος λογισμικού το οποίο ενδέχεται να περιλαμβάνει ή/και να προβεί σε σφάλματα.
        </p>

                                <p className="simple-text">
                                    <strong>5.5</strong> <strong>Διακοπή Λειτουργίας της Εφαρμογής:</strong> Η Εταιρεία διατηρεί το δικαίωμα για
          τεχνικούς λόγους, λόγους&nbsp; συντήρησης ή/και αναβάθμισης της Εφαρμογής να διακόπτει τη λειτουργία της
          προσωρινά και σε τακτά χρονικά διαστήματα. Σε περίπτωση που η διακοπή αυτή διαρκέσει πάνω από ένα εύλογο
          χρονικό διάστημα κατά την απόλυτη κρίση της Εταιρείας, ή έχει ήδη καθοριστεί από την Εταιρεία προγραμματισμένη
          διακοπή, η Εταιρεία θα ενημερώνει τους Χρήστες αναφορικά με το χρόνο και τη διάρκεια διακοπής της λειτουργίας
          της. Ρητά συμφωνείται ότι οι Χρήστες δε δύνανται να εγείρουν αξιώσεις κατά της Εταιρείας για οποιαδήποτε ζημία
          τυχόν υποστούν εκ του λόγου αυτού ακόμα και αν η Εταιρεία κατά την απόλυτη διακριτικής της ευχέρεια δεν τους
          ειδοποιήσει για τη διακοπή αυτή.
        </p>

                                <p className="simple-text">
                                    <strong>5.6</strong> Πλέον των όσων ρητά ορίζονται στο παρόν, η Εταιρεία μπορεί οποτεδήποτε να καταγγείλει,
          αλλάξει, αναστείλει ή διακόψει οποιαδήποτε επιμέρους λειτουργία της Εφαρμογής, συμπεριλαμβανομένης και της
          διαθεσιμότητας της ίδιας της Υπηρεσίας, με την προϋπόθεση ότι θα έχει ενημερώσει προς τούτο εγγράφως τους
          Ταξιδιώτες και τουλάχιστον ένα εύλογο χρονικό διάστημα πριν τη διακοπή που περιγράφεται στην παρούσα διάταξη.
        </p>

                                <p className="simple-text">
                                    <strong>5.7 Περιορισμός Εγγυήσεων</strong>: Η Εταιρεία, οι διευθυντές, μέτοχοι, υπάλληλοι, προμηθευτές,
          χορηγοί, συνεργάτες ενεργούν πάντοτε καλόπιστα και στο πλαίσιο των όσων προβλέπει ο νόμος και οι παρόντες
          Γενικοί Όροι. Συνεπώς έχουμε λάβει και εξακολουθούμε αδιαλείπτως να λαμβάνουμε όλα τα απαραίτητα τεχνικά ή
          άλλα μέτρα και καταβάλουμε κάθε δυνατή προσπάθεια προκειμένου (α) η Εφαρμογή να λειτουργεί αδιαλείπτως και
          προσηκόντως χωρίς προβλήματα, διακοπές, καθυστερήσεις, σφάλματα, ιούς ή λάθη, (β) ελαττώματα ή λάθη θα
          διορθώνονται, γ) τα δεδομένα/πληροφορίες που διαβιβάζονται μέσω της Εφαρμογής αφενός να μην αλλοιώνονται,
          αφετέρου να προστατεύονται με τη δημιουργία αντιγράφων ασφαλείας, καθόσον τα συστήματα ασφαλείας της
          υπόκεινται σε περιορισμούς, (δ) η τεχνολογία που χρησιμοποιεί η ίδια ή οι εξυπηρετητές (Servers) μέσω των
          οποίων η Εφαρμογή τίθεται στη διάθεση των Χρηστών να μην περιέχει ιούς ή άλλα επιζήμια συστατικά, ε)
          οποιοδήποτε λογισμικό&nbsp; ή περιεχόμενο που είναι διαθέσιμο μέσω της υπηρεσίας είναι ελεύθερο από ιούς ή
          αλλά επιβλαβή στοιχεία, ωστόσο ΔΕΝ ΠΑΡΕΧΟΥΜΕ ΣΧΕΤΙΚΕΣ ΕΓΓΥΗΣΕΙΣ για όλα τα ανωτέρω.
        </p>

                                <p className="simple-text">
                                    <strong>5.8</strong> Επιπλέον η Εταιρεία και τα ανωτέρω πρόσωπα, ουδεμία εγγύηση παρέχει (α) για την
          καταλληλότητα, αποτελεσματικότητα, επάρκεια της Εφαρμογής αναφορικά με το σκοπό για τον οποίο ο Ταξιδιώτης τη
          χρησιμοποιεί (β) για τη συμβατότητα της Εφαρμογής με Λογισμικά τρίτων που ενδέχεται να χρησιμοποιεί ο
          Ταξιδιώτης και συνδέονται με την Εφαρμογή, εκτός και αν αυτά (Λογισμικά) παρέχονται από την Εταιρεία και μόνο
          στο βαθμό που έχει εγγυηθεί η Εταιρεία στις σχετικές Άδειες της προς τον Ταξιδιώτη, (γ) την ορθή και
          προσήκουσα εκτέλεση των συναλλακτικών υποχρεώσεων των άλλων Ταξιδιωτών που κάνουν χρήση της Εφαρμογής.
        </p>

                                <p className="simple-text">
                                    <strong>5.9 Περιορισμός Υποχρεώσεων:</strong> Η Εφαρμογή και η Υπηρεσία παρέχεται ‘ως έχει και διατίθεται’ και
          κατά τρόπο απολύτως αυτοματοποιημένο και δεν περιλαμβάνει την εισαγωγή από την Εταιρεία οποιουδήποτε στοιχείου
          στο σύστημα της Υπηρεσίας, ούτε παραμετροποιήσεις σε ad hoc βάση. Η Εφαρμογή ενδέχεται στο μέλλον να
          παραμετροποιηθεί κατά την απόλυτη διακριτική ευχέρεια της Εταιρείας.
        </p>

                                <p className="simple-text">
                                    <strong>5.10</strong> Η Εταιρεία λαμβάνει όλα τα απαραίτητα τεχνικά και άλλα μέτρα προκειμένου να διασφαλίσει
          ότι οι χρήστες (Ταξιδιώτες) της Εφαρμογής συμμορφώνονται με τους παρόντες Γενικούς Όρους, ωστόσο ως Πάροχος
          Υπηρεσιών δεν έχει γενική και βάσει νόμου υποχρέωση (α) ελέγχου, (β) δραστήριας αναζήτησης γεγονότων ή
          περιστάσεων που δείχνουν ότι κάποιος χρήστης (Ταξιδιώτης) έχει προβεί σε παράνομες ή αντισυμβατικές
          δραστηριότητες κατά τη χρήση της Εφαρμογής/Υπηρεσίας, (γ) παροχής υπηρεσιών τεχνικής υποστήριξης ή/και
          εκπαίδευσης των Ταξιδιωτών.
        </p>

                                <p className="simple-text">
                                    <strong>5.11</strong> <strong>Περιορισμός Ευθύνης:</strong> Πλέον των όσων ρητά ορίζονται στο παρόν, η
          Εταιρεία ουδεμία ευθύνη αστική, ποινική, ή άλλως, φέρει έναντι του Ταξιδιώτη, τυχόν εξουσιοδοτημένων υπό αυτόν
          χρηστών του, των Συνεργατών του, Εργοδότου ή υπαλλήλου του ή/και οποιουδήποτε τρίτου έλκει δικαιώματα από
          αυτούς, σε περίπτωση που οποιοσδήποτε από τους ανωτέρω, κατά τη χρήση της Υπηρεσίας υποστεί ζημία άμεση,
          έμμεση, παρεπόμενη, αποθετική ως προς τα κέρδη του, την επιχείρησή του, τα εισοδήματά του, τη φήμη του,
          μειωθούν οι πωλήσεις των προϊόντων του και η συχνότητα παροχής των υπηρεσιών του, υποστεί οποιεσδήποτε
          (άμεσες, έμμεσες, επακόλουθες ή ποινικές) ζημιές, απώλειες ή χρεώσεις, τυχόν (προσωπικό) τραυματισμό, θάνατο,
          φθορά προσωπικής ιδιοκτησίας ή άλλες (άμεσες, έμμεσες, ειδικές, επακόλουθες ή ποινικά κολάσιμες) φθορές,
          απώλειες ή χρεώσεις ή υποστεί οποιαδήποτε άλλη ζημία άμεση, έμμεση ή αποθετική, οικονομικής ή άλλης φύσης,
          διαφυγόντα κέρδη, οφειλόμενη σε:
        </p>

                                <p className="simple-text">
                                    5.11.1 Καθυστερημένη ή μη προσήκουσα ή/και αποδοχή της υπηρεσίας μεταφοράς ή εκτέλεσης αυτής, εξαιτίας λαθών,
                                    παραλήψεων, τεχνικών κολλημάτων λαθών, βλαβών ή δυσλειτουργιών των τηλεπικοινωνιακών Δικτύων, του Διαδικτύου,
                                    του δικτυακού τόπου, των Παρόχων Υπηρεσιών Διαδικτύου.
        </p>

                                <p className="simple-text">
                                    5.11.2 Τυχόν οριστική ή προσωρινή απενεργοποίηση της Υπηρεσίας, διακοπή λειτουργίας του συνόλου της Υπηρεσίας
                                    ή μεμονωμένων λειτουργιών της ή τεχνικά κολλήματα της Υπηρεσίας σύμφωνα με τα οριζόμενα στο παρόν.
        </p>

                                <p className="simple-text">
                                    5.11.3 Γεγονότα, καταστάσεις, ενέργειες, πράξεις ή/και παραλείψεις της Εταιρείας ή τρίτων συμπεριλαμβανομένων
                                    και των άλλων Χρηστών/ Εξουσιοδοτημένων Χρηστών για τις οποίες η Εταιρεία δεν παρέχει εγγυήσεις και δεν φέρει
                                    υποχρέωση σύμφωνα με τα οριζόμενα στο παρόν.
        </p>

                                <p className="simple-text">
                                    5.11.4 Χρήση εκ μέρους των Οδηγών, ή και τρίτων των δεδομένων των Ταξιδιωτών (συμπεριλαμβανομένων και τυχόν
                                    προσωπικών&nbsp; δεδομένων τους) για σκοπούς άλλους από τη χρήση της Υπηρεσίας.
        </p>

                                <p className="simple-text">
                                    5.11.5 Παραβίαση της ισχύουσας νομοθεσίας σχετικά με την προστασία προσωπικών δεδομένων ή άλλων ισχυόντων
                                    νόμων που σχετίζονται, για παράδειγμα, με τη χρήση της Υπηρεσίας.
        </p>

                                <p className="simple-text">
                                    5.11.6 Παράβαση της Πολιτικής Αποδεκτών Χρήσεων, των παρόντων Όρων Χρήσης εκ μέρους των ίδιων των Ταξιδιωτών
                                    ή/και άλλων χρηστών της Εφαρμογής.
        </p>

                                <p className="simple-text">
                                    5.11.7 Τυχόν ασυμφωνία της ώρας του συστήματος της Υπηρεσίας με κάποια ώρα που έχει καθοριστεί επίσημα.
        </p>

                                <p className="simple-text">5.11.8 Γεγονότα ανωτέρας βίας.</p>

                                <p className="simple-text">
                                    5.11.9 Κακή συμπεριφορά ή/και παραβίαση νόμων από τους Οδηγούς για ίδιον όφελος, μη εμφάνισης του Οδηγού ή
                                    καθυστέρησης πέραν όσων έχουν αναφερθεί στο παρόν κείμενο.
        </p>

                                <p className="simple-text">
                                    5.11.10 Απώλεια αντικειμένων ή οποιοδήποτε ατύχημα κατά τη διάρκεια της Μεταφοράς.
        </p>

                                <p className="simple-text">
                                    <strong>5.12</strong> Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΟΠΟΙΑΔΗΠΟΤΕ ΖΗΜΙΑ YΠΟΣΤΕΙ Ο ΧΡΗΣΤΗΣ
          ΤΑΞΙΔΙΩΤΗΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΕΝΔΕΙΚΤΙΚΑ ΑΠΩΛΕΙΑ ΔΕΔΟΜΕΝΩΝ ΑΠΟ ΚΑΘΥΣΤΕΡΗΣΕΙΣ, ΜΗ ΠΑΡΑΔΟΣΕΙΣ, ΛΑΘΗ, ΣΥΣΤΗΜΑΤΑ
          DOWN TIME , ΕΣΦΑΛΜΕΝΕΣ ΠΑΡΑΔΟΣΕΙΣ, ΔΙΑΚΟΠΕΣ ΔΙΚΤΥΟΥ Ή ΣΥΣΤΗΜΑΤΟΣ, ΦΘΟΡΑ ΑΡΧΕΙΩΝ, ΔΙΑΚΟΠΗ ΤΗΣ ΥΠΗΡΕΣΙΑΣ, ΠΟΥ
          ΠΡΟΚΑΛΕΙΤΑΙ ΑΠΟ ΑΜΕΛΕΙΑ ΤΗΣ, ΑΠΟ ΣΦΑΛΜΑΤΑ Ή / ΚΑΙ ΠΑΡΑΛΕΙΨΕΙΣ ΤΩΝ ΧΡΗΣΤΩΝ.
        </p>

                                <p className="simple-text">
                                    <strong>5.13</strong> Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΕΙΝΑΙ ΥΠΕΥΘΥΝΗ ΓΙΑ ΤΥΧΟΝ ΚΑΘΥΣΤΕΡΗΣΕΙΣ, ΑΠΟΤΥΧΙΕΣ ΠΑΡΑΔΟΣΗΣ Η’
          ΟΠΟΙΑΔΗΠΟΤΕ ΑΛΛΗ ΑΠΩΛΕΙΑ Ή ΖΗΜΙΑ ΠΟΥ ΠΡΟΚΥΠΤΕΙ ΑΠΟ ΤΗ ΜΕΤΑΦΟΡΑ ΔΕΔΟΜΕΝΩΝ ΜΕΣΩ ΔΙΚΤΥΩΝ ΚΑΙ ΕΓΚΑΤΑΣΤΑΣΕΩΝ
          ΕΠΙΚΟΙΝΩΝΙΩΝ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΟΥ ΤΟΥ ΔΙΑΔΙΚΤΥΟΥ, ΕΝΩ Ο ΧΡΗΣΤΗΣ – ΤΑΞΙΔΙΩΤΗΣ ΑΝΑΓΝΩΡΙΖΕΙ ΟΤΙ Η ΥΠΗΡΕΣΙΑ ΚΑΙ
          ΟΠΟΙΑΔΗΠΟΤΕ ΔΕΔΟΜΕΝΑ ΠΟΥ ΑΠΟΣΤΕΛΛΟΝΤΑΙ ΑΠΟ ΑΥΤΟΝ/ΑΥΤΗ ΥΠΟΚΕΙΝΤΑΙ ΣΤΟΥΣ ΠΕΡΙΟΡΙΣΜΟΥΣ ΚΑΘΥΣΤΕΡΗΣΕΩΝ ΚΑΙ
          ΟΠΟΙΩΝΔΗΠΟΤΕ ΑΛΛΩΝ ΠΡΟΒΛΗΜΑΤΩΝ ΠΟΥ ΕΜΠΕΡΙΕΧΕΙ Η ΧΡΗΣΗ ΤΕΤΟΙΩΝ ΜΕΣΩΝ ΕΠΙΚΟΙΝΩΝΙΑΣ.
        </p>

                                <p className="simple-text">
                                    <strong>5.14 </strong>Σε κάθε περίπτωση ρητά συμφωνείται ότι η Εταιρεία ευθύνεται μόνο για άμεσες ζημιές, οι
          οποίες προκύπτουν λόγω δόλου ή βαριάς αμέλειας εκ μέρους της. Με την επιφύλαξη αναγκαστικών διατάξεων δικαίου,
          αποκλείεται ρητά η ευθύνη της Εταιρείας για άμεση ζημία λόγω ελαφράς αμέλειας ανεξαρτήτως νομικού λόγου.
          Αποκλείεται πλήρως και ρητά η ευθύνη της Εταιρείας για έμμεσες ή επακόλουθες ζημίες, ανεξαρτήτως λόγου. Βάσει
          των περιορισμών που ισχύουν σύμφωνα με το παρόν και στον βαθμό που επιτρέπεται από τη νομοθεσία, οποιαδήποτε
          ευθύνη της Εταιρείας περιορίζεται στο συνολικού ύψους που αντιστοιχεί στο συνολικό κόστος της Υπηρεσίας
          Μεταφοράς.
        </p>

                                <p className="simple-text">
                                    <strong>5.15</strong> ΟΙ ΠΕΡΙΟΡΙΣΜΟΙ ΠΟΥ ΤΙΘΕΝΤΑΙ ΣΤΗΝ ΠΑΡΟΥΣΑ ΓΙΑ ΤΗΝ ΑΠΟΚΑΤΑΣΤΑΣΗ ΖΗΜΙΑΣ ΙΣΧΥΟΥΝ ΑΚΟΜΑ ΚΑΙ
          ΣΤΗΝ ΠΕΡΙΠΤΩΣΗ ΠΟΥ ΟΙ ΟΡΟΙ ΤΗΣ ΠΑΡΟΥΣΑΣ ΔΕΝ ΕΧΟΥΝ ΕΚΠΛΗΡΩΘΕΙ Η ΘΕΩΡΟΥΝΤΑΙ ΑΚΥΡΟΙ Η ΜΗ ΕΦΑΡΜΟΣΙΜΟΙ ΚΑΙ Ο
          ΧΡΗΣΤΗΣ ΑΝΑΓΝΩΡΙΖΕΙ ΟΤΙ ΟΙ ΠΑΡΟΝΤΕΣ ΠΕΡΙΟΡΙΣΜΟΙ ΕΙΝΑΙ ΛΟΓΙΚΟΙ ΚΑΙ ΑΠΟΤΕΛΟΥΝ ΤΗ ΒΑΣΗ ΤΗΣ ΠΑΡΟΥΣΑΣ ΚΑΙ ΟΤΙ ΧΩΡΙΣ
          ΤΟΥΣ ΠΕΡΙΟΡΙΣΜΟΥΣ ΑΥΤΟΥΣ Η ΕΤΑΙΡΕΙΑ ΔΕ ΘΑ ΠΡΟΣΧΩΡΟΥΣΕ ΣΤΗΝ ΠΑΡΟΥΣΑ.
        </p>

                                <p className="simple-text">
                                    <strong>5.16</strong> <strong>Παροχή Πληροφοριών:</strong> Η Εταιρεία, σε περίπτωση που τούτο της ζητηθεί από
          αρμόδια διωκτική, εποπτική, δικαστική ή άλλη Αρχή, υποχρεούται σε διαβίβαση των δεδομένων του Χρήστη Ταξιδιώτη
          ή/και των Δεδομένων Τρίτων, συμπεριλαμβανομένων και τυχόν προσωπικών δεδομένων των Εξουσιοδοτημένων Χρηστών ή
          τρίτων που χορηγούνται στην Εταιρεία σύμφωνα με το παρόν, και οποιονδήποτε άλλων πληροφοριών ανταλλάσσονται
          μέσω της Υπηρεσίας μεταξύ των Χρηστών χωρίς προηγούμενη ενημέρωση ή συγκατάθεση του Χρήστη, Εξουσιοδοτημένου
          Χρήστη ή τρίτου υποκειμένου των δεδομένων, για την απαραίτητη προστασία από κινδύνους για την κρατική και
          δημόσια ασφάλεια καθώς και για τη δίωξη εγκληματικών πράξεων, συμπεριλαμβανομένων και των φορολογικών
          εγκλημάτων και παραβάσεων.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>6. ΧΡΕΩΣΕΙΣ ΚΑΙ ΤΙΜΟΛΟΓΙΑΚΗ ΠΟΛΙΤΙΚΗ</strong>
                                </p>

                                <p className="simple-text">
                                    Η Εταιρεία παρέχει τη δυνατότητα προ-πληρωμής μέσω PayPal, Checkout, ή Shopify της Μεταφοράς ενός
          Ταξιδιώτη. Το κόστος της Μεταφοράς (<strong>«Κόστος Μεταφοράς»</strong>) είναι το ποσό που εμφανίζεται σε Εσάς
          όταν υπάρχει σχετική αναφορά μέσω της Εφαρμογής ή το ποσό που εμφανίζεται σε Εσάς πριν την πληρωμή. Το Κόστος
          Μεταφοράς περιλαμβάνει το τέλος του Οδηγού και το έσοδο της Εταιρείας. Το έσοδο της Εταιρείας υπολογίζεται ως
          ποσοστό ή μπορεί να είναι σταθερό τέλος και ο υπολογισμός του μπορεί να αλλάξει σύμφωνα με την επιθυμία της
          Εταιρείας και χωρίς προειδοποίηση σε Σας, εάν κριθεί απαραίτητο από την Εταιρεία για τη λειτουργίας της.
        </p>

                                <p className="simple-text">
                                    Ο Οδηγός είναι εξολοκλήρου αποκλειστικός υπεύθυνος να παράσχει στον Ταξιδιώτη εκτυπωμένη απόδειξη και να τηρεί
                                    τους ισχύοντες φορολογικούς κανονισμούς στο συνολικό έσοδο που προέρχεται από τη Μεταφορά.
        </p>

                                <p className="simple-text">
                                    Επιστροφές Χρημάτων: Το πλήρες ποσό του Κόστους Μεταφοράς (συμπεριλαμβανομένου και του εσόδου της Εταιρείας)
                                    χρεώνεται άμεσα κατόπιν της ολοκλήρωσης της σχετικής επιλογής στον PayPal/Checkout/Shopify
                                    λογαριασμό του Ταξιδιώτη και μεταφέρεται (χωρίς το έσοδο της Εταιρείας) στο λογαριασμό του Οδηγού.
        </p>

                                <p className="simple-text">
                                    Σχετικά με την Πολιτική Επιστροφής Χρημάτων παρακαλώ συμβουλευτείτε την Ενότητα II.3-Πολιτική για τις
                                    Υπηρεσίες Μεταφοράς.
        </p>

                                <p className="simple-text">
                                    Προωθητικές Ενέργειες/Προσφορές: Είναι στη διακριτική ευχέρεια της Εταιρείας να δημιουργήσει προωθητικές
                                    ενέργειες/προσφορές, με διάφορα χαρακτηριστικά, και να τις διαθέσει σε οποιοδήποτε Ταξιδιώτη.
        </p>

                                <p className="simple-text">
                                    Διαδικασία Πληρωμών: Όλες οι πληρωμές ή χρεώσεις, όποτε εφαρμόζονται, θα πραγματοποιούνται μέσω Paypal ή
                                    Braitree ή Checkout ή Shopify, που αποτελούν υπηρεσίες τρίτων και χρησιμοποιούνται από την Εταιρεία.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>7. ΓΝΩΜΕΣ ΤΑΞΙΔΙΩΤΩΝ</strong>
                                </p>

                                <p className="simple-text">
                                    <strong>7.1 </strong>Μόνο οι Ταξιδιώτες οι οποίοι έχουν λάβει την Υπηρεσία Μεταφοράς δύνανται να
          αξιολογήσουν/σχολιάσουν την Υπηρεσία Μεταφοράς, τους Οδηγούς, τα οχήματά τους ή και ό,τι άλλο κρίνουν σκόπιμο
          και άξιο σχολιασμού ή παρατήρησης θετικής ή και αρνητικής.
        </p>

                                <p className="simple-text">
                                    <strong>7.2</strong> Ειδικότερα, η Εταιρεία μετά την πραγματοποίηση της Μεταφοράς αποστέλλει email στον
          Ταξιδιώτη προκειμένου να τον προσκαλέσει να αξιολογήσει τον Οδηγό που πραγματοποίησε τη Μεταφορά και την
          Εταιρεία. Ο Ταξιδιώτης αξιολογεί τον Οδηγό βάζοντας βαθμολογία με αστερίσκους από το ένα (1) μέχρι το πέντε
          (5). Για βαθμολογία τριών αστερίσκων και κάτω ο Ταξιδιώτης θα πρέπει να επιλέξει έναν από τους λόγους που του
          παρέχονται για να αιτιολογήσει την αρνητική αξιολόγησή του. Σε κάθε περίπτωση ο Ταξιδιώτης έχει το δικαίωμα να
          αποστείλει σχόλια αναφορικά με τον Οδηγό, την Εταιρεία, τη Μεταφορά ή και ό,τι άλλο.
        </p>

                                <p className="simple-text">
                                    <strong>7.3 </strong>Η Εταιρεία δεν αποζημιώνει ούτε και επιβραβεύει τους Ταξιδιώτες που έχουν παραθέσει την
          αξιολόγηση/σχόλιά τους. Τη κριτική, την αξιολόγηση και τα σχόλια των Ταξιδιωτών δύναται η Εταιρεία και κατά
          την απόλυτη διακριτική της ευχέρεια να τα χρησιμοποιήσει για την ενημέρωση των Ταξιδιωτών, για σκοπούς
          διαφήμισης και για σκοπούς δικής της αξιολόγησης των Οδηγών.
        </p>

                                <p className="simple-text">
                                    <strong>7.4 </strong>Οι Ταξιδιώτες έχουν το δικαίωμα να δημοσιεύουν αξιολογήσεις/σχόλια σε δημόσια μέσα π.χ
          μέσα κοινωνικής δικτύωσης ή ιστοσελίδες όπως FB, TRIPADVISOR και είναι στη διακριτική ευχέρεια της Εταιρείας
          το αν θα λάβει υπόψη και τις εν λόγω αξιολογήσεις/σχόλια στα πλαίσια της αξιολόγησής σας από την ίδια και εν
          γένει τον τρόπο διαχείρισής αυτών.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>8. ΑΠΟΠΟΙΗΣΗ ΕΥΘΥΝΩΝ</strong>
                                </p>

                                <p className="simple-text">
                                    Με την αποδοχή της παρούσας συμφωνίας και τη χρήση του λογισμικού της Εφαρμογής, στο βαθμό που επιτρέπεται από
                                    το νόμο, συμφωνείτε ότι Εσείς θα υπερασπίζεστε, αποζημιώνετε και υποστηρίζετε την Εταιρεία, τους
                                    δικαιοπαρόχους της και κάθε μητρικό οργανισμό, θυγατρική, σχετιζόμενη εταιρεία, διανομείς, συνεργάτες,
                                    μετόχους, αξιωματούχους, διευθυντές, εργαζομένους, αντιπροσώπους, μέλη, δικηγόρους, δικαιοδόχους, πράκτορες, ή
                                    άλλους που συμμετέχουν στη δημιουργία, χορηγία, προώθηση, ή αλλιώς διαθέτουν το λογισμικό και τα περιεχόμενα
                                    σε αυτό δεδομένα, εναντίων όλων των πιθανών απαιτήσεων, κοστών, ζημιών, απωλειών, ευθυνών, και εξόδων
                                    (συμπεριλαμβανομένων των εξόδων για νομικές υπηρεσίες) που προκύπτουν από ή σχετίζονται με:
        </p>

                                <p className="simple-text">
                                    α) οποιαδήποτε τιμωριτική, ειδική, έμμεση ή επακόλουθη απώλεια ή ζημία, οποιαδήποτε απώλεια/ζημία στην
                                    παραγωγή, απώλεια κέρδους, απώλεια εσόδων, απώλεια συμβολαίων, ζημία ή απώλεια καλής θέλησης και φήμης,
                                    απώλεια απαιτήσεων προς τρίτους
        </p>

                                <p className="simple-text">
                                    β) οποιαδήποτε ανακρίβεια σχετιζόμενη με πληροφορίες (συμπεριλαμβανομένων των προσωπικών δεδομένων, σχολίων
                                    και αξιολογήσεων) των χρηστών που έχουν γίνει διαθέσιμες στο λογισμικό μας
        </p>

                                <p className="simple-text">γ) τις υπηρεσίες που προσφέρονται από τον Οδηγό</p>

                                <p className="simple-text">
                                    δ) οποιαδήποτε (άμεση/ο, έμμεση/ο, επακόλουθη/ο, ή τιμωριτική/ο) ζημία, απώλεια, κόστος που επωμίζεστε ή
                                    αναλάβατε Εσείς (συμπεριλαμβανομένων των εξόδων για νομικές υπηρεσίες) σύμφωνα με, που προκύπτει από,
                                    σχετιζόμενη/ο με τη χρήση, αδυναμία χρήσης, καθυστέρηση, ή ιό του λογισμικού Μας ή/και του διακομιστή στον
                                    οποίο το λογισμικό αυτό αποθηκεύεται και διακομίζεται
        </p>

                                <p className="simple-text">
                                    ε) για οποιαδήποτε(προσωπική) βλάβη, θάνατο, καταστροφή περιουσίας ή άλλες (άμεσες, έμμεσες, ειδικές,&nbsp;
                                    επακόλουθες ή τιμωρητικές) ζημίες, απώλειες που προκλήθηκαν ή πληρώθηκαν από Εσάς είτε εξαιτίας νομικών
                                    ενεργειών, λαθών, παραβάσεων, (βαριάς) αμέλειας, δόλιων παραβάσεων, παραλείψεων, μη εμφάνισης, παραποιήσεων,
                                    αδικοπραξίας ή απόλυτης ευθύνης που μπορεί να αποδοθεί συνολικά ή εν μέρει στον χρήστη(εργοδότες του,
                                    διευθυντές, προϊσταμένους, εκπροσώπους, αντιπροσώπους ή θυγατρικές εταιρειών) (περιλαμβανομένου αμοιβές και
                                    κόστη δικηγόρου), περιλαμβανομένου οποιασδήποτε (εν μέρει) ακύρωσης, διπλής κράτησης, απεργίας, λόγου ανωτέρας
                                    βίας ή οποιοδήποτε άλλο γεγονός που δεν μπορούμε να ελέγξουμε.
        </p>

                                <p className="simple-text">
                                    στ)&nbsp; κάθε πληροφορία στο λογισμικό μερικές φορές συνδέεται με εξωτερικούς διαδικτυακούς τόπους τους
                                    οποίους Εμείς δεν μπορούμε να ελέγξουμε και για τους οποίους δεν φέρουμε καμία ευθύνη.&nbsp;
        </p>

                                <p className="simple-text">
                                    ζ) οποιαδήποτε παραβίαση του χρήστη ή παραβίαση οποιουδήποτε όρου αυτής της σύμβασης ή οποιουδήποτε
                                    εφαρμοστέου νόμου ή κανόνα δικαίου, είτε αναφέρεται είτε όχι στο παρόν.&nbsp;
        </p>
                            </StyledCard>
                        </DesktopRightContainer>
                    </DesktopFlexContainer>
                </StyledContent>
            </StyledPage>
        );
    }
    return (
        <StyledPage>
            {window.innerWidth < 768 && <StickyHeader goBack={() => history.push(window.location.href.split("/terms_and_conditions")[0].replace(window.location.origin, ""))} title={t('legal.terms')} subtitle="" />}
            <StyledContent>
                <DesktopFlexContainer align="flex-start">
                    <DesktopList padding="0">
                        {isAuthorised.get() ? <LoggedInUser isSide /> : <GuestUser isSide />}
                    </DesktopList>
                    <DesktopRightContainer >
                        <StyledCard margin="10px" padding="20px 15px">
                            
                                            <h1 className="p1" style={{marginTop: "40px"}}>
                                                <span className="s1">
                                                    <b>
                                                        ΓΕΝΙΚΟΙ ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΚΑΙ ΤΗΣ ΥΠΗΡΕΣΙΑΣ ΜΕ ΤΗΝ ΕΜΠΟΡΙΚΗ ΟΝΟΜΑΣΙΑ
                                                        «WELCOMER»
                  </b>
                                                </span>
                                            </h1>
                                            <p className="p1">
                                                <span className="s1">
                                                    <b>ΠΕΡΙΕΧΟΜΕΝΟ</b>
                                                </span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li1">
                                                    <span className="s1">
                                                        I. <b>ΓΕΝΙΚΑ</b>
                                                    </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li1">
                                                    <span className="s1">
                                                        <b>II. ΟΡΟΙ ΧΡΗΣΗΣ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ</b>
                                                    </span>
                                                </li>
                                            </ul>
                                            <ol className="ol1">
                                                <li className="li1">
                                                    <span className="s1">Περιγραφή Υπηρεσιών Μεταφοράς </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Νομική υπόσταση της Εταιρείας </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Πολιτική για τις Υπηρεσίες Μεταφοράς </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Δικαιώματα και Υποχρεώσεις Ταξιδιωτών- Δηλώσεις-Εγγυήσεις</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">
                                                        Δικαιώματα Εταιρείας<span className="Apple-converted-space">&nbsp; </span>– Περιορισμός Ευθύνης{" "}
                                                    </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Χρεώσεις και Τιμολογιακή Πολιτική </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Γνώμες Ταξιδιωτών </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Δήλωση αποποίησης</span>
                                                </li>
                                            </ol>
                                            <ul className="ul1">
                                                <li className="li1">
                                                    <span className="s1">
                                                        <b>ΙΙΙ. ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</b>
                                                    </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li1">
                                                    <span className="s1">
                                                        <b>IV. ΓΕΝΙΚΟΙ ΝΟΜΙΚΟΙ ΟΡΟΙ</b>
                                                    </span>
                                                </li>
                                            </ul>
                                            <ol className="ol1">
                                                <li className="li1">
                                                    <span className="s1">Πνευματική Ιδιοκτησία</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Τεχνική Υποστήριξη</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Εξωτερικοί Σύνδεσμοι</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Επικοινωνία</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Τελικοί όροι</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Παραίτηση</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Ακυρότητα όρων</span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Εκχώρηση </span>
                                                </li>
                                                <li className="li1">
                                                    <span className="s1">Εφαρμοστέο Δίκαιο</span>
                                                </li>
                                            </ol>
                                            <ul className="ul1">
                                                <li className="li1">
                                                    <span className="s1">
                                                        <b>V. ΠΟΛΙΤΙΚΗ ΑΠΟΔΕΚΤΩΝ ΧΡΗΣΕΩΝ ΤΗΣ ΕΦΑΡΜΟΓΗΣ</b>
                                                    </span>
                                                </li>
                                            </ul>
                                            <p className="p1">
                                                <span className="s1">
                                                    <b>Ι. ΓΕΝΙΚΑ</b>
                                                </span>
                                            </p>
                                            <p className="p4">
                                                <span>
                                                    Οι ακόλουθοι Γενικοί Όροι και Προϋποθέσεις της Χρήσης της Εφαρμογής και της Υπηρεσίας (εφεξής οι{" "}
                                                </span>
                                                <b>«Όροι Χρήσης» </b>
                                                <span>ή </span>
                                                <b>«Γενικοί Όροι Χρήσης»</b>
                                                <span>) έχουν καθορισθεί από την Εταιρεία με την επωνυμία </span>
                                                <span>“</span>
                                                <b>WELCOME TRAVEL TECHNOLOGIES HOLDINGS LTD</b>
                                                <span>”</span> <span>που εδρεύει στo Ηνωμένο Βασίλειο (</span>
                                                <span>1 Wheler Road, Coventry CV3 4LB, UK</span>
                                                <span>) με ΑΦΜ </span>
                                                <span>289 0786 46 </span>
                                                <span>ΚΑΙ αριθμ. εταιρείας </span>
                                                <span>10879236</span>
                                                <span>, (εφεξής η </span>
                                                <b>«Εταιρεία» </b>
                                                <span>ή</span>
                                                <b> «Εμείς» </b>
                                                <span>ή</span>
                                                <b> «Μας» </b>
                                                <span>ή</span>
                                                <b> «Ημών»</b>
                                                <span>
                                                    ), η οποία νομίμως λειτουργεί και διαχειρίζεται την Εφαρμογή για κινητές συσκευές (Mobile App) με την
                  εμπορική ονομασία “Welcome Pickups  (εφεξής η{" "}
                                                </span>
                                                <b>«Εφαρμογή»</b>
                                                <span>), μέσω της οποίας οι τουρίστες/ταξιδιώτες (εφεξής οι </span>
                                                <b>«Ταξιδιώτες» </b>
                                                <span>ή</span>
                                                <b> «Εσείς» </b>
                                                <span>ή</span>
                                                <b> «Εσάς» </b>
                                                <span>ή</span>
                                                <b> «Σας» </b>
                                                <span>ή</span>
                                                <b> «Υμών» </b>
                                                <span>ή</span>
                                                <b> «Χρήστες»</b>
                                                <span>)</span> <span>κάνουν κράτηση για παροχή υπηρεσίας μεταφοράς  (εφεξής </span>
                                                <b>«Υπηρεσία Μεταφοράς»</b>
                                                <span>
                                                    )  εκτελούμενη από Οδηγούς Ιδιωτικών και Δημοσίας Χρήσης Οχημάτων, οι οποίοι έχουν δημιουργήσει
                  Λογαριασμό στη Πλατφόρμα (εφεξής οι{" "}
                                                </span>
                                                <b>«Οδηγοί»</b>
                                                <span>
                                                    ) μέσω της οποίας λαμβάνουν από Εμάς, που λειτουργούμε ως διαμεσολαβητές μεταξύ των Ταξιδιωτών και των
                  Οδηγών, ειδοποιήσεις και αιτήματα για παροχή υπηρεσίας μεταφοράς προς τους Ταξιδιώτες (εφεξής η{" "}
                                                </span>
                                                <b>«Υπηρεσία»</b>
                                                <span>).</span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Οι ακόλουθοι Γενικοί Όροι Χρήσης της Εφαρμογής και της Υπηρεσίας (εφεξής οι <b>«Όροι Χρήσης» </b>ή{" "}
                                                    <b>«Γενικοί Όροι Χρήσης»</b>), αποτελούν τους νομικά δεσμευτικούς όρους και προϋποθέσεις που
                  εφαρμόζονται για την απόκτηση πρόσβασης και τη χρήση της Εφαρμογής και της Υπηρεσίας από τους
                  Ταξιδιώτες, συμπεριλαμβανομένων και οποιονδήποτε προγραμμάτων “SOFTWARE”, υπηρεσιών, χαρακτηριστικών,
                  λειτουργιών, περιεχομένου ιστοσελίδων και υπέρ-συνδέσμων ή/και άλλων εφαρμογών που παρέχονται κατά
                  καιρούς από την Εταιρεία σε συνδυασμό με την Εφαρμογή και την Υπηρεσία.{" "}
                                                </span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Πριν κάνετε χρήση της Εφαρμογής ή/και της Υπηρεσίας, Σας καλούμε να συμβουλευτείτε τους παρόντες Όρους
                                                    Χρήσης και να βεβαιωθείτε ότι συμφωνείτε με αυτούς. Σε περίπτωση διαφωνίας Σας με οποιονδήποτε από
                                                    τους παρόντες Όρους Χρήσης, μη κάνετε χρήση της Εφαρμογής και της Υπηρεσίας. Σε περίπτωση ωστόσο που
                                                    επιθυμείτε κάποια διευκρίνιση ή πληροφορία αναφορικά με τους Όρους Χρήσης ή έχετε κάποια επιφύλαξη ή
                                                    απορία σχετική, μπορείτε να επικοινωνήσετε με το Τμήμα Operations της Εταιρείας, στο τηλέφωνο που
                  βρίσκεται στην Ιστοσελίδα<span className="Apple-converted-space">&nbsp; </span>
                                                    <a href="http://www.welcomepickups.com" title="Www.welcomepickups.com">
                                                        <span className="s3">www.welcomepickups.com</span>
                                                    </a>
                                                    <span className="Apple-converted-space">&nbsp; </span>ή στο email hello@welcomepickups.com πριν τη
                  διενέργεια οποιασδήποτε πράξης Σας στην Εφαρμογή. Με την οποιαδήποτε ωστόσο ενέργειά Σας στην
                  Εφαρμογή, όπως ενδεικτικά την αποδοχή μιας υπηρεσίας μεταφοράς, βεβαιώνετε ότι έχετε διαβάσει,
                  κατανοήσει και ανεπιφύλακτα αποδεχτεί τους Όρους Χρήσης.
                </span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Η Εταιρεία Μας διατηρεί το δικαίωμα να τροποποιεί, ανανεώνει, διαγράφει, προσθέτει, περιορίζει
                                                    μονομερώς α) τους παρόντες Όρους Χρήσης, στο σύνολό τους ή εν μέρει, β) τις Πολιτικές της, γ) την
                  Υπηρεσία, δ) τις τεχνικές προδιαγραφές της Εφαρμογής (εφεξής οι <b>«Αλλαγές»</b>) κατά την απόλυτη
                  διακριτική της ευχέρεια ή/και όταν η οποιαδήποτε αλλαγή κατά τα ανωτέρω επιβάλλεται από το νόμο,
                  ενδεχομένως και χωρίς προηγούμενη ειδοποίηση Σας ή συγκατάθεσή Σας, πάντα ωστόσο στα πλαίσια των
                  συναλλακτικών ηθών και των ορίων που θέτει ο νόμος. Η Εταιρεία αναλαμβάνει την υποχρέωση να Σας
                  ενημερώνει για τις τυχόν αλλαγές σύμφωνα με τα ανωτέρω, μέσω της Εφαρμογής με σχετική ειδοποίηση κατά
                  την είσοδό Σας σε αυτή, οι οποίες και θα ισχύουν από την ημερομηνία ανάρτησης τους. Διευκρινίζεται ότι
                  τυχόν αλλαγή των παρόντων Όρων Χρήσης, δεν καταλαμβάνει υπηρεσίες μεταφοράς ή άλλες συναλλαγές και
                  χρήσεις της Εφαρμογής τις οποίες έχετε ήδη διενεργήσει πριν την έναρξη ισχύος των Αλλαγών σύμφωνα με
                  τα ανωτέρω και η Εταιρεία Μας έχει αποδεχτεί. Σε περίπτωση ωστόσο που επιθυμείτε κάποια διευκρίνιση ή
                  πληροφορία αναφορικά με τις Αλλαγές, ή έχετε κάποια διαφωνία, ή απορία σχετική με αυτές (αλλαγές),
                  μπορείτε να επικοινωνήσετε με το Τμήμα Operations της Εταιρείας, πριν τη διενέργεια οποιασδήποτε
                  πράξης Σας στην Εφαρμογή. Οποιαδήποτε ενέργεια Σας, χρήση ή συναλλαγή σας στην Εφαρμογή μετά τις
                  σύμφωνα με τα ανωτέρω Αλλαγές, λογίζεται ως ανεπιφύλακτη αποδοχή αυτών.
                </span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Να σημειώσουμε ότι οποιαδήποτε πληροφορία/ διευκρίνιση Σας χορηγηθεί σύμφωνα με τα ανωτέρω από το
                                                    Τμήμα Operations αναφορικά με τους Όρους Χρήσης, δε συνιστά αντικατάσταση, υποκατάσταση ή τυχόν
                                                    τροποποίηση των παρόντων Όρων Χρήσης όπως ισχύουν κάθε φορά, καθόσον παρέχονται αποκλειστικά για την
                                                    παροχή βοήθειας στους Χρήστες, οι δε Όροι Χρήσης αποτελούν τη μόνη και αποκλειστική μας συμφωνία.
                </span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Η Εταιρεία διατηρεί το δικαίωμα οποτεδήποτε, αναιτιολόγητα και χωρίς προηγούμενη προειδοποίηση προς
                                                    τον Χρήστη να ματαιώσει, αναστείλει ή τερματίσει τη λειτουργία της Υπηρεσίας, να ανακαλέσει ή να
                  προσαρμόσει τη Πλατφόρμα ή βασικά χαρακτηριστικά της Πλατφόρμας και της Υπηρεσίας.{" "}
                                                </span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Η χρήση της Εφαρμογής από Εσάς και οποιαδήποτε συναλλαγή Σας με την Εφαρμογή, γίνεται με δική Σας
                                                    αποκλειστικά ευθύνη.
                </span>
                                            </p>
                                            <p className="p4">
                                                <span className="s1">
                                                    Αναγνωρίζετε και κατανοείτε ότι θα πρέπει να τηρείτε τους όρους χρήσης του παρόχου που συνεργάζεται με
                  τη συσκευή Σας (οι <b>«Όροι Χρήσης Παρόχου»</b>). Οι Όροι Χρήσης του Παρόχου θεωρούνται ενσωματωμένοι
                  στο παρόν. Αποτελεί αποκλειστικά δική Σας ευθύνη και υποχρέωση να προσδιορίσετε τους Όρους Χρήσης
                  Παρόχου που εφαρμόζονται στη Συσκευή Σας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>
                                                        ΜΕ ΤΗΝ ΑΠΟΔΟΧΗ ΤΩΝ ΠΑΡΟΝΤΩΝ ΟΡΩΝ ΣΥΜΦΩΝΑ ΜΕ ΤΑ ΑΝΩΤΕΡΩ ΟΙ ΧΡΗΣΤΕΣ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΚΑΙ TΡΙΤΩΝ
                                                        ΠΟΥ ΕΝΕΡΓΟΥΝ ΜΕ ΕΞΟΥΣΙΟΔΟΤΗΣΗ ΤΟΥΣ (ΕΦΕΞΗΣ ΟΙ “ΕΞΟΥΣΙΟΔΟΤΗΜΕΝΟΙ ΧΡΗΣΤΕΣ”) ΔΗΛΩΝΟΥΝ ΡΗΤΑ ΚΑΙ
                                                        ΑΝΕΠΙΦΥΛΑΚΤΑ ΟΤΙ ΕΧΟΥΝ ΤΗ ΝΟΜΙΜΗ ΗΛΙΚΙΑ ΠΟΥ ΤΟΥΣ ΕΠΙΤΡΕΠΕΙ ΤΗ ΔΕΣΜΕΥΣΗ ΤΟΥ ΑΠΟ ΤΟΥΣ ΠΑΡΟΝΤΕΣ
                                                        ΓΕΝΙΚΟΥΣ ΟΡΟΥΣ ΑΝΑΦΟΡΙΚΑ ΜΕ ΤΗ ΧΡΗΣΗ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΚΑΙ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΚΑΙ ΤΗΝ ΠΑΡΟΧΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ
                    ΜΕΤΑΦΟΡΑΣ.{" "}
                                                    </b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1. ΠΕΡΙΓΡΑΦΗ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ ΤΩΝ ΤΑΞΙΔΙΩΤΩΝ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    1.1 Κάνοντας κράτηση της Υπηρεσίας Μεταφοράς μέσω Ημών σημαίνει ότι ο Οδηγός θα είναι το πρόσωπο που
                                                    θα Σας υποδεχτεί και καλωσορίσει στη πόλη του και/ή θα Σας μεταφέρει εντός αυτής κατά τη διάρκεια της
                  διαμονής Σας.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ειδικότερα, οι υπηρεσίες μεταφοράς που έχετε τη δυνατότητα να κάνετε κράτηση μέσω της Πλατφόρμας
                  (εφεξής οι <b>«Υπηρεσίες Μεταφοράς» ή «Υπηρεσία Μεταφοράς» ή «Μεταφορά» </b>είναι οι ακόλουθες:
                </span>
                                            </p>

                                            <ol className="ol1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Μεταφορά των Ταξιδιωτών από και προς αεροδρόμια, λιμάνια, σιδηροδρομικούς σταθμούς, και σταθμούς
                                                        λεωφορείων.
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">Μεταφορά Ταξιδιωτών από και προς σημεία διαμονής. </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Μεταφορά Ταξιδιωτών από και προς σημεία τουριστικού ενδιαφέροντος(αξιοθέατα).
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Μεταφορά Ταξιδιωτών από και προς σημείο ενδιαφέροντος στην πόλη με στάσεις (για φαγητό,
                                                        δραστηριότητα, φωτογραφίες κτλ.).
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Μεταφορά Ταξιδιωτών στα πλαίσια περιοδείας και μεταφορά από και προς σημεία δραστηριοτήτων βασισμένα
                    στα ενδιαφέροντά τους.{" "}
                                                    </span>
                                                </li>

                                                <li className="li6">
                                                    <span className="s1">
                                                        Στα πλαίσια του καλωσορίσματος Σας από τους Οδηγούς, ενδέχεται να λάβετε κατά την παραλαβή Σας από
                                                        το σταθμό άφιξης στη χώρα Μας, προϊόντα που Εσείς είτε έχετε αγοράσει από ή μέσω της Εταιρείας, είτε
                    προϊόντα που η Εταιρεία διανέμει δωρεάν (αναμνηστικά, τοπικά εδέσματα, χάρτες της πόλης, κτλ.).{" "}
                                                    </span>
                                                </li>
                                                <li className="li6">
                                                    <b />
                                                    <span className="s1">
                                                        Για την παροχή της Υπηρεσίας Μεταφοράς, η Εταιρεία Μας θα Σας διαβιβάσει το όνομα, το επίθετο και
                                                        τον αριθμό του κινητού τηλεφώνου και εφόσον Μας έχει χορηγηθεί και τη φωτογραφία του.
                  </span>
                                                </li>
                                            </ol>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>
                                                        Δεν είμαστε αδειοδοτημένη ιδιωτική εταιρεία ενοικίασης ούτε μεταφορέας και ΔΕΝ ΠΑΡΕΧΟΥΜΕ Υπηρεσίες
                                                        Μεταφοράς. Η δράση Μας είναι αποκλειστικά η διαμεσολάβηση μεταξύ των Ταξιδιωτών και του Οδηγού.
                                                        Κάνοντας κράτηση για τη παραλαβή Σας μέσω της Εφαρμογής, δεσμεύεστε νομικά και απευθείας με τον
                    Οδηγό που αποδέχτηκε την Παραλαβή Σας.{" "}
                                                    </b>
                  Η Εταιρεία παρέχει πληροφορίες και μεθοδολογία προς διευκόλυνση των Υπηρεσιών Μεταφοράς όχι όμως με
                  σκοπό τη παροχή Υπηρεσιών Μεταφοράς ή τη δράση της ως μεταφορέας με δεδομένο ότι δεν φέρει καμία
                  ευθύνη ή υποχρέωση για οποιαδήποτε Υπηρεσία Μεταφοράς παρεχόμενη από τους Οδηγούς προς Εσάς.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2. ΝΟΜΙΚΗ ΥΠΟΣΤΑΣΗ ΤΗΣ ΕΤΑΙΡΕΙΑΣ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    2.1 Η Εταιρεία Μας παρέχει υπηρεσία ηλεκτρονικής ή τηλεφωνικής διαμεσολάβησης για τη μεταφορά των
                                                    Ταξιδιωτών. Ως φορέας ηλεκτρονικής διαμεσολάβησης στις Υπηρεσίες Μεταφοράς που οι Οδηγοί εκτελούν,
                                                    δρούμε αποκλειστικά ως μεσάζοντες μεταξύ των Υμών και των Οδηγών. Μέσω της Πλατφόρμας της η Εταιρεία
                                                    παρέχει υπηρεσίες διαμεσολάβησης για τη παροχή Υπηρεσιών Μεταφοράς από τους Οδηγούς στους Ταξιδιώτες.
                                                    Ουσιαστικά η Εταιρεία δεν παρέχει η ίδια ούτε δύναται να θεωρηθεί ότι παρέχει η ίδια τις Υπηρεσίες
                                                    Μεταφοράς, καθόσον δεν τυγχάνει αδειοδοτημένος φορέας μεταφοράς. Σε περίπτωση που κάνετε κράτηση για
                                                    Υπηρεσία Μεταφοράς μέσω της Εφαρμογής, αναγνωρίζετε ότι συμβάλλεστε αποκλειστικά και μόνο με τον Οδηγό
                                                    για τη παροχή της Υπηρεσίας Μεταφοράς και ότι ο Οδηγός είναι ο μοναδικός και αποκλειστικός υπεύθυνος
                                                    για την εκπλήρωση της παροχής της Υπηρεσίας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    2.2<span className="Apple-converted-space">&nbsp; </span>Η Εταιρεία δεν παρέχει υπηρεσίες μεταφοράς,
                  ούτε εμπλέκεται στη σχέση μεταξύ Υμών και του εκάστοτε Οδηγού που θα Σας παραλάβει καθόσον η συμβατική
                  σχέση για την παροχή υπηρεσίας μεταφοράς δημιουργείται αποκλειστικά μεταξύ Υμών και του Οδηγού, ο
                  οποίος αναλαμβάνει να Σας παρέχει τις Υπηρεσίες Μεταφοράς. Η Εταιρεία σε καμία απολύτως περίπτωση δεν
                  συμμετέχει ως συμβαλλόμενο μέρος στις συμβάσεις αυτές ούτε και αποκτά δικαιώματα ή υποχρεώσεις που να
                  εκπορεύονται από αυτές. Η Εταιρεία δε φέρει καμία ευθύνη ως προς τη μεταφορά των Ταξιδιωτών, για την
                  οποία οι Οδηγοί είναι αποκλειστικά υπεύθυνοι. Ως εκ τούτου η Εταιρεία, οι εκπρόσωποι, οι εργαζόμενοι
                  και οι βοηθοί της δεν ευθύνονται με κανέναν τρόπο για τους κινδύνους που συνδέονται με τη σύναψη, την
                  εκτέλεση και την ολοκλήρωση των συναλλαγών μεταξύ Ταξιδιωτών και Οδηγών και δεν ευθύνονται με κανέναν
                  τρόπο για τυχόν ζημίες που απορρέουν από τις συναλλαγές αυτές. Εξάλλου, η επιλογή για το αν θα κάνετε
                  κράτηση για τη παραλαβή Σας μέσω της Εφαρμογής εναπόκειται στη διακριτική Σας ευχέρεια. Εφόσον όμως
                  την αποδεχτείτε δεσμεύεστε και εγγυάστε ότι θα τηρείτε όλες τις υποχρεώσεις που απορρέουν από τους
                  παρόντες όρους.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    2.3 Η Εταιρεία δεν εκπροσωπεί ούτε Εσάς ούτε τον Οδηγό, επομένως δεν παρέχει εγγυήσεις για την
                                                    ποιότητα και την ομαλή διεκπεραίωση των Υπηρεσιών Μεταφοράς και αντίστοιχα δεν ευθύνεται για τη
                                                    συμπεριφορά των Ταξιδιωτών και των Οδηγών. Επιπλέον η Εταιρεία δεν εκπροσωπεί ή αντιπροσωπεύει καμία
                                                    εταιρεία μεταφοράς και δεν παρέχει ουδεμία υπηρεσία μεταφοράς.
                  <span className="Apple-converted-space">&nbsp; &nbsp;</span>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    2.4 Επισημαίνεται ότι οι Οδηγοί δεν τελούν σε εργασιακή σχέση με την εταιρεία, ούτε με τους συνεργάτες
                                                    της. Η Εφαρμογή αποτελεί μια μέθοδο σύνδεσης των Οδηγών με Ταξιδιώτες. Επιπροσθέτως, η Εταιρεία δεν
                                                    παρέχει ασφάλιση στους Οδηγούς, ούτε στα οχήματα, ούτε ευθύνεται σε περίπτωση απώλειας προσωπικών
                                                    αντικειμένων που απομένουν στο όχημα από Εσάς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    2.5 Η Εταιρεία μπορεί να παραπέμψει Οδηγούς σε Σας για τη παροχή Υπηρεσιών Μεταφοράς. Δεν θα
                                                    αξιολογήσει την καταλληλόλητα, τη νομιμότητα ή την ικανότητα του κάθε Οδηγού και παραιτείστε ρητώς και
                                                    απαλλάσσετε την Εταιρεία από οποιαδήποτε και από όλες τις απαιτήσεις, ευθύνες ή ζημιές που προκύπτουν
                                                    από ή σχετίζονται με οποιοδήποτε τρόπο με τον Οδηγό. Η Εταιρεία δεν θα αποτελεί μέρος των τυχόν
                                                    διαφορών ανάμεσα σε Εσάς και τους Οδηγούς. Κάνοντας κράτηση
                  <span className="Apple-converted-space">&nbsp; </span>της παροχής των υπηρεσιών μεταφοράς,
                  αναγνωρίζετε ότι η Εταιρεία δεν έχει καμία ανάμειξη στη συμβατική σχέση μεταξύ του Οδηγού και Υμών,
                  ωστόσο κάθε πράξη μεταφοράς (κούρσα) διέπεται από τους παρόντες Γενικούς Όρους.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    2.6 Οι υπηρεσίες διαμεσολάβησης στην υπηρεσία της μεταφοράς διατίθενται μόνο για προσωπική, μη
                                                    εμπορική χρήση. Ως εκ τούτου, δεν επιτρέπεται η μεταπώληση, η διάθεση μέσω deep-link, η χρήση, η
                                                    αντιγραφή, η παρακολούθηση (π.χ. μέσω spider, scrape), η εμφάνιση, η λήψη, ή η αναπαραγωγή
                                                    οποιουδήποτε περιεχομένου ή πληροφορίας, λογισμικού, προϊόντων ή υπηρεσιών που είναι διαθέσιμες στην
                  <span className="Apple-converted-space">&nbsp; </span>Εφαρμογή, για οποιαδήποτε εμπορική ή
                  ανταγωνιστική δραστηριότητα ή σκοπό.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3. ΠΟΛΙΤΙΚΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΜΕΤΑΦΟΡΑΣ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.1 ΠΟΛΙΤΙΚΗ ΚΡΑΤΗΣΕΩΝ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Δεν παρέχουμε τις Υπηρεσίες Μας σε κάθε πόλη. Για να μάθετε αν μπορούμε να εξυπηρετήσουμε τον
                  προορισμό Σας, πρέπει να το ελέγξετε.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Για να κάνετε κράτηση για τη μεταφορά Σας, χρειάζεται να Μας δώσετε κάποιες πληροφορίες όπως προσωπικά
                                                    στοιχεία και στοιχεία επικοινωνίας(e-mail, αριθμό κινητού τηλεφώνου), το σημείο παραλαβής, ημερομηνία,
                                                    σημείο προορισμού, αριθμό πτήσης σε περίπτωση που ταξιδεύετε με αεροπλάνο, ώστε σε περίπτωση που
                                                    υπάρξει καθυστέρηση στην ώρα άφιξης να είμαστε σε θέση να το ρυθμίσουμε, τον αριθμό των επιβατών και
                                                    τον αριθμό των αποσκευών Σας ώστε να επιλέξουμε το κατάλληλο Όχημα για την παραλαβή Σας και επίσης
                                                    στοιχεία τραπεζικού λογαριασμού. Εάν επιθυμείτε να κάνετε κράτηση για τη μεταφορά Σας μέσω Ημών, θα
                                                    πρέπει να αποδεχτείτε τους Όρους Χρήσης και τη Πολιτική Προστασίας Πολιτικών Δεδομένων.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Όταν μια κράτηση για μεταφορά πραγματοποιείται με Εμάς, θα λαμβάνετε με e-mail επιβεβαίωση ότι λάβαμε
                                                    τη κράτησή Σας, δίνοντάς Σας Κωδικό Κράτησης και το σημείο συνάντησης για τη παραλαβή Σας. Θα
                                                    εξετάζουμε εάν μπορούμε να εξυπηρετήσουμε τη μεταφορά στο σύνολο ή εάν σύμφωνα με τους παρόντες
                                                    Γενικούς Όρους Χρήσης έχουμε λόγους να την απορρίψουμε. Εφόσον ανατεθεί σε έναν Οδηγό της Εταιρείας να
                                                    εκτελέσει τη μεταφορά Σας, θα Σας γνωστοποιούμε το όνομά του. Σημαντική Νομική Παρατήρηση: Παρακαλώ
                                                    σημειώστε ότι η ολοκλήρωση της διαδικασίας ηλεκτρονικής κράτησης όπως επίσης και το ηλεκτρονικό μήνυμα
                                                    επιβεβαίωσης που Σας στέλνουμε, δεν αποτελούν αποδοχή της πρότασης Σας να κάνετε κράτηση μέσω Ημών
                                                    ακόμα και αν στο ηλεκτρονικό μήνυμα επιβεβαίωσης περιλαμβάνεται ο Κωδικός Κράτησης. Η Εταιρεία θα
                                                    αποδεχτεί τη κράτησή Σας για τη μεταφορά Σας μέσω της Εφαρμογής τότε και μόνο τότε όταν θα λάβει τη
                                                    πληρωμή από Εσάς, πιο συγκεκριμένα όταν η κάρτα Σας ή ο λογαριασμός PayPal χρεωθεί. Διατηρούμε το
                                                    δικαίωμα να αρνηθούμε τη εντολή κράτησης για οποιοδήποτε λόγο, συμπεριλαμβανομένων νομικών και
                                                    ρυθμιστικών κανόνων που πηγάζουν από την έννομή σχέση. Σε περίπτωση που δεν μπορέσουμε να Σας
                                                    παρέχουμε την Υπηρεσία Μεταφοράς, θα Σας ενημερώσουμε το συντομότερο δυνατό και δεν θα προχωρήσουμε με
                                                    την εντολή Σας. Σε περίπτωση που έχετε ήδη πληρώσει για την Υπηρεσία που τελικά δεν μπορούμε να Σας
                  παρέχουμε, θα Σας επιστρέψουμε τα χρήματα πλήρως σε εύλογο χρονικό διάστημα.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Είστε αποκλειστικά υπεύθυνοι για τη υποβολή προς Εμάς των πραγματικών Σας προσωπικών δεδομένων όταν
                                                    κάνετε κράτηση για τη παραλαβή Σας μέσω Ημών. Επίσης εγγυάστε και δηλώνετε ότι είστε ο νόμιμος κάτοχος
                                                    ή έχετε τα νόμιμα δικαιώματα των καρτών/λογαριασμών των οποίων τα στοιχεία Μας παρέχετε
                  <span className="Apple-converted-space">&nbsp; </span>και ότι τα χρήματα που υπάρχουν, επαρκούν για να
                  καλυφθεί το κόστος της Υπηρεσίας Μεταφοράς. Δυνάμεθα να προχωρήσουμε (παρόλο που δεν είμαστε
                  υποχρεωμένοι) σε διαδικασία ελέγχου των στοιχείων πληρωμής που Μας παρέχετε όταν ως μέθοδος πληρωμής
                  έχει επιλεγεί η χρήση πιστωτικής κάρτας ή μέσω Διαδικτυακών Παρόχων Πληρωμών. Σε περίπτωση λάθους ή
                  άλλου προβλήματος είναι στη διακριτική Μας ευχέρεια να απορρίψουμε την Εντολή Σας.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.2 ΠΟΛΙΤΙΚΗ ΑΙΤΗΜΑΤΟΣ ΑΚΥΡΩΣΗΣ ΑΠΟ ΤΟΥΣ ΤΑΞΙΔΙΩΤΕΣ</b>
                                                </span>
                                            </p>
                                            <span>
                                                Έχετε το δικαίωμα να ακυρώσετε μια Μεταφορά για την οποία ήδη έχετε κάνει κράτηση οποιαδήποτε στιγμή
                                                επικοινωνώντας με την Εταιρεία είτε μέσω τηλεφώνου στον αριθμό που υπάρχει στο ηλεκτρονικό μήνυμα
                επιβεβαίωσης ή στην ιστοσελίδα ή στέλνοντας ηλεκτρονικό μήνυμα στη διεύθυνση{" "}
                                            </span>
                                            <a href="mailto:hello@welcomepickups.com">
                                                <span>hello@welcomepickups.com</span>
                                            </a>
                                            <span>.</span>

                                            <span>
                                                Εάν το αίτημα ακύρωσης ληφθεί από Εμάς έως και 24 ώρες πριν το προγραμματισμένο χρόνο παραλαβής,
                                                μπορείτε να επιλέξετε ένα από τα παρακάτω:
              </span>

                                            <span>
                                                α) να διατηρήσετε το ποσό που πληρώσατε για να το χρησιμοποιήσετε για μελλοντική Μεταφορά σε έναν από
                                                τους προορισμούς στους οποίους λειτουργούμε. Σε αυτή την περίπτωση, εκδίδεται κουπόνι ίσης αξίας με το
                                                ποσό που πληρώθηκε για την αρχική σας Μεταφορά, το οποίο μπορεί να χρησιμοποιηθεί από εσάς ή κάποιον
                                                τρίτο Ταξιδιώτη μέσα σε διάστημα 12 μηνών από την ημερομηνία αίτησης ακύρωσης. Αν η αξία του κουπονιού
                                                είναι υψηλότερη του κόστους της νέας κράτησης Μεταφοράς, η χρηματική διαφορά δεν επιστρέφεται στον
                                                Ταξιδιώτη, το συνολικό κόστος της νέας κράτησης Μεταφοράς καλύπτεται από την αξία του κουπονιού. Αν η
                                                αξία του κουπονιού είναι μικρότερη της νέας κράτησης Μεταφοράς, η σχετική χρηματική διαφορά θα πρέπει να
                                                καλυφθεί από τον Ταξιδιώτη για να ολοκληρωθεί η νέα κράτηση. Το κουπόνι που εκδίδεται μπορεί να
                                                χρησιμοποιηθεί μόνο μία φορά και δεν μπορεί να ακυρωθεί.
              </span>

                                            <span>
                                                β) να προχωρήσετε σε οριστική ακύρωση της κράτησης Μεταφοράς. Σε αυτή την περίπτωση, 20% της αξίας της
                                                υπό ακύρωσης κράτησης για Μεταφορά κρατείται από Εμάς ως αμοιβή ακύρωσης. Το υπόλοιπο 80% επιστρέφεται
                                                στον Ταξιδιώτη.
              </span>

                                            <span>
                                                Εάν το αίτημα ακύρωσης ληφθεί από την Εταιρεία μέσα σε λιγότερο από 24 ώρες πριν τη προγραμματισμένη ώρα
                                                παραλαβής, δεν πραγματοποιείται επιστροφή χρημάτων προς τον Ταξιδιώτη, καθώς θεωρείται ακύρωση
                                                τελευταίας στιγμής, και το 100% της αξίας της υπό ακύρωσης κράτησης για Μεταφορά κρατείται από Εμάς ως
                                                αμοιβή ακύρωσης.
              </span>

                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.3 ΠΟΛΙΤΙΚΗ ΑΙΤΗΜΑΤΟΣ ΑΛΛΑΓΗΣ ΑΠΟ ΤΟΥΣ ΤΑΞΙΔΙΩΤΕΣ</b>{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Μπορείτε να επικοινωνήσετε με την Εταιρεία μέσω τηλεφώνου στον αριθμό που υπάρχει στο ηλεκτρονικό
                                                    μήνυμα επιβεβαίωσης ή στην ιστοσελίδα ή με ηλεκτρονικό μήνυμα και να ζητήσετε να αλλάξετε οποιαδήποτε
                  από τα στοιχεία κράτησης μέχρι 12 ώρες πριν τη προγραμματισμένη ώρα παραλαβής.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ανάλογα με τη ζήτηση, η αξία της Μεταφοράς υπάρχει περίπτωση να αλλάξει. Σε αυτή τη περίπτωση, θα
                                                    ενημερωθείτε για τη νέα αξία της Μεταφοράς και τα νέα στοιχεία της Μεταφοράς θα ισχύουν μόλις Μας
                                                    επιβεβαιωθεί η πληρωμή της όπου χρειάζεται. Εάν η νέα αξία της Μεταφοράς είναι μικρότερη τότε η
                                                    Εταιρεία θα Σας επιστρέψει μερικώς τα χρήματά Σας. Εάν η νέα αξία της Μεταφοράς είναι μεγαλύτερη τότε
                                                    θα πρέπει Εσείς να μας δώσετε τη συγκατάθεσή Σας ώστε να χρεώσουμε τη κάρτα Σας με το επιπλέον ποσό
                  (αν υπάρχει) λόγω της αλλαγής της ζήτησης.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Η Εταιρεία δεν μπορεί να προβεί σε αλλαγές των στοιχείων της Μεταφοράς που ζητούνται μέσα σε λιγότερο
                                                    από 12 ώρες πριν τη προγραμματισμένη ώρα παραλαβής Σας. Σε αυτή τη περίπτωση, είτε θα πραγματοποιηθεί
                                                    όπως αρχικά έγινε η κράτηση, είτε θα προβείτε στη διαδικασία του αιτήματος ακύρωσης σύμφωνα με τη
                  Πολιτική Αιτήματος Ακύρωσης από τους Ταξιδιώτες.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>
                                                        3.4 ΠΟΛΙΤΙΚΗ<span className="Apple-converted-space">&nbsp; </span>ΧΡΟΝΟΥ ΑΝΑΜΟΝΗΣ{" "}
                                                    </b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ο Οδηγός θα Σας περιμένει στο σημείο παραλαβής τη προγραμματισμένη ώρα σύμφωνα με την Εντολή Κράτησης
                                                    της Μεταφοράς που υπόκειται στη Πολιτική Χρόνου Αναμονής και στη Πολιτική Επιπλέον Χρόνου Αναμονής,
                                                    για αυτό θα πρέπει να είστε στο σημείο παραλαβής στην ώρα Σας ώστε να αποφευχθεί το ενδεχόμενο να
                                                    χαρακτηριστείτε ως «Μη Εμφανίσας» όπου σε αυτή τη περίπτωση εφαρμόζεται η Πολιτική της Μη Εμφάνισης
                  των Ταξιδιωτών.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Η Εταιρεία προσφέρει τις ακόλουθες πρόσθετες ώρες αναμονής που εξαρτώνται από το είδος της Μεταφοράς
                                                    που κάνατε κράτηση:
                </span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">Αεροδρόμιο: 60 λεπτά</span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">Λιμάνια, σιδηροδρομικοί σταθμοί και σταθμοί λεωφορείων: 30 λεπτά</span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">‘Άλλες Υπηρεσίες Μεταφοράς, Περιηγήσεις: 15 λεπτά</span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">Ο χρόνος αναμονής αρχίζει να μετράει από τη προγραμματισμένη ώρα παραλαβής. </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ειδικότερα όταν η καθορισμένη ώρα παραλαβής από αεροδρόμια και λιμάνια είναι άλλη από την ώρα άφιξης
                                                    στο σταθμό παραλαβής, και η πτήση ή το καράβι έχει καθυστερήσει να φτάσει στο λιμένα, αυτή (η νέα ώρα
                                                    άφιξης) θεωρείται εφεξής η καθορισμένη ώρα παραλαβής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Για παραλαβές από λιμάνια και αεροδρόμια όπου η ώρα παραλαβής συμπίπτει με την ώρα άφιξης του
                                                    αεροπλάνου ή του πλοίου και υπάρχει καθυστέρηση άφιξης του αεροπλάνου ή καραβιού, ο χρόνος αναμονής
                                                    Σας στο λιμένα αρχίζει από την ώρα άφιξης του αεροπλάνου/πλοίου και με την προϋπόθεση ότι ο Ταξιδιώτης
                                                    έχει δηλώσει σωστές και επαρκείς πληροφορίες σχετικά με την πτήση του ή το ταξίδι του με πλοίο. Τα
                                                    παραπάνω ισχύουν μόνο στην περίπτωση που η καθυστέρηση είναι μέχρι και οκτώ (8) ώρες από την
                                                    καθορισμένη ώρα παραλαβής. Εφόσον η καθυστέρηση ξεπεράσει τις οκτώ (8) ώρες, η Kράτηση ακυρώνεται και
                                                    η αμοιβή της Υπηρεσίας Μεταφοράς επιστρέφεται στον Ταξιδιώτη.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Για παραλαβή από αεροδρόμιο ή λιμάνι όπου η καθορισμένη ώρα παραλαβής είναι η ώρα άφιξης του
                                                    αεροπλάνου/πλοίου και το αεροπλάνο/πλοίο αφιχθεί νωρίτερα από την προγραμματισμένη ώρα άφιξης του, η
                                                    καθορισμένη ώρα παραλαβής παραμένει η αρχική καθορισμένη ώρα παραλαβής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                  Για παραλαβή από αεροδρόμιο, σε περίπτωση που οι Ταξιδιώτες προσγειώνονται με διαφορετικές πτήσεις, η
                  Εταιρεία θεωρεί πως η καθορισμένη ώρα παραλαβής είναι αυτή που δηλώθηκε από τον Ταξιδιώτη στην
                  Εφαρμογή κατά την κράτηση της Υπηρεσίας Μεταφοράς ή όποια άλλη εν τέλει δηλώθηκε σε περίπτωση που αυτή
                  (καθορισμένη ώρα παραλαβής) άλλαξε σύμφωνα με την Πολιτική Αλλαγών εκ μέρους του Ταξιδιώτη.
                  Οποιεσδήποτε αναφορές γίνονται από τον Ταξιδιώτη κατά τη διάρκεια της κράτησης&nbsp;
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Οποιεσδήποτε σημειώσεις γίνονται από τους Ταξιδιώτες κατά τη διάρκεια της κράτησης που αναφέρονται σε
                                                    διαφορετικές πτήσεις δεν αποτελούν προσαρμογή στη προγραμματισμένη ώρα παραλαβής. Σε περίπτωση όπου
                                                    ένα γκρουπ από Ταξιδιώτες φτάνουν με διαφορετικές πτήσεις σε διαφορετικές ώρες και έχουν κάνει κράτηση
                  για μια Μεταφορά, θα πρέπει να προσθέσουν την ώρα της τελευταίας πτήσης στα στοιχεία της πτήσης.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.5 ΠΟΛΙΤΙΚΗ ΓΙΑ ΕΠΙΠΛΕΟΝ ΧΡΟΝΟ ΑΝΑΜΟΝΗΣ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Επιπλέον χρόνος αναμονής ορίζεται ως η επιπλέον ώρα από τον χρόνο αναμονής
                  <span className="Apple-converted-space">&nbsp; </span>που προσφέρει η Εταιρεία, τον οποία ζητά ο
                  Ταξιδιώτης από τον Οδηγό να τον περιμένει.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Μπορείτε να ζητήσετε από τον Οδηγό να Σας περιμένει επιπλέον από τον Χρόνο Αναμονής σύμφωνα με τη
                                                    Πολιτική Χρόνου Αναμονής είτε εκ των προτέρων (πριν πραγματοποιηθεί η Μεταφορά) είτε το αργότερο κατά
                                                    τη λήξη χρόνου αναμονής. Μπορείτε να το κάνετε αυτό επικοινωνώντας με την Εταιρεία είτε τηλεφωνικά
                                                    στον αριθμό που υπάρχει στο επιβεβαιωτικό ηλεκτρονικό μήνυμα είτε στην ιστοσελίδα είτε στέλνοντας
                  ηλεκτρονικό μήνυμα στη διεύθυνση{" "}
                                                    <a href="mailto:hello@welcomepickups.com" title="Hello@welcomepickups.com">
                                                        <span className="s3">hello@welcomepickups.com</span>
                                                    </a>
                  . <span className="Apple-converted-space">&nbsp; </span>Η Εταιρεία εγγυάται μόνο για τον χρόνο
                  αναμονής για τον Ταξιδιώτη εάν ο επιπλέον χρόνος αναμονής δεν ζητηθεί τουλάχιστον 12 ώρες πριν τη
                  προγραμματισμένη ώρα παραλαβής.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Εάν ζητήσετε επιπλέον χρόνο αναμονής και ο Οδηγός μπορεί να τον παρέχει, θα χρεωθεί με ένα επιπλέον
                                                    κόστος για κάθε επιπλέον 15 λεπτά αναμονής. Τα κόστη είναι ως ακολούθως:
                </span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">Για παραλαβή με τύπο οχήματος Sedan : 6 ευρώ/15 λεπτά</span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">Για παραλαβή με τύπο οχήματος Minivan: 9 ευρώ/15 λεπτά</span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">Για παραλαβή με τύπο οχήματος Minibus: 12 ευρώ/15 λεπτά</span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">Η Εταιρεία δεν χρεώνει κάποιο επιπλέον ποσό στα ως άνω κόστη. </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Όταν ο επιπλέον χρόνος αναμονής μπορεί να παρασχεθεί από τον Οδηγό, θα πρέπει να δώσετε την
                                                    συγκατάθεσή Σας για τα επιπλέον κόστη είτε μέσω τηλεφώνου καλώντας την Εταιρεία είτε ολοκληρώνοντας τη
                                                    συναλλαγή από μόνοι Σας σε σύνδεσμο που θα Σας στείλει η Εταιρεία. Η πληρωμή του ποσού μπορεί να γίνει
                                                    είτε απευθείας στον Οδηγό πριν από την Μεταφορά, είτε ηλεκτρονικά στην εταιρεία.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.6 ΠΟΛΙΤΙΚΗ ΜΗ ΕΜΦΑΝΙΣΗΣ ΤΟΥ ΤΑΞΙΔΙΩΤΗ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ταξιδιώτης που δεν εμφανίστηκε στο σημείο παραλαβής ορίζεται ως ο Ταξιδιώτης που δεν βρίσκεται στο
                                                    σημείο παραλαβής πριν το τέλος του χρόνου αναμονής ή πριν τον επιπλέον χρόνο αναμονής αν αυτό έχει
                                                    συμφωνηθεί μεταξύ του Ταξιδιώτη, του Οδηγού και της Εταιρείας όπως ορίζεται στη Πολιτική Χρόνου
                  Αναμονής και στη Πολιτική Επιπλέον Χρόνου Αναμονής.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Σημείο παραλαβής ορίζεται ως το μέρος όπου ο Ταξιδιώτης έχει ζητήσει να γίνει η παραλαβή του.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Σε περίπτωση παραλαβής από αεροδρόμιο/λιμάνια, η Εταιρεία Σας ενημερώνει μέσω του
                  <span className="Apple-converted-space">&nbsp; </span>επιβεβαιωτικού ηλεκτρονικού μηνύματος κράτησης
                  για την ακριβή τοποθεσία όπου ο Οδηγός θα Σας περιμένει, όπως Χώρος Άφιξης, Χώρος Αναμονής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Στις περιπτώσεις όπου οι Ταξιδιώτες δεν εμφανιστούν στη τοποθεσία παραλαβής μέχρι το τέλος του χρόνου
                                                    αναμονή τους ή στο τέλος του επιπλέον χρόνου αναμονής ή εάν «το αίτημα για Επιπλέον Χρόνο Αναμονής»
                                                    δεν μπορεί να το εξυπηρετήσει ο Οδηγός, η Κράτηση χαρακτηρίζεται ως «Μη Εμφάνιση Ταξιδιώτη». Σε αυτή
                                                    τη περίπτωση δεν επιστρέφεται κανένα ποσό στον Ταξιδιώτη. Εάν έχει συμφωνηθεί με τον Ταξιδιώτη
                                                    επιπλέον χρόνος αναμονής σύμφωνα με τη Πολιτική Επιπλέον Χρόνου Αναμονής, ο Ταξιδιώτης πρέπει να
                  πληρώσει την επιπλέον χρέωση για την αναμονή επίσης.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Εάν δεν βρίσκεστε στο σημείο παραλαβής κατά τη προγραμματισμένη ώρα παραλαβής και το σημείο παραλαβής
                                                    είναι Αεροδρόμιο, Λιμάνι, Σιδηροδρομικός Σταθμός ή Σταθμός Λεωφορείων ο Οδηγός που του έχει ανατεθεί η
                                                    Μεταφορά θα προσπαθήσει να επικοινωνήσει μαζί Σας μέσω μηνύματος/Whatsup/Viber ή θα προσπαθήσει να Σας
                                                    καλέσει στο κινητό Σας τηλέφωνο. Κάνοντας κράτηση για Μεταφορά συναινείτε στο να γνωστοποιήσουμε τον
                  αριθμό του κινητού Σας τηλεφώνου στον Οδηγό Σας.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Περιπτώσεις μη εμφάνισης του Ταξιδιώτη που θα αναφερθούν στην Εταιρεία από τον Οδηγός Σας είναι οι
                                                    ακόλουθες:
                </span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Για αεροδρόμια όταν ο Ταξιδιώτης δεν εμφανισθεί μέσα στα επόμενα 45 λεπτά από τη προγραμματισμένη
                                                        ώρα παραλαβής
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Για λιμάνια, σιδηροδρομικούς σταθμούς, σταθμούς λεωφορείων όταν ο Ταξιδιώτης δεν εμφανισθεί μέσα στα
                                                        επόμενα 15 λεπτά από τη προγραμματισμένη ώρα παραλαβής
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Για Διαδρομές σε Αξιοθέατα και άλλες Υπηρεσίες Μεταφοράς όταν ο Ταξιδιώτης δεν εμφανισθεί μέσα στα
                                                        επόμενα 5 λεπτά από τη προγραμματισμένη ώρα παραλαβής
                  </span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">
                                                    Μόλις ο Οδηγός ενημερώσει ότι ο Ταξιδιώτης δεν έχει εμφανιστεί, Η Εταιρεία θα προσπαθήσει να
                                                    επικοινωνήσει μαζί Σας (με κλήση και/ή με μήνυμα/Whatsup/Viber και/ή ηλεκτρονικό μήνυμα) σε μια
                                                    προσπάθεια να εντοπίσουμε την τοποθεσία Σας. Θα προσπαθούμε να εντοπίσουμε τη τοποθεσία Σας μέχρι τη
                  λήξη του Χρόνου Αναμονής Σας όπως προβλέπεται από τους παρόντες Όρους Χρήσης.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">Μετά το τέλος του Χρόνου Αναμονής θα συμβούν τα ακόλουθα:</span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Εάν δεν καταφέρουμε να εντοπίσουμε την τοποθεσία Σας(δεν απαντήσατε στις
                                                        κλήσεις/μηνύματα/ηλεκτρονικά μηνύματα), τότε πρέπει να αποδεσμεύσουμε τον Οδηγό. Σε αυτή τη
                                                        περίπτωση η Κράτηση για τη Μεταφορά θεωρείται ως «Μη Εμφάνιση του Ταξιδιώτη».
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Εάν έχουμε εντοπίσει τη τοποθεσία Σας και μας έχετε ενημερώσει ότι θα είστε στο σημείο παραλαβής έως
                                                        το τέλος του Χρόνου Αναμονής, Εμείς πρέπει να ενημερώσουμε τον Οδηγό να Σας περιμένει. Εάν δεν
                                                        καταφέρετε να εμφανιστείτε έως το τέλος του Χρόνου Αναμονής, θα κάνουμε μια τελευταία προσπάθεια να
                                                        επικοινωνήσουμε μαζί Σας και αναλόγως αν καταφέρουμε να επικοινωνήσουμε μαζί Σας, είτε θα
                                                        αποδεσμεύσουμε τον Οδηγό αμέσως είτε θα ακολουθηθεί η διαδικασία του Επιπλέον Χρόνου Αναμονής
                    σύμφωνα με τη Πολιτική Επιπλέον Χρόνου Αναμονής.{" "}
                                                    </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Εάν καταφέρουμε να επικοινωνήσουμε μαζί Σας και ζητήσετε Επιπλέον Χρόνο Αναμονής, θα επικοινωνήσουμε
                    με τον Οδηγό σύμφωνα με τη Πολιτική Επιπλέον Χρόνου Αναμονής.{" "}
                                                    </span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">
                                                    Έχετε 24 ώρες για να αμφισβητήσετε το χαρακτηρισμό της Κράτησης ως «Μη Εμφάνιση Ταξιδιώτη»
                  επικοινωνώντας με το Τμήμα Εξυπηρέτησης Πελατών.<span className="Apple-converted-space">&nbsp; </span>
                  Στη περίπτωση που διαφωνείτε με το χαρακτηρισμό «Μη Εμφάνιση Ταξιδιώτη» αποτελεί επαρκή απόδειξη ότι ο
                  Οδηγός βρισκόταν στο σημείο παραλαβής όπως ορίζουν οι Πολιτικές της Εταιρείας, εάν ο Οδηγός βρισκόταν
                  στο σημείο παραλαβής στο χρονικό διάστημα ανάμεσα στη προγραμματισμένη ώρα παραλαβής και στο τέλος του
                  Χρόνου Αναμονής (ή Επιπλέον Χρόνου Αναμονής). Εάν προκύπτει επαρκής απόδειξη στον ισχυρισμό ότι και
                  Εσείς και ο Οδηγός βρισκόσασταν στο σημείο παραλαβής αλλά δεν καταφέρατε να συναντηθείτε και η Κράτηση
                  Μεταφοράς δεν πραγματοποιήθηκε, τότε θα Σας επιστρέψουμε το 80% της τιμής της Κράτησης Μεταφοράς, αλλά
                  το 20% θα αποδοθεί από Εμάς στον Οδηγό ως αμοιβή του Οδηγού, από τη στιγμή που η Εταιρεία είναι
                  υπεύθυνη για την αμοιβή των Οδηγών.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.7 ΠΟΛΙΤΙΚΗ ΜΗ ΕΜΦΑΝΙΣΗΣ ΟΔΗΓΟΥ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ο Οδηγός πρέπει να βρίσκεται στο σημείο παραλαβής κατά την καθορισμένη ώρα παραλαβής. Εάν ο Οδηγός
                                                    πρόκειται να καθυστερήσει έως 10 λεπτά, θα πρέπει να επικοινωνήσει και να ενημερώσει τον Ταξιδιώτη
                                                    (μέσω τηλεφώνου και μήνυμα (sms)/whatsup) το συντομότερο δυνατό, και το αργότερο μέχρι την καθορισμένη
                                                    ώρα παραλαβής. Εάν ο Οδηγός πρόκειται να καθυστερήσει περισσότερο από 10 λεπτά, θα πρέπει να
                                                    επικοινωνήσει και να ενημερώσει το Τμήμα Εξυπηρέτησης Πελατών όσο το δυνατόν νωρίτερα, και το αργότερο
                                                    πριν από την καθορισμένη ώρα παραλαβής. Το Τμήμα Εξυπηρέτησης Πελατών θα πρέπει να επικοινωνήσει με
                  τον Ταξιδιώτη και να τον ενημερώσει για την καθυστέρηση του Οδηγού.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.8 ΠΟΛΙΤΙΚΗ ΑΠΟΣΚΕΥΩΝ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Όταν ο Ταξιδιώτης κάνει κράτηση της Μεταφοράς, του ζητείται να δηλώσει τον αριθμό των αποσκευών που θα
                                                    έχουν μαζί τους κατά τη διάρκεια της Μεταφοράς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">Μία αποσκευή θεωρείται:</span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">Μία αποσκευή-σακίδιο πλάτης μεσαίου μεγέθους</span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Μία βαλίτσα, τύπου χειραποσκευής ή κανονικού μεγέθους (με όγκο που να μην ξεπερνά τα 100 λίτρα)
                  </span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                  Εάν έχετε μαζί Σας επιπλέον πράγματα όπως καροτσάκι μωρού, μεταφορέα για κατοικίδιο ζώο, αθλητικό
                  εξοπλισμό, αναδιπλούμενο αναπηρικό καροτσάκι, μουσικά όργανα κτλ, πρέπει να Μας το δηλώσετε και να το
                  περιγράψετε στη κράτησή Σας. Η Εταιρεία μπορεί να επικοινωνήσει μαζί Σας για διευκρινίσεις και να
                  προσαρμόσει τη κράτηση σύμφωνα με τις λεπτομέρειες αυτές ώστε να εξασφαλίσει ότι ο τύπος οχήματος που
                  έχει αναλάβει τη μεταφορά είναι ο κατάλληλος.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Το κόστος της Μεταφοράς και ο τύπος οχήματος που θα πραγματοποιήσει τη Μεταφορά εξαρτώνται μεταξύ
                                                    άλλων από τον αριθμό των Ταξιδιωτών και τον αριθμό αποσκευών που θα δηλωθούν. Εάν εμφανιστούν
                                                    περισσότεροι Ταξιδιώτες ή με περισσότερες αποσκευές από αυτές που θα δηλωθούν στην κράτηση και ο
                                                    Οδηγός που έχει αναλάβει τη Μεταφορά δεν μπορεί με ασφάλεια και νόμιμα να πραγματοποιήσει τη Μεταφορά,
                                                    ο Οδηγός θα πρέπει να επικοινωνήσει με το Τμήμα Εξυπηρέτησης Πελατών της Εταιρείας πριν από την έναρξη
                                                    της Μεταφοράς, εξηγώντας τους την κατάσταση και να συνδράμει τον Ταξιδιώτη ώστε να βρει άλλο όχημα.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Εάν εμφανιστούν περισσότεροι Ταξιδιώτες ή περισσότερες αποσκευές από αυτές που δηλώθηκαν στην κράτηση
                                                    και ο Οδηγός που έχει αναλάβει τη Μεταφορά μπορεί με ασφάλεια και νόμιμα να πραγματοποιήσει τη
                                                    Μεταφορά (πχ σε περίπτωση που τύπος οχήματος minivan είχε αναλάβει τη Μεταφορά για την οποία είχε
                                                    γίνει η κράτηση με όχημα τύπου Sedan), ο Oδηγός θα πρέπει να επικοινωνήσει με το Τμήμα Εξυπηρέτησης
                                                    Πελατών της Εταιρείας πριν από την έναρξη της Μεταφοράς εξηγώντας την κατάσταση. Εάν ο επιπλέον
                                                    αριθμός Ταξιδιωτών ή/και αποσκευών συνεπάγονται διαφορετικό κόστος Μεταφοράς, το Τμήμα Εξυπηρέτησης
                                                    Πελατών θα πρέπει να το υπολογίσει και να Σας ενημερώσει αντίστοιχα. Εάν δεχτείτε να πληρώσετε το
                                                    πρόσθετο κόστος, ο Οδηγός θα πραγματοποιήσει την Μεταφορά με τους επιπλέον Ταξιδιώτες και τις επιπλέον
                                                    αποσκευές. Σε αντίθετη περίπτωση, ο Οδηγός θα πρέπει να Σας βοηθήσετε να βρείτε άλλο όχημα ενώ μπορεί
                                                    να μεταφέρει μόνο τον αριθμό ταξιδιωτών και αποσκευών που δηλώθηκαν στην κράτηση Μεταφοράς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <span className="Apple-converted-space">&nbsp; &nbsp; </span>Η Εταιρεία δεν θα επιβαρυνθεί με το
                  κόστος οποιουδήποτε άλλου μέσου Μεταφοράς χρησιμοποιηθεί από Εσάς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3.9 ΠΟΛΙΤΙΚΗ ΕΠΙΣΤΡΟΦΗΣ ΧΡΗΜΑΤΩΝ ΣΤΟΝ ΤΑΞΙΔΙΩΤΗ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span>
                                                    Το κόστος της Μεταφοράς που έχει πληρώσει ο Ταξιδιώτης του επιστρέφεται στο σύνολό του στις ακόλουθες
                                                    περιπτώσεις:
                </span>
                                                <ul>
                                                    <li>
                                                        <span>Εάν η κράτηση Μεταφοράς ακυρωθεί από την Εταιρεία για λόγους λειτουργικής ικανότητας</span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Εάν η κράτηση Μεταφοράς ακυρώθηκε για λόγους ανωτέρας βίας (σεισμό, διαδήλωση, φυσικές
                                                            καταστροφές, ατύχημα κ.τ.λ.)
                    </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Εάν η κράτηση ακυρώθηκε εξαιτίας καθυστέρησης της πτήσης ή του πλοίου πάνω από 8 ώρες σύμφωνα με
                                                            τη Πολιτική Χρόνου Αναμονής
                    </span>
                                                    </li>
                                                </ul>
                                                <span>Άλλες περιπτώσεις:</span>
                                                <ul>
                                                    <li>
                                                        <span>
                                                            Εάν ληφθεί από Εμάς αίτημα ακύρωσης από τον Ταξιδιώτη έως και 24 ώρες πριν το προγραμματισμένο
                                                            χρόνο παραλαβής, 20% της αξίας της υπό ακύρωσης κράτησης για Μεταφορά κρατείται από Εμάς ως αμοιβή
                                                            ακύρωσης. Το υπόλοιπο 80% επιστρέφεται στον Ταξιδιώτη, σύμφωνα με την Πολιτική Αιτήματος Ακύρωσης
                                                            από τους Ταξιδιώτες.
                    </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Εάν ληφθεί από Εμάς αίτημα ακύρωσης από τον Ταξιδιώτη μέσα σε λιγότερο από 24 ώρες πριν τη
                                                            προγραμματισμένη ώρα παραλαβής, δεν πραγματοποιείται επιστροφή χρημάτων προς τον Ταξιδιώτη, καθώς
                                                            θεωρείται ακύρωση τελευταίας στιγμής, και το 100% της αξίας της υπό ακύρωσης κράτησης για Μεταφορά
                                                            κρατείται από Εμάς ως αμοιβή ακύρωσης, σύμφωνα με την Πολιτική Αιτήματος Ακύρωσης από τους
                                                            Ταξιδιώτες.
                    </span>
                                                    </li>
                                                </ul>
                                                <span>
                                                    Σε περίπτωση που ένας Ταξιδιώτης διαφωνεί με το χαρακτηρισμό «Μη Εμφάνιση Ταξιδιώτη», εάν προκύπτει
                                                    επαρκής απόδειξη ότι και ο Ταξιδιώτης και ο Οδηγός ήταν στο σημείο παραλαβής αλλά δεν κατάφεραν να
                                                    συναντηθούν με αποτέλεσμα η Μεταφορά να μην πραγματοποιηθεί, θα επιστραφεί στον Ταξιδιώτη το 80% της
                                                    κράτησης Μεταφοράς σύμφωνα με τη Πολιτική της Μη Εμφάνισης Ταξιδιώτη.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4. ΔΙΚΑΙΩΜΑΤΑ ΚΑΙ ΥΠΟΧΡΕΩΣΕΙΣ ΤΑΞΙΔΙΩΤΩΝ-ΔΗΛΩΣΕΙΣ-ΕΓΓΥΗΣΕΙΣ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4.1 ΔΗΛΩΣΕΙΣ ΚΑΙ ΕΓΓΥΗΣΕΙΣ ΤΑΞΙΔΙΩΤΩΝ: </b>Χρησιμοποιώντας την Εφαρμογή, συμφωνείτε τα ακόλουθα:
                </span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Να παρέχετε αληθινές, ακριβείς, πλήρεις και ενημερωμένες πληροφορίες για Εσάς στη φόρμα κράτησης και
                                                        να τις ανανεώνετε κάθε φορά που χρειάζεται. Η Εταιρεία δεν είναι υποχρεωμένη να παρακολουθεί ή να
                                                        ελέγχει την ακρίβεια των πληροφοριών που Εσείς παρέχετε. Εάν οποιαδήποτε πληροφορία που Μας παρέχετε
                                                        δεν είναι σύμφωνη με τις συγκεκριμένες διατάξεις ή εάν η Εταιρεία έχει βάσιμους λόγους να υποψιαστεί
                                                        ότι κάποιες πληροφορίες δεν συμφωνούν με τα όσα έχετε δηλώσει, έχει το δικαίωμα να αρνηθεί την
                                                        εγγραφή Σας και να αναστείλει ή να διακόψει αμέσως, χωρίς προειδοποίηση, τη λειτουργία του
                                                        Λογαριασμού Σας, αρνούμενη οποιαδήποτε και κάθε χρήση, παρούσα ή μελλοντική, των Υπηρεσιών στις
                                                        οποίες απαιτείται εγγραφή. Σε αυτή τη περίπτωση δεν δικαιούστε καμία εγγύηση ή αποζημίωση για την
                    απόρριψη, αναστολή ή ακύρωση της εγγραφή Σας.{" "}
                                                    </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα χρησιμοποιείτε την Εφαρμογή για μη νόμιμο σκοπό όπως να
                    <span className="Apple-converted-space">&nbsp; </span>εμποδίζει, καταστρέφει, βλάπτει ή κάνει την
                    Εταιρεία λιγότερο αποδοτική με οποιοδήποτε τρόπο·
                    <span className="Apple-converted-space">&nbsp; </span>όπως η μεταφορά φακέλων που περιέχουν ιούς,
                    Δούρειους Ίππους ή άλλα κακόβουλα προγράμματα· η πρόσβαση ή η προσπάθεια πρόσβασης σε λογαριασμούς
                    άλλων χρηστών ή η παραβίαση ή η προσπάθεια παραβίασης οποιουδήποτε μέτρου ασφαλείας· όπως η διάδοση
                    οποιασδήποτε δήλωσης με συκοφαντικό, χυδαίο περιεχόμενο ή που καταλήγει να είναι σεξιστική,
                    υβριστική ή απειλητική και να αφορά σε διάκριση λόγω θρησκείας, φύλου, σεξουαλικής προτίμησης,
                    φυλής, εθνικότητας, ηλικίας, αναπηρίας ή οτιδήποτε άλλο· η διαφήμιση ή η προώθηση τρίτου μέρους ή
                    δικών Σας προϊόντων ή υπηρεσιών.{" "}
                                                    </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Να μην κάνετε κάτι που θεωρούμε εύλογα ότι είναι κακόφημο ή ικανό να βλάψει την φήμη Μας.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Να αναγνωρίζετε ότι έχουμε περιορισμένο έλεγχο στη φύση και στο περιεχόμενο των πληροφοριών που
                                                        λαμβάνουμε από Εσάς ή από άλλους χρήστες στην Εφαρμογή. Αν και έχουμε το δικαίωμα να το κάνουμε, δεν
                                                        ελέγχουμε το περιεχόμενο αυτό κατά τη διάρκεια της συνηθισμένης πορείας των εργασιών της Εταιρείας
                                                        και δεν μπορούμε να είμαστε υπεύθυνοι για τέτοιου είδους περιεχόμενο. Εάν έχετε παράπονο για άλλο
                                                        Χρήστη, παρακαλώ όπως επικοινωνήσετε μαζί Μας μέσω της Εφαρμογής.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Να συμμορφώνεστε με τους εφαρμοστέους νόμους του έθνους, χώρας, κράτους και πόλης στην οποία
                                                        βρίσκεστε ενώ χρησιμοποιείτε το λογισμικό ή την Υπηρεσία.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Να συμπεριφέρεστε στους Οδηγούς που σας συστήνονται μέσω της Εφαρμογής με σεβασμό και να μην
                                                        προκαλείτε φθορές στο Όχημά τους.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Nα Μας αποζημιώνετε και να Μας υπερασπίζεστε πλήρως ενάντια σε οποιονδήποτε ισχυρισμό ή νομικών
                                                        διαδικασιών που στρέφονται εναντίον μας από οποιοδήποτε άλλο πρόσωπο ως αποτέλεσμα της αθέτησής Σας
                                                        ως προς αυτό το συμβόλαιο.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Η διεύθυνση ηλεκτρονικού ταχυδρομείου (όπως{" "}
                                                        <a href="mailto:xxx@yahoo.com" title="Xxx@yahoo.com">
                                                            <span className="s3">xxx@yahoo.com</span>
                                                        </a>
                    ) που Εσείς έχετε για να κάνετε εγγραφή στην Εφαρμογή δεν θα πρέπει να παραπέμπει στο όνομα της
                    Εταιρείας. Επίσης, αν οι κωδικοί πρόσβασης για είσοδο στην Πλατφόρμα θεωρηθούν προσβλητικοί, έχουμε
                    δικαίωμα να τα διαγράψουμε καθώς επίσης διεύθυνση ιστοσελίδας ή διεύθυνση ηλεκτρονικού ταχυδρομείου.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα χρησιμοποιείτε την Υπηρεσία ή το Λογισμικό για να προκαλέσετε ελαφριά ή σοβαρή ενόχληση.
                  </span>
                                                </li>
                                            </ul>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Σε περίπτωση πρόσβασης και χρήσης της Εφαρμογής μέσω κινητού τηλεφώνου ή οποιασδήποτε κινητής
                                                        συσκευής επικοινωνίας, ή χρήσης συγκεκριμένων δυνατοτήτων απομακρυσμένης πρόσβασης, μπορεί να
                    υποστείτε χρεώσεις από τον τηλεπικοινωνιακό Σας πάροχο (<b>«Πάροχος»</b>) και Εσείς είστε
                    αποκλειστικά υπεύθυνοι για την αποπληρωμή των χρεώσεων αυτών.
                  </span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4.2</b> Αναγνωρίζετε και συμφωνείτε ότι Εσείς πρέπει να αποφεύγετε κάθε πράξη που μπορεί να
                  προκαλέσει προβλήματα σχετικά με την κυκλοφοριακή ασφάλεια. Συγκεκριμένα, Εσείς εγγυάστε και δηλώνετε
                  ότι:
                </span>
                                            </p>
                                            <ul className="ul1">
                                                <li className="li6">
                                                    <span className="s1">
                                                        Με κανέναν τρόπο δεν θα εμποδίζετε τον Οδηγό από το να οδηγεί με ασφάλεια κατά τη διάρκεια της
                                                        Μεταφοράς Σας
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα προσπαθήσετε να αποβιβαστείτε/επιβιβαστείτε από/στο όχημα όταν αυτό κινείται
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">Δεν θα καπνίζετε κατά τη διάρκεια της Μεταφοράς Σας</span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα ανοίγετε τα παράθυρα και τις πόρτες χωρίς την συγκατάθεση του Οδηγού
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Θα είστε κόσμιοι, ευγενικοί και δε θα επιδείξετε σε καμία περίπτωση ακατάλληλη, υβριστική,
                                                        προκλητική ή απειλητική συμπεριφορά προς τον Οδηγό
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα προβείτε σε πράξεις που δύνανται να προκαλέσουν βλάβη στο Όχημα του Οδηγού
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Θα προσέχετε και θα ελέγχετε ότι τα παιδιά, τα ζώα και τα αντικείμενα που μεταφέρονται, μεταφέρονται
                                                        με υπευθυνότητα
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα πετάτε αντικείμενα μέσα ή έξω από το Όχημα και δεν θα το μολύνετε με κανένα τρόπο
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Θα διαβάσετε προσεκτικά και θα εφαρμόσετε τις οδηγίες ταξιδιού με μικρά παιδιά καθώς και τις οδηγίες
                                                        για μεταφορά βρεφικών και παιδικών καροτσιών, αποσκευών, ποδηλάτων και κατοικίδιων
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">
                                                        Δεν θα καταναλώνετε φαγητά ή ποτά καθ’ όλη τη διάρκεια της Μεταφοράς Σας, μέχρι και το τέλος αυτής
                  </span>
                                                </li>
                                                <li className="li6">
                                                    <span className="s1">Δεν θα επιβιβάζεστε σε Όχημα με βρώμικο ή μολυσμένο ρουχισμό </span>
                                                </li>
                                            </ul>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4.3</b> Σε περιπτώσεις όπου μεταφέρετε ζώα ή/και αντικείμενα κατά τη διάρκεια της Μεταφοράς Σας και
                  το Όχημα υποστεί βλάβη/καταστραφεί ή λερωθεί καθ’ οιοδήποτε τρόπο εξαιτίας αυτών, Εσείς αναλαμβάνετε
                  πλήρως την υποχρέωση να πληρώσετε για όλα τα παραπάνω
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4.4 </b>Εγγυάστε ότι Εσείς έχετε διαβάσει στο σύνολό τους και έχετε κατανοήσει πλήρως τους Όρους
                  Χρήσης, την Πολιτική Ορθής Χρήσης, την Πολιτική Προστασίας Πνευματικών Δικαιωμάτων και αποδέχεστε όλα
                  τα παραπάνω πλήρως και άνευ όρων, καθώς και ότι Εσείς πληροίτε όλα τα νομικά προαπαιτούμενα για την
                  αποδοχή των Όρων αυτών
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4.5</b> Εγγυάστε και δηλώνετε ότι Εσείς με δική Σας ευθύνη και κόστος, θα λάβετε άμεσα οποιαδήποτε
                  απαραίτητα μέτρα και σε κάθε περίπτωση θα αποζημιώσετε και θα προστατέψετε την Εταιρεία, τις
                  Θυγατρικές της, τους Εταίρους της καθώς και κάθε άλλη οντότητα στην οποία απορρέουν δικαιώματα από
                  αυτήν (εταίροι, εργαζόμενοι, διαχειριστές, προμηθευτές, πράκτορες, αντιπρόσωποι, καθώς και συνεργάτες
                  όλων αυτών) για κάθε απαίτηση, απώλεια, καταστροφή, κόστος, έξοδο, υποχρέωση, απόκλιση πορείας, ποινή,
                  πρόστιμο, τέλος νομικών συμβουλών που προήλθε από:
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    α) μία/κάθε παραβίαση από Εσάς των Όρων αυτών ή/και της Πολιτικής Αποδεκτών Χρήσεων, της δήλωσης και
                                                    εγγύησής Σας που αφορά το παρόν Συμφωνητικό, και της πνευματικής ιδιοκτησίας ή άλλων δικαιωμάτων της
                                                    Εταιρείας
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    β) κάθε παράνομη πράξη ή νομικά κολάσιμη παράβλεψη κατά τη χρήση της Υπηρεσίας
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">γ) παραβίαση των εφαρμόσιμων νόμων από μέρους Σας</span>
                                            </p>
                                            <p className="p5">
                                                <span className="s1">
                                                    <span className="Apple-converted-space">&nbsp;&nbsp;</span>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5. ΔΙΚΑΙΩΜΑΤΑ ΕΤΑΙΡΕΙΑΣ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.1 Οριστική Απενεργοποίηση (διαγραφή) της Εφαρμογής:</b> Η Εταιρεία ρητά επιφυλάσσεται του
                  δικαιώματός της να απενεργοποιήσει οριστικά την Εφαρμογή και την Υπηρεσία αντίστοιχα, σε περίπτωση που
                  η Εταιρεία κατά την απόλυτη διακριτική της ευχέρεια κρίνει ότι ο Ταξιδιώτης (Εσείς) (α) παραβιάζει την
                  Πολιτική Αποδεκτών Χρήσεων της Εφαρμογής, ή/και (β) παραβιάζει οποιονδήποτε όρο ή διάταξη των παρόντων
                  Όρων Χρήσης και δη των παρεχόμενων από αυτόν εγγυήσεων και η παράβαση αυτή δύναται κατά την απόλυτη
                  κρίση της Εταιρείας και σε αντίθεση ενδεχομένως με άλλες διατάξεις του παρόντος, να προκαλέσει άμεσο
                  κίνδυνο-ζημία στην Εταιρεία ή/και σε οποιονδήποτε άλλο χρήστης της Εφαρμογής, ή/και (γ) έχει διαπράξει
                  κατά την κρίση της Εταιρείας ποινικό ή άλλο αδίκημα κατά την χρήση της Εφαρμογής και της παρεχόμενης
                  μέσω αυτής Υπηρεσίας ή/και την παροχή της Υπηρεσίας Μεταφοράς ή/και (δ) για οποιονδήποτε άλλο σπουδαίο
                  λόγο μη αναφερόμενο στο παρόν.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.2</b> Στις ως άνω περιπτώσεις η απενεργοποίηση της Εφαρμογής/Υπηρεσίας ενδέχεται να είναι άμεση
                  και αυτόματη, χωρίς η Εταιρεία να δίνει δικαίωμα στον Ταξιδιώτη να άρει τυχόν παραβίαση, η Εταιρεία
                  ρητά δε επιφυλάσσεται παντός νομίμου δικαιώματός της συμπεριλαμβανομένου και του δικαιώματος για
                  αποκατάσταση οποιασδήποτε ζημίας έχει τυχόν υποστεί από τις πράξεις ή/και παραλείψεις του Χρήστη
                  σύμφωνα με τα ανωτέρω.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.3</b> <b>Προσωρινή Απενεργοποίηση της Εφαρμογής</b>: Εκτός των ως άνω περιπτώσεων ή και άλλων
                  διατάξεων του παρόντος, η παραβίαση των οποίων ενδεχομένως να συνεπάγεται και προσωρινή απενεργοποίηση
                  της Εφαρμογής/Υπηρεσίας, η Εταιρεία ρητά επιφυλάσσεται του δικαιώματός της κατά την απόλυτη διακριτική
                  της ευχέρεια και σε αντίθεση ενδεχομένως με άλλες διατάξεις του παρόντος, να απενεργοποιήσει προσωρινά
                  την Εφαρμογή/Υπηρεσία για τον Ταξιδιώτη, άμεσα και αζημίως, σε περίπτωση που αυτή κρίνει ότι ο
                  Ταξιδιώτης έχει παραβιάσει οποιονδήποτε όρο του παρόντος και μέχρις ότου άρει την παράβαση εντός
                  διαστήματος που η Εταιρεία κατά την απόλυτη διακριτική της ευχέρεια που έχει τάξει. Σε περίπτωση μη
                  συμμόρφωσης του, η Εταιρεία δύναται να απενεργοποιήσει οριστικά την Εφαρμογή για τον Ταξιδιώτη αυτόν,
                  επιφυλασσόμενης ρητά παντός νομίμου δικαιώματός της συμπεριλαμβανομένου και του δικαιώματος για
                  αποκατάστασης οποιασδήποτε ζημίας έχει τυχόν υποστεί από τις πράξεις ή/και παραλείψεις του Χρήστη
                  σύμφωνα με τα ανωτέρω.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.4</b> Ρητά συμφωνείται ότι ο Χρήστης δε δύναται για όσες περιπτώσεις των άρθρων 5.1 και 5.2
                  <span className="Apple-converted-space">&nbsp; </span>η Εταιρεία βάσισε τις αποφάσεις σε
                  αυτοματοποιημένα μέσα, να αξιώσει την ανόρθωση οποιαδήποτε ζημίας του από την απενεργοποίηση της
                  Εφαρμογής/Υπηρεσίας σύμφωνα με τις ως άνω διατάξεις, ακόμη και σε περίπτωση που οι ενδείξεις στις
                  οποίες βασίστηκε η Εταιρεία για την απενεργοποίηση της ήταν εσφαλμένες, καθόσον αναγνωρίζει και
                  αποδέχεται ότι τα αυτοματοποιημένα μέσα που χρησιμοποιεί η Εφαρμογή για την εξεύρεση τυχόν παράνομων ή
                  αντισυμβατικών δραστηριοτήτων των χρηστών βασίζονται είτε σε γενικές τυποποιημένες/αυτοματοποιημένες
                  εντολές ενός προγράμματος λογισμικού το οποίο ενδέχεται να περιλαμβάνει ή/και να προβεί σε σφάλματα.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.5</b> <b>Διακοπή Λειτουργίας της Εφαρμογής:</b> Η Εταιρεία διατηρεί το δικαίωμα για τεχνικούς
                  λόγους, λόγους<span className="Apple-converted-space">&nbsp; </span>συντήρησης ή/και αναβάθμισης της
                  Εφαρμογής να διακόπτει τη λειτουργία της προσωρινά και σε τακτά χρονικά διαστήματα. Σε περίπτωση που η
                  διακοπή αυτή διαρκέσει πάνω από ένα εύλογο χρονικό διάστημα κατά την απόλυτη κρίση της Εταιρείας, ή
                  έχει ήδη καθοριστεί από την Εταιρεία προγραμματισμένη διακοπή, η Εταιρεία θα ενημερώνει τους Χρήστες
                  αναφορικά με το χρόνο και τη διάρκεια διακοπής της λειτουργίας της. Ρητά συμφωνείται ότι οι Χρήστες δε
                  δύνανται να εγείρουν αξιώσεις κατά της Εταιρείας για οποιαδήποτε ζημία τυχόν υποστούν εκ του λόγου
                  αυτού ακόμα και αν η Εταιρεία κατά την απόλυτη διακριτικής της ευχέρεια δεν τους ειδοποιήσει για τη
                  διακοπή αυτή.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.6</b> Πλέον των όσων ρητά ορίζονται στο παρόν, η Εταιρεία μπορεί οποτεδήποτε να καταγγείλει,
                  αλλάξει, αναστείλει ή διακόψει οποιαδήποτε επιμέρους λειτουργία της Εφαρμογής, συμπεριλαμβανομένης και
                  της διαθεσιμότητας της ίδιας της Υπηρεσίας, με την προϋπόθεση ότι θα έχει ενημερώσει προς τούτο
                  εγγράφως τους Ταξιδιώτες και τουλάχιστον ένα εύλογο χρονικό διάστημα πριν τη διακοπή που περιγράφεται
                  στην παρούσα διάταξη.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.7 Περιορισμός Εγγυήσεων</b>: Η Εταιρεία, οι διευθυντές, μέτοχοι, υπάλληλοι, προμηθευτές, χορηγοί,
                  συνεργάτες ενεργούν πάντοτε καλόπιστα και στο πλαίσιο των όσων προβλέπει ο νόμος και οι παρόντες
                  Γενικοί Όροι. Συνεπώς έχουμε λάβει και εξακολουθούμε αδιαλείπτως να λαμβάνουμε όλα τα απαραίτητα
                  τεχνικά ή άλλα μέτρα και καταβάλουμε κάθε δυνατή προσπάθεια προκειμένου (α) η Εφαρμογή να λειτουργεί
                  αδιαλείπτως και προσηκόντως χωρίς προβλήματα, διακοπές, καθυστερήσεις, σφάλματα, ιούς ή λάθη, (β)
                  ελαττώματα ή λάθη θα διορθώνονται, γ) τα δεδομένα/πληροφορίες που διαβιβάζονται μέσω της Εφαρμογής
                  αφενός να μην αλλοιώνονται, αφετέρου να προστατεύονται με τη δημιουργία αντιγράφων ασφαλείας, καθόσον
                  τα συστήματα ασφαλείας της υπόκεινται σε περιορισμούς, (δ) η τεχνολογία που χρησιμοποιεί η ίδια ή οι
                  εξυπηρετητές (Servers) μέσω των οποίων η Εφαρμογή τίθεται στη διάθεση των Χρηστών να μην περιέχει ιούς
                  ή άλλα επιζήμια συστατικά, ε) οποιοδήποτε λογισμικό
                  <span className="Apple-converted-space">&nbsp; </span>ή περιεχόμενο που είναι διαθέσιμο μέσω της
                  υπηρεσίας είναι ελεύθερο από ιούς ή αλλά επιβλαβή στοιχεία, ωστόσο ΔΕΝ ΠΑΡΕΧΟΥΜΕ ΣΧΕΤΙΚΕΣ ΕΓΓΥΗΣΕΙΣ
                  για όλα τα ανωτέρω.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.8</b> Επιπλέον η Εταιρεία και τα ανωτέρω πρόσωπα, ουδεμία εγγύηση παρέχει (α) για την
                  καταλληλότητα, αποτελεσματικότητα, επάρκεια της Εφαρμογής αναφορικά με το σκοπό για τον οποίο ο
                  Ταξιδιώτης τη χρησιμοποιεί (β) για τη συμβατότητα της Εφαρμογής με Λογισμικά τρίτων που ενδέχεται να
                  χρησιμοποιεί ο Ταξιδιώτης και συνδέονται με την Εφαρμογή, εκτός και αν αυτά (Λογισμικά) παρέχονται από
                  την Εταιρεία και μόνο στο βαθμό που έχει εγγυηθεί η Εταιρεία στις σχετικές Άδειες της προς τον
                  Ταξιδιώτη, (γ) την ορθή και προσήκουσα εκτέλεση των συναλλακτικών υποχρεώσεων των άλλων Ταξιδιωτών που
                  κάνουν χρήση της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.9 Περιορισμός Υποχρεώσεων:</b> Η Εφαρμογή και η Υπηρεσία παρέχεται ‘ως έχει και διατίθεται’ και
                  κατά τρόπο απολύτως αυτοματοποιημένο και δεν περιλαμβάνει την εισαγωγή από την Εταιρεία οποιουδήποτε
                  στοιχείου στο σύστημα της Υπηρεσίας, ούτε παραμετροποιήσεις σε ad hoc βάση. Η Εφαρμογή ενδέχεται στο
                  μέλλον να παραμετροποιηθεί κατά την απόλυτη διακριτική ευχέρεια της Εταιρείας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.10</b> Η Εταιρεία λαμβάνει όλα τα απαραίτητα τεχνικά και άλλα μέτρα προκειμένου να διασφαλίσει
                  ότι οι χρήστες (Ταξιδιώτες) της Εφαρμογής συμμορφώνονται με τους παρόντες Γενικούς Όρους, ωστόσο ως
                  Πάροχος Υπηρεσιών δεν έχει γενική και βάσει νόμου υποχρέωση (α) ελέγχου, (β) δραστήριας αναζήτησης
                  γεγονότων ή περιστάσεων που δείχνουν ότι κάποιος χρήστης (Ταξιδιώτης) έχει προβεί σε παράνομες ή
                  αντισυμβατικές δραστηριότητες κατά τη χρήση της Εφαρμογής/Υπηρεσίας, (γ) παροχής υπηρεσιών τεχνικής
                  υποστήριξης ή/και εκπαίδευσης των Ταξιδιωτών.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.11</b> <b>Περιορισμός Ευθύνης:</b> Πλέον των όσων ρητά ορίζονται στο παρόν, η Εταιρεία ουδεμία
                  ευθύνη αστική, ποινική, ή άλλως, φέρει έναντι του Ταξιδιώτη, τυχόν εξουσιοδοτημένων υπό αυτόν χρηστών
                  του, των Συνεργατών του, Εργοδότου ή υπαλλήλου του ή/και οποιουδήποτε τρίτου έλκει δικαιώματα από
                  αυτούς, σε περίπτωση που οποιοσδήποτε από τους ανωτέρω, κατά τη χρήση της Υπηρεσίας υποστεί ζημία
                  άμεση, έμμεση, παρεπόμενη, αποθετική ως προς τα κέρδη του, την επιχείρησή του, τα εισοδήματά του, τη
                  φήμη του, μειωθούν οι πωλήσεις των προϊόντων του και η συχνότητα παροχής των υπηρεσιών του, υποστεί
                  οποιεσδήποτε (άμεσες, έμμεσες, επακόλουθες ή ποινικές) ζημιές, απώλειες ή χρεώσεις, τυχόν (προσωπικό)
                  τραυματισμό, θάνατο, φθορά προσωπικής ιδιοκτησίας ή άλλες (άμεσες, έμμεσες, ειδικές, επακόλουθες ή
                  ποινικά κολάσιμες) φθορές, απώλειες ή χρεώσεις ή υποστεί οποιαδήποτε άλλη ζημία άμεση, έμμεση ή
                  αποθετική, οικονομικής ή άλλης φύσης, διαφυγόντα κέρδη, οφειλόμενη σε:
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.1 Καθυστερημένη ή μη προσήκουσα ή/και αποδοχή της υπηρεσίας μεταφοράς ή εκτέλεσης αυτής, εξαιτίας
                                                    λαθών, παραλήψεων, τεχνικών κολλημάτων λαθών, βλαβών ή δυσλειτουργιών των τηλεπικοινωνιακών Δικτύων,
                                                    του Διαδικτύου, του δικτυακού τόπου, των Παρόχων Υπηρεσιών Διαδικτύου.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.2 Τυχόν οριστική ή προσωρινή απενεργοποίηση της Υπηρεσίας, διακοπή λειτουργίας του συνόλου της
                                                    Υπηρεσίας ή μεμονωμένων λειτουργιών της ή τεχνικά κολλήματα της Υπηρεσίας σύμφωνα με τα οριζόμενα στο
                                                    παρόν.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.3 Γεγονότα, καταστάσεις, ενέργειες, πράξεις ή/και παραλείψεις της Εταιρείας ή τρίτων
                                                    συμπεριλαμβανομένων και των άλλων Χρηστών/ Εξουσιοδοτημένων Χρηστών για τις οποίες η Εταιρεία δεν
                                                    παρέχει εγγυήσεις και δεν φέρει υποχρέωση σύμφωνα με τα οριζόμενα στο παρόν.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.4 Χρήση εκ μέρους των Οδηγών, ή και τρίτων των δεδομένων των Ταξιδιωτών (συμπεριλαμβανομένων και
                  τυχόν προσωπικών<span className="Apple-converted-space">&nbsp; </span>δεδομένων τους) για σκοπούς
                  άλλους από τη χρήση της Υπηρεσίας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.5 Παραβίαση της ισχύουσας νομοθεσίας σχετικά με την προστασία προσωπικών δεδομένων ή άλλων
                                                    ισχυόντων νόμων που σχετίζονται, για παράδειγμα, με τη χρήση της Υπηρεσίας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.6 Παράβαση της Πολιτικής Αποδεκτών Χρήσεων, των παρόντων Όρων Χρήσης εκ μέρους των ίδιων των
                                                    Ταξιδιωτών ή/και άλλων χρηστών της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.7 Τυχόν ασυμφωνία της ώρας του συστήματος της Υπηρεσίας με κάποια ώρα που έχει καθοριστεί
                                                    επίσημα.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">5.11.8 Γεγονότα ανωτέρας βίας.</span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.9 Κακή συμπεριφορά ή/και παραβίαση νόμων από τους Οδηγούς για ίδιον όφελος, μη εμφάνισης του
                                                    Οδηγού ή καθυστέρησης πέραν όσων έχουν αναφερθεί στο παρόν κείμενο.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    5.11.10 Απώλεια αντικειμένων ή οποιοδήποτε ατύχημα κατά τη διάρκεια της Μεταφοράς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.12</b> Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΟΠΟΙΑΔΗΠΟΤΕ ΖΗΜΙΑ YΠΟΣΤΕΙ Ο ΧΡΗΣΤΗΣ
                  ΤΑΞΙΔΙΩΤΗΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΕΝΔΕΙΚΤΙΚΑ ΑΠΩΛΕΙΑ ΔΕΔΟΜΕΝΩΝ ΑΠΟ ΚΑΘΥΣΤΕΡΗΣΕΙΣ, ΜΗ ΠΑΡΑΔΟΣΕΙΣ, ΛΑΘΗ,
                  ΣΥΣΤΗΜΑΤΑ DOWN TIME , ΕΣΦΑΛΜΕΝΕΣ ΠΑΡΑΔΟΣΕΙΣ, ΔΙΑΚΟΠΕΣ ΔΙΚΤΥΟΥ Ή ΣΥΣΤΗΜΑΤΟΣ, ΦΘΟΡΑ ΑΡΧΕΙΩΝ, ΔΙΑΚΟΠΗ ΤΗΣ
                  ΥΠΗΡΕΣΙΑΣ, ΠΟΥ ΠΡΟΚΑΛΕΙΤΑΙ ΑΠΟ ΑΜΕΛΕΙΑ ΤΗΣ, ΑΠΟ ΣΦΑΛΜΑΤΑ Ή / ΚΑΙ ΠΑΡΑΛΕΙΨΕΙΣ ΤΩΝ ΧΡΗΣΤΩΝ.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.13</b> Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΕΙΝΑΙ ΥΠΕΥΘΥΝΗ ΓΙΑ ΤΥΧΟΝ ΚΑΘΥΣΤΕΡΗΣΕΙΣ, ΑΠΟΤΥΧΙΕΣ ΠΑΡΑΔΟΣΗΣ Η’ ΟΠΟΙΑΔΗΠΟΤΕ
                  ΑΛΛΗ ΑΠΩΛΕΙΑ Ή ΖΗΜΙΑ ΠΟΥ ΠΡΟΚΥΠΤΕΙ ΑΠΟ ΤΗ ΜΕΤΑΦΟΡΑ ΔΕΔΟΜΕΝΩΝ ΜΕΣΩ ΔΙΚΤΥΩΝ ΚΑΙ ΕΓΚΑΤΑΣΤΑΣΕΩΝ
                  ΕΠΙΚΟΙΝΩΝΙΩΝ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΟΥ ΤΟΥ ΔΙΑΔΙΚΤΥΟΥ, ΕΝΩ Ο ΧΡΗΣΤΗΣ – ΤΑΞΙΔΙΩΤΗΣ ΑΝΑΓΝΩΡΙΖΕΙ ΟΤΙ Η
                  ΥΠΗΡΕΣΙΑ ΚΑΙ ΟΠΟΙΑΔΗΠΟΤΕ ΔΕΔΟΜΕΝΑ ΠΟΥ ΑΠΟΣΤΕΛΛΟΝΤΑΙ ΑΠΟ ΑΥΤΟΝ/ΑΥΤΗ ΥΠΟΚΕΙΝΤΑΙ ΣΤΟΥΣ ΠΕΡΙΟΡΙΣΜΟΥΣ
                  ΚΑΘΥΣΤΕΡΗΣΕΩΝ ΚΑΙ ΟΠΟΙΩΝΔΗΠΟΤΕ ΑΛΛΩΝ ΠΡΟΒΛΗΜΑΤΩΝ ΠΟΥ ΕΜΠΕΡΙΕΧΕΙ Η ΧΡΗΣΗ ΤΕΤΟΙΩΝ ΜΕΣΩΝ ΕΠΙΚΟΙΝΩΝΙΑΣ.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.14 </b>Σε κάθε περίπτωση ρητά συμφωνείται ότι η Εταιρεία ευθύνεται μόνο για άμεσες ζημιές, οι
                  οποίες προκύπτουν λόγω δόλου ή βαριάς αμέλειας εκ μέρους της. Με την επιφύλαξη αναγκαστικών διατάξεων
                  δικαίου, αποκλείεται ρητά η ευθύνη της Εταιρείας για άμεση ζημία λόγω ελαφράς αμέλειας ανεξαρτήτως
                  νομικού λόγου. Αποκλείεται πλήρως και ρητά η ευθύνη της Εταιρείας για έμμεσες ή επακόλουθες ζημίες,
                  ανεξαρτήτως λόγου. Βάσει των περιορισμών που ισχύουν σύμφωνα με το παρόν και στον βαθμό που
                  επιτρέπεται από τη νομοθεσία, οποιαδήποτε ευθύνη της Εταιρείας περιορίζεται στο συνολικού ύψους που
                  αντιστοιχεί στο συνολικό κόστος της Υπηρεσίας Μεταφοράς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.15</b> ΟΙ ΠΕΡΙΟΡΙΣΜΟΙ ΠΟΥ ΤΙΘΕΝΤΑΙ ΣΤΗΝ ΠΑΡΟΥΣΑ ΓΙΑ ΤΗΝ ΑΠΟΚΑΤΑΣΤΑΣΗ ΖΗΜΙΑΣ ΙΣΧΥΟΥΝ ΑΚΟΜΑ ΚΑΙ
                  ΣΤΗΝ ΠΕΡΙΠΤΩΣΗ ΠΟΥ ΟΙ ΟΡΟΙ ΤΗΣ ΠΑΡΟΥΣΑΣ ΔΕΝ ΕΧΟΥΝ ΕΚΠΛΗΡΩΘΕΙ Η ΘΕΩΡΟΥΝΤΑΙ ΑΚΥΡΟΙ Η ΜΗ ΕΦΑΡΜΟΣΙΜΟΙ ΚΑΙ
                  Ο ΧΡΗΣΤΗΣ ΑΝΑΓΝΩΡΙΖΕΙ ΟΤΙ ΟΙ ΠΑΡΟΝΤΕΣ ΠΕΡΙΟΡΙΣΜΟΙ ΕΙΝΑΙ ΛΟΓΙΚΟΙ ΚΑΙ ΑΠΟΤΕΛΟΥΝ ΤΗ ΒΑΣΗ ΤΗΣ ΠΑΡΟΥΣΑΣ ΚΑΙ
                  ΟΤΙ ΧΩΡΙΣ ΤΟΥΣ ΠΕΡΙΟΡΙΣΜΟΥΣ ΑΥΤΟΥΣ Η ΕΤΑΙΡΕΙΑ ΔΕ ΘΑ ΠΡΟΣΧΩΡΟΥΣΕ ΣΤΗΝ ΠΑΡΟΥΣΑ.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>5.16</b> <b>Παροχή Πληροφοριών:</b> Η Εταιρεία, σε περίπτωση που τούτο της ζητηθεί από αρμόδια
                  διωκτική, εποπτική, δικαστική ή άλλη Αρχή, υποχρεούται σε διαβίβαση των δεδομένων του Χρήστη Ταξιδιώτη
                  ή/και των Δεδομένων Τρίτων, συμπεριλαμβανομένων και τυχόν προσωπικών δεδομένων των Εξουσιοδοτημένων
                  Χρηστών ή τρίτων που χορηγούνται στην Εταιρεία σύμφωνα με το παρόν, και οποιονδήποτε άλλων πληροφοριών
                  ανταλλάσσονται μέσω της Υπηρεσίας μεταξύ των Χρηστών χωρίς προηγούμενη ενημέρωση ή συγκατάθεση του
                  Χρήστη, Εξουσιοδοτημένου Χρήστη ή τρίτου υποκειμένου των δεδομένων, για την απαραίτητη προστασία από
                  κινδύνους για την κρατική και δημόσια ασφάλεια καθώς και για τη δίωξη εγκληματικών πράξεων,
                  συμπεριλαμβανομένων και των φορολογικών εγκλημάτων και παραβάσεων.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>6. ΧΡΕΩΣΕΙΣ ΚΑΙ ΤΙΜΟΛΟΓΙΑΚΗ ΠΟΛΙΤΙΚΗ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Η Εταιρεία παρέχει τη δυνατότητα προ-πληρωμής μέσω PayPal, Checkout, ή Shopify της
                  Μεταφοράς ενός Ταξιδιώτη. Το κόστος της Μεταφοράς (<b>«Κόστος Μεταφοράς»</b>) είναι το ποσό που
                  εμφανίζεται σε Εσάς όταν υπάρχει σχετική αναφορά μέσω της Εφαρμογής ή το ποσό που εμφανίζεται σε Εσάς
                  πριν την πληρωμή. Το Κόστος Μεταφοράς περιλαμβάνει το τέλος του Οδηγού και το έσοδο της Εταιρείας. Το
                  έσοδο της Εταιρείας υπολογίζεται ως ποσοστό ή μπορεί να είναι σταθερό τέλος και ο υπολογισμός του
                  μπορεί να αλλάξει σύμφωνα με την επιθυμία της Εταιρείας και χωρίς προειδοποίηση σε Σας, εάν κριθεί
                  απαραίτητο από την Εταιρεία για τη λειτουργίας της.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Ο Οδηγός είναι εξολοκλήρου αποκλειστικός υπεύθυνος να παράσχει στον Ταξιδιώτη εκτυπωμένη απόδειξη και
                                                    να τηρεί τους ισχύοντες φορολογικούς κανονισμούς στο συνολικό έσοδο που προέρχεται από τη Μεταφορά.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Επιστροφές Χρημάτων: Το πλήρες ποσό του Κόστους Μεταφοράς (συμπεριλαμβανομένου και του εσόδου της
                                                    Εταιρείας) χρεώνεται άμεσα κατόπιν της ολοκλήρωσης της σχετικής επιλογής στον
                                                    PayPal/Checkout/Shopify λογαριασμό του Ταξιδιώτη και μεταφέρεται (χωρίς το έσοδο της
                                                    Εταιρείας) στο λογαριασμό του Οδηγού.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Σχετικά με την Πολιτική Επιστροφής Χρημάτων παρακαλώ συμβουλευτείτε την Ενότητα II.3-Πολιτική για τις
                                                    Υπηρεσίες Μεταφοράς.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Προωθητικές Ενέργειες/Προσφορές: Είναι στη διακριτική ευχέρεια της Εταιρείας να δημιουργήσει
                                                    προωθητικές ενέργειες/προσφορές, με διάφορα χαρακτηριστικά, και να τις διαθέσει σε οποιοδήποτε
                                                    Ταξιδιώτη.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Διαδικασία Πληρωμών: Όλες οι πληρωμές ή χρεώσεις, όποτε εφαρμόζονται, θα πραγματοποιούνται μέσω Paypal
                  ή Braitree ή Checkout ή Shopify, που αποτελούν υπηρεσίες τρίτων και χρησιμοποιούνται από την Εταιρεία.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>7. ΓΝΩΜΕΣ ΤΑΞΙΔΙΩΤΩΝ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>7.1 </b>Μόνο οι Ταξιδιώτες οι οποίοι έχουν λάβει την Υπηρεσία Μεταφοράς δύνανται να
                  αξιολογήσουν/σχολιάσουν την Υπηρεσία Μεταφοράς, τους Οδηγούς, τα οχήματά τους ή και ό,τι άλλο κρίνουν
                  σκόπιμο και άξιο σχολιασμού ή παρατήρησης θετικής ή και αρνητικής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>7.2</b> Ειδικότερα, η Εταιρεία μετά την πραγματοποίηση της Μεταφοράς αποστέλλει email στον
                  Ταξιδιώτη προκειμένου να τον προσκαλέσει να αξιολογήσει τον Οδηγό που πραγματοποίησε τη Μεταφορά και
                  την Εταιρεία. Ο Ταξιδιώτης αξιολογεί τον Οδηγό βάζοντας βαθμολογία με αστερίσκους από το ένα (1) μέχρι
                  το πέντε (5). Για βαθμολογία τριών αστερίσκων και κάτω ο Ταξιδιώτης θα πρέπει να επιλέξει έναν από
                  τους λόγους που του παρέχονται για να αιτιολογήσει την αρνητική αξιολόγησή του. Σε κάθε περίπτωση ο
                  Ταξιδιώτης έχει το δικαίωμα να αποστείλει σχόλια αναφορικά με τον Οδηγό, την Εταιρεία, τη Μεταφορά ή
                  και ό,τι άλλο.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>7.3 </b>Η Εταιρεία δεν αποζημιώνει ούτε και επιβραβεύει τους Ταξιδιώτες που έχουν παραθέσει την
                  αξιολόγηση/σχόλιά τους. Τη κριτική, την αξιολόγηση και τα σχόλια των Ταξιδιωτών δύναται η Εταιρεία και
                  κατά την απόλυτη διακριτική της ευχέρεια να τα χρησιμοποιήσει για την ενημέρωση των Ταξιδιωτών, για
                  σκοπούς διαφήμισης και για σκοπούς δικής της αξιολόγησης των Οδηγών.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>7.4 </b>Οι Ταξιδιώτες έχουν το δικαίωμα να δημοσιεύουν αξιολογήσεις/σχόλια σε δημόσια μέσα π.χ μέσα
                  κοινωνικής δικτύωσης ή ιστοσελίδες όπως FB, TRIPADVISOR και είναι στη διακριτική ευχέρεια της
                  Εταιρείας το αν θα λάβει υπόψη και τις εν λόγω αξιολογήσεις/σχόλια στα πλαίσια της αξιολόγησής σας από
                  την ίδια και εν γένει τον τρόπο διαχείρισής αυτών.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>8. ΑΠΟΠΟΙΗΣΗ ΕΥΘΥΝΩΝ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Με την αποδοχή της παρούσας συμφωνίας και τη χρήση του λογισμικού της Εφαρμογής, στο βαθμό που
                                                    επιτρέπεται από το νόμο, συμφωνείτε ότι Εσείς θα υπερασπίζεστε, αποζημιώνετε και υποστηρίζετε την
                                                    Εταιρεία, τους δικαιοπαρόχους της και κάθε μητρικό οργανισμό, θυγατρική, σχετιζόμενη εταιρεία,
                                                    διανομείς, συνεργάτες, μετόχους, αξιωματούχους, διευθυντές, εργαζομένους, αντιπροσώπους, μέλη,
                                                    δικηγόρους, δικαιοδόχους, πράκτορες, ή άλλους που συμμετέχουν στη δημιουργία, χορηγία, προώθηση, ή
                                                    αλλιώς διαθέτουν το λογισμικό και τα περιεχόμενα σε αυτό δεδομένα, εναντίων όλων των πιθανών
                                                    απαιτήσεων, κοστών, ζημιών, απωλειών, ευθυνών, και εξόδων (συμπεριλαμβανομένων των εξόδων για νομικές
                                                    υπηρεσίες) που προκύπτουν από ή σχετίζονται με:
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    α) οποιαδήποτε τιμωριτική, ειδική, έμμεση ή επακόλουθη απώλεια ή ζημία, οποιαδήποτε απώλεια/ζημία στην
                                                    παραγωγή, απώλεια κέρδους, απώλεια εσόδων, απώλεια συμβολαίων, ζημία ή απώλεια καλής θέλησης και
                                                    φήμης, απώλεια απαιτήσεων προς τρίτους
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    β) οποιαδήποτε ανακρίβεια σχετιζόμενη με πληροφορίες (συμπεριλαμβανομένων των προσωπικών δεδομένων,
                                                    σχολίων και αξιολογήσεων) των χρηστών που έχουν γίνει διαθέσιμες στο λογισμικό μας
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">γ) τις υπηρεσίες που προσφέρονται από τον Οδηγό</span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    δ) οποιαδήποτε (άμεση/ο, έμμεση/ο, επακόλουθη/ο, ή τιμωριτική/ο) ζημία, απώλεια, κόστος που επωμίζεστε
                                                    ή αναλάβατε Εσείς (συμπεριλαμβανομένων των εξόδων για νομικές υπηρεσίες) σύμφωνα με, που προκύπτει
                                                    από, σχετιζόμενη/ο με τη χρήση, αδυναμία χρήσης, καθυστέρηση, ή ιό του λογισμικού Μας ή/και του
                                                    διακομιστή στον οποίο το λογισμικό αυτό αποθηκεύεται και διακομίζεται
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    ε) για οποιαδήποτε(προσωπική) βλάβη, θάνατο, καταστροφή περιουσίας ή άλλες (άμεσες, έμμεσες, ειδικές,
                  <span className="Apple-converted-space">&nbsp; </span>επακόλουθες ή τιμωρητικές) ζημίες, απώλειες που
                  προκλήθηκαν ή πληρώθηκαν από Εσάς είτε εξαιτίας νομικών ενεργειών, λαθών, παραβάσεων, (βαριάς)
                  αμέλειας, δόλιων παραβάσεων, παραλείψεων, μη εμφάνισης, παραποιήσεων, αδικοπραξίας ή απόλυτης ευθύνης
                  που μπορεί να αποδοθεί συνολικά ή εν μέρει στον χρήστη(εργοδότες του, διευθυντές, προϊσταμένους,
                  εκπροσώπους, αντιπροσώπους ή θυγατρικές εταιρειών) (περιλαμβανομένου αμοιβές και κόστη δικηγόρου),
                  περιλαμβανομένου οποιασδήποτε (εν μέρει) ακύρωσης, διπλής κράτησης, απεργίας, λόγου ανωτέρας βίας ή
                  οποιοδήποτε άλλο γεγονός που δεν μπορούμε να ελέγξουμε.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    στ)<span className="Apple-converted-space">&nbsp; </span>κάθε πληροφορία στο λογισμικό μερικές φορές
                  συνδέεται με εξωτερικούς διαδικτυακούς τόπους τους οποίους Εμείς δεν μπορούμε να ελέγξουμε και για
                  τους οποίους δεν φέρουμε καμία ευθύνη.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    ζ) οποιαδήποτε παραβίαση του χρήστη ή παραβίαση οποιουδήποτε όρου αυτής της σύμβασης ή οποιουδήποτε
                  εφαρμοστέου νόμου ή κανόνα δικαίου, είτε αναφέρεται είτε όχι στο παρόν.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>IV. ΓΕΝΙΚΟΙ ΝΟΜΙΚΟΙ ΟΡΟΙ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>
                                                        1.<span className="Apple-converted-space">&nbsp; </span>Πνευματική Ιδιοκτησία
                  </b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1.1 </b>Η Εταιρεία είναι ο νομίμως κάτοχος των Δικαιωμάτων Πνευματικής Ιδιοκτησίας στην Εφαρμογή
                  και της Υπηρεσίας και παραχωρεί στον χρήστη Ταξιδιώτη το δικαιώματα πρόσβασης στην Εφαρμογή και χρήση
                  της Υπηρεσίας σύμφωνα με τους παρόντες Γενικούς Όρους (η «Άδεια»). Περιορισμοί της Άδειας αναφέρονται
                  στην Πολιτική Αποδεκτών Χρήσεων της Εφαρμογής.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1.2 </b>Η Εταιρεία κατέχει και διατηρεί στην κατοχή και ιδιοκτησία της όλα τα δικαιώματα, τίτλους
                  και συμφέροντα στην Εφαρμογή και την Υπηρεσία, στις λειτουργίες και στα χαρακτηριστικά της
                  (συμπεριλαμβανομένων ενδεικτικά και όχι περιοριστικά: των Λογισμικών της, προγραμμάτων της, της
                  φιλοσοφίας, look and feel, μεθοδολογίας και τεχνικής με τις οποίες αυτή έχει σχεδιαστεί, του μοντέλο,
                  των αλγορίθμων, πληροφοριών και υλικών της, της τεχνογνωσίας αναφορικά με την Εφαρμογή και την
                  Υπηρεσία και των Λογισμικών της, καθώς και τυχόν τροποποιήσεων, ενημερώσεων, βελτιώσεων,
                  παραμετροποιήσεων της, παράγωγων λειτουργιών τους, των εγχειριδίων και άλλων εγγράφων που αναφέρονται
                  στην Εφαρμογή και στη λειτουργία της.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1.3 </b>Όλο το περιεχόμενο της Εφαρμογής/ Υπηρεσίας, συμπεριλαμβανομένων εικόνων, γραφικών,
                  φωτογραφιών, σχεδίων, κειμένων, παρεχόμενων υπηρεσιών κτλ. αποτελούν πνευματική ιδιοκτησία της
                  Εταιρείας και προστατεύονται κατά τις σχετικές διατάξεις του Ελληνικού δικαίου, του Ευρωπαϊκού δικαίου
                  και των διεθνών συμβάσεων και συνθηκών και ως εκ τούτου ο Χρήστης Ταξιδιώτης δεν έχει κανένα δικαίωμα
                  στην Υπηρεσία και στα ανωτέρω συστατικά της, άλλο από αυτό που του παραχωρείται με την Άδεια δυνάμει
                  της παρούσας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1.4 </b>Τα ονόματα, εικόνες, λογότυπα και διακριτικά γνωρίσματα που αντιπροσωπεύουν την Εταιρεία
                  μας και τα προϊόντα/ υπηρεσίες της, όπως η εμπορική επωνυμία WELCOME, WELCOME PICKUPS και τα
                  παρεχόμενα με αυτό το brand προϊόντα/υπηρεσίες, παράγωγα, σύνθετα, ομόηχα ή παρόμοια ονόματα,
                  οποιαδήποτε άλλα λογότυπα, εμπορικά σήματα και διακριτικούς τίτλους, εμπορικά μυστικά, πατέντες και
                  οποιαδήποτε άλλα δικαιώματα πνευματικής ιδιοκτησίας αναφορικά με την Εφαρμογή/Υπηρεσία και τις
                  λειτουργίες τα οποία καθίστανται γνωστά στο Χρήστη Ταξιδιώτη μέσω της Υπηρεσίας, είναι αποκλειστικά
                  σήματα και διακριτικά γνωρίσματα της Εταιρείας μας και προστατεύονται από τους Ελληνικούς, κοινοτικούς
                  και διεθνείς νόμους περί εμπορικών σημάτων και βιομηχανικής και πνευματικής ιδιοκτησίας και αθέμιτου
                  ανταγωνισμού. Σε κάθε περίπτωση η εμφάνισή και έκθεση τους στην Εφαρμογή και το Δικτυακό τόπο δεν θα
                  πρέπει κατά κανένα τρόπο να εκληφθεί ως μεταβίβαση ή εκχώρηση άδειας ή δικαιώματος χρήσης τους από
                  Εσάς ή οποιονδήποτε τρίτο.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1.5 </b>Με του παρόντες Γενικούς Όρους παρέχουμε σε Εσάς περιορισμένη και μη μεταβιβάσιμη άδεια
                  πρόσβασης και προσωπικής χρήσης της Εφαρμογής και της Υπηρεσίας, αλλά όχι άδεια λήψης του περιεχομένου
                  και του κώδικα της Εφαρμογής στο σύνολό του ή εν μέρει, παρά μόνο με τη ρητή γραπτή άδεια Μας. Η άδεια
                  αυτή δεν επιτρέπει οποιαδήποτε μεταπώληση ή εμπορική χρήση της Εφαρμογής/Υπηρεσίας ή του περιεχομένου
                  της, συλλογή και χρήση των καταλόγων Μας, των προϊόντων, της εμπορικής Μας πολιτικής, εξόρυξη
                  δεδομένων κ.α. Έχετε δικαίωμα να χρησιμοποιείτε τα ανωτέρω μόνο για προσωπική Σας χρήση.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1.6 </b>Δεν επιτρέπεται να χρησιμοποιείτε οποιεσδήποτε «μετά-ετικέτες» ή οποιοδήποτε άλλο «κρυφό
                  κείμενο» με βάση επωνυμίες ή εμπορικά σήματα της Εταιρείας Μας χωρίς τη ρητή γραπτή Μας συγκατάθεση.
                  Σε περίπτωση μη εξουσιοδοτημένης χρήσης, η Άδεια που έχει παραχωρηθεί παύει να ισχύει. Το αυτό ισχύει
                  και για τυχόν χρήση των λογοτύπων και εμπορικών σημάτων της Εταιρείας μας. Απαγορεύεται οποιαδήποτε
                  αντιγραφή, αναλογική / ψηφιακή εγγραφή και μηχανική αναπαραγωγή, διανομή, μεταφορά, downloading,
                  μεταποίηση, μεταπώληση, μέρος ή του συνόλου του περιεχομένου της Εφαρμογής για κανέναν σκοπό, πλην της
                  αυστηρά προσωπικής χρήσης, εκτός και αν σας χορηγηθεί εγγράφως η συναίνεσή μας.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2. Τεχνική Υποστήριξη – Βελτιώσεις Εφαρμογής</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Η Εταιρεία δεν έχει υποχρέωση και δε δεσμεύεται δια του παρόντος να παρέχει τεχνική υποστήριξη,
                                                    βελτιώσεις, παραμετροποιήσεις στην Εφαρμογή ή ανανεώσεις, ωστόσο ενδέχεται κατά καιρούς και κατά την
                                                    απόλυτη διακριτική της ευχέρεια να προβαίνει σε αναβαθμίσεις της Εφαρμογής, οπότε και αυτομάτως οι
                                                    αναβαθμίσεις αυτές θα επικαιροποιούνται στην έκδοση της Εφαρμογής που χρησιμοποιείτε. Ενδέχεται δε να
                                                    πρέπει να προβείτε και οι ίδιοι σε ενημερώσεις λογισμικού, διαφορετικά η Εταιρεία ουδεμία ευθύνη φέρει
                                                    για τυχόν μη συμβατά χαρακτηριστικά- λειτουργίες της έκδοσης της Εφαρμογής που χρησιμοποιείτε. Σε κάθε
                                                    περίπτωση αναγνωρίζετε και αποδέχεστε ότι οι παρόντες Γενικοί Όροι διέπουν την οποιαδήποτε τρέχουσα
                  έκδοση της Εφαρμογής.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                                                    <b>3. Εξωτερικοί Σύνδεσμοι </b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Η Εφαρμογή μπορεί να περιέχει συνδέσμους προς ιστοσελίδες που διατηρούνται από τρίτους, των οποίων οι
                                                    πληροφορίες και οι πρακτικές προστασίας δεδομένων είναι διαφορετικές από τις δικές μας. Η Εταιρεία Μας
                                                    δε φέρει καμία ευθύνη για τις πληροφορίες ή τις πρακτικές προστασίας δεδομένων που χρησιμοποιούνται σε
                                                    ιστοσελίδες τρίτων. Συνιστάται, πριν την χρήση άλλων ιστοσελίδων, η ανάγνωση και κατανόηση των όρων
                                                    χρήσης των και της πολιτικής απορρήτου τους. Η Εταιρεία Μας δεν ελέγχει τη διαθεσιμότητα, το
                                                    περιεχόμενο, την πολιτική προστασίας προσωπικών δεδομένων, την ποιότητα και πληρότητα των υπηρεσιών
                                                    άλλων ιστοσελίδων στις οποίες ενδέχεται να παραπέμπει η Εφαρμογή μέσω «δεσμών», hyperlinks ή
                                                    διαφημιστικών banners. Η Εταιρεία Μας σε καμία περίπτωση δεν πρέπει να θεωρηθεί ότι ενστερνίζεται ή
                                                    αποδέχεται το περιεχόμενο ή τις υπηρεσίες των σελίδων στα οποία παραπέμπει, ή ότι συνδέεται με αυτά
                                                    κατά οποιονδήποτε άλλο τρόπο.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>4. Επικοινωνία</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Με τη χρήση της Εφαρμογής/Υπηρεσίας, συμφωνείτε να λαμβάνετε ηλεκτρονικές επικοινωνίες από Εμάς και
                                                    αποδέχεστε ανεπιφύλακτα ότι αυτές πληρούν όλες τις νόμιμες απαιτήσεις γραπτής επικοινωνίας αναφορικά
                                                    με το λόγο που δημιουργούνται. Οποιαδήποτε παράπονα, σχόλια, προτάσεις, κτλ. θέλετε να Μας υποβάλετε,
                  επικοινωνήστε μαζί Μας με το Τμήμα Εξυπηρέτησης Πελατών.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                                                    <b>5. Τελικοί Όροι</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                  Οι παρόντες Γενικοί Όροι αποτελούν<span className="Apple-converted-space">&nbsp; </span>τους τελικούς
                  και μοναδικούς όρους σε ισχύ αναφορικά με την παροχή της Υπηρεσίας εκ μέρους της Εταιρείας στον Οδηγό
                  και καταργούν τυχόν προ ισχύσαντες όρους, προηγούμενες συμβάσεις και διακανονισμούς, έγγραφους ή
                  προφορικούς μεταξύ της Επιχείρησης και του Χρήστη αναφορικά με τη χρήση της Υπηρεσίας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>6. Παραίτηση</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Καμία καθυστέρηση, αμέλεια ή ανοχή της Εταιρείας στην επιβολή της τήρησης κάποιου παρόντος όρου από
                                                    τον Οδηγό, δεν αποτελεί παραίτηση ούτε βλάπτει προβλεπόμενο στην παρούσα
                  <span className="Apple-converted-space">&nbsp; </span>δικαίωμά της. Εάν οποιοσδήποτε παρόν όρος ήθελε
                  κριθεί από οποιοδήποτε αρμόδιο Δικαστήριο η Αρχή ως άκυρος και κατά συνέπεια ανεφάρμοστος, ο όρος
                  αυτός δε θα συμπαρασύρει σε ακυρότητα τους υπόλοιπους όρους της παρούσης, οι οποίοι θα παραμείνουν σε
                  πλήρη ισχύ.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>7. Ακυρότητα Όρων</b>{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Στην περίπτωση που κάποιο τμήμα του παρόντος κηρυχθεί ή ορισθεί με δικαστική απόφαση άκυρο, η
                                                    ακυρότητα αυτή δεν θα επηρεάσει το κύρος του υπολειπόμενου τμήματος του παρόντος, το οποίο θα
                                                    παραμείνει ισχυρό σαν οι παρόντες Γενικοί Όροι να είχαν εκτελεσθεί με το άκυρο τμήμα τους
                                                    διαγεγραμμένο. Η Εταιρεία θα επιδιώξει να αντικαταστήσει κάθε άκυρο όρο, με νέο έγκυρο όρο, του οποίου
                                                    το αποτέλεσμα θα είναι το εγγύτατο ισοδύναμο αυτού που ακυρώθηκε.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>8.</b> <b>Εκχώρηση</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Αυτή η Σύμβαση δεν μπορεί να εκχωρηθεί από Εσάς, χωρίς την προηγούμενη γραπτή έγκριση της Εταιρείας,
                                                    αλλά μπορεί να ανατεθεί χωρίς τη συγκατάθεσή Σας από την Εταιρία σε (i) μητρική ή θυγατρική εταιρεία,
                                                    (ii) αγοραστή ενεργητικού ή (iii) διάδοχο από συγχώνευση. Οποιαδήποτε απόπειρα εκχώρησης κατά παράβαση
                                                    του παρόντος άρθρου είναι άκυρη.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>9.</b> <b>Εφαρμοστέο Δίκαιο – Δικαιοδοσία</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    Κάθε διαφορά μεταξύ των συμβαλλομένων σχετικά με την εφαρμογή, ερμηνεία, ακυρότητα όρων της σύμβασης,
                                                    την ύπαρξη ή ανυπαρξία δικαιωμάτων και υποχρεώσεων των συμβαλλομένων εκ συμβάσεως ή και αδικοπραξίας,
                                                    ερμηνεύεται σύμφωνα με τους Ελληνικούς νόμους και υπάγεται στην αποκλειστική δικαιοδοσία των καθ’ ύλη
                                                    αρμοδίων δικαστηρίων της πόλεως των Αθηνών, στην δικαιοδοσία των οποίων τα μέρη υποβάλλονται από
                                                    σήμερα οικειοθελώς. Συμφωνείται δε ότι επειδή οι παρόντες όροι έχουν μεταφραστεί και σε άλλες γλώσσες,
                                                    σε περίπτωση διαφοράς μεταξύ της Ελληνικής έκδοσης των Γενικών Όρων και της Αγγλικής ή/και άλλης, θα
                                                    υπερισχύει η Ελληνική έκδοσης των παρόντων όρων.&nbsp;
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>V. ΠΟΛΙΤΙΚΗ ΑΠΟΔΕΚΤΩΝ ΧΡΗΣΕΩΝ ΤΗΣ ΕΦΑΡΜΟΓΗΣ</b>
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>1. </b>Οι Χρήστες της Εφαρμογής και οι Εξουσιοδοτημένοι Χρήστες τους, οφείλουν να κάνουν χρήση της
                  Εφαρμογής σύμφωνα με τους Όρους Χρήσης, και τους εφαρμοστέους νόμους και κανονισμούς και σύμφωνα με
                  την καλή πίστη και τα χρηστά ήθη.&nbsp;
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2. </b>Απαγορεύεται στα ανωτέρω πρόσωπα:
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.1 </b>Οποιαδήποτε χρήση της Εφαρμογής για σκοπό άλλον σκοπό από την χρήση της Υπηρεσίας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                                                    <b>2.2 </b>Οποιαδήποτε πρόσβαση ή απόπειρα πρόσβασης σε πληροφορίες και δεδομένα (συμπεριλαμβανομένων
                  και των προσωπικών δεδομένων) που διακινούνται μέσω της Εφαρμογής και για τα οποία δεν έχουν καμία
                  εξουσιοδότηση ή εξουσία να χρησιμοποιήσουν.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                                                    <b>2.3 </b>Η πρόσβαση στην Εφαρμογή με σκοπό τη δημιουργία ή παραγωγή προϊόντος ή υπηρεσίας που να
                  ανταγωνίζεται την Εφαρμογή και το Λογισμικό της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.4 </b>Η χρήση της Εφαρμογής με σκοπό την παροχή εκ μέρους τους υπηρεσιών προς τρίτους, πλην των
                  επιτρεπόμενων.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.5 </b>Η πώληση, μίσθωση, διάθεση, μεταβίβαση, εκχώρηση, παραχώρηση χρήσης, παρουσίαση, διαβίβαση
                  και εν γένει η εμπορική εκμετάλλευση της Άδειας πρόσβασης και χρήσης της Εφαρμογής και των δικαιωμάτων
                  που τους παραχωρούνται δυνάμει της Άδειας σύμφωνα με τους Όρους Χρήσης.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.6 </b>Η διευκόλυνση με οποιονδήποτε τρόπο και με οποιαδήποτε μέσα τρίτων να αποκτήσουν πρόσβαση
                  στην Εφαρμογή και σε οποιαδήποτε δεδομένα (συμπεριλαμβανομένων και των προσωπικών δεδομένων) και
                  πληροφορίες.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.7 </b>Η μετάδοση και μεταφορά ιών, η αποστολή και διαβίβαση εγγράφων και πληροφοριών τα οποία
                  είναι παράνομα, επιζήμια, απειλητικά, δυσφημιστικά, επικίνδυνα για την ασφάλεια της Εφαρμογής, και για
                  τη φήμη και την υπόληψη της Εταιρείας και των άλλων Χρηστών της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.8 </b>Η ανάρτηση μηνυμάτων, πληροφοριών, εγγράφων και άλλων δεδομένων και στοιχείων καθώς και η
                  χορήγηση οποιονδήποτε δεδομένων (συμπεριλαμβανομένων και των προσωπικών δεδομένων) και πληροφοριών τα
                  οποία ενδέχεται να περιέχουν υβριστικό, απειλητικό, δυσφημιστικό και εν γένει παράνομο περιεχόμενο.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.9 </b>Οποιαδήποτε ενέργεια είναι ή θεωρείται επιζήμια για τα συμφέροντα της Εταιρείας, και των
                  άλλων Χρηστών της Εφαρμογής ή μπορεί να αποτρέψει οποιονδήποτε τρίτο να κάνει χρήση της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.10 </b>Η πώληση, παραχώρηση, εκμίσθωση, διαβίβαση, επεξεργασία οιονδήποτε δεδομένων λάβει γνώση
                  από τη χρήση της Εφαρμογής, σε οιονδήποτε τρίτο και για οιονδήποτε σκοπό.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    {" "}
                                                    <b>2.11 </b>Δημοσίευση ή μετάδοση περιεχομένου που δεν είναι πρέπον, είναι παράνομο, υβριστικό,
                  βλαβερό, απειλητικό, προσβλητικό, συκοφαντικό, δυσφημιστικό, χυδαίο, άσεμνο, πορνογραφικό, βλάσφημο,
                  και με οιονδήποτε τρόπο αντίθετο στα χρηστά ήθη, αποτελεί παραβίαση του απορρήτου κάποιου άλλου, είναι
                  εμπιστευτικό, δείχνει εμπάθεια, δύναται να προκαλέσει ενόχληση, αναστάτωση ή περιττό άγχος σε τρίτους,
                  εκφράζει φυλετικές, θρησκευτικές, εθνικές και άλλες διακρίσεις, δύναται να προκαλέσει βλάβες σε
                  ανήλικους με οποιονδήποτε τρόπο, παραβιάζει πνευματικά δικαιώματα ή άλλα ιδιοκτησιακά δικαιώματα
                  τρίτων, χρησιμοποιείται για συλλογή ή αποθήκευση προσωπικών δεδομένων άλλων, προωθεί αλκοόλ, φάρμακα,
                  τσιγάρα, τυχερά παιχνίδια και στοιχήματα, όπλα, εκρηκτικά, προωθεί πυραμιδικά σχήματα, παράνομες
                  δραστηριότητες ή παραβιάζει με οποιοδήποτε τρόπο το νόμο ή τους Όρους Χρήσης, είναι διαφημιστικό,
                  συνιστά ή ενθαρρύνει συμπεριφορά που θα θεωρούνταν αξιόποινη πράξη, που θα επέσυρε αστική ευθύνη ή θα
                  καταστρατηγούσε το δίκαιο ή θα παραβίαζε τα δικαιώματα οποιουδήποτε τρίτου σε οποιαδήποτε χώρα στον
                  κόσμο, περιέχει ιούς λογισμικού ή οποιουσδήποτε άλλους κώδικες, αρχεία ή προγράμματα, που έχουν
                  σχεδιαστεί με σκοπό τη διακοπή, την πρόκληση βλάβης, την παρεμπόδιση της λειτουργίας ή την καταστροφή
                  οποιουδήποτε λογισμικού, εξοπλισμού ή υλικού υπολογιστών, ή θεωρηθεί ακατάλληλο κατά την απόλυτη κρίση
                  της Εταιρείας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.12 </b>Οποιασδήποτε μορφής πειρατείας Λογισμικού, hacking ή/και υποκλοπής δεδομένων
                  (συμπεριλαμβανομένων και των προσωπικών δεδομένων) και πληροφοριών.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.13 </b>Η εισαγωγή στην Εφαρμογή, η αποστολή και διαβίβαση πληροφοριών ή προγραμμάτων – Λογισμικών
                  (software) τα οποία ενδέχεται να περιέχουν ιούς ή άλλα επιζήμια χαρακτηριστικά τα οποία μπορούν είτε
                  να προκαλέσουν δυσλειτουργία της Εφαρμογής, ή να προκαλέσουν υπέρογκη κίνηση δεδομένων (traffic) στην
                  Εφαρμογή με αποτέλεσμα να δυσχεραίνουν τη χρήση αυτής από τους άλλους Χρήστες.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.14 </b>Η καθ’ οιονδήποτε τρόπο χρήση της Εφαρμογής, η οποία ανεξαρτήτως σκοπιμότητας, (δόλου ή
                  αμέλειας) θα μπορούσε να προκαλέσει δυσλειτουργία της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.15</b> Η παραχώρηση άδεια χρήσης, μεταπώληση, εκμίσθωση, leasing, εκχώρηση, υπεργολαβία και η
                  καθ΄ οιονδήποτε τρόπο διάθεση της<span className="Apple-converted-space">&nbsp; </span>Εφαρμογής σε
                  τρίτους για οιανδήποτε χρήση και σκοπό, πλην, των ρητά επιτρεπόμενων χρήσεων αυτής δυνάμει της
                  παρούσας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.16 </b>Η αποσυμπίληση (decompilation), αντιγραφή, παραποίηση, διανομή, παρουσίαση, του Λογισμικού
                  που περιλαμβάνεται στην<span className="Apple-converted-space">&nbsp; </span>Εφαρμογή ή μέρος αυτού
                  ή/και η καθ οιονδήποτε τρόπο επεξεργασία του πηγαίου του κώδικα.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.17 </b>Η σύνδεση της Εφαρμογής με οποιοδήποτε προϊόν/ υπηρεσία των Χρηστών, χωρίς τη ρητή και
                  έγγραφη συγκατάθεση της Εταιρείας.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.18 </b>Οποιαδήποτε ενέργεια δύναται να επηρεάσει τη δυνατότητα άλλων Χρηστών να κάνουν χρήση της
                  Εφαρμογής, ή να καταστρατηγήσει τα δικαιώματα των άλλων Χρηστών, όπως ενδεικτικά δικαιώματα
                  πνευματικής τους ιδιοκτησίας, προσωπικά δεδομένα κ.α.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.19 </b>Η χρήση μηχανισμών, λογισμικού ή άλλων ενεργειών που παρεμποδίζουν την κανονική λειτουργία
                  της Εφαρμογής.
                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>2.20 </b>Οποιαδήποτε αντιγραφή, αναλογική/ψηφιακή εγγραφή και μηχανική αναπαραγωγή, διανομή,
                  μεταφορά, downloading, μεταποίηση, μεταπώληση, δημιουργία παράγωγης εργασίας της Εφαρμογής και των
                  συστατικών της που περιγράφονται στο παρόν.{" "}
                                                </span>
                                            </p>
                                            <p className="p6">
                                                <span className="s1">
                                                    <b>3. </b>Σε περίπτωση που τα ανωτέρω πρόσωπα έχουν αμφιβολίες κατά πόσο κάποια ενέργεια ή παράλειψή
                  τους συνιστά παραβίαση της παρούσας Πολιτικής Αποδεκτών Χρήσεων ή/και σε περίπτωση που επιθυμεί να
                  αναφέρει στην Εταιρεία, οποιαδήποτε παραβίαση της παρούσας Πολιτικής έχει πέσει στην αντίληψη του,
                  μπορεί να επικοινωνήσει με την Εταιρεία.
                </span>
                                            </p>
                        </StyledCard>
                    </DesktopRightContainer>
                </DesktopFlexContainer>
            </StyledContent>
        </StyledPage>
    )
};

export default withTranslation()(withRouter(TermsConditionsGreek));

import { IonSlide, IonSlides } from "@ionic/react";
import React, { Fragment } from "react";
import styled from "styled-components";
import { Text1, Text3 } from "../../../components/styled/Typography";
import { FlexContainer } from "../../../components/styled/Wrappers";
import theme from "../../../theme";
import { trackEvent } from "../../../tracking";
import { onExternalLinkClick } from "../../../components/common/ExternalLink/ExternalLink";

const slideOpts = {
    slidesPerView: 1,
    coverflowEffect: {
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
    },
    on: {
        beforeInit() {
            const swiper = this;

            swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
            swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

            swiper.params.watchSlidesProgress = true;
            swiper.originalParams.watchSlidesProgress = true;
        },
        setTranslate() {
            const swiper = this;
            // const { $wrapperEl } = swiper;


            // Set correct perspective for IE10
            if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
                // const ws = $wrapperEl[0].style;
            }
        },
        setTransition(duration) {
            const swiper = this;
            swiper.slides
                .transition(duration)
                .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
                .transition(duration);
        }
    }
}

const Sride = ({ sRide: { id, photos, title, price, passengersForPrice, duration, numberOfStops, tourLink, currency } }) => {

    return (
        <a onClick={(e) => {
            trackEvent("traveler app", "s-ride", `click ${title}`);
            onExternalLinkClick(e, tourLink);
          }}
          href={tourLink} target="_blank" rel="noopener noreferrer">
            <CustomSlides pager={true} options={slideOpts} >
                {photos.map((image, index) => {
                    const { large } = image;
                    return (
                        <IonSlide key={`${id}-${index}`}>
                            <RidePhoto image={large} />
                        </IonSlide>
                    )
                })}
            </CustomSlides>
            <Text1 weight="500" margin="10px auto">{title}</Text1>
            {price !== null &&
                <Fragment>
                    <FlexContainer justify="space-between" align="center">
                        <Text1>{currency.symbol}{`${price ? price : 0}`}</Text1>
                        <DescriptionText className="stops">{duration}</DescriptionText>
                    </FlexContainer>
                    <FlexContainer justify="space-between" align="center">
                        <DescriptionText className="persons-number">{passengersForPrice}</DescriptionText>
                        <DescriptionText className="stops">{`${numberOfStops}`} stops</DescriptionText>
                    </FlexContainer>
                </Fragment>
            }
        </a>
    )
}

const CustomSlides = styled(IonSlides)`
    --bullet-background: ${theme.colors.greys.grey6};
    --bullet-background-active: ${theme.colors.base.white75};
    --bullet-width: 5px;
`;

const DescriptionText = styled(Text3)`
    color: ${theme.colors.greys.grey5};
    font-weight: 700;
`;

const RidePhoto = styled.div`
    border-radius: 4px;
    background-image: url(${props => props.image});
    width: 100%;
    max-width: 244px;
    height: 142px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${theme.colors.greys.grey15};

    @media (max-width: ${theme.breakpoints[0]}px){
        max-width: 100%;
        height: 184px;
    }
`;

export default Sride;

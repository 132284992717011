import React from "react";

const Sad = ({ fill }) => (
  <svg width="112px" height="112px" viewBox="0 0 112 112" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Account Deleted</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="05.14.41.D.Account_and_data.Account_deleted_dialog" transform="translate(-544.000000, -248.000000)">
        <g id="Main_card" transform="translate(300.000000, 184.000000)">
          <g id="Sad.Icon" transform="translate(244.000000, 64.000000)">
            <g>
              <circle id="Oval" fill={fill} cx="56" cy="56" r="56" />
              <circle id="Oval-2" fill="#FFFFFF" cx="41.5" cy="48.5" r="4.5" />
              <circle id="Oval-2" fill="#FFFFFF" cx="70.5" cy="48.5" r="4.5" />
              <path d="M34.4902054,83.2013014 C33.7609454,83.5943299 32.8511509,83.3217603 32.4581224,82.5925003 C32.065094,81.8632403 32.3376635,80.9534458 33.0669235,80.5604173 C40.7007726,76.4462196 48.3652793,74.3808594 56.0444336,74.3808594 C63.7235879,74.3808594 71.3880946,76.4462196 79.0219437,80.5604173 C79.7512037,80.9534458 80.0237732,81.8632403 79.6307448,82.5925003 C79.2377163,83.3217603 78.3279218,83.5943299 77.5986618,83.2013014 C70.3885981,79.3154992 63.209192,77.3808594 56.0444336,77.3808594 C48.8796751,77.3808594 41.7002691,79.3154992 34.4902054,83.2013014 Z" id="Path-2" fill="#FFFFFF" fillRule="nonzero" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Sad;

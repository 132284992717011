import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

export async function set(key: (string), value: any): Promise<any> {
  // TODO: return without await
  try {
    await Storage.set({
      key: key,
      value: JSON.stringify(value)
    });
  } catch (e) {
    return Promise.resolve(null);
  }
}

export async function get(key: string): Promise<any> {
  let item = null;
  try{
    item = await Storage.get({ key: key });
  } catch (e) {
    return Promise.resolve(null);
  }

  if(item && typeof(item.value) === "string") {
    let parsed = null;
    try {
      parsed = JSON.parse(item.value);
    } catch (e) {
      parsed = null;
    }
    return Promise.resolve(parsed);
  }
  return Promise.resolve(null);
}

export async function remove(key: string): Promise<void> {
  await Storage.remove({
    key: key
  });
}

import styled from "styled-components";
import theme from "../../theme";

const Heading2 = styled.h2`
    color: ${props => props.color || theme.colors.base.graphite};
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 12px;
    text-align: ${props => props.align || "initial"};

    @media (max-height: 442px) and (max-width: ${theme.breakpoints[0]}px){ 
        display:inline-block;
        margin-bottom:10px;
        margin-left:10px;
    }
`;

const Paragraph = styled.p`
    color: ${props => props.color || theme.colors.greys.grey5};
    font-size: ${props => props.size || "14px"};
    line-height: 21px;
    font-weight: ${props => props.weight || "500"};
    max-width: ${props => props.maxWidth || "240px"};
    text-align:${props => props.align || "center"};
    margin: ${props => props.margin || "0"};

    @media (max-height: 442px) and (max-width: ${theme.breakpoints[0]}px){ 
        display:none;
    }
`;

const Heading3 = styled.h3`
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`;

const Heading4 = styled.h4`
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`;

const Heading5 = styled.h5`
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
  `;

const Text2 = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`;

const Text1 = styled.p`
  font-size: 16px;
  font-weight: ${props => props.weight || "700"};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.color || theme.colors.base.graphite};
  margin: ${props => props.margin || "initial"}
`;

const Text3 = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
`;

const TextInfo = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  color: ${theme.colors.greys.grey5};
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  margin-top: 8px;
  color: ${theme.colors.accent.red};
`;

export {
    Heading2,
    Heading3,
    Heading5,
    Paragraph,
    Text1,
    Text2,
    Text3,
    Heading4,
    TextInfo,
    ErrorMessage
}
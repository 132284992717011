import theme from "../../../../theme";

const MapTemplatePickup = [
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
        {
            "saturation": 36
        },
        {
            "color": "#333333"
        },
        {
            "lightness": 40
        }
    ]
  },
  {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#969da6"
          }
      ]
  },
  {
      "featureType": "administrative.country",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.locality",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "administrative.neighborhood",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "weight": "1.00"
          },
          {
              "hue": "#0000ff"
          },
          {
              "visibility": "on"
          },
          {
              "saturation": "-97"
          },
          {
              "lightness": "0"
          },
          {
              "gamma": "0.51"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "weight": "1.00"
          },
          {
              "lightness": "-3"
          },
          {
              "saturation": "-18"
          },
          {
              "hue": "#0000ff"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "gamma": "0.70"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "weight": "1.00"
          },
          {
              "lightness": "30"
          },
          {
              "saturation": "3"
          },
          {
              "hue": "#0000ff"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "weight": "0.50"
          },
          {
              "gamma": "3.20"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "hue": "#0000ff"
          },
          {
              "gamma": "1.55"
          },
          {
              "saturation": "-65"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "featureType": "poi.business",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "saturation": "-60"
          },
          {
              "hue": "#0000ff"
          },
          {
              "gamma": "1.55"
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "simplified"
          },
          {
              "hue": "#00ff23"
          },
          {
              "saturation": "33"
          },
          {
              "gamma": "0.20"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "weight": "1.00"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 18
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f2f2f2"
          },
          {
              "lightness": 19
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#bfd2e4"
          },
          {
              "lightness": 17
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  }
]

const MapTemplateDropoff = [
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [{
        "saturation": 36
      },
      {
        "color": "#333333"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [{
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.icon",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [{
        "color": "#fefefe"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text",
    "stylers": [{
      "color": "#969da6"
    }]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "administrative.province",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "simplified"
    }]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [{
        "weight": "1.00"
      },
      {
        "hue": "#0000ff"
      },
      {
        "visibility": "on"
      },
      {
        "saturation": "-97"
      },
      {
        "lightness": "0"
      },
      {
        "gamma": "0.51"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [{
        "visibility": "on"
      },
      {
        "weight": "1.00"
      },
      {
        "lightness": "-3"
      },
      {
        "saturation": "-18"
      },
      {
        "hue": "#0000ff"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.stroke",
    "stylers": [{
        "visibility": "on"
      },
      {
        "gamma": "0.70"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.fill",
    "stylers": [{
        "visibility": "on"
      },
      {
        "weight": "1.00"
      },
      {
        "lightness": "30"
      },
      {
        "saturation": "3"
      },
      {
        "hue": "#0000ff"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers": [{
        "weight": "0.50"
      },
      {
        "gamma": "3.20"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{
        "color": "#f5f5f5"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [{
        "visibility": "simplified"
      },
      {
        "hue": "#00ff23"
      },
      {
        "saturation": "33"
      },
      {
        "gamma": "0.20"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [{
        "color": "#ffffff"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [{
        "weight": "1.00"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [{
        "color": "#ffffff"
      },
      {
        "lightness": 18
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [{
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [{
        "color": "#f2f2f2"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{
        "color": "#bfd2e4"
      },
      {
        "lightness": 17
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }
]

  const rendererOptions = () => ({
    suppressMarkers: true,
    markerOptions: {
      visible: false
    },
    polylineOptions: {
      strokeColor: theme.colors.cta.green,
      strokeWeight: 3,
      fillOpacity: 1,
      scale: 1,
      icons: [
        {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillOpacity: 1,
            scale: 1,
          },
          offset: "0",
          repeat: "15px",
        },
      ],
    },
  });

  const getTemplate = (transferType) => {
    if(transferType === "airport_pickup"){
      return MapTemplatePickup;
    }
    else if(transferType === "airport_dropoff") {
      return MapTemplateDropoff;
    }
    else {
      return MapTemplatePickup;
    }
  }
  
  const mapOptions = (lat, lng, transferType ) => ({
    center: { lat: Number(lat), lng: Number(lng) },
    zoom: 18,
    styles: getTemplate(transferType),
    disableDefaultUI: true,
  });
  
  export { rendererOptions, mapOptions };
  
import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { IonModal } from "@ionic/react";
import { Heading4 } from "../../../components/styled/Typography";
import { ButtonDiscard, ButtonTabber } from "../../../components/styled/Buttons";
import { FlexContainer } from "../../../components/styled/Wrappers";
import { withTranslation } from "react-i18next";

const LogoutModal = ({ isOpen, onClose, logout, t }) => (
    <LogoutModalContainer onClose={onClose} isOpen={isOpen} ionBackButton={onClose}>
        <div style={{ padding: "0 30px" }}>
            <LogoutHeader>{t('sign_in._are_you_sure_logout')}</LogoutHeader>
            <FlexContainer align="center" justify="space-between">
                <CancelButton onClick={onClose}>{t('data.cancel')}</CancelButton>
                <LogoutButton onClick={logout}>{t('profile.logout')}</LogoutButton>
            </FlexContainer>
        </div>
    </LogoutModalContainer>
)

const LogoutHeader = styled(Heading4)`
    text-align:center;
    margin: 30px 0;
`;

const LogoutModalContainer = styled(IonModal)`
    --height: 180px;
    --width: 350px;
    --border-radius: 4px;
`;

const CancelButton = styled(ButtonTabber)`
    border: 1px solid rgba(45,59,78,0.15);
    border-radius: 4px;

    @media (max-width: ${theme.breakpoints[0]}px) {
        display: block;
        margin: 8px 0;
    }
`;

const LogoutButton = styled(ButtonDiscard)`
    height: 38px;

    @media (max-width: ${theme.breakpoints[0]}px) {
        display: block;
        margin: 8px 0;
        margin-left: auto;
    }
`;

export default withTranslation()(LogoutModal);

import TravelerApi from "../API";
import stores from '../stores';
import { isPlatform } from '@ionic/react';
import history from "../components/common/history";
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;
const { PushNotifications } = Plugins;
const { travelerAppStore, messagesStore } = stores;

class PushNotificationsService {
	register = () => {
		// Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        // console.log("PushNotifications: Request granted");
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token) => {
        console.log('Push registration success, token: ' + token.value);
        // console.log(`PushNotifications: Registered with token: ${token.value}`);
        // saves firebase token to store
        // calls API to register device
        this.onRegisterSuccess(token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error) => {
      // console.log('Error on registration: ', JSON.stringify(error));
      // TODO: log error on rollbar;
    });

    // Method called when receiving a notification when app is opened
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push received: ', JSON.stringify(notification));
        // console.log(`PushNotifications: Received notification: ${JSON.stringify(notification)}`);
        const openUrl = notification?.data?.open_url;
        if(openUrl) {
          // console.log(`PushNotifications: Received notification direct to: ${openUrl}`);
          const internalUrl = openUrl.split('traveler_app').pop();
          // TODO: Display toast with title and message same as Notification's and on clcik redirect internally
          // {"open_url": "dsadasd", title":"Driver assigned","body":"Driver Haris Simintzis assigned for your transfer in Athens. Open to learn more"}}
          history.push(internalUrl, { fetch: true });
        }
      }
    );
    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (pushNotificationActionPerformed) => {
        console.log('Push action performed: ', JSON.stringify(pushNotificationActionPerformed));
        // console.log(`PushNotifications: Notification action performed: ${JSON.stringify(pushNotificationActionPerformed)}`);
        const openUrl = pushNotificationActionPerformed.notification?.data?.open_url;
        if(openUrl) {
          // console.log(`PushNotifications: Received notification direct to: ${openUrl}`);
          const internalUrl = openUrl.split('traveler_app').pop();
          history.push(internalUrl, { fetch: true });
        }
      },
    );
	};

	onRegisterSuccess = async (firebaseToken) => {
		// TODO: return if same token is already saved in store
		//  TODO: save in store only after registering device in API
		travelerAppStore.firebaseToken.set(firebaseToken);
    messagesStore.registerDevice(firebaseToken);

		if(!travelerAppStore.isAuthorised.get()) { return true; }

		let uid = null;
		let device_type = null;

		const deviceInfo = await Device.getInfo();
		uid = deviceInfo.uuid;

    if (isPlatform('ios')) {
      device_type = 'ios';
    } else if (isPlatform('android')) {
      device_type = 'android';
    }

    console.log("PushNotificationsService: Will register device:", uid, firebaseToken, device_type);
		return TravelerApi.registerDevice(uid, firebaseToken, device_type)
			.then((data) => {
				// on success
				// save traveler info to storage
				// save traveler info to store
        console.log("Registered device to BE:", uid, firebaseToken, device_type);
				return true;
			})
			.catch((err) => {
			  // TODO: log on rollbar
			  // Msg: Error: No type registered for device
			  console.error(err);
			  return false;
			});
	};

	unregister = async () => {
		PushNotifications.removeAllListeners();
		travelerAppStore.firebaseToken.set("");

    let uid = null;
    let device_type = null;

    const deviceInfo = await Device.getInfo();
    uid = deviceInfo.uuid;

    if (isPlatform('ios')) {
      device_type = 'ios';
    } else if (isPlatform('android')) {
      device_type = 'android';
    }
    console.log("PushNotificationsService: Will unregister device:", uid, null, device_type);
    return TravelerApi.registerDevice(uid, null, device_type)
      .then((data) => {
        // on success
        // save traveler info to storage
        // save traveler info to store
        console.log("UnRegistered device from BE:", uid, null, device_type);
        return true;
      })
      .catch((err) => {
        // TODO: log on rollbar
        // Msg: Error: No type registered for device
        console.error(err);
        return false;
      });
	};
}
export default new PushNotificationsService();
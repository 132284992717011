import { get, set, remove } from "./storage.ts";
import queryString from 'query-string';
import TravelerApi from "../API";
import stores from '../stores';
import { getCookie } from "../components/common/helpers";
import PushNotificationsService from './pushNotifications.js';
import TrackTravelerAppEventsService from './trackTravelerAppEvents.js';
import { isMobileAppPlatform } from "../components/common/helpers";
import { isPlatform } from '@ionic/react';
import braze from "./braze";

const isMobileApp = isMobileAppPlatform();

const { travelerAppStore, brandedSettingsStore, messagesStore } = stores;

class AuthenticationService {
	authenticateTraveler = async () => {
		// read token from param > storage > cookies
		// get token from param
    const values = queryString.parse(window.location.search);
    let { travelers_access_token } = values;

    // get token from storage
    const travelerInfo = await get('travelerInfo');
		const travelers_access_token_from_storage = travelerInfo?.accessToken;
    
    if(!travelers_access_token) {
    	travelers_access_token = travelers_access_token_from_storage;
		}

		// Ignore cookies
    // get token from cookies if not mobile
    if(!travelers_access_token) {
      if(isPlatform('desktop') || isPlatform('mobileweb')){
        // read cokies
        travelers_access_token = getCookie('travelers_access_token')
      }
    }

    // if token exists already in storage authenticate from storage and refresh traveler info asynchronously
    if (travelers_access_token) {
			// refresh Token synchronously
			return this.loginWithToken(travelers_access_token);
    }
    else {
      return Promise.resolve(false);
    }
	}

	authenticateWithToken = async (token) => {
		if (travelerAppStore.isAuthorised.get() && travelerAppStore.travelerInfo.accessToken === token) {
		  return Promise.resolve(true);
		}
		else {
    	// login to API
    	return this.loginWithToken(token);
    }
	}

	loginWithToken = async (token) => {
		// call api to to login traveler and get his info
		return TravelerApi.loginWithToken(token)
			.then(async (travelerInfo) => {
				// on success
				// save traveler info to storage
				// save traveler info to store
				if (travelerAppStore.isAuthorised.get() && travelerAppStore.travelerInfo.accessToken !== token){
					await this.onLogoutSuccess();
				}
				await this.onLoginSuccess(travelerInfo);
				// console.log("After login success");
				return true;
      })
      .catch((err) => {
      	// Remove token from storage if token is not valid anymore
      	this.onLoginFailure();
        console.error(err);
        return false;
      });
	};

	onLoginSuccess = (travelerInfo) => {
		this.saveTravelerInfoToStorage(travelerInfo);
		this.saveTravelerInfoToStore(travelerInfo);
		if(isMobileApp) {
			PushNotificationsService.register();
		}
		if(travelerInfo.chatUser) {
			messagesStore.connect(travelerInfo.chatUser);
		}
		TrackTravelerAppEventsService.trackLoginEvent();
		braze.changeUser(travelerInfo.id);
	}

	onLoginFailure = (travelerInfo) => {
		this.removeTravelerInfoFromStorage(travelerInfo);
		this.removeTravelerInfoFromStore(travelerInfo);
		messagesStore.disconnect(travelerAppStore.firebaseToken.get());
		if(isMobileApp) {
			PushNotificationsService.unregister();
		}
	}

	onLogoutSuccess = async () => {
		messagesStore.disconnect(travelerAppStore.firebaseToken.get());
		if(isMobileApp) {
			await PushNotificationsService.unregister();
		}
		this.removeTravelerInfoFromStorage();
		this.removeTravelerInfoFromStore();
		return brandedSettingsStore.fetchBrandedSettings();
	}

	saveTravelerInfoToStorage = async (travelerInfo) => {
		const saved = await set('travelerInfo', travelerInfo);
		return saved;
	}

	saveTravelerInfoToStore = (travelerInfo) => {
		travelerAppStore.setTravelerInfo(travelerInfo);
	}

	getTravelerInfoFromStorage = async () => {
		return await get('travelerInfo');
	}

	removeTravelerInfoFromStorage = async () => {
		await remove('travelerInfo');
	}

	removeTravelerInfoFromStore = () => {
		return travelerAppStore.resetTravelerInfo();
	}

	getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : false;
  }
}
export default new AuthenticationService();
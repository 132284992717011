import React, {Component, Fragment} from "react";
import styled from "styled-components";
import {IonToast, IonModal} from "@ionic/react";
import {Text2, Heading2} from "../../../components/styled/Typography";
import {FlexContainer} from "../../../components/styled/Wrappers";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import InvalidTravelerDataBlock from "../../../components/common/InvalidTravelerDataBlock";
import Success from "../../../components/svgs/Success";
import {ButtonSecondary3} from "../../../components/styled/Buttons";
import {withTranslation} from "react-i18next";

const AlertContainer = styled("div")`
  position: relative;
  z-index: 9;
  flex: 0 0 100%;
  max-width: 600px;
  display: block;
  margin-top: ${(props) => (props.alertsAreShownIn === "Trip" ? "32px" : "0")};
  padding: ${(props) =>
    props.alertsAreShownIn === "Trip" || props.alertsAreShownIn === "Profile"
      ? "0px 15px 0 15px"
      : "0px"};
`;

const StyledModal = styled(IonModal)`
  --width: 460px;
  --height: 420px;
  --border-radius: 4px;
  @media (max-width: 767px) {
    --height: 100vh;
  }
`;
const ConfirmButton = styled(ButtonSecondary3)`
  background: none;
  margin-top: 24px;
  min-width: 200px;
`;
const ModalContainer = styled(FlexContainer)`
  flex: 0 0 100%;
  max-width: 400px;
  display: flex;
  padding: 0 15px;
  margin: auto;
  text-align: center;
  flex-flow: column wrap;
`;
const SuccessIconWraper = styled.div`
  margin: 0 auto;
  width: 112px;
  height: 112px;
  min-width: 20px;
  min-height: 20px;
  margin-bottom: 20px;
`;

class AutoCheckErrors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
      toastMessage: "",
    };
  }
  render() {
    const {
      t,
      autocheckErrorList,
      removeError,
      alertsAreShownIn,
      transferId,
      editUrl,
      errors,
      isDetailsPage,
    } = this.props;
    const { showToast, toastMessage } = this.state;

    return (
      <Fragment>
        {autocheckErrorList &&
        autocheckErrorList.length === 0 &&
        alertsAreShownIn !== "Trip" &&
        alertsAreShownIn !== "Profile" &&
        showToast ? (
          <StyledModal isOpen={showToast}>
            <ModalContainer align="center" justify="center">
              <SuccessIconWraper>
                <Success />
              </SuccessIconWraper>
              <Heading2>{t("personal_info.changes_saved")}</Heading2>
              <Text2 weight="500">{t("alert.error_resolved_desc")}</Text2>
              <ConfirmButton
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({showToast: false});
                }}
              >
                <span>{t("alert.ok")}</span>
              </ConfirmButton>
            </ModalContainer>
          </StyledModal>
        ) : (
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => {
              this.setState({showToast: false});
            }}
            message={toastMessage}
            duration={2000}
            cssClass="styled-toast-notify"
          />
        )}

        { !isDetailsPage && autocheckErrorList && autocheckErrorList.length > 0 && errors && (
          <AlertContainer alertsAreShownIn={alertsAreShownIn}>
            <InvalidTravelerDataBlock
              alertsAreShownIn={alertsAreShownIn}
              removeError={removeError}
              transferId={transferId}
              editUrl={editUrl}
              autocheckFailAlerts={autocheckErrorList}
              classNameForWrapper="error-for-transfer"
              errors={errors}
              isDetailsPage={isDetailsPage}
              showToast={({toastMessage}) => {
                //hide any open toasts
                this.setState({showToast: false});
                //open the new one
                this.setState({showToast: true, toastMessage: toastMessage});
              }}
            />
          </AlertContainer>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(
  withRouter(inject("travelerAppStore")(observer(AutoCheckErrors)))
);

import React, { memo } from "react";
import styled from "styled-components";
import { ButtonBase } from "../../../../components/styled/Buttons";
import { StyledCard } from "../../../../components/styled/IonElements";
import { Heading3, Heading5 } from "../../../../components/styled/Typography";
import theme from "../../../../theme";
import { trackEvent } from "../../../../tracking";
import { externalLinkRedirection } from "../../helpers";
import PopularSlides from "./PopularSlides";
import { withTranslation } from "react-i18next";
import { onExternalLinkClick } from "../../../../components/common/ExternalLink/ExternalLink";

const PopularDestinationsButton = ({ redirectTo, t }) => (
  <TransferButtonBase kind="secondary" onClick={redirectTo}>
    {t('popular_destinations.book_different')}
  </TransferButtonBase>
);

const PopularDestinations = ({ destinationReturn,
  link,
  popularDestinationsReturn,
  popularDestinationsOutsideCity,
  transfers,
  city,
  t }) => {

  return (
    <StyledCard id={!destinationReturn ? "PopularDestinations" : ""} margin="20px">
      <PopularDestinationsHeader>{destinationReturn ? t('my_trip.book_a_return_transfer') : t('popular_destinations.another_ride')}</PopularDestinationsHeader>
      <Content>{destinationReturn ? `${"from"} ${transfers[0].toTitle || transfers[0].fromTitle}` : t('popular_destinations.inside_outside',{city_name: city}) }</Content>
      <ImageSlider>
        <PopularSlides
          popularDestinationsReturn={popularDestinationsReturn}
          popularDestinationsOutsideCity={popularDestinationsOutsideCity}
          destinationReturn={destinationReturn} />
      </ImageSlider>
      <ButtonWrapper >
        <PopularDestinationsButton t={t} redirectTo={ async (e) => {
          trackEvent("traveler app", "trip", destinationReturn ? "popular destinations outside city" : "popular destinations return");
          const isWebLink = await onExternalLinkClick(e, link);
          console.log("IsWeb", isWebLink);
          if(isWebLink) {
            externalLinkRedirection(link);
          }
        }} />
      </ButtonWrapper>
    </StyledCard >
  )
}

const ImageSlider = styled.div`
`;

const ButtonWrapper = styled.div`
      display: flex;
      justify-content: center;
      margin-top:24px;
`;

const Content = styled(Heading5)`
    color: ${theme.colors.greys.grey5};
    font-size: 14px;
    line-height: 21px;
    margin: 4px 0 24px 0;
    font-weight: 500;
`;

const PopularDestinationsHeader = styled(Heading3)`
    color: ${theme.colors.base.graphite};
    font-size: 20px;
    line-height: 26px;
    margin: 0;
`;

const TransferButtonBase = styled(ButtonBase)`
    height: 48px;
    border-radius: 4px;
    background-color: ${theme.colors.base.graphite};
    color: ${theme.colors.base.white};
    width: 100%;
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
    max-width:260px;
    margin-right:40px;
    min-height:48px;
    outline: none;
    white-space: normal !important;
    height: auto;
    word-break:break-word;

    :hover {
      background-color: #425269;
    }
  
  :active {
    outline: none;
    background-color: #1f2836;
    }
  
  :focus{
    background-color: ${theme.colors.base.graphite};
    }

    @media (max-width: ${theme.breakpoints[0]}px){
      margin-right:20px;
      max-width:calc(50% - 10px);
    }

  `;

// const StyledContainer = styled.div`
//     border-radius: 4px;
//     width: 100%;
//     background-color: ${theme.colors.base.white};
//     box-shadow: 0 2px 4px 0 ${theme.colors.greys.grey06};
//     margin-top: 8px;
//     position: relative;
//     z-index: 9;
//     padding: 32px 0px 40px 40px;
    
//   @media (max-width: ${theme.breakpoints[0]}px){
//     border-radius: 12px;
//     padding: 32px 0px 40px 20px;
//     }
//   `;

export default memo(withTranslation()(PopularDestinations));

import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { IonCardContent, IonItemDivider } from "@ionic/react";
import { ButtonBase } from "../../../components/styled/Buttons";
import { StyledItem } from "../../../components/styled/IonElements";
import theme from "../../../theme";
import { Heading3, Paragraph, Text1 } from "../../../components/styled/Typography";
import GreenArrow from "../GreenArrow";
import { withTranslation } from "react-i18next";
import { useStores } from "../../../stores/useStores";
import { observer } from "mobx-react";

const SignInButton = styled(StyledItem)`
    button {
        min-width: 93px !important;
        width: 100px;
    }
`;

// const { signIn: { signIn }, profile: { legalInformation, signInToSeeYourInformation }, myTrip: { help, profile } } = GonProvider.i18nTranslations.travelerApp;

const GuestUser = ({ location, history, t, isSide }) => {
    const { brandedSettingsStore: { getCoBrandedHelpCenterUrl, brandedSettings: { coBrandingThemeProperties: { mainColor } } } } = useStores();

    return (
        <IonCardContent style={{ padding: "0" }}>
            <StyledAccount>
                <Heading3>{t('my_trip.profile')}</Heading3>
                <Paragraph align="left" style={{ marginBottom: "20px" }}>{t('profile.sign_in_to_see_your_information')}</Paragraph>
                <SignInButton routerLink="/login" lines="none" padding="0">
                    <ButtonBase bgcolor={mainColor}>
                        <span>{t('sign_in.sign_in')}</span>
                    </ButtonBase>
                </SignInButton>
                <IonItemDivider mode="md" style={{ padding: "0 20px" }} />
            </StyledAccount>

            <CustomItem lines="none" color={mainColor} showBorder detail="false" isSide={isSide} padding="0" margin="0" href={getCoBrandedHelpCenterUrl} target="_blank" rel="noopener noreferrer">
                <Text1 weight="500">{t('my_trip.help')}</Text1>
                <GreenArrow />
            </CustomItem>

            <CustomItem color={mainColor} isSide={isSide} lines="none" padding="0" margin="0" onClick={() => history.push("/profile/terms_and_conditions")} isCurrent={location.pathname === "/profile/terms_and_conditions"}>
                <Text1 weight="500">{t('legal.terms')}</Text1>
                <GreenArrow />
            </CustomItem>
        </IonCardContent>
    );
}
const CustomItem = styled(StyledItem)`
	border-left: ${props => (props.isSide && props.isCurrent) && `3px solid ${props => props.color}`};
    padding: 0 20px;
	cursor: pointer;

    p {
        font-size: 16px;
    }

    ion-icon {
        display: none;
    }
`;

const StyledAccount = styled.div`
	padding: 30px 20px;

	h3 {
		font-size: 20px;
		font-weight: 700;
		margin: 0 0 10px;
		color: ${theme.colors.base.graphite};
    }

    p {
        font-size: 15px;
    }
`;


export default withTranslation()(withRouter(observer(GuestUser)));

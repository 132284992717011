import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import GoToLayout from "../../GoToLayout";
import styled from "styled-components";
import { IonText } from "@ionic/react";
import theme from "../../../../theme";
import SocialButtons from "./SocialButtons";
import { Heading4, Paragraph } from "../../../../components/styled/Typography";
import { trackEvent } from "../../../../tracking";
import { FlexContainer } from "../../../../components/styled/Wrappers";
import { withTranslation } from "react-i18next";
import { isDesktop } from '../../../../components/common/helpers';
import CheckMark from "../../../../components/svgs/CheckMark";
const fromDesktop = isDesktop();

const Driver = ({ t, onChangeModalVisibility, travelerAppStore: { chosenTransfer } }) => {
	const driverData = !chosenTransfer.operatorConfirmedDriver ? chosenTransfer.confirmedDriver : chosenTransfer.operatorConfirmedDriver;
	return (
		<div style={{ marginBottom: "10px", padding: "20px", width: "100%" }}>
			<IonText><Heading4>{driverData ? t('driver_dialog.my_driver') : t('trip_details.driver_not_assigned')}</Heading4></IonText><br />
			{driverData ? (
				<Fragment>
					<DriverInformation justify="space-between">
						<GoToLayout arrowVisible onClickFunc={() => {
							trackEvent("traveler app", "driver modal", "open");
							onChangeModalVisibility("driverInfoModalVisibility");
						}}>
							<DriverInfoWrapper className="driver-info-wrapper" driverAvatar={driverData.professionalPhotoUrl} justify="flex-start">
								<div className="driver-info-avatar" />
								<div className="driver-info">
									<IonText>
										<p className="driver-info-name">{driverData.fullName}</p>
										<p className="driver-info-additional">
											<span className="vehicle-color">{driverData.vehilcleColor || ""}</span>
											{` ${driverData.vehicleBrand || ""} ${driverData.vehicleModel || ""}`}
										</p>
										<p className="driver-info-additional">{driverData.vehiclePlateNumber ? `${t('driver_dialog.plate')} ${driverData.vehiclePlateNumber}` : ""}</p>
										<p className="driver-info-additional">{driverData.phvLicenseNumber ? `${t('driver_dialog.phv_licence')} ${driverData.phvLicenseNumber}` : ""}</p>
										<p className="driver-info-additional">{driverData.phoneNumber ? `${driverData.phoneNumber}` : ""}</p>
									</IonText>
								</div>


							</DriverInfoWrapper>
						</GoToLayout>
					</DriverInformation>{chosenTransfer.isHubPickup && <DriverWaitingNotice>
            <CheckMark/>
                  <p>
                  {t("driver_dialog.monitor_details")}
                  </p>
                </DriverWaitingNotice> }
					<SocialButtons fromDesktop={fromDesktop} isGuestDriver={ driverData.status === 'guest_driver' } driverPhoneNumber={driverData.phoneNumber} transfer={chosenTransfer} />
				</Fragment>
			)
				: <Paragraph maxWidth="100%" align="left" color={theme.colors.base.graphite} size="16px">{t('trip_details.driver_will_be_assigned')}</Paragraph>
			}
		</div>
	)
};

const DriverWaitingNotice = styled(FlexContainer)`
width: 100%;
margin:16px 0;
padding:18px 24px;
display:flex;
background: rgba(45, 59, 78, 0.05);
border-radius: 4px;
p{text-align:left;
  font-size: 12px;
  line-height: 18px;
  color: #2D3B4E;margin-left:24px;}
svg{flex:0 0 24px;max-width:24px;position:relative;top:4px;}
`;
const DriverInformation = styled(FlexContainer)`
	width: 100%;
`;

const DriverInfoWrapper = styled(FlexContainer)`
	width: 100%;

	p {
		margin: 0;
	}

	.driver-info-avatar {
		border-radius: 100%;
		width: 64px;
		height: 64px;
		margin-right: 20px;
		background-repeat: no-repeat;
		background-image: ${props => props.driverAvatar ? `url(${props.driverAvatar})` : "unset"};
		background-size: 64px 64px;
		background-color: ${props => props.driverAvatar ? "unset" : theme.colors.greys.grey05};

    background-size: cover;
		}

		.driver-info-additional {
			color: ${theme.colors.greys.grey5};
			font-size: 12px;
			line-height: 18px;
			.vehicle-color {
			text-transform:capitalize;
		}
}
`;

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(Driver))));

import React from "react";
import { IonSelect, IonSelectOption, isPlatform } from '@ionic/react';
import { withRouter } from "react-router-dom";
import { useStores } from "../../../stores/useStores";
import { observer } from "mobx-react";
import { availableLanguages } from "../../../locales/languages";
import styled from "styled-components";
import "./styles.scss";
import { withTranslation } from "react-i18next";
import theme from "../../../theme";

const fromIphone = isPlatform('iphone');

const LanguageSelector = ({ i18n }) => {
    const { travelerAppStore: { setLocale } } = useStores();
    const bigLangWidth = window.innerWidth < 768 ? "70px" : "100px";
    let smallLangWidth = null;
    if(fromIphone) {
      smallLangWidth = window.innerWidth < 768 ? "50px" : "55px";
    }
    else {
      smallLangWidth = window.innerWidth < 768 ? "30px" : "35px";
    }

    const changeLanguage = (lang) => {
        setLocale(lang);
    }
    
    return (
        <StyledSelect width={(i18n.language !== "zh-hans" && i18n.language !== "pt-pt") ? smallLangWidth : bigLangWidth} className="language-select" mode="ios" interface={window.innerWidth > 768 ? "alert" : "action-sheet"} text={{textWeight: "700"}} value={i18n.language} selectedText={(i18n.language).toUpperCase()} okText="Okay" cancelText="Dismiss" onIonChange={e => changeLanguage(e.detail.value)} >
            {availableLanguages.map(({ name, code }) =>
                <IonSelectOption key={code} value={code}>{name}</IonSelectOption>
            )}
        </StyledSelect>
    );
}

export default withTranslation()(withRouter(observer(LanguageSelector)));

const StyledSelect = styled(IonSelect)`
    --padding-start: 0;
    --padding-end: 0;
    width: ${props => props.width};
    z-index: 100;
    color: ${theme.colors.base.white};
    
    --text {
        font-weight: 700;
    }

    
`;
import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { StyledContent, StyledPage } from "../../../../components/styled/IonElements";
import TransfersList from "../../List/TransfersList";
import { DesktopFlexContainer, DesktopList } from "../../../../components/styled/Wrappers";
import StickyHeader from "../../../../components/common/StickyHeader";
import { withTranslation } from "react-i18next";
import { onExternalLinkClick } from "../../../../components/common/ExternalLink/ExternalLink";
import { externalLinkRedirection } from "../../helpers";
import TrackTravelerAppEventsService from '../../../../services/trackTravelerAppEvents.js';
import CommonDetails from "../CommonDetails";
import debounce from '../../../../helpers/debounce';

const isSightSeeingRide = ({ transferType }) => typeof transferType === "undefined";
class StaticDetails extends PureComponent {
  constructor(){
    super();

    this.trackTransfePageVisitedEventDebounced = debounce(
      TrackTravelerAppEventsService.trackTransfePageVisitedEvent,
      3000
    );
  }

  transferDetailsContainer = React.createRef();

  state = {
    driverInfoModalVisibility: false,
    meetingPointModalVisibility: false,
    showHeader: false
  }

  onTransferAction = async (makeRedirection, link, event) => {
    event.preventDefault();
    if (makeRedirection) {
      // trackEvent("traveler app", "grey footer link", link);
      const isWebLink = await onExternalLinkClick(event, link);
      if(isWebLink) {
        externalLinkRedirection(link);
      }
    }
  }

  onChangeModalVisibility = (modalStateName) => {
    this.setState(state => {
      return {
        [modalStateName]: !state[modalStateName],
      }
    });
  }

  componentWillUnmount() {
    this.trackTransfePageVisitedEventDebounced.stop();
  }

  componentDidMount = () => {
    const { travelerAppStore: { currentTrip } } = this.props;
    if(currentTrip?.id) {
      this.trackTransfePageVisitedEventDebounced.init(currentTrip);
    }
  }

  render() {
    const { travelerAppStore: { chosenTransfer, currentTrip: { id: tripId } }, history, t} = this.props;
    const isSRide = isSightSeeingRide(chosenTransfer);
    const stickyHeaderTitle = isSRide ? t('tour_details.sightseeing_ride') : t('trip_details.split_transfer_one');

    return (
        <StyledPage>
          <StyledContent >
            <DesktopFlexContainer align="flex-start">
              <DesktopList padding="0">
                <TransfersList errors isTransferDetailsPage />
              </DesktopList>
              {window.innerWidth < 768 && <StickyHeader goBack={() => history.push(`/trip/${tripId}`)} title={stickyHeaderTitle} subtitle={chosenTransfer.title || `${t('my_trip.from')} ${chosenTransfer.fromTitle}`} />}
              <CommonDetails />
            </DesktopFlexContainer>
          </StyledContent>
        </StyledPage>
    )
  }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(StaticDetails))));

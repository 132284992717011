import { observable } from "mobx";
// import TravelerApi from "../API";
import { StreamChat } from 'stream-chat';
import { Capacitor } from '@capacitor/core';

import travelerAppStore from "./travelerAppStore";
import { Plugins } from '@capacitor/core';
const { App } = Plugins;

const fromMobileApp = Capacitor.isNative;
// See https://mobx.js.org/refguide/action.html --> Michel Weststrate comment about enforcing actions
// configure({ enforceActions: "observed" });
/* eslint-disable max-statements */

/*
ChatUser:
  chat_member_id: "traveler_87038"
  member_type: "traveler"
  token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoidHJhdmVsZXJfODcwMzgifQ.lpX1Lh98T5i2S9Q7hn2izBvshdXFJbrtmPXn3nhA4OM"
*/

class MessagesStore {
  constructor() {
    this.appStateChangeListenner = null;
  }

  /* OBSERVABLE PROPERTIES */

  chatClient = observable.box(null);

  chatUser = observable.object({});

  showNewMessagesBadge = observable.box(false);

  channels = observable.array([]);

  reconnecting = observable.box(false);

  // activeChannel = observable({});
  transfersPerChannelToken = observable.object({});

  /* SETTERS */
  setshowNewMessagesBadge = (state) => {
    this.showNewMessagesBadge.set(state);
  }

  /* METHODS */
  connect = async (chatUser) => {
    // If we do not have a connection or if we have with a different chat user
    // connect to stream
    if(this.chatClient.get() === null){
      const chatClient = new StreamChat(process.env.REACT_APP_STREAM_API_KEY, { timeout: 15000 });
      //Set the client server location to new edge API infrastructure
      chatClient.setBaseURL('https://chat.stream-io-api.com');
      this.chatClient.set(chatClient);
    }
    // set chat user
    if(!this.chatUser.chatMemberId || (this.chatUser.chatMemberId && this.chatUser.chatMemberId !== chatUser.chatMemberId)) {
      // Add listener to reconnect/disconnect user when App's state changes
      if(fromMobileApp) {
        this.appStateChangeListenner = App.addListener('appStateChange', async (state) => {
          // state.isActive contains the active state
          console.log('App state changed. Is active?', state.isActive);
          if(state.isActive){
            if(this.chatClient.get()?.wsConnection && !this.chatClient.get()?.wsConnection?.isConnecting) {
              console.log("Getstream user will connect");
              await this.chatClient.get().wsConnection.connect();
              await this.chatClient.get().recoverState();
              console.log("Getstream user connected");
            }
          }
          else {
            // App goes to background
            if(this.chatClient.get()?.wsConnection && this.chatClient.get().user !== undefined) {
              console.log("Getstream user will disconnect");
              await this.chatClient.get().wsConnection.disconnect();
              console.log("Getstream user disconnected");
            }
          }
        });
      }

      this.chatUser = chatUser;
      const connected = await this.chatClient.get().connectUser({
        id: chatUser.chatMemberId,
      },
      chatUser.token);

      // Register device for push notifications
      if(fromMobileApp && travelerAppStore.firebaseToken.get()) {
        await this.registerDevice(travelerAppStore.firebaseToken.get());
      }

      if(connected && connected.me.total_unread_count > 0) {
        this.setshowNewMessagesBadge(true);
      }

      // Listen for client events
      this.clientEventsHandler = this.chatClient.get().on(event => {
        if ( (event.total_unread_count !== undefined && event.total_unread_count > 0) ||
          (event.unread_channels !== undefined && event.unread_channels > 0)
          ) {
          // console.log(`unread messages count is now: ${event.total_unread_count}`);
          this.setshowNewMessagesBadge(true);
        }
      });
    }
    return Promise.resolve(true);
  };

  disconnect = async (firebaseToken) => {
    // if we have a connection disconnect and reset info
    if(this.chatClient.get()) {
      // Stop listening for client events
      this.chatClient.get().off(this.clientEventsHandler);
      // Unregister device
      if(fromMobileApp) {
        await this.unregisterDevice(firebaseToken);
      }
      // Disconnect from getstream
      await this.chatClient.get().disconnect();
    }
    this.chatUser = {};

    if (this.appStateChangeListenner) {
      this.appStateChangeListenner.remove();
    }
  };

  fetchChannels = async () => {
    const filters = { type: 'messaging', members: { $in: [this.chatUser.chatMemberId] } };
    const sort = { last_message_at: -1 };
    const channels = await this.chatClient.get().queryChannels(filters, sort, { watch: true, state: true });
    this.channels.replace(channels)
    return channels;
  }

  reconnectUser = () => {
    if(this.chatClient.get() && this.chatUser.chatMemberId) {
      console.log("Getstream user connecting");
      const connected = this.chatClient.get().connectUser({
          id: this.chatUser.chatMemberId,
        },
        this.chatUser.token);
      return connected;
      /*return this.chatClient.get().partialUpdateUser({
        id: this.chatUser.chatMemberId,
        set: { invisible: false }
      });*/
    }
    else {
      return Promise.resolve(true);
    }
  }

  disconnectUser = async () => {
    if(this.chatClient.get() && this.chatUser.chatMemberId) {
      console.log("Getstream user disconnecting");
      return this.chatClient.get().disconnect();
      /*return this.chatClient.get().partialUpdateUser({
        id: this.chatUser.chatMemberId,
        set: { invisible: true }
      });*/
    }
    else {
      return Promise.resolve(true);
    }
  }

  registerDevice = async(firebaseToken) => {
    if(this.chatClient.get() && this.chatUser.chatMemberId) {
      return await this.chatClient.get().addDevice(firebaseToken, 'firebase', this.chatUser.chatMemberId);
    }
    return Promise.resolve(false);
  }

  unregisterDevice = async(firebaseToken) => {
    if(this.chatClient.get() && this.chatUser.chatMemberId) {
      return await this.chatClient.get().removeDevice(firebaseToken, this.chatUser.chatMemberId);
    }
    return Promise.resolve(false);
  }

  getTransferForChannelToken = (channelToken) => {
    // return transfer from stores or fetch it
    return this.transfersPerChannelToken[channelToken];
  }

  saveTransferWithChannelToken = (transfer, channelToken) => {
    this.transfersPerChannelToken[channelToken] = transfer;
  }
}
const messagesStore = new MessagesStore();

export default messagesStore;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import ContinueButton from "./ContinueButton";
import { trackEvent } from "../../tracking";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import theme from "../../theme";
import { withTranslation } from "react-i18next";

const buttonStyle = {
    backgroundColor: "transparent",
    border: "none",
    padding: "2px 9px"
}

class PhoneInputContainer extends Component {

    state = {
        phone: ""
    }

    onPhoneInputChange = (phone) => {
        const { travelerAppStore: { setCodeRequestError } } = this.props;

        this.setState({
            phone,
        })
        setCodeRequestError({});
    }


    onContinuePress = () => {
        const formData = new FormData();
        const { travelerAppStore: { codeRequest }, history: { push } } = this.props;
        const { phone } = this.state;

        formData.append("phone", `+${phone}`);
        formData.append("message_method", "sms");
        formData.append("new_login", true);
        trackEvent("traveler app", "login", "continue");
        codeRequest(formData).then(() => {
          push({
            pathname: "/login/verification_code_phone_only",
            state: {
                phone: `+${phone}`,
                hasValidPhone: true,
                phoneNumber: `+${phone}`
            }
          });
        }).catch(() => { });
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.onContinuePress();
        }
    }

    render() {
        const { phone } = this.state;
        const { travelerAppStore: { codeRequestError }, t } = this.props;
        let error = codeRequestError.get().error ? codeRequestError.get().error : null;
        error = error === "No bookings found with this email" ? null : error;
        const errorMessage = error === "No bookings found with this phone number" ? t('sign_in.no_bookings_phone') : error;

        return (
            <div style={{ position: "relative", width: "100%" }}>
                <PhoneInput country="gr"
                    placeholder="phone"
                    onChange={this.onPhoneInputChange}
                    value={phone}
                    onKeyDown={this.onKeyDown}
                      error={error}
                    autoFormat={false}
                    type="phone"
                    buttonStyle={buttonStyle}
                    inputStyle={{
                        height: "50px",
                        border: error ? "1px solid rgba(242,116,112,1)" : `1px solid ${theme.colors.greys.grey15}`,
                        color: "#2D3B4E",
                        width: "100%"
                    }}
                    pattern="\d*"
                />
                { error && <span className="error">{errorMessage}</span> }
                <ContinueButton onContinuePress={this.onContinuePress} error={false} disabled={phone.length === 0} />
            </div>
        )
    }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(PhoneInputContainer))));

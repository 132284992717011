import { IonBackButton } from "@ionic/react";
import { observer } from "mobx-react";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useStores } from "../../../stores/useStores";

const SightSeeingImage = ({history}) => {
  const { travelerAppStore: {currentTrip: { id } } } = useStores();
  return (
    <Fragment>
      <div className="header-gradient" />
      <div className="image-top">
        <IonBackButton
          onClick={() => history.push(`/trip/${id}`)}
        />
      </div>
    </Fragment>
  );
}

  export default withRouter(observer(SightSeeingImage));
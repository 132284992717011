import React from "react";
import helpIcon from "../../../assets/icons/circle-help-white.svg";
import styled from 'styled-components';
import { IonImg } from "@ionic/react";
import LanguageSelector from "../LanguageSelector";
import { FlexContainer } from "../../styled/Wrappers";
import { useStores } from "../../../stores/useStores";
import { observer } from "mobx-react";

const HeaderRightContainer = () => {
    const { brandedSettingsStore: { getCoBrandedHelpCenterUrl } } = useStores();

    return (
        <RightContainer className={window.innerWidth < 768 && "mobile_lang_picker"}>
            {window.innerWidth < 768 && <LanguageSelector />}
            <HelpButton target="_blank" href={getCoBrandedHelpCenterUrl}><IonImg src={helpIcon} /></HelpButton>
        </RightContainer>
    )
};

export default observer(HeaderRightContainer);

const HelpButton = styled.a`
`;

const RightContainer = styled(FlexContainer)`
    position: absolute;
    right: 20px;
    top: 20px;
`;

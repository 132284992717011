import {
    cs, da, de, el, es, fi, fr, he, hr,
    hu, id, it, ja, ko, lt, lv, nl, pl,
    pt, ro, ru, sv, tr, uk, vi, zhCN
  } from "date-fns/locale"
  
  const dateLocales = {
    cs,
    da,
    de,
    el,
    es,
    fi,
    fr,
    he,
    hr,
    hu,
    id,
    it,
    ja,
    ko,
    lt,
    lv,
    nl,
    pl,
    pt,
    ro,
    ru,
    sv,
    tr,
    uk,
    vi,
    zhCN
  }
  
  export default dateLocales;
  
import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { CustomInput } from "../../components/styled/Inputs";
import ContinueButton from "./ContinueButton";
import { trackEvent } from "../../tracking";
import { withTranslation } from "react-i18next";

class EmailInput extends Component {

  state = {
    email: ""
  }

  componentDidMount() {
    if (window.innerWidth > 769) {
      this.emailInput.focus();
    }
  }

  onEmailInputChange = (e) => {
    const { value } = e.target;
    const { travelerAppStore: { setCodeRequestError } } = this.props;
    this.setState({
      email: value,
    })
    setCodeRequestError({});
  }

  onContinuePress = () => {
    const formData = new FormData();
    const { travelerAppStore: { codeRequest }, history: { push } } = this.props;
    
    const { email } = this.state;
    formData.append("email", email);
    formData.append("message_method", "email");
    formData.append("new_login", true);
    trackEvent("traveler app", "login", "continue");
    codeRequest(formData).then(({ hasValidPhone, phoneNumber }) => {
      push("/login/verification_code", {
        email,
        hasValidPhone,
        phoneNumber
      });
    }).catch(() => { });
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onContinuePress();
    }
  }

  render() {
    const { travelerAppStore: { codeRequestError }, t } = this.props;
    const { email } = this.state;
    let error = codeRequestError.get().error ? codeRequestError.get().error : null;
    error = error === "No bookings found with this phone number" ? null : error;
    const errorMessage = error === "No bookings found with this email" ? t('sign_in.no_bookings') : error;

    return (
      <div style={{ position: "relative", width: "100%" }}>
          <CustomInput
            ref={(ref) => this.emailInput = ref}
            placeholder="@"
            onChange={this.onEmailInputChange}
            value={email}
            onKeyDown={this.onKeyDown}
            error={error}
            type="email"
          />
          {error && <span className="error">{errorMessage}</span>}
          <ContinueButton onContinuePress={this.onContinuePress} disabled={email.length === 0}/>
      </div>
    )
  }
}

export default withTranslation()(withRouter(inject("travelerAppStore")(observer(EmailInput))));

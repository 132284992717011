
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import arrow from "../../assets/icons/arrowDownWhite.svg";
import { IonItem, IonRefresher, IonRefresherContent, IonSelect, IonSelectOption, IonSkeletonText, IonText, IonThumbnail, IonToolbar, isPlatform } from "@ionic/react";
import { HeaderLogo, PoweredByWhiteImg } from "../../components/styled/Images";
import { FullHeightOverlay, HeaderBottomFlexContainer } from "../../components/styled/Wrappers";
import { Heading2 } from "../../components/styled/Typography";
import theme from "../../theme";
import { convertToTimeZone } from "date-fns-timezone";
import { format, parseISO } from "date-fns";
import { StyledCard, StyledContent, StyledPage } from "../../components/styled/IonElements";
import NoUpcomingTrips from "./List/NoUpcomingTrips";
import PromoPopupMyTrip from "./List/PromoPopupMyTrip";
import TransfersList from "./List/TransfersList";
import AppsBlock from "./List/AppsBlock";
import PopularDestinationsBlocks from "./List/PopularDestinationsBlocks";
import { get, set } from "../../services/storage.ts";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import HeaderRightContainer from "../../components/common/HeaderRightContainer";
import poweredByWhite from "../../assets/branding/poweredByLight.svg";

function displayDates(startsOn, timezone, formatStr) {
  if (startsOn) {
    return (format(convertToTimeZone(parseISO(startsOn), { timeZone: timezone || "Europe/Berlin" }), formatStr))
  }
}

// TODO: use a utility function
const fromMobileApp = !isPlatform('mobileweb') && !isPlatform("pwa") && !isPlatform("desktop");

const StyledIonToolbar = styled(IonToolbar)`
  --background: url('${props => props.image}') no-repeat center/cover !important;
  --min-height: 240px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 4px;
  margin-bottom: -20px;
  z-index: 0;

  @media (max-width: 768px) {
    margin-top: 0;
    border-radius: 0;
  }
`;

const MyTripHeader = styled(IonText)`
  width: 160px;
  h2{
    width:100%;
    min-width:300px;
  }
`;

const TripSelection = styled(IonSelect)`
  color: ${theme.colors.base.white} !important;
  padding: 0;
  font-weight: 800;
`;

const customActionSheetOptions = (t) => {
  return {
    header: t('my_trip.my_trip')
  }
};

class Trip extends Component {

  constructor(props) {
    super(props);

    this.state = {
      comingTripsDropdownVisible: false,
      bookAnotherTransferDropdownVisible: false,
      bookAnotherTransferDropdownVisiblePopup: false,
      destinationModalVisibility: false,
      floatingButtonVisibility: false,
    }
  }

  //TODO: change
  showedPopup = false;
  showedPopupWebView = false;

  async componentDidMount() {
    const {
      match: {
        params: {
          tripToken
        }
      },
      location,
      travelerAppStore: {
        currentTrip,
        setPromoVisiblePopupMyTrip,
        trips,
        getTrips,
        setShowExploreBadge
      },
      destinationsStore: {
        destinations,
        fetchDestinations
      }
    } = this.props;

    // Add param to enforce fetching of trips/transfer
    const fetch = location?.state?.fetch;

    // Only in first load fetch the trips
    if (trips.length === 0 || (tripToken && (tripToken !== currentTrip.id)) || fetch) {
      if (!destinations.length) {
        fetchDestinations()
      }

      if(this.shouldFetchTrips()) {
        getTrips(tripToken).then((transfers) => {
          get("loggedTimes").then(times => {
            set("loggedTimes", JSON.stringify(Number(times) + 1));

            if (!times || times < 3) {
              setShowExploreBadge(true);
              if (!fromMobileApp) {
                setPromoVisiblePopupMyTrip(true);
              }
              return;
            }
            setShowExploreBadge(false);
            setPromoVisiblePopupMyTrip(false);

          });

        }).catch(() => {
          // if (MyTripsView.times < 3) {
          //   MyTripsView.add();
          // }
        });
      }
    }
  }

  componentDidUpdate(prevProps){
    const {
      match: {
        params: {
          tripToken: prevTripToken
        }
      },
    } = prevProps;

    const {
      match: {
        params: {
          tripToken
        }
      },
      travelerAppStore: {
        getTrips
      },
    } = this.props;

    if (prevTripToken !== tripToken && this.shouldFetchTrips()) {
      getTrips(tripToken);
    }
  }

  shouldFetchTrips(){
    const {
      match: {
        params: {
          tripToken
        }
      },
      travelerAppStore: {
        currentTrip,
        trips,
        isAuthorised
      },
    } = this.props;

    return (isAuthorised.get() && (trips.length === 0 || (tripToken !== currentTrip.id) || (fetch) ));
  }

  choseTrip = (value) => {
    const { travelerAppStore: { getTripTransfers, setLoadingState, currentTrip }, history } = this.props;

    if (currentTrip.id !== value) {
      setLoadingState(true);
      getTripTransfers(value);
      history.push(`/trip/${value}`);
    }
  }

  doRefresh = (event) => {
    const { travelerAppStore: { getTrips } } = this.props;

    getTrips();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }

  togglePopups = () => {
    const { travelerAppStore: { setPromoVisiblePopupMyTrip, promoVisiblePopupMyTrip } } = this.props;
    setPromoVisiblePopupMyTrip(!promoVisiblePopupMyTrip.get());
  }

  render() {
    const { travelerAppStore: { cityImage, currentTrip, activeTrips, loading, transfers, promoVisiblePopupMyTrip, isAuthorised }, brandedSettingsStore: { isPartner, brandedSettings: { partnerId, brandLogoForTravelerAppUrl } } } = this.props;
    const noTrips = (activeTrips.length === 0) && !loading.get();
    const { t } = this.props;
    const showPoweredBy = isPartner && window.innerWidth < 768;
    const isPartnerTraveler = partnerId !== "";
    const tripWelcomeMessage = (currentTrip && currentTrip.greeting) || t('my_trip.my_trip');
    const hasPopularDestinationsReturn = currentTrip.popularDestinationsReturn && currentTrip.popularDestinationsReturn.length > 0;

    const showBookTransfer = !hasPopularDestinationsReturn &&
      !currentTrip.hasReturnTransfer &&
      transfers.length > 0;

    const showPopularDestinationsReturn = hasPopularDestinationsReturn &&
      !currentTrip.hasReturnTransfer &&
      transfers.length > 0;
    const showPopularDestinations = currentTrip.popularDestinationsOutsideCity && currentTrip.popularDestinationsOutsideCity.length > 0;

    if (noTrips || !isAuthorised.get()) {
      return (
        <NoUpcomingTrips key={promoVisiblePopupMyTrip.get()}>
          <PromoPopupMyTrip onChangeVisibility={this.togglePopups} open={promoVisiblePopupMyTrip.get()} />
          {!(isPartnerTraveler) && !fromMobileApp && !this.showedPopupWebView && <AppsBlock onClick={this.togglePopups} showText />}
        </NoUpcomingTrips>
      )
    }

    return (
      <StyledPage key={promoVisiblePopupMyTrip.get()} >
        <StyledContent>
          <IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
            <IonRefresherContent pullingIcon="lines"></IonRefresherContent>
          </IonRefresher>

          <StyledCard padding="0" margin="20px">
            {loading.get()
              ? <IonItem lines="none" style={{ width: "100%", maxWidth: "600px", margin: "15px auto" }}>
                <IonThumbnail style={{ width: "100%", height: "200px", margin: 0 }} slot="start">
                  <IonSkeletonText animated />
                </IonThumbnail>
              </IonItem>
              : <StyledIonToolbar image={cityImage}>
                <FullHeightOverlay />
                <HeaderLogo src={brandLogoForTravelerAppUrl} maxWidth={showPoweredBy ? "70px" : "100%"}/>
                <HeaderRightContainer />
                <HeaderBottomFlexContainer align="flex-end" justify={showPoweredBy ? "space-around" : "flex-start"}>
                  <MyTripHeader>
                    <Heading2 color={theme.colors.base.white}>{tripWelcomeMessage}</Heading2>
                    <TripSelection
                      icon={arrow}
                      interfaceOptions={customActionSheetOptions(t)}
                      mode="ios" interface={window.innerWidth > 768 ? "alert" : "action-sheet"} text={{textWeight: "700"}}
                      onIonChange={e => this.choseTrip(e.detail.value)}
                      value={currentTrip.id}
                      placeholder="Select another trip"
                    >
                      {activeTrips && activeTrips.map(({ id, city, startsOn, timeZone }) => (
                        <IonSelectOption key={id} value={id}>{city} {timeZone && displayDates(startsOn, timeZone, 'd MMM yyyy')}</IonSelectOption>
                      ))}
                    </TripSelection>
                  </MyTripHeader>

                  {(showPoweredBy) && <PoweredByWhiteImg src={poweredByWhite} alt="powered by Welcome Pickups" />}
                </HeaderBottomFlexContainer>

              </StyledIonToolbar>}

            <TransfersList errors />
          </StyledCard>

          {!loading.get() && <PopularDestinationsBlocks
            currentTrip={currentTrip}
            transfers={transfers}
            showBookTransfer={showBookTransfer}
            showPopularDestinationsReturn={showPopularDestinationsReturn}
            showPopularDestinations={showPopularDestinations} />}
          <PromoPopupMyTrip onChangeVisibility={this.togglePopups} open={promoVisiblePopupMyTrip.get()} />
          {!isPartnerTraveler && !fromMobileApp && !this.showedPopupWebView && <AppsBlock onClick={this.togglePopups} showText />}
        </StyledContent>
      </StyledPage>
    )
  }
}
export default withTranslation()(withRouter(inject("travelerAppStore", "destinationsStore", "brandedSettingsStore")(observer(Trip))));

import React, { memo, Fragment } from "react";
import BookReturnTransfer from "./BookReturnTransfer";
import PopularDestinations from "./PopularDestinations";

const PopularDestinationsBlocks = ({ transfers,
  currentTrip,
  showBookTransfer,
  showPopularDestinationsReturn,
  showPopularDestinations }) => {

  return <Fragment>
    {showBookTransfer && <BookReturnTransfer />}
    {showPopularDestinationsReturn &&
      <PopularDestinations
        destinationReturn
        transfers={transfers}
        link={currentTrip.popularDestinationsReturnDifferentRideUrl}
        popularDestinationsReturn={currentTrip.popularDestinationsReturn} />}
    {showPopularDestinations &&
      <PopularDestinations
        link={currentTrip.popularDestinationsOutsideCityDifferentRideUrl}
        popularDestinationsOutsideCity={currentTrip.popularDestinationsOutsideCity} city={currentTrip.city} />}
  </Fragment>

}

export default memo(PopularDestinationsBlocks);





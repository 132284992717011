import React from "react";
import styled from "styled-components";
import { Heading4 } from "../../../components/styled/Typography";
import GoToLayout from "../GoToLayout";

const Section = ({ children, title, drilldown, handleClick = () => {} }) => (
  <div style={{padding: "0 20px"}}>
    {drilldown
      ? <GoToLayout arrowVisible onClickFunc={handleClick}>
          <StyledHeading>{title}</StyledHeading>
        </GoToLayout>
      : <StyledHeading>{title}</StyledHeading>
    }
    {children}
  </div>
);

const StyledHeading = styled(Heading4)`
  margin: 0;
`;

export default Section;

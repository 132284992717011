import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { StyledCard, StyledContent, StyledPage } from "../../components/styled/IonElements";
import LoggedInUser from "./LoggedInUser";
import GuestUser from "./GuestUser";
import { useStores } from "../../stores/useStores";

const Profile = () => {
    const { travelerAppStore: { isAuthorised } } = useStores();
    const CustomProfile = (isAuthorised.get() ? LoggedInUser : GuestUser);

    return (
        <StyledPage>
            <StyledContent>
                <StyledCard margin="20px" padding="0" height={window.innerWidth < 768 && "100%"}>
                    <CustomProfile />
                </StyledCard>
            </StyledContent>
        </StyledPage>
    );
}

export default withRouter(observer(Profile));


import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import Comment from "../../../../components/svgs/Comment";
import ChildSeat from "../../../../components/svgs/ChildSeat";
import Luggage from "../../../../components/svgs/Luggage";
import Sedan from "../../../../components/svgs/Sedan";
import Minivan from "../../../../components/svgs/Minivan";
import Minibus from "../../../../components/svgs/Minibus";
import Passenger from "../../../../components/svgs/Passengers";
import { capitalizeFirstLetter, replaceLast } from "../../helpers";
import { Text1 } from "../../../../components/styled/Typography";
import { useStores } from "../../../../stores/useStores";
import { withTranslation } from "react-i18next";

const svgStyles = {
  minWidth: "20px",
  minHeight: "20px",
  marginRight: "20px",
  marginTop: "1px"
}



const InRideInfo = ({ chosenTransfer, isSRide, t }) => {
  const getTextsAndIcons = (itemName, quantity, amenityItems, vehicleType, travelerNote) => {

    switch (itemName) {

      case "Passengers":
        return {
          icon: Passenger,
          text: quantity > 1 ? `${quantity} ${t('trip_details.passengers')}` : `${quantity} ${t('trip_details.passenger')}`
        }
      case "Checked luggage":
        return {
          icon: Luggage,
          text: quantity > 1 ? `${quantity} ${t('trip_details.luggage_pieces')}` : `${quantity} ${t('trip_details.luggage_pieces').substring(0, t('trip_details.luggage_pieces').length - 1)}`
        }
      case "Traveler note":
        return {
          icon: Comment,
          text: travelerNote
        }
      case "Driver":

        if (vehicleType === "Coupe" || vehicleType === "Sedan") {
          return {
            icon: Sedan,
            text: "sedan"
          }
        }
        if (vehicleType === "Minivan") {
          return {
            icon: Minivan,
            text: "minivan"
          }
        }

        return {
          icon: Minibus,
          text: "MiniBus"
        }

      case "Amenities":
        const amenityText = amenityItems.reduce((aggr, { itemName, quantity }) => {

          let translatedText = itemName;

          if (itemName === "Infant carrier") { translatedText = "infantCarrier"; }

          if (itemName === "Child seat") { translatedText = "childSeat"; }

          if (itemName === "Booster") { translatedText = "booster"; }

          return quantity > 0 ? aggr += `${aggr === "" ? capitalizeFirstLetter(translatedText) : translatedText} x  ${quantity} , ` : aggr
        }, "");

        return {
          icon: ChildSeat,
          text: replaceLast(", ", "", amenityText)
        }
      default:
    }
  }


  const RideItem = ({ driver, itemName, quantity, amenityItems, vehicleType, travelerNote, color }) => {

    const details = getTextsAndIcons(itemName, quantity, amenityItems, vehicleType, travelerNote);
    const SvgIcon = details.icon;

    return <li>
      <IconWrapper driver={driver}>
        <SvgIcon styles={svgStyles} fill={color} />
      </IconWrapper>
      <DetailText driver={driver}> {`${details.text}`}</DetailText>
    </li >
  }

  const { numberOfPassengers, checkedLuggageQuantity, travelerNote, amenityItems, operatorConfirmedDriver, confirmedDriver } = chosenTransfer;
  const { brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: { mainColor } } } } = useStores();

  const driver = isSRide ? confirmedDriver : operatorConfirmedDriver;

  return (
    <StyledList>
      {
        [{
          itemName: "Driver",
          vehicleType: driver ? driver.vehicleType : null,
          quantity: driver ? 1 : 0
        },
        {
          itemName: "Passengers",
          quantity: numberOfPassengers
        }, {
          itemName: "Amenities",
          amenityItems,
          quantity: amenityItems ? amenityItems.reduce((aggr, { quantity }) => aggr += quantity, 0) : 0
        }, {
          itemName: "Checked luggage",
          quantity: checkedLuggageQuantity
        }, {
          itemName: "Traveler note",
          travelerNote,
          quantity: !travelerNote ? 0 : 1
        }
        ]
          .filter((amenity) => (Number(amenity.quantity)))
          .map((amenity) => <RideItem color={mainColor} driver={amenity.itemName === "Driver"} key={amenity.itemName} {...amenity} />)
      }
    </StyledList>
  )
}


const IconWrapper = styled.div`
  margin-left: ${(props) => props.driver ? "2px" : "6px"};
`;

const DetailText = styled(Text1)`
  font-weight:normal;
  margin: 0;
`;

const StyledList = styled.ul`

  margin: 24px 0;
  list-style: none;
  padding: 0;

  li {
    display: flex;
    margin-bottom: 20px;

    align-items: flex-start;
    :last-child {
      margin-bottom: 0;
    }

    :first-of-type .driver{
      margin-left: 2px;
    }

    :first-of-type .driver-bus{
      margin-left: -1px;
    }
  }

li {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;

  : last-child {
    margin-bottom: 0;
  }
}

`;

export default withTranslation()(observer(InRideInfo));

import React, { Component } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import styled from "styled-components";
import { Text2, TextInfo } from "../../../../components/styled/Typography";
import Offline from "../../../../assets/icons/offline.svg";
import { FlexContainer } from "../../../../components/styled/Wrappers";
import { onExternalLinkClick } from "../../../../components/common/ExternalLink/ExternalLink";

const slideOpts = {
  slidesPerView: window.innerWidth> 768 ? 3 : 2,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  on: {
    beforeInit() {
      const swiper = this;

      swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
      swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

      swiper.params.watchSlidesProgress = true;
      swiper.originalParams.watchSlidesProgress = true;
    },
    setTransition(duration) {
      const swiper = this;
      swiper.slides
        .transition(duration)
        .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
        .transition(duration);
    }
  }
}

export default class CarouselComponent extends Component {

  render() {
    const { destinationReturn, popularDestinationsReturn, popularDestinationsOutsideCity } = this.props;
    const destinationsStype = destinationReturn ? popularDestinationsReturn : popularDestinationsOutsideCity;

    return (
      <IonSlides pager={false} options={slideOpts}>
        {destinationsStype.map(({ popularDestinationImage, bookingUrl, estimatedPrice, popularDestinationName,currency }, index) => (
          <IonSlide key={index}>
            <Anchor href={bookingUrl} target="_blank" rel="noopener noreferrer" onClick={(e) => { onExternalLinkClick(e,bookingUrl) }}>
              <CarouselImg className="rounded-image"
                onError={(ev) => { ev.target.src = Offline }}
                alt="" src={popularDestinationImage} />
              <Description align="start">
                <ContentText as="span">{popularDestinationName}</ContentText>
                <ContentText as="span"><Text as="span">{"from".toUpperCase()}</Text><Price>{currency.symbol}{`${estimatedPrice}`}</Price></ContentText>
              </Description>
            </Anchor>
          </IonSlide>
        ))}
      </IonSlides>
    );
  }
}

const CarouselImg = styled.img`
  height: 95px;
  display: block;
  object-fit: cover;
  object-position: inherit;
  width: 157px !important;
  height: 98px !important;
  box-sizing: inherit;
  border-radius: 4px !important;
`;

const Anchor = styled.a`
    :visited,:focus,:active,:hover{
        outline:0 none !important;
    }
`;

const Description = styled(FlexContainer)`
      margin-top:10px;
      flex-direction:column;
`;

const ContentText = styled(Text2)`
    outline: none;
    white-space: normal !important;
    height: auto;
    word-break:break-word;
`;

const Text = styled(TextInfo)`
      letter-spacing: 0.8px;
`;

const Price = styled.span`
      font-weight: 700;
      margin-left: 8px;
`;

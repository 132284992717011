import { observable, set } from "mobx";
import TravelerApi from "../API";
import { isWebPlatform, fromCoBrandedDomain } from '../components/common/helpers';
import welcomeLogo from "../assets/branding/welcome_graphite.svg";
import whiteWelcomeLogo from "../assets/branding/welcome_light.svg";
import welcomeFavicon from "../assets/branding/welcome-favicon.png";
import theme from "../theme";
import  travelerAppStore  from "./travelerAppStore";

// See https://mobx.js.org/refguide/action.html --> Michel Weststrate comment about enforcing actions
// configure({ enforceActions: "observed" });
/* eslint-disable max-statements */

const welcomeThemeSettings = {
  partnerId: "",
  partnerName: "Welcome Pickups",
  quoteUrl: "",
  coBrandedTermsUrl: "/terms_and_conditions",
  brandLogoUrl: welcomeLogo,
  brandLogoForTravelerAppUrl: whiteWelcomeLogo,
  helpCenterUrl: process.env.REACT_APP_HELP_CENTER_URL,
  brandFaviconUrl: welcomeFavicon,
  coBrandingThemeProperties: {
    themeName: "Welcome",
    mainColor: theme.colors.cta.green,
    mainMediumColor: theme.colors.cta.greenMedium,
    widgetButtonBackgroundColor: theme.colors.cta.green,
    widgetButtonFontColor: theme.colors.base.white
  }
}

function changeFavicon(src) {
  var favicon = document.getElementById("favicon");
  favicon.href = src;
}

class BrandedSettingsStore {
  brandedSettings = observable(welcomeThemeSettings);

  get isPartner() {
    return this.brandedSettings.partnerId !== "";
  }

  get getCoBrandedHelpCenterUrl(){
    return travelerAppStore.isAuthorised.get() && travelerAppStore.travelerInfo.accessToken ?
      `${ this.brandedSettings.helpCenterUrl}?travelers_access_token=${travelerAppStore.travelerInfo.accessToken}`
      :
      this.brandedSettings.helpCenterUrl;
  }

  setBrandedSettings = (brandedSettings) => {
    if (brandedSettings.partnerId !== this.brandedSettings.partnerId) {
      document.title = `${brandedSettings.partnerName} Transfer App`;
      changeFavicon(brandedSettings.brandFaviconUrl);
      set(this.brandedSettings, brandedSettings);
    }
  };

  resetBrandedSettings = () => {
    if (this.isPartner) {
      document.title = `Welcome Pickups Traveler App`;
      changeFavicon(welcomeThemeSettings.brandFaviconUrl);
      set(this.brandedSettings, welcomeThemeSettings);
    }
  };

  fetchBrandedSettings = () => {
    if (isWebPlatform() && fromCoBrandedDomain()) {
      console.log("Web branded case");
      return TravelerApi.getBrandedSettings()
        .then((brandedSettings) => {
          if(brandedSettings?.id) {
            this.setBrandedSettings(brandedSettings);
            console.log("Branded property", brandedSettings.id);
            return true;
          }
          else {
            return false;
          }
        })
        .catch((err) => {
          // console.log(err);
          return false;
        })
    }
    else {
      return Promise.resolve(false);
    }
  }
}
const brandedSettingsStore = new BrandedSettingsStore();

export default brandedSettingsStore;

import React from "react"
import ArrowTriangle from "../../../components/svgs/ArrowTriangle";
import HubPickupInfo from "../../../components/common/HubPickupInfo";

import styled from "styled-components";
import theme from "../../../theme";
import { observer } from "mobx-react";
import { useStores } from "../../../stores/useStores";

const svgStyles = {
  position: "absolute",
  bottom: "-10px",
  left: "-4px"
}

const TransferPoint = ({ title, subtitle, date, isSideMenu, transfer = null }) => {
  const { brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: { mainColor }}} } = useStores();
  const showHubPickupInfo = !isSideMenu && transfer && !transfer.isFinished;

  return (
  <StyledTransferPoint color={mainColor}>
    <StyledTransferPointContentWrapper>
      <p className="place-title">{title}</p>
      <p className="place-address">{subtitle}</p>
    { showHubPickupInfo &&
      <HubPickupInfo
        pickupWaitingTimeMinutes={transfer.pickupWaitingTimeMinutes}
        hubPickupArrivalStatus={transfer.hubPickupArrivalStatus}
        hubPickupItirenaryInfo={transfer.hubPickupItirenaryInfo}
        hubPickupKind={transfer.hubPickupKind}
        isHubPickup={transfer.isHubPickup}
      />
    }
    </StyledTransferPointContentWrapper>
    <p className="transferTime">{date}</p>
    <i className="trip-arrow" > <i><ArrowTriangle styles={svgStyles} fill={mainColor} /></i></i>

  </StyledTransferPoint>
);
  }


const StyledTransferPointContentWrapper = styled.div`
  white-space: pre-wrap;
`;


const StyledTransferPoint = styled.div`
  margin-left: 5px;
  width: calc(100% - 5px);
  position: relative;
  padding: 0 0px 12px 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .place-title {
    color: ${theme.colors.base.graphite};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .place-address {
    color: ${theme.colors.greys.grey5};
    font-size: 12px;
    line-height: 18px;
  }

  .transferTime {
    right:0px;
    color: ${theme.colors.greys.grey5};
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    white-space: nowrap;
    margin-top:2px;
    margin-left: 20px;
  }

  :last-child {
    padding-bottom: 0px;

    .trip-arrow {
      border-left: 2px solid transparent;
      border-image-source: unset;

      i{
        display: none;
      }
    }
    margin-bottom:16px
  }

  .trip-arrow {
    display: block;
    border-style: dotted;
    border-color: ${props => props.color};
    border-width: 0 0 0 2px;
    border-image-repeat: repeat;
    position: absolute;
    top: 20px;
    bottom: 9px;
    left: 0;

    ::before {
      position: absolute;
      content: '';
      border: 2px solid ${props => props.color};
      border-radius: 100%;
      top: -14px;
      left: -5px;
      width: 4px;
      height: 4px;
    }

  }

  @media (max-width: ${theme.breakpoints[0]}px){
      padding: 0 0 12px 29px;
      margin-left: 9px;
      width: calc(100% - 9px);
    }
`;

export default observer(TransferPoint);

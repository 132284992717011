// TODO: Split helpers
import React from "react";
import styled from "styled-components";
import { format, parseISO, compareAsc, differenceInSeconds } from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import { trackEvent } from "../../tracking";
import dateLocales from "./dateLocales";
import { NavLink } from "react-router-dom";
import { isPlatform } from '@ionic/react';

/**
 * @param rawText
 * @param words
 */
function replaceTranslatedProperties(rawText, ...words) {

  const specialCharactersRegEx = /%{[_a-z0-9]*}/;

  return rawText ? words.reduce((text, word) => {
    return text.replace(specialCharactersRegEx, word);
  }, rawText) : "";
}

const noOp = () => { };

const addScript = (scriptSrc, callback = noOp, id = "", async = true, onload = noOp) => {
  const script = document.createElement("script");
  script.onload = onload;
  script.src = scriptSrc;
  script.async = async;
  script.id = id;
  document.body.appendChild(script);
  callback();
};

const removeScript = (id) => {
  const scriptList = document.querySelectorAll("script");
  const convertedNodeList = Array.from(scriptList);
  const script = convertedNodeList.find(script => script.id === id);
  script.parentNode.removeChild(script)
}

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * @param timeZone
 * @param date
 * @param formatStr
 */
function dateLocale(date, timeZone, formatStr = "PP", selectedLocale) {
  let locale;

  switch (selectedLocale) {
    case "zh-hans":
      locale = "zhCN";
      break;
    case "pt-pt":
      locale = "pt";
      break;
    default:
      locale = selectedLocale;
  }

  return toTitleCase(
    format(convertToTimeZone(parseISO(date), { timeZone: timeZone || "Europe/Berlin" }), formatStr, {
      locale: dateLocales[locale],
    })
  );
}


const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : false;
};

const getNavigationComponent = ({ children, callback, link, styles = null, target, as = null, ...props }) => {

  const pattern = /^https?:\/\//i;
  const isExternal = pattern.test(link);

  const NavigationComponent = styled.a`${styles}`;

  return isExternal ?
    <NavigationComponent as={as || "a"} target={target} rel="noopener noreferrer" href={link} onClick={(e) => {
      trackEvent("traveler app", "external link", link);
      if (callback) { callback(e); }
    }} {...props} >

      {children}
    </NavigationComponent> :
    <NavigationComponent as={as || NavLink} to={link} onClick={callback} {...props} >
      {children}
    </NavigationComponent>;
}

const isDesktop = () => {
  return isPlatform('desktop');
}

const isWebPlatform = () => {
  return (isPlatform('desktop') || isPlatform('pwa') || isPlatform('mobileweb'));
}

const isMobilePlatform = () => {
  return (isPlatform('mobile') || isPlatform('mobileWeb'));
}

const isMobileWebPlatform = () => {
  return isPlatform('mobileWeb');
}

const isMobileAppPlatform = () => {
  return (!isWebPlatform() && (isPlatform('ios') || isPlatform('android')))
}

const isIosMobileApp = () => {
  return (!isWebPlatform() && isPlatform('ios'))
}

const isAndroidMobileApp = () => {
  return (!isWebPlatform() && isPlatform('android'))
}

const getPlatformForTravelerAppEvent = () => {
  if (isPlatform('desktop')) {
    return "desktop";
  }
  else if(isPlatform('mobileweb')) {
    return 'mobileweb';
  }
  else if(isPlatform('ios')) {
    return 'ios';
  }
  else if(isPlatform('android')) {
    return 'android';
  }
  else {
    return null;
  }
}

const fromCoBrandedDomain = () => {
  const host = window.location.hostname;
  const traveler_domain = process.env.REACT_APP_TRAVELER_DOMAIN;
  return host !== traveler_domain;
}

/**
 * @description sort array of objects by date
 * @param {array of objects which need to be sort by date} objects
 * @param {the key of the object which contains the date} key
 * @param {boolean flag to transform the date key to date object} transformToDate
 * @returns sorted by date array of objects
 */
const sortObjectsByDate = (objects, key, transformToDate=false) => {
  return objects.sort((objectA, objectB) => {
    const dateA = transformToDate ? parseISO(objectA[key]):objectA[key];
    const dateB = transformToDate ? parseISO(objectB[key]):objectB[key];
    const result = compareAsc(dateA, dateB)
    return result;
  })
}

const getDiffInSecondsFromTimestamps = (firstTimestamp,lastTimestamp) => {
  const lastDate = new Date(lastTimestamp);
  const fistDate = new Date(firstTimestamp);
  const diff = differenceInSeconds(lastDate, fistDate);
  return diff;
}

const stringWithEllipsis = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + String.fromCharCode(8230) : str;
}

export {
  replaceTranslatedProperties,
  addScript,
  removeScript,
  dateLocale,
  getCookie,
  getNavigationComponent,
  isDesktop,
  isWebPlatform,
  isMobilePlatform,
  isMobileWebPlatform,
  isMobileAppPlatform,
  isIosMobileApp,
  isAndroidMobileApp,
  getPlatformForTravelerAppEvent,
  fromCoBrandedDomain,
  sortObjectsByDate,
  getDiffInSecondsFromTimestamps,
  stringWithEllipsis
}
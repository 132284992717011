import { IonProgressBar } from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { StyledCard } from "../../../../components/styled/IonElements";
import { useStores } from "../../../../stores/useStores";
import { Text2 } from "../../../../components/styled/Typography";
import { parseISO, format, addMinutes } from "date-fns";
import { withTranslation } from "react-i18next";

function getDriverWaitingTime(driverLeavesDate, fromDatetime) {
    const expectedMeetingTime= parseISO(fromDatetime);
    // The extra time that the driver needs to wait (in minutes)
    const driverExtraWaiting = (Math.abs(driverLeavesDate.getTime() - expectedMeetingTime.getTime())/1000)/60;

    // The process bar will be shown 2 hours before the expected meeting + the extra time the driver can wait
    const processBarFullRange = 120+driverExtraWaiting;
    const today = new Date();
    // The time remaining for the driver to wait
    const diffMinutes = (Math.abs(driverLeavesDate.getTime() - today.getTime())/1000)/60;
    return ((diffMinutes*100)/processBarFullRange)/100;
}

const ProgressBar = ({increasedMargin, t}) => {
    const { brandedSettingsStore: { brandedSettings: {coBrandingThemeProperties: { mainColor }} }, travelerAppStore: { chosenTransfer: { fromDatetime, pickupWaitingTimeMinutes }} }= useStores();
    if(!fromDatetime) {
      return null;
    }
    const increasedMarginPx = increasedMargin ? 49:0;
    // TODO: use only from datetime and extra waiting time minutes: pickup_waiting_time_minutes of chosenTransfer. check for s-rides
    // TODO: improve getDriverWaitingTime
    const driverLeavesDate = addMinutes(parseISO(fromDatetime), pickupWaitingTimeMinutes || 0);
    const driverLeavesTime = format(driverLeavesDate, 'HH:mm');

    return (
        <ProgressContainer padding="0" increasedMarginPx={increasedMarginPx}>
            <Text2 style={{padding: "0px 10px"}}>{t('live.driver_waiting')}: <b>{driverLeavesTime}</b></Text2>
            <CustomProgressBar value={getDriverWaitingTime(driverLeavesDate, fromDatetime)} color={mainColor}></CustomProgressBar>
        </ProgressContainer>
    )

}

export default withTranslation()(ProgressBar);

const ProgressContainer = styled(StyledCard)`
    height: 37px;
    min-width: 203px;
    max-width: 280px;
    width: fit-content;
    margin: ${props => window.innerHeight-200-props.increasedMarginPx}px auto 8px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @supports (-webkit-touch-callout: none) {
        margin: ${props => window.innerHeight-250-props.increasedMarginPx}px auto 8px auto !important;
    }
`;

const CustomProgressBar = styled(IonProgressBar)`
    margin-top: 5px;
    --progress-background: ${props => props.color};
`;
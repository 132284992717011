import TrackTravelerAppEventsService from './trackTravelerAppEvents.js';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review'; // https://github.com/dpa99c/cordova-launch-review
import stores from '../stores';

const { travelerAppStore } = stores;

// https://welcomepickups.atlassian.net/wiki/spaces/TRAV/pages/3985080329/App+Review+Collection
class InAppReviewService {
  requested = () => {
    TrackTravelerAppEventsService.trackStoreReviewRequestSeenEvent();
    travelerAppStore.setTravelerInfo({
      ...travelerAppStore.travelerInfo,
      promptForStoreReview: false
    });
  }

  // shown = () => {
  //   TrackTravelerAppEventsService.trackStoreReviewRequestSeenEvent();
  //   travelerAppStore.setTravelerInfo({
  //     ...travelerAppStore.travelerInfo,
  //     promptForStoreReview: false
  //   });
  // }

  // dismissed = () => {
  //   TrackTravelerAppEventsService.trackStoreReviewRequestSubmittedEvent();
  //   travelerAppStore.setTravelerInfo({
  //     ...travelerAppStore.travelerInfo,
  //     promptForStoreReview: false
  //   });
  // }

  showInAppReviewWindow = () => (
    LaunchReview.rating().subscribe(value => {
      switch(value){
        case 'requested': this.requested(); break;
        // case 'shown': this.shown(); break;
        // case 'dismissed': this.dismissed(); break;
        default: return;
      }
    })
  )
}

export default new InAppReviewService();
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { IonToolbar } from "@ionic/react";
import theme from "../../../theme";

const DP320 = "(min-resolution: 320dpi)";
const DP220 = "(min-resolution: 220dpi)";

const GmapsApiKey = "AIzaSyBiGo_qb7GASBrmk0nvjlx0n9PyJAzAvPw";
const styleParams =
    `style=element:geometry|color:0xf5f5f5&style=element:labels.icon|visibility:off&style=element:labels.text.fill|color:0x616161&style=element:labels.text.stroke|color:0xf5f5f5&style=feature:administrative.land_parcel|element:labels.text.fill|color:0xbdbdbd&style=feature:poi|element:geometry|color:0xeeeeee&style=feature:poi|element:labels.text.fill|color:0x757575&style=feature:poi.park|element:geometry|color:0xe5e5e5&style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e&style=feature:road|element:geometry|color:0xffffff&style=feature:road.arterial|element:labels.text.fill|color:0x757575&style=feature:road.highway|element:geometry|color:0xdadada&style=feature:road.highway|element:geometry.fill|visibility:off&style=feature:road.highway|element:geometry.stroke|visibility:off&style=feature:road.highway|element:labels.text.fill|color:0x616161&style=feature:road.local|element:labels.text.fill|color:0x9e9e9e&style=feature:transit.line|element:geometry|color:0xe5e5e5&style=feature:transit.station|element:geometry|color:0xeeeeee&style=feature:water|element:geometry|color:0xc9c9c9&style=feature:water|element:geometry.fill|lightness:40&style=feature:water|element:labels.text.fill|color:0x9e9e9e
`

const TransferDetailsMap = (props) => {

    const mapSizeFormat = (ratio, clientWidth, clientHeight) => `${(Math.ceil(ratio * clientWidth))}x${(Math.ceil(ratio * clientHeight) - 16)}`;
    const [clientWidth] = useState(window.innerWidth > 768 ? 600 : window.innerWidth);
    const [clientHeight] = useState(240);
    const [mapSize, setMapWidth] = useState(mapSizeFormat(1, clientWidth, clientHeight));
  
    const concatCoordinates = (from, to) => `${from},${to}`;
  
    const { fromLatitude, fromLongitude, toLatitude, toLongitude } = props;
    const fromCoordinate = concatCoordinates(fromLatitude, fromLongitude);
    const toCoordinate = concatCoordinates(toLatitude, toLongitude);
  
  
    useEffect(() => {
  
      if (window.matchMedia && matchMedia(DP320).matches) {
        setMapWidth(mapSizeFormat(2, clientWidth, clientHeight));
      }
      else if (window.matchMedia && matchMedia(DP220).matches) {
        setMapWidth(mapSizeFormat(1.5, clientWidth, clientHeight));
      }
      else {
        setMapWidth(mapSizeFormat(1, clientWidth, clientHeight));
      }
  
  
    }, [clientHeight, clientWidth]);
  

    return (
        <IonToolbar style={{marginTop: window.innerWidth < 768 ? "40px" : "25px"}}>
            {/* <BackButton alt="go-back" src={arrowLeft}
                onClick={() => {
                    const tripToken = props.match.params.tripToken
                    props.history.push(`/trip/${tripToken}`);
                }}
            /> */}
            <MapContainer>
                {mapSize && <img width={clientWidth} height={clientHeight} alt="route preview" src={window.encodeURI(`https://maps.googleapis.com/maps/api/staticmap?language=en&maptype=roadmap&size=${mapSize}&markers=icon:https://www.welcomepickups.com/wp-content/uploads/2020/11/departure-pin-green.png|${fromCoordinate}&markers=icon:https://www.welcomepickups.com/wp-content/uploads/2020/11/destination-pin-green.png|${toCoordinate}&key=${GmapsApiKey}&style=${styleParams}`)} />}
            </MapContainer>
            { props.children }
        </IonToolbar>
    )
}

const MapContainer = styled.div`
  background-color: #EAECED;
  max-width: 600px;
  height: 240px;
  border-radius: 4px;

  img {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints[0]}px){
    max-height: 240px;
  }
`;

export default withRouter(TransferDetailsMap);

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import TravelerApi from "../../API";

import { withChatContext, Chat, Channel, ChannelList, Window } from 'stream-chat-react';
import { MessageList } from 'stream-chat-react';
import { MessageInput, Thread } from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';
import "./welcome-chat.css";
import styled from "styled-components";
import theme from "../../theme";
import { IonList, IonCard, IonItem, IonSpinner, IonModal, IonPopover, IonRefresher, IonRefresherContent } from "@ionic/react";
import SkeletonDetails from "../../components/common/ScarySkeletons/SkeletonDetails";
import { StyledContent, StyledPage, StyledCard } from "../../components/styled/IonElements";
import { DesktopFlexContainer, StickyList } from "../../components/styled/Wrappers";
import { Heading2, Text2 } from "../../components/styled/Typography";
import noMessagesIcon from "../../assets/icons/no_messages_icon.svg";
import helpIcon from "../../assets/icons/help_icon_black.svg";
import driverIcon from "../../assets/icons/driver_icon.svg";
import carIcon from "../../assets/icons/car_icon.svg";
import arrowLeft from "../../assets/icons/leftGraphiteArrow.svg";
import ellipsisIcon from "../../assets/icons/ellipsis_horizontal_icon.svg";
// import welcomeFileUploadIcon from "../../assets/icons/camera_icon.svg";

import { format, parseISO, isToday } from "date-fns";

import { isMobilePlatform, isMobileWebPlatform, isIosMobileApp, stringWithEllipsis } from "../../components/common/helpers";

const isMobile = isMobilePlatform();
const isMobileWeb = isMobileWebPlatform();
const isIosApp = isIosMobileApp();

const getDriver = (channel,currentUser) => {
  const members = Object.values(channel.state.members);

  if (members.length === 2) {
    const driver = members.find((m) => m.user.member_type === "driver");
    return driver?.user;
  }
}

class Messages extends Component {
  constructor(props) {
    console.log("Messages constructor");
    super(props);

    // const noMessages = !(travelerInfo.chatUser) || !(chatClient.get());
    const connecting = true;//(travelerInfo.chatUser) && !(chatClient.get());
    // const preselectedChannelToken = props.location?.state?.selectedChannelToken;

    this.state = {
      connecting,
      preselectedChannelToken: null,
      activeChannels: [],
      selectedChannel: null,
      filters: null
    }

    this.keyboardDidShowListenerFn = this.keyboardDidShowListener.bind(this);
    this.keyboardDidHideListenerFn = this.keyboardDidHideListener.bind(this);
  }

  onViewTransferClick(e, channel) {
    e.preventDefault();

    const tripToken = channel.data.service.trip_token;
    const transferToken = channel.data.service.token;
    this.props.history.push(`/trip/${tripToken}/transfer/${transferToken}`);
  }

  doRefresh(event) {
    console.log("Refreshing");
    this.setState({connecting: true});
    this.componentDidMount();
    event.detail.complete();
  }

  scrollToLastMessage() {
    // console.log("Scroll to last message");
    const list = document.querySelector('.str-chat__list');
    const messages = document.querySelectorAll('.str-chat__li');
    const lastMessage = messages[messages.length- 1];

    if(lastMessage) {
      list.scrollTo(0,lastMessage.offsetTop);
    }
  }

  keyboardDidShowListener(e) {
    // console.log('keyboard did show with height', e.keyboardHeight, JSON.stringify(e));

    this.scrollToLastMessage();

    // Do not lose focus when user clicks send message button
    const sendButton = document.querySelector('.str-chat__send-button');
    if(sendButton) {
      sendButton.onclick = (e) => {
        // console.log("Will focus");
        const inputElement = document.querySelector('.str-chat__textarea textarea');
        if(inputElement) {
          inputElement.focus();
          // or select
        }
      };
    }
  }

  keyboardDidHideListener() {
    // console.log('keyboard will hide', this);

    this.scrollToLastMessage();
  }

  removeKeyboardListeners() {
    // console.log("Removing keyboard listeners");
    window.removeEventListener('ionKeyboardDidShow', this.keyboardDidShowListenerFn);
    window.removeEventListener('ionKeyboardDidHide', this.keyboardDidHideListenerFn);
  }

  async componentDidMount() {
    const { 
      travelerAppStore: { travelerInfo },
      messagesStore: { connect, showNewMessagesBadge, setshowNewMessagesBadge }
    } = this.props;

    // Get channel from params if exists
    // TODO: move to constructor and save it to state
    // Here clear the state with history.replace()
    // const preselectedChannelToken = location?.state?.selectedChannelToken;

    console.log("Messages componentDidMount");

    // Add keyboard handler for mobile
    if(isMobile) {
      // console.log("On Mobile");
      this.removeKeyboardListeners();
      // When keyboard shows: scroll to last message and prevent send button to lose focus onClick
      window.addEventListener('ionKeyboardDidShow', this.keyboardDidShowListenerFn);

      // Scroll to last message when keyboard hides
      window.addEventListener('ionKeyboardDidHide', this.keyboardDidHideListenerFn);
    }

    if(travelerInfo.chatUser) {
      const connected = await connect(travelerInfo.chatUser);
      if(connected){
        // const channels = await fetchChannels();
        const filters = { type: 'messaging', members: { $in: [travelerInfo.chatUser.chatMemberId] }, frozen: false };
        this.sort = { last_message_at: -1 };
        this.options = { watch: true, state: true };
        // let activeChannels = await chatClient.get().queryChannels(this.filters, this.sort, this.options);
        // if no channels set noMessages to true;
        // const selectedChannel = preselectedChannelToken ? activeChannels.find((channel) => { return channel.id === preselectedChannelToken }) : activeChannels[0];
        /*this.setState({ connecting: false, preselectedChannelToken: preselectedChannelToken,
                        selectedChannel: selectedChannel, activeChannels: activeChannels});*/
        this.setState({ connecting: false, filters });
      }
      this.setState({ connecting: false });
    }
    else {
      this.setState({ connecting: false });
    }
    if(showNewMessagesBadge.get()) {
      setshowNewMessagesBadge(false);
    }
  }

  componentWillUnmount() {
    const { 
      messagesStore: { showNewMessagesBadge, setshowNewMessagesBadge  },
    } = this.props;

    if(showNewMessagesBadge.get()) {
      setshowNewMessagesBadge(false);
    }

    if(isMobile) {
      this.removeKeyboardListeners();
    }
  }

  render() {
    const {
      travelerAppStore: { travelerInfo },
      messagesStore: { chatClient, reconnecting },
      brandedSettingsStore: { getCoBrandedHelpCenterUrl: helpUrl },
      match: { params },
      location,
      t
    } = this.props;

    const { connecting } = this.state;
    const noMessages = !(travelerInfo.chatUser) || !(chatClient.get());
    // TODO: read directly from state
    const preselectedChannelToken = location?.state?.selectedChannelToken || params.channelToken;
    const archivedListFilters = Object.assign({},this.state.filters, { frozen: true });
    // console.log("Active channels:",this.props.client.activeChannels);
    // || !(this.state.filters)?
    return (
      connecting || reconnecting.get() ?
        <SkeletonDetails />
        :
        <StyledPage>
          <StyledContent >
            <Container align="flex-start">
              <Chat client={chatClient.get()} theme="messaging light" initialNavOpen={true}>
                { isMobile ?
                  [
                    <IonRefresher slot="fixed" onIonRefresh={this.doRefresh.bind(this)}>
                        <IonRefresherContent pullingIcon="lines"></IonRefresherContent>
                    </IonRefresher>,
                    <StyledCard padding="0" style={{width: '100%'}}>
                      <MobileHeader>
                        <Heading2>{t('messages.menu_button')}</Heading2>
                        <HelpButton target="_blank" href={helpUrl}>
                          <img alt={t('my_trip.help')} src={helpIcon} />
                          <Text2 style={{ paddingLeft: "8px"}}>{t('my_trip.help')}</Text2>
                        </HelpButton>
                      </MobileHeader>
                      <HeaderDivider/>
                      { noMessages ?
                        <NoMessagesComponent/>
                        :
                        [
                        <StyledIonList key="channels-list-container">
                          <ChannelList
                            key="active-list"
                            setActiveChannelOnMount={false}
                            filters={this.state.filters}
                            sort={this.sort}
                            options={this.options}
                            customActiveChannel={preselectedChannelToken || ""}
                            Preview={WelcomeChannelPreview}
                            EmptyStateIndicator={ArchivedListEmptyStateIndicator}
                            allowNewMessagesFromUnfilteredChannels={false}
                          />
                          <ChannelList
                            key={"archived-list"}
                            className="archived"
                            setActiveChannelOnMount={false}
                            filters={archivedListFilters}
                            sort={this.sort}
                            options={this.options}
                            customActiveChannel={preselectedChannelToken || ""}
                            Preview={WelcomeChannelPreview}
                            EmptyStateIndicator={ArchivedListEmptyStateIndicator}
                            List={withTranslation()(ArchivedList)}
                            allowNewMessagesFromUnfilteredChannels={false}
                          />
                        </StyledIonList>,
                        <MobileChannelComponentWithChatContext
                          key="channel-container"
                          helpUrl={helpUrl}
                          onViewTransferClick={this.onViewTransferClick.bind(this)}
                        />
                        ]
                        }
                      </StyledCard>
                  ]
                  :
                  noMessages ?
                    <StyledCard padding="15px 20px" margin="20px" style={{width: '100%'}}>
                      <NoMessagesComponent/>
                    </StyledCard>
                    :
                    [
                    <ChannelsListContainer padding="0" key="channels-list-container">
                      <StyledIonList key="lists-wrapper">
                        <ChannelList
                          key={"active-list"}
                          setActiveChannelOnMount={true}
                          filters={this.state.filters}
                          sort={this.sort}
                          options={this.options}
                          customActiveChannel={preselectedChannelToken || ""}
                          Preview={WelcomeChannelPreview}
                          EmptyStateIndicator={ArchivedListEmptyStateIndicator}
                          allowNewMessagesFromUnfilteredChannels={false}
                        />
                        <ChannelList
                          key={"archived-list"}
                          className="archived"
                          setActiveChannelOnMount={false}
                          filters={archivedListFilters}
                          sort={this.sort}
                          options={this.options}
                          customActiveChannel={preselectedChannelToken || ""}
                          Preview={WelcomeChannelPreview}
                          EmptyStateIndicator={ArchivedListEmptyStateIndicator}
                          List={withTranslation()(ArchivedList)}
                          allowNewMessagesFromUnfilteredChannels={false}
                        />
                      </StyledIonList>
                    </ChannelsListContainer>,
                    <ChannelContainer key="channel-container">
                      <DesktopChannelComponentWithChatContext onViewTransferClick={this.onViewTransferClick.bind(this)} />
                    </ChannelContainer>
                    ]
                }
              </Chat>
            </Container>
          </StyledContent>
        </StyledPage>
    );
  }
}

class MobileChannel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopover: false,
      popoverEvent: undefined
    };
  }

  onClose(e) {
    this.props.setActiveChannel(null, this.props.watchers, e);
  }

  transferFromDatetimeTimeFormatted(datetime) {
    const date = parseISO(datetime);
    return format(date,"d MMM");
  }

  componentDidMount() {
    // Handle mobile browser back button
    /*window.onpopstate = function(event) {
      if(this.props.channel) {
        console.log("Back button pressed when modal was open");
        this.onClose(null);
        this.props.history.push("/messages");
      }
    }.bind(this);*/
    /*this.backListener = this.props.history.listen(location => {
      debugger;
      console.log("On back button", JSON.stringify(location));
      if (this.props.history.action === "POP") {
        // Do your stuff
        if(this.props.channel) {
          this.onClose(null);
        }
      }
    });*/
  }

  componentWillUnmount() {
    // Unbind listener
    // this.backListener();
  }


  render() {
    const { channel, t } = this.props;
    if(!(channel)) {
      return null;
    }

    const driver = getDriver(channel);
    const driverFirstName = driver?.first_name || driver?.name; // change to first_name
    const driverName = `Driver ${driverFirstName}`;

    let transferFromTitle, transferToTitle, transferFromDateTime;

    const service = channel.data.service;
    const transferToken = service.token || service.service_token; //TODO: remove_service
    const transferType = service.type || service.service_type; //TODO: remove service

    const transfer = this.props.messagesStore.getTransferForChannelToken(transferToken);

    if(transfer && transferType === "Transfer") {
      transferFromTitle = transfer.fromTitle;
      transferToTitle = transfer.toTitle;
      transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);
    }
    else if(transfer && transferType === "TourTransfer") {
      transferFromTitle = transfer.fromTitle;
      transferToTitle = transfer.title;
      transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);
    }

    return (
      channel ?
        <IonModal isOpen={(this.props.channel)} onClose={this.onClose.bind(this)} ionBackButton={this.onClose.bind(this)} onDidDismiss={this.onClose.bind(this)}>
          { channel ?
            <div style={{ height: '100%'}}>
              <MobileChannelHeader isIosApp={isIosApp}>
              <div
                className="back-arrow-icon"
                onClick={this.onClose.bind(this)}
                ionBackButton={this.onClose.bind(this)}
              />
              <MobileChannelTitle>
                <MobileDriverName>
                  {driverName}
                </MobileDriverName>
                <TransferDetails>
                  <span>{ transferFromTitle ? `From ${transferFromTitle} ${String.fromCharCode(8594)} ` : '' }</span>
                  <span>{ transferToTitle ? `${stringWithEllipsis(transferToTitle, 20)} - ` : '' }</span>
                  <span>{ transferFromDateTime || ''}</span>
                </TransferDetails>
              </MobileChannelTitle>
              <div>
                <button
                  style={{width: "40px", height: "40px", background: "transparent"}}
                  onClick={(e) => {
                    e.persist();
                    this.setState({ showPopover: true, popoverEvent: e});
                  }}
                >
                  <img alt={'Menu'} src={ellipsisIcon} />
                </button>
                <IonPopover
                  cssClass='my-custom-class'
                  event={this.state.popoverEvent}
                  isOpen={this.state.showPopover}
                  onDidDismiss={() => this.setState({ showPopover: false, popoverEvent: undefined })}
                >
                  <IonList lines="none">
                    <IonItem button onClick={(e) => this.props.onViewTransferClick(e, channel)} detail={false}>{t('messages.cta_to_transfer')}</IonItem>
                    <IonItem href={this.props.helpUrl} target="_blank" detail={false}>{t('my_trip.help')}</IonItem>
                  </IonList>
                </IonPopover>
              </div>
              </MobileChannelHeader>
              <MobileChannelContainer className={ `${isMobileWeb ? "mobile-web" : ""} ${isIosApp ? "from-ios-app":""}` }>
                <Channel acceptedFiles={['image/*']}>
                  <Window>
                    <MessageList />
                    { channel && channel.data.frozen ?
                      null
                      :
                      <MessageInput/>
                    }
                  </Window>
                  <Thread />
                </Channel>
              </MobileChannelContainer>
            </div>
            :
            null
          }
        </IonModal>
        :
        null
    );
  }
}

const MobileChannelComponentWithChatContext = withTranslation()(withRouter(withChatContext(inject("messagesStore")(observer(MobileChannel)))));

function DesktopChannelComponent(props) {
  const { channel, t } = props;
  let channelTitle = null;

  if(channel) {
    const driver = getDriver(channel);
    const driverFirstName = driver?.first_name || driver?.name; // change to first_name
    channelTitle = `Driver ${driverFirstName}`;
  }

  return(
    [
    channel ?
      <ChannelHeading key="channel-heading">
        <ChannelTitle>{channelTitle}</ChannelTitle>
        <LinkToTransfer onClick={(e) => props.onViewTransferClick(e, channel)}>
          <img style={{marginRight: '8px'}} alt={t('messages.cta_to_transfer')} src={carIcon} />
          <Text2>{t('messages.cta_to_transfer')}</Text2>
        </LinkToTransfer>
      </ChannelHeading>
      :
      null,
    <ChannelWrapper key="channel-wrapper">
      <Channel EmptyPlaceholder={<NoMessagesComponent/>} acceptedFiles={['image/*']}>
        <Window>
          <MessageList />
          { channel && channel.data.frozen ?
            null
            :
            <MessageInput focus/>
          }
        </Window>
        <Thread />
      </Channel>
    </ChannelWrapper>
    ]
  );
}

const DesktopChannelComponentWithChatContext = withTranslation()(withChatContext(DesktopChannelComponent));

const MobileChannelHeader = styled.div`
  height: 60px;
  padding-top: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(45,59,78,0.06);
  margin-top: ${props => props.isIosApp ? "30px" : "0px"};

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  .back-arrow-icon {
    background-image: url(${arrowLeft});
    background-size: 20px 20px;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const MobileChannelTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Container = styled(DesktopFlexContainer)`
  padding-top: 24px;
  @media (max-width: ${theme.breakpoints[0]}px){
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const ChannelsListContainer = styled(StickyList)`
  top: 0px;
  max-height: 535px;
`;

const StyledIonList = styled.div`
  margin-top: 12px !important;
  height: 535px;
  width: 360px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(45,59,78,0.06);
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;

  .str-chat__channel-preview:first-of-type {
    margin-top: 12px;
  }

  .str-chat.messaging, .str-chat.commerce {
    background-color: #fff;
  }

  @media (max-width: ${theme.breakpoints[0]}px){
    height: auto;
    width: 100%;
    overflow-x: hidden;
    // overflow-y:scroll;
    // To remove from production
  }
`;

const ChannelContainer = styled(IonCard)`
  overflow: unset;
  height: 792px;
  width: 600px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(45,59,78,0.06);
  margin-top: 0px;
`;

const MobileChannelContainer = styled.div`
  height: 100%;
`;

const ChannelHeading = styled.div`
  height: 96px;
  width: 600px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const ChannelTitle = styled(Heading2)`
  margin-left: 40px;
  margin-top: unset;
  margin-bottom: unset;
`;

const LinkToTransfer = styled.a`
  margin-right: 28px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const ChannelWrapper = styled.div`
  position: relative;
  height: calc(100% - 96px);
  width: 100%
`;

const MobileHeader = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left:20px;
  margin-right: 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const HelpButton = styled.a`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const NoMessagesContainer = styled.div`
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  font-weight: 700;
  margin-top:100px;
  margin-bottom:100px;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-bottom:34px;

  @media (max-width: ${theme.breakpoints[0]}px){
    margin-top: 120px;
    margin-bottom: 120px;
  }
`;

const NoMessagesTitle = styled.div`
  width: 360px;
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  word-break:break-word;
  margin-top:20px;

  @media (max-width: ${theme.breakpoints[0]}px){
    margin-top: 24px;
    width: 296px;
  }
`;


const NoMessagesSubtitle = styled.div`
  margin-top:16px;
  min-height: 63px;
  width: 360px;
  color: ${theme.colors.greys.grey5};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  word-break:break-word;

  @media (max-width: ${theme.breakpoints[0]}px){
    width: 296px;
  }
`;

const ArchivedTitle = styled.div`
  display: none;
  height: 14px;
  width: 100%;
  margin-top: 32px;
  padding-left:32px;
  margin-bottom: 4px;
  color: rgba(45,59,78,0.5);
  font-size: 14px;

  &.with-channels {
    display: block;
  }
`;

class ArchivedList extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <ArchivedTitle className={this.props?.children?.props?.children?.length > 0 ? "with-channels":""}>{t('messages.archived_list_title').toUpperCase()}</ArchivedTitle>
        { this.props.children }
      </div>
    );
  }
}

const ArchivedListEmptyStateIndicator = styled.div`
`;

class MyChannelPreview extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      fetchingTransferData: true,
      fetchedTransferData: false
    };
  }

  parseAndSaveTransferToState(transfer) {
    const transferFromTitle = transfer.fromTitle;
    const transferToTitle = transfer.toTitle;
    const transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);

    this.setState({
      fetchingTransferData: false,
      fetchedTransferData: true,
      transferFromTitle,
      transferToTitle,
      transferFromDateTime
    });
  }

  parseAndSaveTourTransferToState(transfer) {
    const transferFromTitle = transfer.fromTitle;
    const transferToTitle = transfer.title;
    const transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);

    this.setState({
      fetchingTransferData: false,
      fetchedTransferData: true,
      transferFromTitle,
      transferToTitle,
      transferFromDateTime
    });
  }

  onUnableToFetchTransfer() {
    this.setState({
      fetchingTransferData: false,
      fetchedTransferData: false
    });
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      travelerAppStore,
      messagesStore,
      channel
    } = this.props;

    const service = channel.data.service;
    if(!service) {
      this.onUnableToFetchTransfer();
      return;
    }

    const transferToken = service.token || service.service_token; //TODO: remove_service
    const transferType = service.type || service.service_type; //TODO: remove service

    if(transferType === "Transfer" && transferToken) {
      const foundTransfer = messagesStore.getTransferForChannelToken(transferToken) || travelerAppStore.transfers.find((tr) => tr.id === transferToken);
      if(foundTransfer && foundTransfer.transferType !== undefined) {
        messagesStore.saveTransferWithChannelToken(foundTransfer, transferToken);
        this.parseAndSaveTransferToState(foundTransfer);
        return;
      }

      TravelerApi.getTransferById(transferToken)
        .then((transfer) => {
          messagesStore.saveTransferWithChannelToken(transfer, transferToken);
          if(!this._isMounted) { return; }
          this.parseAndSaveTransferToState(transfer);
        })
        .catch(() => {
          this.onUnableToFetchTransfer();
        });
    }
    else if(transferType === "TourTransfer" && transferToken){
      const foundTransfer = messagesStore.getTransferForChannelToken(transferToken) || travelerAppStore.transfers.find((tr) => tr.id === transferToken);

      if(foundTransfer && foundTransfer.transferType === undefined) {
        messagesStore.saveTransferWithChannelToken(foundTransfer, transferToken);
        this.parseAndSaveTourTransferToState(foundTransfer);
        return;
      }

      TravelerApi.getTourById(transferToken)
        .then((transfer) => {
          messagesStore.saveTransferWithChannelToken(transfer, transferToken);
          if(!this._isMounted) { return; }
          this.parseAndSaveTourTransferToState(transfer);
        })
        .catch(() => {
          this.onUnableToFetchTransfer();
        });
    }
    else {
      this.onUnableToFetchTransfer();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  lastMessageTimeFormatted(channel,datetime) {
    if(!datetime) {
      return null;
    }
    const date = typeof datetime === "string" ? parseISO(datetime) : datetime;

    if(date.toDateString() === "Invalid Date") {
      return "";
    }

    if(channel.data.frozen) {
      return format(date,"d MMM y");
    }
    else {
      if(isToday(date)){
        return format(date,"HH:mm");
      }
      else{
        return format(date,"d MMM");
      }
    }
  }

  transferFromDatetimeTimeFormatted(datetime) {
    const date = parseISO(datetime);
    return format(date,"d MMM, HH:mm");
  }

  render() {
      const { setActiveChannel, channel, active, displayImage, latestMessage, unread} = this.props;
      const hasUnread = (unread > 0);
      const driverImage = displayImage;
      const driver = getDriver(channel);
      const driverFirstName = driver?.first_name || driver?.name; // change to first_name
      const lastMessageText = stringWithEllipsis(latestMessage, 25);
      const lastMessageDateTime = this.lastMessageTimeFormatted(channel, channel.state.last_message_at);
      const archived = channel.data.frozen;
      const { fetchingTransferData, fetchedTransferData, transferFromTitle, transferToTitle, transferFromDateTime } = this.state;
      const className = `${active ? "active":""} ${archived ? "archived":""}`

      return (
        [
        <Wrapper key={"wrapper-" + channel.id} className={className}
          onClick={ (e) => {
            setActiveChannel(channel, this.props.watchers, e);
          }}
        >
            <AvatarWrapper>
              <Avatar driverAvatar={driverImage} className={className}>
              </Avatar>
              <DriverIconWrapper><DriverIcon><img alt="Driver" src={driverIcon}></img></DriverIcon></DriverIconWrapper>
            </AvatarWrapper>
            <Details>
              <Info>
                <DriverName className={className}>{driverFirstName}</DriverName>
                <Time>
                  { hasUnread && <NotificationBadge/> }
                  <span style={{ color: 'rgba(45,59,78,0.5)'}}>{lastMessageDateTime}</span>
                </Time>
              </Info>
              <LastMessage className={className}>{lastMessageText}</LastMessage>
              { fetchingTransferData ?
                <IonSpinner name="dots" />
                :
                fetchedTransferData ?
                  <TransferDetails>
                    <span>{ `${transferFromTitle} ${String.fromCharCode(8594)}` }</span>
                    <br/>
                    <span>{`${stringWithEllipsis(transferToTitle, 20)} - `}</span><span>{transferFromDateTime}</span>
                  </TransferDetails>
                  :
                  null
              }
            </Details>
        </Wrapper>,
        <Divider key={"divider-" + channel.id}/>]
    );
  }
}

const WelcomeChannelPreview = inject("travelerAppStore", "messagesStore")(MyChannelPreview);

const Wrapper = styled.div`
  cursor:pointer;

  height: 70px;
  width: 100%;
  margin-top: 20px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: ${theme.colors.white};

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  &.active {
    border-left: 3px solid #48D9A4;
  }

  @media (max-width: ${theme.breakpoints[0]}px){
    padding-right: 20px;
    padding-left: 20px;
    justify-content: space-evenly;
  }
`;

const AvatarWrapper = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
`;

const Avatar = styled.div`
  display: block;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-image: ${props => props.driverAvatar ? `url(${props.driverAvatar})` : "unset"};
  background-size: 64px 64px;
  background-color: ${props => props.driverAvatar ? "unset" : theme.colors.greys.grey05};

  &.archived {
    opacity: 0.5;
  }
`;

const DriverIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%
`;

const DriverIcon = styled.div`
  height: 24px;
  width: 24px;
  margin: 4px;
`;

const Details = styled.div`
  width: 212px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: ${theme.breakpoints[0]}px){
    width: 236px;
  }
`;

const Info = styled.div`
  margin-bottom: 2.2px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const DriverName = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20.8px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.active {
    font-weight: bold;
  }

  &.archived {
    color: rgba(45,59,78,0.5);
  }
`;

const MobileDriverName = styled(DriverName)`
  color: #2D3B4E;
`;
const Time = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotificationBadge = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #F27470;
  margin-right: 3px;
  margin-bottom: 1px;
`;

const LastMessage = styled.div`
  margin-bottom: 6px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18.2px;

  &.active {
    font-weight: bold;
  }

  &.archived {
    color: rgba(45,59,78,0.5);
  }
`;

const TransferDetails = styled.div`
  width: 100%;
  font-size: 12px;
  color: rgba(45,59,78,0.5);

  span {
    color: rgba(45,59,78,0.5);
    letter-spacing: 0;
    line-height: 15.6px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 296px;
  background-color: rgba(45,59,78,0.08);
  margin-left: 32px;
  margin-top: 20px;

  @media (max-width: ${theme.breakpoints[0]}px){
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const HeaderDivider = styled.div`
  height: 1px;
  background-color: rgba(45,59,78,0.08);
  margin-left: 20px;
  margin-right: 20px;
`;

const NoMessagesComp = (props) => {
  const { t } = props;
  return (
    <NoMessagesContainer >
      <img alt="No Messages" src={noMessagesIcon} />
      <NoMessagesTitle>{t('messages.no_messages_title')}</NoMessagesTitle>
      <NoMessagesSubtitle>{t('messages.no_messages_message')}</NoMessagesSubtitle>
    </NoMessagesContainer>
  );
}

const NoMessagesComponent = withTranslation()(NoMessagesComp);

export default withChatContext(withRouter(withTranslation()(inject("travelerAppStore", "messagesStore", "brandedSettingsStore")(observer(Messages)))));
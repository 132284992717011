import { isWebPlatform, isMobileAppPlatform } from '../components/common/helpers';
import AppboyPlugin from 'appboy-cordova-sdk/www/AppboyPlugin.js';

class Braze{
  constructor() {
    // Here we should import the correct sdk for web, android or ios.
    this.braze = require('@braze/web-sdk');
    this.isBrazeInitialized = false;
  };

  initialize() {
    if (isWebPlatform()) {
      this.isBrazeInitialized = this.braze.initialize(process.env.REACT_APP_BRAZE_WEB_API_KEY, {
        baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT
      });
      
      this.openSession();

      return this.isBrazeInitialized; 
    }
  }

  openSession() {
    if (isWebPlatform() && this.isBrazeInitialized) {
      return this.braze.openSession();
    }
  }

  changeUser(userId) {
    if (isWebPlatform() && this.isBrazeInitialized) {
      return this.braze.changeUser(userId);
    }

    if (isMobileAppPlatform()) {
      return AppboyPlugin.changeUser(userId);
    }
  }
}

export default new Braze();
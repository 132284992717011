import React, { Component } from 'react';
import './App.css';
import styled from "styled-components";
import { Provider } from "mobx-react";
import stores from './stores';
import { IonReactRouter } from "@ionic/react-router";
import braze from "./services/braze";
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

import Menu from './components/common/Menu';
import { IonApp, IonContent, IonImg, isPlatform } from '@ionic/react';
import Rollbar from "rollbar";
import AuthenticationService from './services/authentication.js';
import history from "./components/common/history";
import { FlexContainer } from './components/styled/Wrappers';
import logoAnimated from "./assets/branding/welcomeAnimated.gif";
import welcomeText from "./assets/branding/WelcomeTextWhite.svg";
import { Text1 } from './components/styled/Typography';
import { Capacitor, Plugins } from '@capacitor/core';
import { get as getStorage } from "./services/storage.ts";
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import { FirebaseCrashlytics } from '@ionic-native/firebase-crashlytics';
import queryString from 'query-string';
import GitInfo from 'react-git-info/macro';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review';
import InAppReviewService from './services/inAppReview';

// import AppUrlListener from './AppUrlListener';
const { SplashScreen, Keyboard } = Plugins;

const fromMobileApp = !isPlatform('mobileweb') && !isPlatform("pwa") && !isPlatform("desktop");

const gitInfo = GitInfo();
const appVersion = process.env.REACT_APP_VERSION;

// Set whether the accessory bar(DONE) should be visible on the keyboard. Fix for iphones.
if(fromMobileApp && Capacitor.getPlatform() === 'ios') {
  Keyboard.setAccessoryBarVisible({isVisible: true});
}

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      initializingApp: true,
      showAnimation: fromMobileApp ? true : false,
      transitionClassRight: "",
      transitionClassLeft: "",
    }

    // Initialize Braze
    braze.initialize();

    // Initialize App Center Crashes
    if (fromMobileApp) {
      FirebaseAnalytics.setEnabled(true);
      this.crashlytics = FirebaseCrashlytics.initialise();
    }

    // Init Rollbar
    this.initRollbar();

    // Setup dynamic links
    if(fromMobileApp) {
      this.initializeDynamicLinks();
    }

    const { destinationsStore: { destinationsCount, fetchDestinations } } = stores;
    if (!destinationsCount.get()) {
      fetchDestinations();
    }
  }

  async componentDidMount() {
    // authenticate traveler if possible
    console.log("App.js: on componentDidMount");

    if(fromMobileApp) {
      this.setAnimationDelays();
    }

    // On mobile devices, the authentication of the user will happen after the deeplink
    // initialization and only if the app did not open from a deeplink.
    let authenticated = false;
    if (!fromMobileApp) {
      authenticated = await AuthenticationService.authenticateTraveler();
    }
    
    if (!authenticated) {
      const locale = await getStorage('locale');
      if (locale) {
        stores.travelerAppStore.setLocale(locale);
      }
      //if isWeb and the host is different than traveler domain make an API call to get branding settings
      const { brandedSettingsStore } = stores;
      await brandedSettingsStore.fetchBrandedSettings();
    }

    this.setState({ initializingApp: false });
  }

  initRollbar = () => {
    if (process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN && process.env.NODE_ENV !== "development") {
      const travelerId = stores.travelerAppStore.isAuthorised.get() ? stores.travelerAppStore.travelerInfo.id : null;
      const rollbar = new Rollbar({
        enabled: true,
        accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        reportLevel: 'error',
        payload: {
          environment: process.env.NODE_ENV,
          client: {
            javascript: {
              source_map_enabled: true,
              guess_uncaught_frames: true,
              code_version: gitInfo.commit.hash
            }
          },
          person: {
            id: travelerId
          },
          appVersion: appVersion
        }
      })
      console.log("Rollbar initialized:", rollbar?.options?.enabled);
      stores.travelerAppStore.setRollbar(rollbar);
    }
  }

  showInAppReview = () => {
    const { travelerAppStore: { isAuthorised, travelerInfo } } = stores;

    if (
      fromMobileApp &&
      LaunchReview.isRatingSupported() &&
      isAuthorised.get() &&
      travelerInfo.promptForStoreReview
    ) {
      InAppReviewService.showInAppReviewWindow();
    }
  }

  setAnimationDelays = () => {
    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);
    
    setTimeout(() => {
      this.setState({
        transitionClassRight: "transition-right",
        transitionClassLeft: "transition-left"
      })
    }, 4000);

    setTimeout(() => {
      this.setState({
        showAnimation: false
      }, () => {
        // Show rating dialog some seconds after the animiation disappears
        setTimeout(this.showInAppReview, 2000);
      })
    }, 7000);
  }

  handleDynamicLink = async (data) => {
    if (!data.deepLink) {return}

    const deepLink = data.deepLink;
    const slug = deepLink.split('traveler_app').pop();
    if (slug) {
      console.log(`DynamicLink received and redirecting: ${slug}`);
      const url = new URL(deepLink);
      const values = queryString.parse(url.search);
      let { travelers_access_token } = values;
      if(travelers_access_token){
        console.log(`DynamicLink authenticating traveler with token: ${travelers_access_token}`);
        await AuthenticationService.loginWithToken(travelers_access_token);
      } else {
        console.log(`DynamicLink authenticating traveler without token`);
        await AuthenticationService.authenticateTraveler();
      }
      console.log(`DynamicLink redirecting: ${slug}`);
      history.push(slug, { fetch: true });
    }
  }

  initializeDynamicLinks = () => {
    console.log("Initializing Dynamic Links");
    FirebaseDynamicLinks.getDynamicLink()
      .then(async (data) => {
        console.log(`getDynamicLink received: ${JSON.stringify(data)}`);
        if(data) {
          this.handleDynamicLink(data);
        }
        else {
          console.log("App wasn't started from a dynamic link");
          await AuthenticationService.authenticateTraveler();
          history.push('/');
        }
      })
      .catch((error) => {
        console.log(error)
      });

    FirebaseDynamicLinks.onDynamicLink()
      .subscribe(async (data) => {
        console.log(`onDynamicLink received: ${JSON.stringify(data)}`);
        this.handleDynamicLink(data);
      },
      (error) => {
        console.log(error)
      });
      console.log("After Initialization of Dynamic Links");
  }

  render() {
    const { travelerAppStore, destinationsStore, brandedSettingsStore, messagesStore } = stores;
    const { initializingApp, transitionClassRight, transitionClassLeft, showAnimation } = this.state;
    const { destinationsCount } = destinationsStore;

    return (
      <IonApp>
        {(showAnimation || (fromMobileApp && initializingApp)) &&
          <FlexContainer className="splash-container" align="center" justify="center" style={{ zIndex: 10000, position: "absolute", top: 0, height: "100vh", width: "100%" }} >
            <div>
              <LogoContainer className={transitionClassRight}>
                <AnimatedLogo src={logoAnimated} />
              </LogoContainer>
              <WelcomeText src={welcomeText} className={transitionClassLeft} />
              {
                destinationsCount.get() && (
                  <DestinationsText color="#fff" className={transitionClassLeft}>Available in {destinationsCount.get()} destinations</DestinationsText>
                )
              }
            </div>
          </FlexContainer>
        }
        {(!initializingApp) && <IonReactRouter basename={process.env.REACT_APP_RAILS_PUBLIC_URL} history={history}>
        {/*<AppUrlListener></AppUrlListener>*/}
          <IonContent>
            <Provider travelerAppStore={travelerAppStore} destinationsStore={destinationsStore}
              brandedSettingsStore={brandedSettingsStore} messagesStore={messagesStore}>
              <Menu />
            </Provider>
          </IonContent>
        </IonReactRouter>
        }
      </IonApp>
    );
  }
}
export default App;

const LogoContainer = styled.div`
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  position: absolute;
  top: 32%;
  left: 25%;
  right: 25%;
`;

const AnimatedLogo = styled.img`
  width: 112px;
  display:block;
  margin: 0 auto;
  border-radius: 60px;
`;

const WelcomeText = styled(IonImg)`
  left: 25%;
  right: 25%;
  display:block;
  margin: 5px auto;
  -webkit-transition: all 1.5s ease;
  -moz-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  -ms-transition: all 1.5s ease;
  transition: all 1.5s ease;
  position: absolute;
`;

const DestinationsText = styled(Text1)`
  left: 25%;
  right: 25%;
  bottom: 50px;
  width: 100%;
  display:block;
  margin: 32px auto;
  -webkit-transition: all 1.5s ease;
  -moz-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  -ms-transition: all vs ease;
  transition: all 1.5s ease;
  position: absolute;
`;
import { IonItemDivider, IonItemGroup } from "@ionic/react";
import React, { PureComponent } from "react";
import styled from "styled-components";
import { dateLocale } from "../../../components/common/helpers";
import { StyledItem } from "../../../components/styled/IonElements";
import { Paragraph, Text1 } from "../../../components/styled/Typography";
import { getTripDates } from "../../../stores/helpers";
import { withTranslation } from "react-i18next";

class SingleHistoryTrip extends PureComponent {
    render() {
        const { location,
            dateFrom,
            dateTo,
            onClicklFunc,
            timeZone, i18n } = this.props;

        let tripDates;

        if ((dateFrom && dateTo)) {
            tripDates = getTripDates(timeZone, dateFrom, dateTo, i18n.language)
        } else if (dateFrom) {
            tripDates = dateLocale(dateFrom, timeZone, "d MMMM yyyy", i18n.language)
        } else {
            tripDates = "";
        }

        return (
            <IonItemGroup onClick={onClicklFunc} style={{cursor: "pointer"}}>
                <StyledItem lines="none" padding="0" margin="0" isMinHeight>
                    <Text1 weight="500">{location}</Text1>
                </StyledItem>
                <StyledDivider mode="md">
                    <Paragraph size="12px" weight="500">{tripDates}</Paragraph>
                </StyledDivider>
            </IonItemGroup>
        )
    }
}

const StyledDivider = styled(IonItemDivider)`
    padding-bottom: 20px;
    margin-bottom: 20px;
    --padding-start: 0;
`;

export default withTranslation()(SingleHistoryTrip);

class DirectionsService {

    static getService(){
      if(this.service){
        return this.service;
      }
      this.service = new window.google.maps.DirectionsService();
      return this.service;
    }
    
  }
  
  export default DirectionsService;
  
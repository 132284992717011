/**
 * @description creates array of promises to get all necessary things by Promise.all (tours, transfers and etc.)
 * @param {array of objects with ids of things need to be got from api} array
 * @param {unction of which gets data from api} promiseFunction
 * @returns array of promises
 */
// eslint-disable-next-line
import { format, parseISO, compareAsc } from "date-fns";
import {dateLocale} from "../components/common/helpers";

export const getArrayOfPromisses = (array, promiseFunction) => {
  const promises = [];
  array.forEach((item) => {
    promises.push(
      promiseFunction(item.id)
        .then(result => result.data)
        .catch((err) => { console.log(err) })
    )
  });
  return promises;
}

/**
 * @description sort array of transfers by date
 * @param {array of transfers which need to be sort by date} transfers 
 * @returns sorted by date array of transfers
 */
export const sortTransfersByDate = (transfers) => {
  return transfers.sort((transferA, transferB) => {
    const dateTranferA = parseISO(transferA.fromDatetime);
    const dateTransferB = parseISO(transferB.fromDatetime);
    const result = compareAsc(dateTranferA, dateTransferB)

    return result;
  })
}

export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const filterSplittedTransfer = (transfers, setSiblingTransfers) => {

  const groupTransferBySplittedTransferId = groupBy("splittedTransferId");
  const groupedTransfers = groupTransferBySplittedTransferId(transfers);
  const normalTransfers = groupedTransfers.null;
  const tourTransfers = groupedTransfers.undefined;

  const splittedTransfersAfterGrouping = Object.keys(groupedTransfers).filter((key) => (key !== "null" && key !== "undefined"));

  setSiblingTransfers(groupedTransfers);

  const splittedTransfers = splittedTransfersAfterGrouping.map((key) => {
    return groupedTransfers[key][0];
  });

  let filteredTransfers = normalTransfers ? splittedTransfers.concat(normalTransfers) : splittedTransfers;
  filteredTransfers = tourTransfers ? filteredTransfers.concat(tourTransfers) : filteredTransfers;

  return filteredTransfers;

}

/**
 * @description get active trips
 * @param {array of trips} tripsArray
 * @returns array of active trips
 */

export const getActiveTrips = (tripsArray) => {

  let activeTrips = tripsArray.filter((trip) => (trip.chronologicalStatus !== null && trip.chronologicalStatus !== "past"));

  if (activeTrips.length === 0) {
    activeTrips = tripsArray.filter((trip) => (trip.chronologicalStatus === "past"));
    if (activeTrips.length === 0) {
      return [];
    }
  }

  return activeTrips;
}

export const getActiveTrip = (tripsArray) => {

  const groupedActiveTrips = groupBy("chronologicalStatus")(tripsArray);

  if (groupedActiveTrips.current && groupedActiveTrips.current.length > 0) {
    return groupedActiveTrips.current[0];
  }

  if (groupedActiveTrips.recent && groupedActiveTrips.recent.length > 0) {
    return groupedActiveTrips.recent[0];
  }

  if (groupedActiveTrips.future && groupedActiveTrips.future.length > 0) {
    return groupedActiveTrips.future[groupedActiveTrips.future.length - 1];
  }

  if (groupedActiveTrips.past && groupedActiveTrips.past.length > 0) {
    return groupedActiveTrips.past[0];
  }

  return undefined;
}

const D_MMM_YYYY = "d MMMM yyyy";
const D_MMMM = "d MMMM";
const MMMM_YYYY = "MMMM yyyy";

export const getTripDates = (timeZone, fromDateString, toDateString, locale) => {
  let date = "";
  const dateFrom = parseISO(fromDateString);
  const dateTo = parseISO(toDateString);

  if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
    date = `${dateLocale(fromDateString, timeZone, D_MMM_YYYY, locale)} – ${dateLocale(toDateString, timeZone, D_MMM_YYYY, locale)}`;
  } else if (dateFrom.getMonth() === dateTo.getMonth()) {
    date = `${dateFrom.getDate()}–${dateTo.getDate()} ${dateLocale(fromDateString, timeZone, MMMM_YYYY, locale)}`;
  } else {
    date = `${dateLocale(fromDateString, timeZone, D_MMMM, locale)} – ${dateLocale(toDateString, timeZone, D_MMM_YYYY, locale)}`;
  }
  return date;
};


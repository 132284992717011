import React, { useEffect } from "react";
import { isPlatform } from '@ionic/react';
import Footer from "./Footer";
import { addScript, removeScript } from "../../components/common/helpers";
import { StyledCard, StyledContent, StyledPage } from "../../components/styled/IonElements";
import Header from "./Header";
import { withTranslation } from "react-i18next";
import { useStores } from "../../stores/useStores";
import { observer } from "mobx-react";
import { onExternalLinkClick } from "../../components/common/ExternalLink/ExternalLink";

const isMobile = !isPlatform('desktop') && !isPlatform('mobileweb');

const Book = () => {
  const {
    travelerAppStore: {
      locale,
      isAuthorised,
      travelerInfo: { id }
    },
    brandedSettingsStore: {
      brandedSettings: {
        quoteUrl,
        coBrandingThemeProperties: {
          widgetButtonBackgroundColor,
          widgetButtonFontColor
        }
      }
    }
  } = useStores();

  const language = locale.get();

  useEffect(() => {
    if (isMobile) {
      // console.log("Setting iabCallback on window");
      window.iabCallback = onExternalLinkClick;
    }
    
    const widget = document.getElementById("welcomepickups");
    const widgetProps = document.createElement("script");
    widgetProps.type = "text/props";
    widgetProps.id = "widget-props"
    const props = {
      "locale": language,
      "button_color": widgetButtonBackgroundColor,
      "button_text_color": widgetButtonFontColor,
      "no_card": "",
      "fluid": "0",
      "no_header_block": "1",
      "is_extra_transfer": isAuthorised.get(),
      "traveler_id": isAuthorised.get() ? `${id}` : null,
      "quoteUrl": quoteUrl ? `${quoteUrl}` : "",
      "iab_callback": isMobile
    };
    widgetProps.innerHTML = JSON.stringify(props);
    widget.appendChild(widgetProps);

    addScript(process.env.REACT_APP_WIDGET_URL, () => { }, "welcome-widget", true, () => {});

    return () => {
      removeScript("welcome-widget");
    }

  }, [
    id,
    isAuthorised,
    language,
    quoteUrl,
    widgetButtonBackgroundColor,
    widgetButtonFontColor
  ])

  return (
    <StyledPage style={{ overflow: "auto" }} key={locale.get()}>
      <StyledContent>
        <Header />
        <StyledCard style={{overflow:"unset"}} margin={"0"} padding="0">
          <div id="welcomepickups" />
          <Footer />
        </StyledCard>
      </StyledContent>
    </StyledPage>
  )

}

export default withTranslation()(observer(Book));

const availableLanguages = [
	{ name:"Chinese", code:"zh-hans" },
	{ name:"English", code:"en" },
	{ name:"French", code:"fr" },
	{ name:"German", code:"de" },
	{ name:"Greek", code:"el" },
	{ name:"Italian", code:"it" },
	{ name:"Polish", code:"pl" },
	{ name:"Portuguese", code:"pt-pt" },
	{ name:"Russian", code:"ru" },
	{ name:"Spanish", code:"es" },
	{ name:"Swedish", code:"sv" }
];

export {
	availableLanguages
};
import React from 'react';
import {
    IonSkeletonText, IonAvatar, IonCardContent, IonItemDivider
} from '@ionic/react';

import './SkeletonTextExample.css';
import { StyledItem } from '../../styled/IonElements';

const SkeletonProfile = () => (

    <IonCardContent >
        <IonAvatar slot="start">
            <IonSkeletonText animated />
        </IonAvatar>
        <div style={{ marginTop: "20px" }}>
            <IonSkeletonText animated style={{ width: '250px', height: "18px", marginBottom: "10px" }} />
            <IonSkeletonText animated style={{ width: '170px' }} />
            <IonSkeletonText animated style={{ width: '300px' }} />
        </div>
        <IonItemDivider mode="md" style={{ margin: "0 auto", width: "90%" }}/>
        <div style={{ marginTop: "40px" }}>
            <StyledItem padding="0" margin="0">
                <IonSkeletonText animated style={{ width: '200px' }} />
            </StyledItem>
            <StyledItem padding="0" margin="0">
                <IonSkeletonText animated style={{ width: '200px' }} />
            </StyledItem>
            <StyledItem padding="0" margin="0">
                <IonSkeletonText animated style={{ width: '200px' }} />
            </StyledItem>
        </div>
        <div style={{ marginTop: "20px" }}>
            <IonSkeletonText animated style={{ width: '100px', margin: "20px 0" }} />
        </div>
    </IonCardContent>
);

export default SkeletonProfile;
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { Text2 } from "../../components/styled/Typography";
import { FlexContainer } from "../../components/styled/Wrappers";
import ArrowRight from "../../components/svgs/ArrowRight";
import { useStores } from "../../stores/useStores";

const ArrowRightWrapper = styled.div`
  display: ${props => props.arrowVisible ? "flex" : "none"};
  align-items: center;
`;

const StyledContainer = styled(FlexContainer)`
  cursor:pointer;
  width: 100%;
`;

const Content = styled(Text2)`
  margin-right:8px;
  cursor:pointer;
  color:${props => props.color}
`;

const GoToLayout = ({ onClickFunc, children, arrowVisible, textContent }) => {
  const { brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: {mainColor, mainMediumColor}} } } = useStores();

  return (
  <StyledContainer align="center" justify="space-between" className="main-container" onClick={onClickFunc}>
    {children}
    <ArrowRightWrapper arrowVisible={arrowVisible}>
      <Content color={mainMediumColor}>{textContent}</Content>
      <ArrowRight fill={mainColor} />
    </ArrowRightWrapper>
  </StyledContainer>
);
  }



export default observer(GoToLayout);

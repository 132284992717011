import React, { Component } from "react";
import styled from "styled-components";
import { ButtonBase } from "../../../components/styled/Buttons";
import noUpcomingTripsImage from "../../../assets/branding/no_upcoming_trips.jpg";
import { StyledCard, StyledContent, StyledIonToolbar, StyledItem, StyledPage } from "../../../components/styled/IonElements";
import { inject, observer } from "mobx-react";
import { HeaderLogo } from "../../../components/styled/Images";
import { withTranslation } from "react-i18next";
import { FullHeightOverlay } from "../../../components/styled/Wrappers";
import { Paragraph } from "../../../components/styled/Typography";
import HeaderRightContainer from "../../../components/common/HeaderRightContainer";

class NoUpcomingTrips extends Component {

  constructor(props) {
    super(props);
    this.state = {
      destinationModalVisibility: false,
    }

    // this.newTransferUrl = "https://traveler.stg1.welcomd.com/en/athens/transfer/new?luggage=1&passengers=1&time=11%3A50&date=12%2F23%2F2020&city=athens&property_id=&from=Athens%20Airport%20&from_lat=37.935647&from_lng=23.948416&from_category=hub&from_address=Athens%20International%20Airport%2C%20Eleftherios%20Venizelos&to=Piraeus%20Port&to_lat=37.940556&to_lng=23.633333&to_category=hub&to_address=Piraeus%20Port%20-%20Cruise%20Terminal&from_type=airport&to_type=port&from_location_id=1&to_location_id=849&puid=&from_time_as_pickup=1"
  }

  onModalChangeVisibilty = (modalName) => {
    this.setState((state) => ({ [modalName]: !state[modalName] }))
  }

  render() {
    const { children, travelerAppStore: { isAuthorised }, t, brandedSettingsStore: { isPartner, brandedSettings: { brandLogoForTravelerAppUrl, coBrandingThemeProperties: { mainColor } } }  } = this.props;

    return (
      <StyledPage>
        <StyledContent>
          <StyledIonToolbar image={noUpcomingTripsImage} marginBottom="auto">
            <FullHeightOverlay />
            <HeaderLogo src={brandLogoForTravelerAppUrl} maxWidth={isPartner ? "70px" : "100%"}/>
            <HeaderRightContainer />
          </StyledIonToolbar>
          <StyledCard height="270px" margin="0" padding="60px 20px">
            <Title weight="700" maxWidth="100%">{t('my_trip.no_upcoming_trips')}</Title>
            <Paragraph margin=" 20px" maxWidth="100%">{isAuthorised.get() ? t('my_trip.its_a_time') : t('trips.if_you_have_a_booking')}</Paragraph>
            { isAuthorised.get() ?
              <StyledItem routerLink="/book" lines="none" detail={false}>
                <ButtonBase bgcolor={mainColor} style={{margin: "0 auto"}}>
                  <span>{t('book.book_a_transfer')}</span>
                </ButtonBase>
              </StyledItem>
              : <StyledItem routerLink="/login" lines="none" detail={false} detailIcon="none">
                <ButtonBase bgcolor={mainColor} style={{margin: "0 auto"}}>
                  <span>{t('sign_in.sign_in')}</span>
                </ButtonBase>
              </StyledItem>
            }
          </StyledCard>
          {/* <Modal
          open={destinationModalVisibility}
          onClose={() => this.onModalChangeVisibilty("destinationModalVisibility")}
        >
          <DestinationsList startNewTrip fromUpcoming onClose={() => this.onModalChangeVisibilty("destinationModalVisibility")} />
        </Modal> */}
          {children}
          <div className="mainView-lifter" />
        </StyledContent>
      </StyledPage>
    )
  }
}

export default withTranslation()(inject("travelerAppStore", "brandedSettingsStore")(observer(NoUpcomingTrips)));

const Title = styled(Paragraph)`
  font-size: 24px;
  margin-bottom: 13px;
`;

import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "./stores/useStores";
import Book from "./pages/Book";
import Explore from "./pages/Explore";
import Messages from "./pages/Messages";
import Profile from "./pages/Profile";
import AccountAndData from "./pages/Profile/AccountAndData";
import Edit from "./pages/Profile/LoggedInUser/Edit";
import TripHistory from "./pages/Profile/TripHistory";
import Trip from "./pages/Trip";
import TransferDetails from "./pages/Trip/TransferDetails";
import TermsAndConditions from "./pages/Profile/TermsAndConditions";

const Routes = observer(() => {
  const { travelerAppStore: { isAuthorised } } = useStores();

  /**
   * Redirects the user to another path when they are not logged in.
   * @param {string} redirectToPath The path you want to send the user that is not logged in
   * @param {ReactElement} Page The page that will load if the user is logged in
   */
  const redirectIfNotLoggedIn = (redirectToPath, Page) => (!isAuthorised.get() ? <Redirect to={redirectToPath} /> : Page)
  
  return (
    <Fragment>
        <Route exact path='/'
           render={() => {
             return (
               isAuthorised.get() ?
               <Redirect to="/trip" /> :
               <Redirect to="/book" />
             )
           }}
        />
        <Route exact path='/book' component={Book} />

        {/* Trip Routes */}
        <Route exact path='/trip/:tripToken/transfer/:transferToken' component={TransferDetails} />
        <Route exact path='/trip/:tripToken' component={Trip} />
        <Route exact path='/trip' component={Trip} />

        {/* Explore Routes */}
        <Route exact path='/explore/:destinationToken' component={Explore} />
        <Route exact path='/explore' component={Explore} />

        {/* Messages Routes */}
        <Route exact path='/messages' component={Messages} />
        <Route exact path='/messages/:channelToken' component={Messages} />

        {/* Profile Routes */}
        <Route exact path='/profile/trip_history' render={()=>redirectIfNotLoggedIn('/profile', <TripHistory/>)} />
        <Route exact path='/profile/account_and_data' render={()=>redirectIfNotLoggedIn('/profile', <AccountAndData/>)} />
        <Route exact path='/profile/edit' render={()=>redirectIfNotLoggedIn('/profile', <Edit/>)} />
        <Route exact path='/profile/terms_and_conditions' component={TermsAndConditions} />
        <Route exact path='/profile' component={Profile} />

        <Route exact path='/' component={Book} />
    </Fragment>
  )
})

export default Routes;
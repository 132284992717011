import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Sad from "../../../components/svgs/SadFace";
import theme from "../../../theme";
import { Heading3, Text2 } from "../../../components/styled/Typography";
import { ButtonBase } from "../../../components/styled/Buttons";
import styled from "styled-components";
import { IonModal } from "@ionic/react";
import closeIcon from "../../../assets/icons/close-delete-icon.svg";
import { FlexContainer } from "../../../components/styled/Wrappers";
import { withTranslation } from "react-i18next";
import { useStores } from "../../../stores/useStores";

const FeedbackModal = ({ history, isOpen, onClose, t }) => {
    const { brandedSettingsStore: { brandedSettings: { coBrandingThemeProperties: { mainColor } } } } = useStores();

    return (
        <StyledModal
            isOpen={isOpen}
            onClick={() => { history.push("/login") }}
        >
            <CloseIconWrapper align="center" justify="center">
                <CloseIcon role="button" onClick={onClose} ionBackButton={onClose} />
            </CloseIconWrapper>
            <Container>
                <SadIconWraper><Sad fill={mainColor} /></SadIconWraper>
                <Heading3 >{t('data.account_deleted ')}</Heading3>
                <Text2>{t('data.sorry')}</Text2>
                <FlexContainer justify="center" style={{ marginTop: "50px" }}>
                    <ButtonBase bgcolor={mainColor} onClick={() => { history.push("/login") }}><span>{t('data.send_feedback')}</span></ButtonBase>
                </FlexContainer>
            </Container>
        </StyledModal>
    )
}

const CloseIconWrapper = styled(FlexContainer)`
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${theme.colors.greys.grey05};
  position: absolute;
  right: 20px;
  top: 20px;
`;

const CloseIcon = styled.div`
  background-image: url(${closeIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`;

const Container = styled.div`
    padding: 60px 32px;
    text-align: center;
`;

const SadIconWraper = styled.div`
  margin: 0 auto;
	width: 112px;
	height: 112px;
	min-width: 20px;
	min-height: 20px;
	margin-bottom: 20px;
`;

const StyledModal = styled(IonModal)`
    --border-radius: 4px;
`;

export default withTranslation()(withRouter(observer(FeedbackModal)));

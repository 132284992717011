import React from "react";
import defaultAvatar from "../../../assets/icons/avatar.svg";
import { IonAvatar } from "@ionic/react";

function Avatar(props) {
  const {
    src = defaultAvatar,
    size = '64px',
    alt = '',
    onClick = ()=>{},
    margin = '0px',
  } = props;

  const avatarStyle = {
    margin: margin,
    width: size,
    height: size,
    cursor: props.onClick ? 'pointer' : 'default' // Set cursor pointer if user set an onClick listenner
  }

  return (
    <>
      <IonAvatar onClick={onClick} style={avatarStyle}>
          <img
              onError={(ev) => { ev.target.src = defaultAvatar }}
              src={src}
              alt={alt}
          />
      </IonAvatar>
    </>
    
  );
}

export default Avatar;

import travelerAppStore from "./travelerAppStore";
import destinationsStore from "./destinationsStore";
import brandedSettingsStore from "./brandedSettingsStore";
import messagesStore from "./messagesStore";

export default {
  travelerAppStore,
  destinationsStore,
  brandedSettingsStore,
  messagesStore
}
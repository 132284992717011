import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Timer from "./Timer";
import VerificationCodeInput from "./VerificationCodeInput";
import { trackEvent } from "../../tracking";
import { IonBackButton } from "@ionic/react";
import { Heading2, Paragraph } from "../../components/styled/Typography";
import { BackButton } from "../../components/styled/Buttons";
import AuthenticationService from '../../services/authentication.js';
import { StyledCard, StyledContent, StyledPage } from "../../components/styled/IonElements";
import theme from "../../theme";
import { withTranslation } from "react-i18next";

const COUNT_DOWN_SECONDS = 59;
class VerificationCodeInputPageSMS extends Component {

  constructor(props) {
    super(props);

    this.state = {
      input: []
    }
  }

  onBackButtonPress = () => {
    const { history: { goBack } } = this.props;
    trackEvent("traveler app", "login", "back");
    goBack();
  }

  onResendSMSButtonPress = () => {
    const { travelerAppStore: { keepTrackOfCountDown } } = this.props;
    keepTrackOfCountDown(COUNT_DOWN_SECONDS);
    trackEvent("traveler app", "login", "resend sms");
    this.resendCode("sms");
    // NotificationToast(codeResent);

    this.setState({
      input: []
    })
  }

  onLoginSuccess = async (data) => {
    trackEvent("traveler app", "login", "success");
    // TODO: add await
    await AuthenticationService.onLoginSuccess(data);
    this.props.history.push("/trip");
  }

  resendCode = (param) => {
    const { location } = this.props;
    const { travelerAppStore: { codeRequest } } = this.props;
    const formData = new FormData();
    formData.append("email", location.state?.email);
    formData.append("message_method", param);
    formData.append("new_login", true);
    codeRequest(formData);
  }

  login = (code) => {
    const { travelerAppStore: { login }, location } = this.props;
    const loginData = new FormData();
    loginData.append("email", location.state?.email);
    loginData.append("code", code);
    trackEvent("traveler app", "login", "attempt");
    login(loginData)
      .then((data) => {
        this.onLoginSuccess(data);
      })
      .catch(() => { });
  }

  componentWillUnmount() {
    const { travelerAppStore: { setLoginError } } = this.props;
    setLoginError({});
  }

  render() {
    const { history: { goBack }, travelerAppStore: { loginError, timerCompleted }, location, t } = this.props;
    const { error } = loginError.get();
    const completed = timerCompleted.get();
    const { input } = this.state;

    return (
      <StyledPage style={{ overflow: "auto" }}>
        <StyledContent>
          {/* <StyledLoading
            cssClass='loading-modal'
            isOpen={isLoading}
            message={t('please_wait')}
            duration={5000}
          /> */}
          <StyledCard height="400px" margin="20px" padding="10px">
            <BackButton style={{ margin: "20px" }}
              onClick={() => goBack()}
            />
            <IonBackButton />
            <Heading2 align="center">{t('sign_in.sms_code')}</Heading2>
            <VerificationSMSParagraph maxWidth="100%">{`${t('sign_in.sms_code_sent')} ${location.state?.phoneNumber}`}</VerificationSMSParagraph>
            <VerificationCodeInput login={this.login} key={input} input={input} setInput={(e) => this.setState({ input: e })} />
            {error && <Paragraph maxWidth="100%" weight="700" margin="10px" color={theme.colors.accent.red}>{error}</Paragraph>}
            {completed ? <Button maxWidth="100%" weight="700" completed={completed} margin="20px" onClick={this.onResendSMSButtonPress}> <span>{t('sign_in.resend_sms')}</span></Button >
              :
              <Button maxWidth="100%" weight="700" margin="20px" completed={completed} ><Timer /></ Button>}
          </StyledCard>
        </StyledContent>
      </StyledPage>
    )
  }
}

const Button = styled(Paragraph)`
    ${(props) => !props.completed ? "Disabled" : ""};
    margin-top:${(props) => props.margin};
`;
const VerificationSMSParagraph = styled(Paragraph)`
    margin-bottom: 40px;
    margin-top: 0;
`;


export default withTranslation()(withRouter(inject("travelerAppStore")(observer(VerificationCodeInputPageSMS))));


import React from "react";
import StickyHeader from "../../../components/common/StickyHeader";
import { StyledCard, StyledContent, StyledPage } from "../../../components/styled/IonElements";
import { DesktopFlexContainer, DesktopList, DesktopRightContainer } from "../../../components/styled/Wrappers";
import { useStores } from "../../../stores/useStores";
import GuestUser from "../GuestUser";
import LoggedInUser from "../LoggedInUser";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const TermsAndConditionsEnglish = ({ partnerId, history, t }) => {
    const { travelerAppStore: { isAuthorised } } = useStores();
    
    if (partnerId === 7580) {
        //Layovers
        return (
            <StyledPage>
                {window.innerWidth < 768 && <StickyHeader goBack={() => history.push(window.location.href.split("/terms_and_conditions")[0].replace(window.location.origin, ""))} title={t('legal.terms')} subtitle="" />}
                <StyledContent>
                    <DesktopFlexContainer align="flex-start">
                        <DesktopList padding="0">
                            {isAuthorised.get() ? <LoggedInUser isSide /> : <GuestUser isSide />}
                        </DesktopList>
                        <DesktopRightContainer >
                            <StyledCard margin="10px" padding="20px 15px">
                                <h1 style={{marginTop: "40px"}}>
                                    GENERAL TERMS &amp; CONDITIONS OF USE OF THE PLATFORM &amp; THE SERVICE UNDER THE TRADE NAME «WELCOME PICKUPS»
                                    FOR TRAVELLERS
                                </h1>
                                <p className="simple-text">
                                    <strong>
                                        <em>CONTENT</em>
                                    </strong>
                                </p>
                                <ol>
                                    <li>
                                        <strong>IN GENERAL</strong>
                                    </li>
                                </ol>
                                <ol>
                                    <li>
                                        <strong>TERMS &amp; CONDITIONS FOR THE TRANSPORT SERVICES&nbsp;</strong>
                                    </li>
                                </ol>
                                <ol>
                                    <li>
                                        <strong>Description of Transport Services&nbsp;</strong>
                                    </li>
                                    <li>
                                        <strong>Legal Status of the Company&nbsp;&nbsp;&nbsp;</strong>
                                    </li>
                                    <li>
                                        <strong>Policies of Transport Services&nbsp;</strong>
                                    </li>
                                    <li>
                                        <strong>Declarations of Travellers</strong>
                                    </li>
                                    <li>
                                        <strong>Company’s rights- Limitation of Liability&nbsp;</strong>
                                    </li>
                                    <li>
                                        <strong>Charges and Payment Policy&nbsp;</strong>
                                    </li>
                                    <li>
                                        <strong>Travellers’ opinion&nbsp;</strong>
                                    </li>
                                    <li>
                                        <strong>Disclaimer</strong>
                                    </li>
                                </ol>

                                <h2>I. IN GENERAL</h2>

                                <p className="simple-text">
                                    The following General Terms and Conditions of Use of the Platform and Service (hereinafter referred to as the
          «<strong>General Terms of Use</strong>» or «<strong>General Terms</strong>») have been established by
          the&nbsp; Limited Liability Company under the name “<strong>WELCOME TRAVEL TECHNOLOGIES HOLDINGS LTD</strong>
          ”, with no 10879236, headquartered in c/o Harrison Beale &amp; Owen Seven Stars House, 1 Wheler Road, Coventry
          CV3 4LB, UK with VAT np: 289 0786 46, email{" "}
                                    <a href="mailto:hello@welcomepickups.com">hello@welcomepickups.com</a> (hereinafter referred to as &#8221; the
          “<strong>Company” </strong>or <strong>“We” </strong>or<strong> “us” </strong>or <strong>“our</strong>”), which
          operates and manages the web platform under the trade brand name “Welcome Pickups” (hereafter referred to as
          the &#8220;<strong>Platform</strong>&#8220;), through which tourists/travellers (hereinafter “
          <strong>Travellers</strong>”, “<strong>You</strong>”, “<strong>Yours</strong>”, “<strong>User</strong>”) make
          a booking of a transport service as defined herein (hereinafter “<strong>Transport Services</strong>”)
          operated by drivers of Private and Public Use Vehicles who have an Account in the Platform (hereinafter the “
          <strong>Drivers</strong>”) through which they receive by Us, acting as intermediary between You and the
          Drives, notifications and requests for the provision of Transport Services to You (hereinafter “
          <strong>Service</strong>”).
        </p>

                                <p className="simple-text">
                                    The following General Terms of Use of the Platform and the Service (hereinafter “<strong>Terms of Use</strong>
          ”, or “<strong>General Terms of Use</strong>”) constitute the legally binding terms and conditions applicable
          to Your access and use of the Platform and the Service, including any use of software, services, features,
          functions, content, website(s) and hyperlinks and / or any other applications provided from time to time by
          the Company in the Platform and/or in the Service.
        </p>

                                <p className="simple-text">
                                    Before any use of the Platform and the Service, You are advised to read the present Terms of Use and make sure
                                    You agree with them. In case You disagree with any term and / or all of them, You should not use the Platform
                                    and the Service. In case You have any inquiries or You need any clarification and/or information regarding the
                                    Terms of Use, or You have any prejudice or relevant inquiry, You can contact Our Customer Support Team at the
          phone numbers shown in the website <a href="http://www.welcomepickups.com">www.welcomepickups.com</a> or via
          the email <a href="mailto:hello@welcomepickups.com">hello@welcomepickups.com</a>, before any use of the
          Platform. By using the Platform you warrant and acknowledge that You have reviewed, understood and accept
          these General Terms of Use unconditionally and without any prejudice and that You are bound by them.
        </p>

                                <p className="simple-text">
                                    Our Company reserves the right to amend, modify, renew, delete, add or restrict unilaterally (a) these Terms
                                    of Use in whole or in part; (b) its Policies; (c) the Service; (d) the Technical Specifications of the
          Platform or other (hereinafter referred to as the &#8220;<strong>Changes</strong>&#8220;) at its sole
          discretion and / or when any amendment, as above, is required by law, possibly without Your prior notice or
          consent, nevertheless within the context of the business ethics and limits set forth by law. The Company
          undertakes the obligation to notify You any Changes, through the Platform by a prominent notice when the Users
          enters the Platform. Any Changes will be effective from the date of their posting. It is clarified that any
          change to these Terms of Use does not cover bookings or other transactions and uses of the Platform and the
          Service which have been made before the entry into force of the Changes and been accepted by our Company.
          However, in case the Users wish any clarification or information regarding the Changes, or in case they have
          any dispute, reservation or enquiry related to these Changes, prior to any action made by them on the
          Platform, they should contact Our Customer Support team. Any action, use or transaction made by the Users on
          the Platform, including the use of the Service itself, following the Changes, is considered as an
          unconditional acceptance thereof.
        </p>

                                <p className="simple-text">
                                    It should be noted that any information / clarification granted by the Customer Support regarding the Terms of
                                    Use does not constitute a replacement, substitution or any modification of these Terms of Use, as they are
                                    provided solely for the purpose of assisting the Users whilst the Terms Use constitute our sole and exclusive
                                    agreement.
        </p>

                                <p className="simple-text">
                                    The Company reserves the right at any time, without providing any justification and without prior notice to
                                    the User, to cancel, suspend or terminate the operation of the Service, to revoke or customize the Platform,
                                    or certain features of the Platform and the Service. The Users are solely and exclusively responsible and
                                    liable with regard to the use of the Platform and the Service.&nbsp;
        </p>

                                <p className="simple-text">
                                    You acknowledge and understand that You must comply with the Terms of Use of the provider which co-operating
                                    with Your device (referred to hereinafter as the &#8220;Provider’s Terms of Use&#8221;). The Provider’s Terms
                                    of Use are considered to be incorporated herein. The Users are solely and exclusively responsible and liable
                                    as far as the obligation to determine the Provider’s Terms of Use applicable to their Device is concerned.
        </p>

                                <p className="simple-text">
                                    BY ACCEPTING THESE TERMS IN ACCORDANCE WITH THE ABOVEMENTIONED, THE USERS (AS WELL AS ANY THIRD PARTY ACTING
                                    ON THEIR BEHALF (the “AUTHORISED USERS”) EXPRESSLY AND UNCONDITIONALLY DECLARE THAT THEY HAVE THE LAWFUL AGE
                                    WHICH ALLOWS THEM TO BE BOUND BY THESE GENERAL TERMS AND HAVE OBTAINED ALL THE NECESSARY APPROVALS AND
                                    LICENSES FOR THEIR REGISTRATION IN THE PLATFORM AND THE USE OF THE SERVICE.
        </p>

                                <h2>1. Description of Transport Services to Travelers&nbsp;</h2>

                                <p className="simple-text">
                                    1.1 Booking a Transport Service with Us, means that a Driver will become the person who will greet and welcome
                                    You to their city, and / or will transfer You during Your stay.
        </p>

                                <p className="simple-text">
                                    In particular, the transport services that You can book through the Platform, are the following (hereinafter
          referred to as &#8220;<strong>Transport Services</strong>&#8221; or <strong>“Pickups” or “Transfer”</strong>):
        </p>

                                <p className="simple-text">1.1.1 Travellers’ Transport from and to airports.</p>

                                <p className="simple-text">1.1.2 Travellers‘ Transport from and to sightseeings.</p>

                                <p className="simple-text">
                                    1.1.3 Travellers’ Transport from and to a point of interest in the city, including stop(s) over (for food,
                                    activity, photos, etc.).
        </p>

                                <p className="simple-text">
                                    1.1.4 Travellers’ Transport while in tour and transport from and to places of activities based on their
                                    interests.
        </p>

                                <p className="simple-text">
                                    1.2 At the point You will be welcomed by the Driver, You will receive products that You may have purchased
                                    from Us. and/or products that the We distribute free of charge (souvenirs, local sweets, city maps,
                                    etc.).&nbsp;
        </p>

                                <p className="simple-text">
                                    1.3 To make Your pick ups a personal experience for You, We will forward You the Driver’s name, surname and
                                    their&nbsp; mobile phone and/or their photo in case We have been provided with this.&nbsp;
        </p>

                                <p className="simple-text">
                                    <strong>
                                        We are not a licensed private hire operator, nor a transportation carrier and WE DO NOT provide the
                                        Transport Services. We act solely as an intermediary between You and the Driver. By booking Your Pickup
                                        through the Platform, You enter into a direct and legally binding contractual relationship with the Driver
                                        who accepts Your Pickup
          </strong>
          . The Company provides information and a method to facilitate the Transport Services, but does not intend to
          provide Transport Services or act in any way as a transportation carrier, therefore has no responsibility or
          liability for any Transport Services provided to You by the Drivers.
        </p>

                                <h2>2. Legal Status of the Company&nbsp;</h2>

                                <p className="simple-text">
                                    2.1 Our Company provides electronic or telephone mediation services for the transport of Travellers. As an
                                    e-mediator body in the Transport Service that Drivers carry out, we act solely as intermediaries between the
                                    Travellers and the Drivers. Through its Platform, the Company provides mediation services for the provision of
                                    Transport Services from Drivers to Travelers. Actually, the Company does not itself provide nor could it be
                                    considered to provide the Transport Services by itself, since it is not a licensed transport operator. In case
                                    that You make a booking of a Transport Service through the Platform, You acknowledge that you are entering
                                    into a contract for the provision of the Transport Service solely with the Drivers and You and the Driver bear
                                    sole liability for the fulfillment of the provision of the Transport.
        </p>

                                <p className="simple-text">
                                    2.2 The Company does not provide transport services, and it is not involved in the relationship between You
                                    and the Driver who Picks You Up, as the contractual relationship for the provision of Transport Service, is
                                    established exclusively between You and the Driver who undertakes to provide You with Transport Services. In
                                    no way does the Company participate as a contracting party in these contracts, nor does it obtain any rights
                                    or obligations deriving from such contracts. The Company assumes no responsibility for the Travelers’
                                    transport, for which Drivers are solely responsible. As a result, the Company, its representatives, employees
                                    and assistants are not liable in any way for the risks associated with the conclusion, execution and
                                    completion of the transactions between the Travelers and Drivers and are not liable in any way for any damages
                                    deriving from such transactions. Besides, the choice of whether to book a Pick Up through the Platform, is at
                                    Your sole discretion. However, if You accept it, You are bound and warrant that You will meet all obligations
                                    arising out of these Terms of Use.
        </p>

                                <p className="simple-text">
                                    2.3 The Company does not represent You or the Driver, so it does not provide any guarantees regarding the
                                    quality of the Transport Services, the completion of the Transfer, Travelers’ and Drivers’ behavior. In
                                    addition, the Company does not represent or act on behalf of any transport company.
        </p>

                                <p className="simple-text">
                                    2.4 Please note that Drivers do not have an employment relationship with the Company or its partners. The
                                    Platform constitutes a method of linking You to Drivers. In addition, the Company does not provide any
                                    insurance to Drivers or to their vehicles and assumes no liability if You lose Your staff while on a Pick
                                    Up.&nbsp;
        </p>

                                <p className="simple-text">
                                    2.5 The Company may refer Drivers to You for the provision of the Transport Services. It will not evaluate the
                                    suitability, legality or ability of any Driver and You expressly waive and relieve the Company of any and all
                                    claims, liabilities or damages arising from, or are in any way related to, the Driver. The Company shall not
                                    be part of any dispute between You and the Drivers. By booking Transport Services, You acknowledge that the
                                    Company has no involvement in the contractual relationship between the Driver and You, however the Transport
                                    Services are governed by these General Terms.
        </p>

                                <p className="simple-text">
                                    2.6 The inter-Mediation services We provide are only available for personal, non-commercial use. Therefore, it
                                    is not permitted to resale, distribute through deep-link, use, copy, track (e.g. through spider, scrape),
                                    display, download or reproduce any content or information, software, products or services available at the
                                    Platform, for any commercial or competitive activity or purpose.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3. Policies of the Layover Services</strong>
                                </p>

                                <p className="simple-text">
                                    <strong>3.1 Booking Policy</strong>
                                </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    To book Your transfer, You need to provide Us with some information such as Your personal details and contact
                                    points (email – mobile), date and time, Your flight number, so We can make arrangements in Your favor in case
                                    there is a delay, the number of passengers and their pieces of luggage in order to arrange for the right
                                    Vehicle to pick You up, and also Your payment information. You have to accept Our Terms of Use and Privacy
                                    Policy, should You wish to make a booking for Your Transfer&nbsp; with Us.
        </p>

                                <p className="simple-text">
                                    When a booking Transfer&nbsp; is placed with Us, You will receive a confirmation email of receipt of Your
                                    booking and We will provide You with Your Booking code and the meeting point at the Pickup place. We will
                                    examine whether We can serve the Transfer&nbsp; as a whole, or We have grounds to reject it, according to the
                                    provisions of the present General Terms of Use. Once a Welcome Driver has been assigned to operate your
                                    Transfer we will provide You with their name Important Legal Notice: Please note that completion of the online
                                    booking process along with the acknowledgement email We send to You, does not constitute Our acceptance of
                                    Your offer to book a Transfer through Us, even though on the acknowledgement email of Your offer, the Booking
                                    code is depicted. Our acceptance of Your offer to provide through Our Platform with Transfer , will take place
                                    only when.&nbsp;
        </p>

                                <p className="simple-text">
                                    We take payment from You (i.e. when Your card or PayPal account is credited). We reserve the right to decline
                                    a Booking Order for any reason, including legal and regulatory reasons. If we cannot provide You with the
                                    Transport Service, We will not process Your order, We will inform You as soon as possible and, if You have
                                    already paid for the products we cannot deliver, refund You in full in a timely manner.
        </p>

                                <p className="simple-text">
                                    You are solely responsible for submitting Us Your real personal data when booking a pick up with through Us.
                                    You also warrant and represent that You are the holder or have legal rights on the payment cards/ accounts
                                    details You provide Us with and that there is enough money to cover the value of the Transfer Service. We may
                                    proceed (however, are not obliged to) in an examination process of the payment details You provide Us with
                                    when choosing credit card as a payment method or via Online Providers. In case an error, misleading statement
                                    or other problem occurs, We may, at Our sole discretion, reject Your Order.
        </p>

                                <p className="simple-text">
                                    <strong>
                                        You are solely responsible for the adequate selection of the Transfer according to the arrival and the
                                        departure time between the flights. The Company has no liability in case You miss the connecting flight due
                                        to the preselected Transfer.&nbsp;&nbsp;
          </strong>
                                </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong></strong>In order to book a Transfer with us we suggest that Your minimum Duration time between
          flights should be 6 hours.
        </p>

                                <p className="simple-text">
                                    You can make an online booking a Transfer with us until 10 hours prior the scheduled arrival time at Athens
                                    International Airport.&nbsp;
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    When traveling to/from non- Schengen destinations, Passengers must have a valid Schengen visa to leave the
                                    airport and get back
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.2 Traveller Cancellation Request Policy</strong>
                                </p>

                                <p className="simple-text">
                                    You have the right to cancel a Transfer&nbsp; already booked through Us, at any time by contacting the Company
                                    either by phone call at the phone number provided in the confirmation email or in the website or email sent to
                                    hello@welcomepickups.com.
        </p>

                                <p className="simple-text">
                                    If a cancellation request is received by Us up to 48 hours prior to the scheduled pick up time, We will fully
                                    refund You in total.
        </p>

                                <p className="simple-text">
                                    If a cancellation request is received by the Company between 48 hours and 24 hours prior to the scheduled pick
                                    time, We will charge You a cancellation fee equal to 9% of the Transfer fee
        </p>

                                <p className="simple-text">and refund You with the remaining amount paid, as soon as reasonably possible.</p>

                                <p className="simple-text">
                                    If a cancellation request is received by the Company within less than 24 hours prior to the scheduled pick up
                                    time, We will not refund You as this is a last minute and non refundable cancellation.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.3 Traveller Change Request Policy</strong>
                                </p>

                                <p className="simple-text">
                                    Extension of ride duration, additional services or amendments cannot be made.&nbsp;
        </p>

                                <p className="simple-text">Pick up and drop off time and location cannot be changed.</p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.4 Complimentary Waiting Time Policy</strong>
                                </p>

                                <p className="simple-text">
                                    Your Welcomer will be waiting for You at the Pick Up place at the time that is scheduled in the Booking Order,
                                    subject to the provisions of this Complementary Waiting Time Policy so You must make sure that You show up in
                                    time, to avoid being marked as a non-show where all the Terms of the Travellers’ No – Show Policy apply.&nbsp;
        </p>

                                <p className="simple-text">
                                    The Company offers the following complimentary waiting time (if the duration time between connecting flights
                                    permits it):
        </p>

                                <p className="simple-text">Airport: 15 minutes</p>

                                <p className="simple-text">
                                    The complimentary waiting time starts counting from the scheduled pickup time.For an airport where the pickup
          time is the flight arrival time and the flight{" "}
                                </p>

                                <p className="simple-text">
                                    arrives earlier than the scheduled time, the scheduled pickup time remains the initial scheduled pickup time.
        </p>

                                <p className="simple-text">
                                    For an airport pickup where the Travellers are arriving with more than one flight, the Company considers the
                                    scheduled pickup time to be the one declared by the Traveller as the arrival time during the booking, or an
                                    updated arrival time as per the Traveller Change Request Policy. Any notes left by the Traveller during the
                                    booking mentioning several flights do not constitute a scheduled pickup time adjustment. In the occasion where
                                    a group of Travellers arriving with multiple flights landing at different times and then depart with the same
                                    connecting flight and&nbsp; have booked a Transfer service, it is suggested that they adding the flight
                                    details the flight arriving last.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.5 Traveller No-Show Policy</strong>
                                </p>

                                <p className="simple-text">
                                    Traveller No-Show (TNS) is defined as a Traveller not being at the pickup location by the scheduled pick up
                                    time as per, that has been agreed between the Traveller, the assigned Driver and the Company as per the
                                    Complementary Waiting Time Policy.
        </p>

                                <p className="simple-text">
                                    The pickup location is defined as the place where the Traveller has asked to be picked up from.
        </p>

                                <p className="simple-text">
                                    In case of airport pickup, the Company notifies You via the booking confirmation email the exact pickup
                                    location where the Driver will be waiting for You (i.e. Arrivals hall, Departure hall,etc.).
        </p>

                                <p className="simple-text">
                                    In the occasions where the Traveller fails to appear at the pickup location by the end of their Scheduled pick
                                    up time, the Transfer is marked as a “Traveller No Show” (“TNS”). In this case no amount is refunded to the
                                    Traveller.
        </p>

                                <p className="simple-text">
                                    If You are not at the pickup location at the scheduled pickup time, the assigned Driver will try to contact
                                    You via text/Whatsapp/Viber or he will try to call You on Your Mobile. By booking a Transfer You consent to Us
                                    providing Your mobile phone number to Your Welcomer.
        </p>

                                <p className="simple-text">
                                    A possible TNS will be reported by Your Welcomer to the Company, at the following times:
        </p>

                                <p className="simple-text">
                                    ● For airport pickups by 15 minutes of a Traveller’s delay to appear at the pickup location after the
                                    scheduled pickup time Upon a Driver’s notification of a possible TNS, the Company will try to contact You (via
                                    call and/or text/Whatsapp/Viber and/or email) in an effort to locate You. These efforts will be carried out
                                    until the end of Your complimentary waiting time as per these present Terms of Use.
        </p>

                                <p className="simple-text">By the end of Your complimentary waiting time, the following will happen:</p>

                                <ul>
                                    <li>
                                        If You have not been reached (have not replied) , We must “release” the Driver. On this occasion, the
                                        Transfer is marked as a “TNS” .
          </li>
                                    <li>
                                        If You have been reached and have communicated that You will be at the pickup location by the end of the
                                        complimentary waiting time, We must inform the Driver to wait.
          </li>
                                    <li>
                                        If You fail to appear by the end of the complimentary waiting time, We will make one final effort to reach
                                        out to You.
          </li>
                                </ul>

                                <p className="simple-text">
                                    You have 24 hours to dispute a TNS by contacting Welcome’s Customer Support. In the occasion You dispute a
                                    TNS, if the Driver was at the pickup location between the scheduled pickup time and the end of the
                                    complimentary waiting time there is sufficient evidence to assume the Driver was at the pickup location as per
                                    Company’s policies. If there is sufficient evidence to assume that both You and the Driver were at the pickup
                                    location but failed to meet each other and the Transfer was not operated, You will be refunded 80% of the
                                    Transfer price, whereas the 20% will be kept by Us in order to cover partially Driver’s fee, since the Company
                                    is responsible to pay them their fee.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.6 Driver No-Show Policy</strong>
                                </p>

                                <p className="simple-text">
                                    The Driver must be at the pickup location by the scheduled pickup time. If the Driver is to be up
        </p>

                                <p className="simple-text">
                                    to 10 minutes late, they will contact and notify the Traveller (via call and text/Whatsapp) as much in advance
                                    as possible, and the latest by the scheduled pickup time. If the Driver is to be more than 10 minutes late,
                                    they will contact and notify Company ’s CS as much in advance as possible, and the latest by the scheduled
                                    pickup time. Company ’s CS will contact the Traveller and notify them of the expected Driver delay.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.7 Baggage Allowance Policy</strong>
                                </p>

                                <p className="simple-text">
                                    Travelers should consider where to store their luggage between flights before they board for the Transfer.
                                    Baggage storage service can be found at Athens International Airport that provides safe storage at a cost. For
                                    more information please call here: (+30) 210 3530352 (
          <a href="https://www.aia.gr/traveler/airport-information/baggage-services/">
                                        https://www.aia.gr/traveler/airport-information/baggage-services/
          </a>
          ).In case of connecting flights with Aegean Airlines hand luggage can be carried in the car.&nbsp;
        </p>

                                <ul>
                                    <li>
                                        In case passengers want to carry more on their next (connecting) flight please note that extra baggage&nbsp;
            will be charged according to Aegean Airlines pricing policy. For more information please click here:{" "}
                                        <a href="https://en.aegeanair.com/travel-information/baggage/">
                                            https://en.aegeanair.com/travel-information/baggage/
            </a>
            &nbsp;
          </li>
                                </ul>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    <strong>3.8 Traveller Refund Policy</strong>
                                </p>

                                <p className="simple-text">
                                    The Transfer fee paid by a Traveller is refunded in its entirety in the following occasions:
        </p>

                                <p className="simple-text">
                                    ● If the booked Transfer is cancelled by the Company for operational capacity reasons
        </p>

                                <p className="simple-text">
                                    ● If the booked Transfer is cancelled due to a force majeure incident (strike, demonstration, natural
                                    disaster, accident, etc.)
        </p>

                                <p className="simple-text">● If the Transfer cannot be operated at all due to flight cancellation</p>

                                <p className="simple-text">● If the Transfer is cancelled due to a flight delay</p>

                                <p className="simple-text">
                                    ● If the Company receives a Traveller cancellation request up to 48 hours prior to the scheduled pickup time
                                    as per the Traveller Cancellation Request Policy
        </p>

                                <p className="simple-text">
                                    In the occasion of a Traveller Cancellation Request received by the Company between 48 hours and 24 hours
                                    prior to the scheduled pickup time, the Company charges a Cancelation Fee which equals to 9% of the Transfer
                                    price. The remaining amount of the Transfer price is refunded to the Traveller as per the Traveller
                                    Cancellation Policy.
        </p>

                                <p className="simple-text">
                                    In the occasion of a Traveller-disputed “Traveller No-Show”, if there is sufficient evidence to assume that
                                    both the Traveller and Driver were at the pickup location but failed to meet each other and the Transfer was
                                    not operated, the Traveller will be refunded 80% of the Transfer price as per the Traveller No-Show Policy.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">3.9 ADVICE TO INTERNATIONAL TRAVELLERS</p>

                                <ul>
                                    <li>
                                        There are specific customs restrictions in Athens International Airport for passengers entering Greece from
                                        any country outside the Schengen Zone (Extra Schengen), Switzerland, Liechtenstein, Iceland and Norway:
            <ul>
                                            <li>
                                                If you fly to and from airports outside the Schengen Zone, please note that you will have to pass
                                                through passport control before your next flight
              </li>
                                            <li>
                                                If you fly from an airport within the Schengen Zone to a destination outside the Schengen Zone please
                                                note that you will have to pass passport and security control
              </li>
                                            <li>
                                                If you fly from an airport outside the Schengen Zone to a destination within the Schengen Zones, please
                                                note that you will have to pass through passport and security control and also claim your baggage before
                                                your next flight.
              </li>
                                            <li>
                                                According to the above we expect our passengers to be ready for boarding and pass the passport and
                                                security control minimum 1,5 hour before their scheduled departure time. For instance, if we assume that
                                                your departure flight is at 17:00, your selected Transfer should be ending at 14:30.&nbsp;
              </li>
                                        </ul>
                                    </li>
                                </ul>

                                <p className="simple-text">
                                    For more information regarding connecting passengers, please click here:{" "}
                                    <a href="https://en.aegeanair.com/travel-information/at-the-airport/connecting-passengers/">
                                        https://en.aegeanair.com/travel-information/at-the-airport/connecting-passengers/
          </a>
          &nbsp;
        </p>

                                <ul>
                                    <li>
                                        In case passengers travel with pets please note that pets should be carried in their pet carriers. For more
            information please click here:{" "}
                                        <a href="https://en.aegeanair.com/travel-information/special-assistance/traveling-with-pet/">
                                            https://en.aegeanair.com/travel-information/special-assistance/traveling-with-pet/
            </a>
            &nbsp;
          </li>
                                </ul>

                                <h2>4. Travellers’ Rights and Obligations &#8211; Representations &#8211; Warranties</h2>

                                <p className="simple-text">
                                    4.1 &nbsp; &nbsp; <strong>TRAVELLERS’ REPRESENTATIONS-WARRANTIES</strong>: By using the Platform, You agree:
        </p>

                                <ul>
                                    <li>
                                        to provide true, accurate, complete and updated information about yourself at the booking form, and update
                                        them whenever necessary. The Company&nbsp; is not obligated to monitor or control the accuracy of
                                        information provided by You. If any information provided by You is not in accordance with these provisions
                                        or if the Company has reasonable grounds to suspect that such pieces of information do not agree, the
                                        Company has the right to refuse Your registration, and suspend or terminate immediately, regardless of
                                        notice, the registration of Your account and refuse any and every use, present or future, of the Services
                                        that require registration. In this case, You shall not be entitled to any indemnity or compensation for the
                                        refusal, suspension or cancellation of Your registration.
          </li>
                                    <li>
                                        that You will not use the Platform: for any unlawful purpose; in any way that interrupts, damages, impairs
                                        or renders the Company less efficient; to transfer files that contain viruses, trojans or other harmful
                                        programs; to access or attempt to access the accounts of other users or to penetrate or attempt to penetrate
                                        any security measures; to disseminate any content which is defamatory, obscene, or may have the effect of
                                        being harassing, threatening or abusive to an individual or group of individuals on the basis of religion,
                                        gender, sexual orientation, race, ethnicity, age or disability or otherwise; to advertise or promote third
                                        party or Your own products or services;
          </li>
                                    <li>
                                        to refrain from doing anything which we reasonably believe to be disreputable or capable of damaging our
                                        reputation
          </li>
                                    <li>
                                        and acknowledge that we have limited control over the nature and content of information&nbsp; received by
                                        You or other users of the Platform. Although we reserve the right to do so, we do not monitor such content
                                        in the usual course of business and will not be liable for any such content. If You have a complaint about
                                        another user please contact us via the Platform;
          </li>
                                    <li>
                                        to comply with all applicable laws from Your home nation, the country, state and city in which You are
                                        present while using the software or Service;
          </li>
                                    <li>
                                        to treat Drivers introduced to You through the Platform with respect and not to cause damage to their
                                        Vehicle;
          </li>
                                    <li>
                                        to compensate and defend us fully against any claims or legal proceedings brought against us by any other
                                        person as a result of Your breach of this agreement.
          </li>
                                    <li>
                                        that the email (e.g xxx@yahoo.com) that You use to register on the Platform shall not resemble to Company’s
                                        company name. Also log in passwords deemed offensive may be deleted, as well as containing a URL or email
                                        address.
          </li>
                                    <li>that You shall not use the Service or software to cause nuisance, annoyance or inconvenience</li>
                                    <li>
                                        that by accessing and using the Services via mobile devices, or by using certain mobile features, You may
                                        incur fees from the provider or carrier of the mobile services that You use (“Carrier”) and You are solely
                                        responsible for the payment of such fees.
          </li>
                                </ul>

                                <p className="simple-text">
                                    4.2&nbsp; &nbsp; You acknowledge and agree that You must avoid any action that may cause traffic safety
                                    problems. Particularly, You warrant and represent that You will:
        </p>

                                <ul>
                                    <li>&nbsp;not in any way hinder the Driver from driving safely during Your transfer,</li>
                                    <li>&nbsp;not attempt to disembark or board the vehicle when it is moving,</li>
                                    <li>&nbsp;not smoke,</li>
                                    <li>&nbsp;not open windows and doors without the Driver&#8217;s consent;</li>
                                    <li>
                                        be decent, courteous and not display inappropriate, abusive, provocative or threatening behavior in any way
                                        during the Transfer;
          </li>
                                    <li>not take any action that may cause damage to the Driver’s Vehicle,</li>
                                    <li>take care that the children, animals and objects to be transferred are done so with responsibility,</li>
                                    <li>Not throw objects inside or outside the Vehicles or pollute them in any way,</li>
                                    <li>
                                        Carefully observe the instructions for traveling with young children and for the transport of infant and kid
                                        strollers, luggage, bicycles and pets,
          </li>
                                    <li>not consume food and drinks throughout Your Transfer and until the end of the Transfer.</li>
                                    <li>not embark on a Vehicle with dirty or contaminated clothing</li>
                                </ul>

                                <p className="simple-text">
                                    4.3 In cases where You carry animals and/or objects during the Transfer and the Vehicle is damaged/destroyed
                                    or gets dirty somehow due to them, You undertake the obligation to pay for amy such damages.&nbsp;
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    4.4. You also warrant that You have fully read and understood these Terms of Use, the Policy of Acceptable
                                    Uses, the Privacy Policy and accepts them fully and unconditionally and that You meet all the legal
                                    requirements for the acceptance of these Terms.
        </p>

                                <p className="simple-text"></p>

                                <p className="simple-text">
                                    4.5 You warrant and represent that at Your own responsibility and expense, You shall immediately take all
                                    necessary measures and in any case fully compensate, indemnify and hold harmless the Company, its Affiliates
                                    and its Partners and any third party that derives rights therefrom (partners, employees, administrators,
                                    suppliers, agents, representatives of the same and its associates) for any claim, loss, damage, cost,
                                    expenses, liabilities, deviations, penalties, fines, lawyer fees We have suffered as a result of (a) any
                                    violation by You of these Terms of Use and / or the Policy of Acceptable Uses, of Your representations and
                                    warranties under this Agreement, of intellectual property or other rights of the Company; (b) any other action
                                    or omission that is illegal while using the Service; (c) violation of applicable law on Your behalf.
        </p>

                                <h2>
                                    5. <strong>Company’s Rights</strong>
                                </h2>

                                <p className="simple-text">
                                    5.1 <em>&nbsp;&nbsp;</em>
                                    <strong>Definite deactivation (deletion) of the Platform</strong>
                                    <em>: </em>Save as provided in these General Terms the Company expressly reserves the right to delete the
          Platform and the Service&nbsp; respectively, in the following cases at the Company&#8217;s absolute
          discretion: a) in case You violate the Acceptable Use Policy of the Platform, and / or b) violates any term or
          provision mentioned and at the Company&#8217;s absolute discretion this violation causes immediate risk-loss
          to the Company and / or to any other User of the Platform c) has committed, at the Company&#8217;s absolute
          discretion, a penal or other offence, during the use of the Platform&nbsp; and the Service and / or the
          Transport Services and/or d) for any other important reason referred to herein.
        </p>

                                <p className="simple-text">
                                    5.2&nbsp; In the above cases, the Platform/Service is deactivated immediately and automatically, without the
                                    Company providing to the Traveller the right to remove the violation, expressly reserving all its legal
                                    rights, including the right to compensation for any damage that may have been suffered by the acts and / or
                                    omissions of its Users according to the above mentioned.
        </p>

                                <p className="simple-text">
                                    5.3&nbsp;{" "}
                                    <strong>
                                        <em>T</em>
                                    </strong>
                                    <strong>
                                        <em>emporary de-activation (deletion) of the Platform</em>
                                    </strong>
                                    <em>:</em>
                                    <em> </em>Save as provided in these General Terms regarding the deactivation (deletion) of the Platform, the
          Company expressly reserves its right at its sole discretion to temporarily deactivate the Platform/Service,
          directly and without any penalty in the event that it considers that the Traveller has breached or has
          violated any applicable law and until the Traveller removes the breach within the time period drawn by the
          Company, at its sole discretion. In the event of the Traveller’s non-compliance, the Company may definitively
          deactivate the Platform for the Traveller, expressly reserving all its lawful rights, including the right for
          compensation of any damage suffered by the&nbsp; User&#8217;s actions and / or omissions in accordance with
          the above mentioned.
        </p>

                                <p className="simple-text">
                                    5.4 &nbsp; It is expressly agreed that in the event of a permanent or temporary deactivation (deletion) of the
                                    Platform as per articles 5.1 and 5.2 above is based on automated means, the user is not entitled to claim
                                    compensation of any damage caused by the deactivation of the Platform/Service, in accordance with the above
                                    provisions, even if the evidence on which the Company was based for the deletion of the Platform was incorrect
                                    since the user acknowledges and accepts that the automated means used by the Company to identify any illegal
                                    or contract breaching actions of users are based either on generalised / automated electronic commands of a
                                    software program that may include and / or make errors or on complaints by other Users.
        </p>

                                <p className="simple-text">
                                    5.5 &nbsp; <strong>T</strong>
                                    <strong>ermination of the Platform s Function:</strong>
                                    <em> </em>The Company reserves the right, for technical reasons, maintenance and / or upgrade of the Platform,
          to discontinue the function of the Platform/Service temporarily and at regular intervals. In the event that
          this interruption lasts more than a reasonable period of time at the absolute discretion of the Company or
          such interruption has already been scheduled by the Company, the latter will notify its Users. It is expressly
          agreed that Users may not raise any claim against the Company for any loss or damage whatsoever arising out of
          this reason even if the Company, at its sole discretion, does not give notice of such interruption.
        </p>

                                <p className="simple-text">
                                    5.6&nbsp; Except as expressly set forth herein, the Company may at any time terminate, change, suspend or
                                    discontinue any particular function of the Platform, including the availability of the Service itself,
                                    provided that it notify Travellers in writing within reasonable notice period before the termination date as
                                    per this article.
        </p>

                                <p className="simple-text">
                                    5.7 &nbsp; <strong>Limitation of warranties</strong>: The Company, its directors, shareholders, employees,
          providers, partners always act in good faith in the context of the law and the present General Terms of Use.
          Hence it has take all technical and other measures and uses its best endeavours to ensure that (a) the
          Platform operates continuously and properly without any problems, interruptions, delays, errors or mistakes;
          (b) defects and/or mistakes are corrected, (c ) data/information transferred through the Platform&nbsp; (d)
          the technology used by the Company or the Servers, through which the Service is made available to the Users,
          does not contain viruses or other harmful components, however THE COMPANY DOES NOT PROVIDE ANY RESPECTIVE
          WARRANTIES for all of the above mentioned.
        </p>

                                <p className="simple-text">
                                    5.8 &nbsp; In addition, the Company does not provide any warranty for (a) the appropriate, effective, and
                                    efficient function of the Platform with regard to the purpose for which the Traveller uses it; (b) the
                                    compatibility of the Platform with third party software that Driver may use, unless these (software) are
                                    provided by the Company and only to the extent the Company has guaranteed for them in the relevant licenses
                                    provided to the Travellers (c) the proper and efficient execution of the Drivers’ transaction Obligation.
        </p>

                                <p className="simple-text">
                                    5.9&nbsp; <strong>&nbsp;Limitation of Obligations</strong>: The Platform and the Service is provided &#8220;as
          it is and is made available&#8221; and in a fully automated manner and does not include the importation by the
          Company of any element in the Service&#8217;s system nor any customisations on an ad hoc basis. The
          Application may in the future be parameterised, at the sole discretion of the Company.
        </p>

                                <p className="simple-text">
                                    5.10 The Company shall take all necessary technical and other measures to ensure that Travellers comply with
                                    the present Terms of Use, however, as a Provider of Information Society Services, it has no general nor
                                    statutory obligation to (a) control, (b) actively seek for facts or circumstances that indicate that Traveller
                                    has engaged in an unlawful or contract breaching activity while using the Platform/Service, (c) provision of
                                    technical support and/or education of the Travellers.
        </p>

                                <p className="simple-text">
                                    5.11&nbsp; &nbsp;<strong>Limitation of Liability.</strong>
                                    <strong> </strong>In addition to what is expressly stated herein, the Company has no civil, penal and bears no
          liability towards the Traveller, any Authorised Users, their Affiliates, Employer or employee and / or any
          third party rendering rights whatsoever, in case that any of these persons, during using the Service, suffer
          any damage, loss, direct or indirect, incidental or damage for loss of profits, business, income, reputation,
          sales of products and frequency of services’ provision, or any other direct, indirect or repayable, economic
          or otherwise, loss of profits, due to:
        </p>

                                <p className="simple-text">
                                    &nbsp;&nbsp;&nbsp;5.11.1 Delayed or inappropriate sending, transmission, maintenance, retention, downloading
                                    of any data, information or /and Content and / or loss and destruction of such data due to mistakes,
                                    omissions, malfunctions or dysfunctions of telecommunication Networks, the Internet, the website, Internet
                                    Service Providers.&nbsp;
        </p>

                                <p className="simple-text">
                                    5.11.2&nbsp; Any permanent or temporary disabling of the Service, any termination of the Service or its
                                    individual functions, or any technical collision of the Service in accordance with the terms herein.&nbsp;
        </p>

                                <p className="simple-text">
                                    5.11.3 &nbsp; Events, situations, acts, actions and / or omissions by the Company or third parties including
                                    the Users/Authorised Users for which the Company does not provide any warranties and is not bound by the terms
                                    herein.
        </p>

                                <p className="simple-text">
                                    5.11.4 &nbsp; Use by the Drivers or third parties of&nbsp; Travellers’ data (including any personal data) for
                                    purposes other than the use of the Service&nbsp;
        </p>

                                <p className="simple-text">
                                    5.11.5&nbsp; Infringement of applicable law on the protection of personal data or other applicable legislation
                                    which relates, for example, to the use of the Service
        </p>

                                <p className="simple-text">
                                    5.11.6 &nbsp; Infringement of the ACCEPTABLE USE OF THE PLATFORM POLICY , the present Terms of Use by the
                                    Travellers or other users of the Application.
        </p>

                                <p className="simple-text">
                                    5.11.7 &nbsp; &nbsp;Any discrepancy between the time system of the Service and an officially defined time
                                    system.&nbsp;
        </p>

                                <p className="simple-text">5.11.8 &nbsp; &nbsp; Events of force majeure.</p>

                                <p className="simple-text">
                                    5.11.9 Drivers mis conduct and/or breach of any laws on their behalf. Drivers non show, or delays other than
                                    as assumens herein.&nbsp;
        </p>

                                <p className="simple-text">5.11.10 Loss of staff during the transfer or any accident.&nbsp;</p>

                                <p className="simple-text">
                                    &nbsp;5.12
          <strong>
                                        THE COMPANY ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES THE TRAVELLER&nbsp; MAY SUFFER INCLUDING BUT NOT
                                        LIMITED TO LOSS OF DATA FROM DELAYS, NON-DELIVERIES, ERRORS, SYSTEM DOWN TIME, INCORRECT DELIVERY, NETWORK
                                        OR SYSTEM, DAMAGE FILES, INTERRUPTION OF SERVICE CAUSED BY NEGLIGENCE , FROM ERRORS AND / OR OMISSIONS OF
                                        THE USERS .
          </strong>
                                </p>

                                <p className="simple-text">
                                    5.13
          <strong>
                                        {" "}
            COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS, FAILURES DELIVERY OR ANY OTHER LOSS OR DAMAGE ARISING FROM DATA
            TRANSFER VIA NETWORK AND INSTALLATION COMMUNICATIONS INCLUDING INTERNET WHILE USER &#8211; TRAVELLERS
            RECOGNIZES THAT THE SERVICE AND ANT DATA SENT BY HIM/HER ARE SUBJECT TO LIMITATIONS OF DELAYS AND ANY OTHER
            PROBLEMS WHICH ARE RELATED TO THE USE OF THESE MEDIA COMMUNICATIONS.
          </strong>
                                </p>

                                <p className="simple-text">
                                    5.14 In any case, it is expressly agreed that the Company is liable only for direct damages that result from
                                    deception or gross negligence on its part. Subject to mandatory legal provisions, the liability of the Company
                                    for direct loss due to gross negligence irrespective of legal reason is expressly excluded. The
                                    Company&#8217;s liability for indirect or consequential damages, irrespective of reason, is completely and
                                    explicitly excluded. Subject to the limitations set forth herein and to the extent permitted by law, any
                                    liability of the Company is limited to the total amount corresponding to the total cost of the Transport
                                    Service.
        </p>

                                <p className="simple-text">
                                    5.15{" "}
                                    <strong>
                                        LIMITATIONS AND RESTRICTIONS SET HEREIN WITH REGARD TO DAMAGE COMPENSATION SHALL APPLY EVEN IF THE PRESENT
                                        TERMS AND CONDITIONS ARE NOT MET AND SATISFIED OR EVEN IF THEY ARE CONSIDERED VOID OR UNENFORCEABLE AND THE
                                        USER ACKNOWLEDGES THAT THESE LIMITATIONS ARE JUST AND CONSTITUTE THE BASIS OF THIS DOCUMENT AND THAT WITHOUT
                                        THEM THE COMPANY WOULD NOT PROCEED.
          </strong>
                                </p>

                                <p className="simple-text">
                                    <em>5.16 </em>
                                    <strong>Provision of Information</strong>
                                    <strong> </strong>
                                    <em>: </em>The Company, if so requested by a competent prosecution, supervisory, judicial or other Authority,
          is obliged to transmit the data of the Users and/or of Third Parties, including any personal data of
          Authorised Users provided to the Company in accordance with the present General Terms, as well as any other
          data and any other information provided by them without their prior notice or consent, on the grounds of&nbsp;
          necessary protection measures against risks to state and public security as well as for prosecution reasons,
          including tax crimes and offences.
          <br />
                                </p>

                                <h2>6. Charges and Payment Policy</h2>

                                <p className="simple-text">
                                    The Company facilitates pre-payment via PayPal, Checkout or Shopify, Checkout or Shopify of a
                                    Traveller’s Transfer,&nbsp; The cost of a Transfer (“Transfer Cost”) is the amount shown to You when You get a
                                    quote via the Platform or the amount shown to You before payment. Transfer cost includes Driver’s fee and
                                    Company’s revenue. The Company’s Revenue is calculated on a percentage basis or may be a fixed fee&nbsp; and
                                    its calculation may change without any previous notice to You and according to the Company’s will and as it is
                                    deemed necessary for Company’s business.&nbsp;
        </p>

                                <p className="simple-text">
                                    The Driver is solely responsible for providing a printed receipt to the Traveller and abiding with local tax
                                    regulations on the income generated by the Transfer. We do not hold any responsibility whatsoever for tax
                                    compliance of the Driver and/or Traveller with local tax authorities.
        </p>

                                <p className="simple-text">
                                    Refunds: The full amount of the Transfer Cost (including the Company’s Revenue), is charged immediately
                                    following completion of such election to the Traveller’s authorized PayPal, Checkout or Shopify
                                    account and transferred (less the Company’s Revenue) to such Driver’s account.&nbsp;
        </p>

                                <p className="simple-text">About the Refund Policy please see section II. 3 -Policies of Transport Services.</p>

                                <p className="simple-text">
                                    Promotional Offers: The Company, at its sole discretion, may make available promotional offers with different
                                    features to any of Travellers..&nbsp;
        </p>

                                <p className="simple-text">
                                    Facilitation of Payments: All Donations or Charges, as applicable, shall be facilitated through PayPal or
                                    Checkout or Shopify, Company’s third-party payment processing service.
        </p>

                                <h2>7. Traveler Opinions</h2>

                                <p className="simple-text">
                                    7.1 Only the Travellers who have received the Transport Service may rate / comment the Transport Service, the
                                    Drivers, their vehicles or whatever else they deem appropriate and worthwhile of annotation or of positive or
                                    negative remark.
        </p>

                                <p className="simple-text">
                                    7.2 In particular, the Company, upon completion of the Transport, sends an email to the Traveler in order to
                                    invite them to rate the Driver who carried out the Transport, and the Company. The Traveler rates the Driver
                                    by setting a score from one (1) up to five (5) stars. For a score of three or less stars, the Traveler should
                                    choose one of the reasons presented to them to justify their negative feedback. In any case the Traveler has
                                    the right to send to the Company comments regarding the Driver, the Company, the Transport and / or anything
                                    else.
        </p>

                                <p className="simple-text">
                                    7.4 The Company does not indemnify or reward Travelers who have sent their rating / feedback. Travelers&#8217;
                                    review, rating and comments may be used by the Company at its absolute discretion to inform Travelers for
                                    marketing purposes and for purposes of its own evaluation of the Drivers.
        </p>

                                <p className="simple-text">
                                    7.5 Travelers have the right to publish ratings / comments in public media, for example, social media or
                                    websites such as FB, TRIPADVISOR and it is at the discretion of the Company whether to consider these ratings
                                    / comments too, as part of Your evaluation by the Company and how these are managed in general.
        </p>

                                <h2>8. DISCLAIMER</h2>

                                <p className="simple-text">
                                    By entering into this agreement and using the software, the Platform or Service, to the extent permitted by
                                    law, You agree that You shall defend, indemnify and hold the company, its licensors and each such party’s
                                    parent organizations, subsidiaries, affiliated companies, distributors, affiliate (distribution) partners,
                                    shareholders, officers, directors, employees, representatives, members, attorneys, licensees, agents, or
                                    others involved in creating, sponsoring, promoting, or otherwise making available the software and its
                                    contents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses
                                    (including attorneys’ fees and costs) arising out of or in connection with: (i) any punitive, special,
                                    indirect or consequential loss or damages, any loss of production, loss of profit, loss of revenue, loss of
                                    contract, loss of or damage to goodwill or reputation, loss of claim, (ii) any inaccuracy relating to the
                                    (descriptive) information (including personal data, comments, and ratings) of the users as made available on
                                    our software, (iii) the services rendered by the driver, (iv) any (direct, indirect, consequential or
                                    punitive) damages, losses or costs suffered, incurred or paid by You (including attorneys’ fees and costs),
                                    pursuant to, arising out of or in connection with the use, inability to use or delay or virus of our software
                                    and/or the server that the software is hosted, or (v) for any (personal) injury, death, property damage, or
                                    other (direct, indirect, special, consequential or punitive) damages, losses or costs suffered, incurred or
                                    paid by You, whether due to (legal) acts, errors, breaches, (gross) negligence, willful misconduct, omissions,
                                    non-performance, misrepresentations, tort or strict liability by or (wholly or partly) attributable to the
                                    user (its employees, directors, officers, agents, representatives or affiliated companies)(including
                                    attorneys’ fees and costs), including any (partial) cancellation, double-booking, strike, force majeure or any
                                    other event beyond our control (vi) any information on this software sometimes linked to external sites over
                                    which our services have no control and for which we assume no responsibility (vii) any user violation or
                                    breach of any term of this agreement or any applicable law or regulation, whether or not referenced herein.
        </p>
                            </StyledCard >
                        </DesktopRightContainer>
                    </DesktopFlexContainer>
                </StyledContent>
            </StyledPage>
        );
    }

    return (
        <StyledPage>
            {window.innerWidth < 768 && <StickyHeader goBack={() => history.push(window.location.href.split("/terms_and_conditions")[0].replace(window.location.origin, ""))} title={t('legal.terms')} subtitle="" />}
            <StyledContent>
                <DesktopFlexContainer align="flex-start">
                    <DesktopList padding="0">
                        {isAuthorised.get() ? <LoggedInUser isSide /> : <GuestUser isSide />}
                    </DesktopList>
                    <DesktopRightContainer >
                        <StyledCard margin="10px" padding="20px 15px">
                            <h1 className="main-title" style={{marginTop: "40px"}}>
                                General terms & conditions of use of the platform & the service under the trade name "Welcome Pickups" for
                                travellers
      </h1>
                            <h2 className="title">CONTENT</h2>
                            <ol className="point-marker-list">
                                <li className="point-marker-list-item">
                                    <span>IN GENERAL</span>
                                    <ul className="point-marker-list">
                                        <li className="point-marker-list-item">1. Description of Transport Services</li>
                                        <li className="point-marker-list-item">2. Legal Status of the Company</li>
                                        <li className="point-marker-list-item">3. Policies of Transport Services</li>
                                        <li className="point-marker-list-item">4. Declarations of Travellers</li>
                                        <li className="point-marker-list-item">5. Company’s rights- Limitation of Liability</li>
                                        <li className="point-marker-list-item">6. Charges and Payment Policy</li>
                                        <li className="point-marker-list-item">7. Travellers’ opinion</li>
                                        <li className="point-marker-list-item">8. Disclaimer</li>
                                    </ul>
                                </li>
                                <li className="point-marker-list-item">TERMS & CONDITIONS FOR THE TRANSPORT SERVICES</li>
                                <li className="point-marker-list-item">PRIVACY POLICY</li>
                                <li className="point-marker-list-item">GENERAL LEGAL TERMS</li>
                                <li className="point-marker-list-item">ACCEPTABLE USE OF THE PLATFORM POLICY</li>
                            </ol>

                            <h2 className="title">I. IN GENERAL</h2>
                            <p className="simple-text">
                                The following General Terms and Conditions of Use of the Platform and Service (hereinafter referred to as the
                                «General Terms of Use» or «General Terms» ) have been established by the Limited Liability Company under the
                                name “WELCOME TRAVEL TECHNOLOGIES HOLDINGS LTD” , with no 10879236, headquartered in c/o Harrison Beale & Owen
                                Seven Stars House, 1 Wheler Road, Coventry CV3 4LB, UK with VAT np: 289 0786 46, email hello@welcomepickups.com
                                (hereinafter referred to as ” the “Company” or “We” or “us” or “our” ), which operates and manages the web
                                platform under the trade brand name “Welcome Pickups” (hereafter referred to as the “P latform” ), through which
                                tourists/travellers (hereinafter “Travellers” , “You” , “Yours” , “User” ) make a booking of a transport service
                                as defined herein (hereinafter “Transport Services” ) operated by drivers of Private and Public Use Vehicles who
                                have an Account in the Platform (hereinafter the “Drivers” ) through which they receive by Us, acting as
                                intermediary between You and the Drives, notifications and requests for the provision of Transport Services to
                                You (hereinafter “Service ”).
      </p>
                            <p className="simple-text">
                                The following General Terms of Use of the Platform and the Service (hereinafter “Terms of Use” , or “General
                                Terms of Use” ) constitute the legally binding terms and conditions applicable to Your access and use of the
                                Platform and the Service, including any use of software, services, features, functions, content, website(s) and
                                hyperlinks and / or any other applications provided from time to time by the Company in the Platform and/or in
                                the Service.
      </p>
                            <p className="simple-text">
                                Before any use of the Platform and the Service, You are advised to read the present Terms of Use and make sure
                                You agree with them. In case You disagree with any term and / or all of them, You should not use the Platform
                                and the Service. In case You have any inquiries or You need any clarification and/or information regarding the
                                Terms of Use, or You have any prejudice or relevant inquiry, You can contact Our Customer Support Team at the
                                phone numbers shown in the website www.welcomepickups.com or via the email hello@welcomepickups.com, before any
                                use of the Platform. By using the Platform you warrant and acknowledge that You have reviewed, understood and
                                accept these General Terms of Use unconditionally and without any prejudice and that You are bound by them.
      </p>
                            <p className="simple-text">
                                Our Company reserves the right to amend, modify, renew, delete, add or restrict unilaterally (a) these Terms of
                                Use in whole or in part; (b) its Policies; (c) the Service; (d) the Technical Specifications of the Platform or
                                other (hereinafter referred to as the “Changes” ) at its sole discretion and / or when any amendment, as above,
                                is required by law, possibly without Your prior notice or consent, nevertheless within the context of the
                                business ethics and limits set forth by law. The Company undertakes the obligation to notify You any Changes,
                                through the Platform by a prominent notice when the Users enters the Platform. Any Changes will be effective
                                from the date of their posting. It is clarified that any change to these Terms of Use does not cover bookings or
                                other transactions and uses of the Platform and the Service which have been made before the entry into force of
                                the Changes and been accepted by our Company. However, in case the Users wish any clarification or information
                                regarding the Changes, or in case they have any dispute, reservation or enquiry related to these Changes, prior
                                to any action made by them on the Platform, they should contact Our Customer Support team. Any action, use or
                                transaction made by the Users on the Platform, including the use of the Service itself, following the Changes,
                                is considered as an unconditional acceptance thereof.
      </p>
                            <p className="simple-text">
                                It should be noted that any information / clarification granted by the Customer Support regarding the Terms of
                                Use does not constitute a replacement, substitution or any modification of these Terms of Use, as they are
                                provided solely for the purpose of assisting the Users whilst the Terms Use constitute our sole and exclusive
                                agreement.
      </p>
                            <p className="simple-text">
                                The Company reserves the right at any time, without providing any justification and without prior notice to the
                                User, to cancel, suspend or terminate the operation of the Service, to revoke or customize the Platform, or
                                certain features of the Platform and the Service. The Users are solely and exclusively responsible and liable
                                with regard to the use of the Platform and the Service. You acknowledge and understand that You must comply with
                                the Terms of Use of the provider which co-operating with Your device (referred to hereinafter as the “Provider’s
                                Terms of Use”). The Provider’s Terms of Use are considered to be incorporated herein. The Users are solely and
                                exclusively responsible and liable as far as the obligation to determine the Provider’s Terms of Use applicable
                                to their Device is concerned.
      </p>
                            <p className="simple-text">
                                <strong>
                                    BY ACCEPTING THESE TERMS IN ACCORDANCE WITH THE ABOVEMENTIONED, THE USERS (AS WELL AS ANY THIRD PARTY ACTING
                                    ON THEIR BEHALF (the “AUTHORISED USERS”) EXPRESSLY AND UNCONDITIONALLY DECLARE THAT THEY HAVE THE LAWFUL AGE
                                    WHICH ALLOWS THEM TO BE BOUND BY THESE GENERAL TERMS AND HAVE OBTAINED ALL THE NECESSARY APPROVALS AND
                                    LICENSES FOR THEIR REGISTRATION IN THE PLATFORM AND THE USE OF THE SERVICE.
        </strong>
                            </p>

                            <h2 className="title">1. Description of Transport Services to Travelers</h2>
                            <p className="simple-text">
                                1.1 Booking a Transport Service with Us, means that a Driver will become the person who will greet and welcome
                                You to their city, and / or will transfer You during Your stay.
      </p>
                            <p className="simple-text">
                                In particular, the transport services that You can book through the Platform, are the following (hereinafter
                                referred to as ” Transport Services ” or “Pickups” or “Transfer” ):
      </p>
                            <p className="simple-text">
                                1.1.1 Travellers’ Transport from and to airports, ports, train stations, bus stations.
      </p>
                            <p className="simple-text">1.1.2 Travellers‘ Transport from and to place of residence.</p>
                            <p className="simple-text">1.1.3 Travellers‘ Transport from and to sightseeings.</p>
                            <p className="simple-text">
                                1.1.4 Travellers’ Transport from and to a point of interest in the city, including stop(s) over (for food,
                                activity, photos, etc.).
      </p>
                            <p className="simple-text">
                                1.1.5 Travellers’ Transport while in tour and transport from and to places of activities based on their
                                interests.
      </p>
                            <p className="simple-text">
                                1.2 At the point You will be welcomed by the Driver, You will receive products that You may have purchased from
                                Us. and/or products that the We distribute free of charge (souvenirs, local sweets, city maps, etc.).
      </p>
                            <p className="simple-text">
                                1.4 To make Your pick ups a personal experience for You, We will forward You the Driver’s name, surname and
                                their mobile phone and/or their photo in case We have been provided with this.
      </p>
                            <p className="simple-text">
                                <strong>
                                    We are not a licensed private hire operator, nor a transportation carrier and WE DO NOT provide the Transport
                                    Services. We act solely as an intermediary between You and the Driver. By booking Your Pickup through the
                                    Platform, You enter into a direct and legally binding contractual relationship with the Driver who accepts
                                    Your Pickup.
        </strong>
        The Company provides information and a method to facilitate the Transport Services, but does not intend to
        provide Transport Services or act in any way as a transportation carrier, therefore has no responsibility or
        liability for any Transport Services provided to You by the Drivers.
      </p>

                            <h2 className="title">2. Legal Status of the Company</h2>
                            <p className="simple-text">
                                2.1 Our Company provides electronic or telephone mediation services for the transport of Travellers. As an
                                e-mediator body in the Transport Service that Drivers carry out, we act solely as intermediaries between the
                                Travellers and the Drivers. Through its Platform, the Company provides mediation services for the provision of
                                Transport Services from Drivers to Travelers. Actually, the Company does not itself provide nor could it be
                                considered to provide the Transport Services by itself, since it is not a licensed transport operator. In case
                                that You make a booking of a Transport Service through the Platform, You acknowledge that you are entering into
                                a contract for the provision of the Transport Service solely with the Drivers and You and the Driver bear sole
                                liability for the fulfillment of the provision of the Transport.
      </p>
                            <p className="simple-text">
                                2.2 The Company does not provide transport services, and it is not involved in the relationship between You and
                                the Driver who Picks You Up, as the contractual relationship for the provision of Transport Service, is
                                established exclusively between You and the Driver who undertakes to provide You with Transport Services. In no
                                way does the Company participate as a contracting party in these contracts, nor does it obtain any rights or
                                obligations deriving from such contracts. The Company assumes no responsibility for the Travelers’ transport,
                                for which Drivers are solely responsible. As a result, the Company, its representatives, employees and
                                assistants are not liable in any way for the risks associated with the conclusion, execution and completion of
                                the transactions between the Travelers and Drivers and are not liable in any way for any damages deriving from
                                such transactions. Besides, the choice of whether to book a Pick Up through the Platform, is at Your sole
                                discretion. However, if You accept it, You are bound and warrant that You will meet all obligations arising out
                                of these Terms of Use.
      </p>
                            <p className="simple-text">
                                2.3 The Company does not represent You or the Driver, so it does not provide any guarantees regarding the
                                quality of the Transport Services, the completion of the Transfer, Travelers’ and Drivers’ behavior. In
                                addition, the Company does not represent or act on behalf of any transport company.
      </p>
                            <p className="simple-text">
                                2.4 Please note that Drivers do not have an employment relationship with the Company or its partners. The
                                Platform constitutes a method of linking You to Drivers. In addition, the Company does not provide any insurance
                                to Drivers or to their vehicles and assumes no liability if You lose Your staff while on a Pick Up.
      </p>
                            <p className="simple-text">
                                2.5 The Company may refer Drivers to You for the provision of the Transport Services. It will not evaluate the
                                suitability, legality or ability of any Driver and You expressly waive and relieve the Company of any and all
                                claims, liabilities or damages arising from, or are in any way related to, the Driver. The Company shall not be
                                part of any dispute between You and the Drivers. By booking Transport Services, You acknowledge that the Company
                                has no involvement in the contractual relationship between the Driver and You, however the Transport Services
                                are governed by these General Terms.
      </p>
                            <p className="simple-text">
                                2.6 The inter-Mediation services We provide are only available for personal, non-commercial use. Therefore, it
                                is not permitted to resale, distribute through deep-link, use, copy, track (e.g. through spider, scrape),
                                display, download or reproduce any content or information, software, products or services available at the
                                Platform, for any commercial or competitive activity or purpose.
      </p>

                            <h2 className="title">3. Policies of the Transport Services</h2>
                            <h3 className="subtitle">3.1 Booking Policy</h3>
                            <p className="simple-text">
                                We do not operate Our Service in every city. To find out if We can serve You in Your destination, You have to
                                check with Us.
      </p>
                            <p className="simple-text">
                                To book Your transfer, You need to provide Us with some information such as Your personal details and contact
                                points (email – mobile), the Pick Up place, date and time, Your place of destination, flight number in case You
                                arrive by plane so We can make arrangements in Your favor in case there is a delay, the number of passengers and
                                their pieces of luggage in order to arrange for the right Vehicle to pick You up, and also Your payment
                                information. You have to accept Our Terms of Use and Privacy Policy, should You wish to make a booking for Your
                                transfer with Us.
      </p>
                            <p className="simple-text">
                                When a booking transfer is placed with Us, You will receive a confirmation email of receipt of Your booking
                                transfer and We will provide You with Your Booking code and the meeting point at the Pickup place. We will
                                examine whether We can serve the Transfer as a whole, or We have grounds to reject it, according to the
                                provisions of the present General Terms of Use. Once a Welcome Driver has been assigned to operate your Transfer
                                we will provide You with their name Important Legal Notice: Please note that completion of the online booking
                                process along with the acknowledgement email We send to You, does not constitute Our acceptance of Your offer to
                                book a Transfer through Us, even though on the acknowledgement email of Your offer, the Booking code is
                                depicted. Our acceptance of Your offer to provide through Our Platform with a Transfer, will take place only
                                when We take payment from You (i.e. when Your card or PayPal account is credited). We reserve the right to
                                decline a Booking Order for any reason, including legal and regulatory reasons. If we cannot provide You with
                                the Transport Service, We will not process Your order, We will inform You as soon as possible and, if You have
                                already paid for the products we cannot deliver, refund You in full in a timely manner.
      </p>
                            <p className="simple-text">
                                You are solely responsible for submitting Us Your real personal data when booking a pick up with through Us. You
                                also warrant and represent that Your are the holder or have legal rights on the payment cars/ accounts details
                                You provide Us with and that there is enough money to cover the value of the Transfer Service. We may proceed
                                (however, are not obliged to) in an examination process of the payment details You provide Us with when choosing
                                credit card as a payment method or via Online Providers. In case an error, misleading statement or other problem
                                occurs, We may, at Our sole discretion reject Your Order.
      </p>

                            <h2>3.2 Traveller Cancellation Request Policy</h2>

                            <p className="simple-text">
                                You have the right to cancel a Transfer already booked through Us, at any time by contacting the Company either
                                by phone call at the phone number provided in the confirmation email or in the website or email sent to
                                hello@welcomepickups.com.
      </p>

                            <p className="simple-text">
                                If a cancellation request is received by Us up to 24 hours prior to the scheduled pick up time, you have the
                                following options: a) reserve the amount paid to be used for any future Transfer in the cities we operate. In
                                this case, You get a coupon equal to this amount, which can be used by You or You can even pass it to another
                                Traveller, and can be used for a limited period of 12 months from the day of your cancellation request. If the
                                coupon amount is higher the cost of the new booking, there is no refund for the difference, the total cost of
                                the new booking is covered by the coupon. If the coupon amount is less than the cost of the new booking, the
                                Traveller will have to proceed with a payment of the difference. The coupon is issued for a single use and can
                                not be canceled. b) proceed with cancellation. In this case, there is a cancellation fee of 20% of the amount
                                paid which will be held by Us. The rest 80% is refunded to You.
      </p>

                            <p className="simple-text">
                                If a cancellation request is received by the Company within less than 24 hours prior to the scheduled pick up
                                time, it is considered as a last minute and non refundable cancellation, resulting in a 100% cancellation fee
                                and NO refund to the Traveller.
      </p>

                            <h2>3.3 Traveller Change Request Policy</h2>
                            <p className="simple-text">
                                You can contact the Company via a phone call at the phone number provided in the confirmation email or in the
                                website or email at hello@welcomepickups.com and ask to change any of the booking details up until 12 hours
                                prior the scheduled pickup time.
      </p>
                            <p className="simple-text">
                                Depending on the changes requested, the Transfer price might change. On that occasion, the new Transfer price
                                will be communicated to You, and the new Transfer details will be in effect once there is payment confirmation
                                of the new Transfer price where applicable. If the new Transfer price is less, the Company shall partially
                                refund You. If the new Transfer price is more You should give us Your consent to charge Your card with the
                                additional amount (if any) due to the change request.
      </p>
                            <p className="simple-text">
                                The Company cannot accommodate any changes to the details of Transfers requested within less than 12 hours prior
                                to the scheduled pickup time. I n this occasion, the Transfer will either be operated as initially booked, or
                                the You can proceed with a cancellation request, as per the Traveller Cancellation Request Policy.
      </p>

                            <h2>3.4 Complimentary Waiting Time Policy</h2>
                            <p className="simple-text">
                                Your Welcomer will be waiting for You at the Pick Up place at the time that is scheduled in the Booking Order,
                                subject to the provisions of this Complementary Waiting Time Policy and Extra Waiting Time Policy, so You must
                                make sure that You show up in time, to avoid as being marked as a non-show where all the Terms of the
                                Travellers’ No – Show Policy apply.
      </p>
                            <p className="simple-text">
                                The Company offers the following complimentary waiting times, depending on the type of Transfer booked:
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">Airport: 60 minutes</li>
                                <li className="point-marker-list-item">Port, Train Station, Bus Station: 30 minutes</li>
                                <li className="point-marker-list-item">Other pickups (incl. Sightseeing Rides): 15 minutes</li>
                            </ul>
                            <p className="simple-text">The complimentary waiting time starts counting from the scheduled pickup time.</p>
                            <p className="simple-text">
                                More specifically, for an airport/port pickup where the scheduled pickup time is other than the flight/ship
                                arrival time and the flight/ship is delayed and expected later than the scheduled pickup time, the new arrival
                                time becomes the pickup time.
      </p>
                            <p className="simple-text">
                                For an airport/port pickup where the scheduled pickup time is the flight/ship arrival time and there is a
                                flight/ship delay, the complimentary waiting time starts from the moment the flight/ship arrives provided the
                                Traveller has provided correct and adequate flight/ship details to allow for the tracking of the arrival. The
                                above is true for up to 8 hours of flight/ship delay. If the flight/ship is delayed more than 8 hours, the
                                Transfer is cancelled and the entire Transfer fee is refunded to the Traveller.
      </p>
                            <p className="simple-text">
                                For an airport/port pickup where the pickup time is the flight/ship arrival time and the flight/ship arrives
                                earlier than the scheduled time, the scheduled pickup time remains the initial scheduled pickup time.
      </p>
                            <p className="simple-text">
                                For an airport pickup where the Travellers are arriving with more than one flights, the Company considers the
                                scheduled pickup time to be the one declared by the Traveller as the arrival time during the booking, or an
                                updated arrival time as per the Traveller Change Request Policy. Any notes left by the Traveller during the
                                booking mentioning several flights do not constitute a scheduled pickup time adjustment. In the occasion where a
                                group of Travellers arriving with multiple flights landing at different times have booked one Transfer, it is
                                suggested that they add in the flight details the flight arriving last.
      </p>

                            <h2>3.5 Extra Waiting time Policy</h2>
                            <p className="simple-text">
                                Extra waiting time is defined as an amount of time in addition to the complementary waiting time offered by the
                                Company for which the Traveller asks the Driver to wait for them.
      </p>
                            <p className="simple-text">
                                You can ask for the Driver to wait for You longer than Company’s complementary waiting time as per the
                                Complementary Waiting Time Policy, either in advance (before the Transfer is operated) or the latest by the end
                                of the complementary waiting time. You can do so by contacting Company’s Customer Support either by a phone call
                                at the phone number provided in the confirmation email or in the website or by sending an email at
                                hello@welcomepickups.com. The Company can guarantee only the complimentary waiting time as additional waiting
                                time for the Traveller if the extra waiting time has not been asked for at least 12 hours before the scheduled
                                pickup time.
      </p>
                            <p className="simple-text">
                                If You request an extra waiting time and the assigned Driver can accommodate this, an extra fee will be charged
                                to You for every 15 minutes of extra waiting time, as follows:
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">For a Pickup where a Sedan car-type is assigned: EUR 6.00 / 15 min</li>
                                <li className="point-marker-list-item">
                                    For a Pickup where a Minivans car-type is assigned: EUR 9.00 / 15 min
        </li>
                                <li className="point-marker-list-item">
                                    For a Pickup where a Minibus car-type is assigned: EUR 12.00 / 15 min
        </li>
                            </ul>
                            <p className="simple-text">The Company does not charge any additional commissions to these fees.</p>
                            <p className="simple-text">
                                When the extra waiting time request can be accommodated by the Driver, You have to consent on the additional
                                applicable fees either over a phone call with the Company or by completing the transaction by Yourself in the
                                link that the Company shall send to You . Payment of the fee can be made either in cash directly to the Driver
                                before the start of the Transfer, or electronically to the Company.
      </p>

                            <h2>3.6 Traveller No-Show Policy</h2>
                            <p className="simple-text">
                                Traveller No-Show (TNS) is defined as a Traveller not being at the pickup location by the end of the
                                complimentary waiting time, or the extra waiting time if that has been agreed between the Traveller, the
                                assigned Driver and the Company as per the Complementary Waiting Time Policy and the Extra Waiting Time Policy .
      </p>
                            <p className="simple-text">
                                The pickup location is defined as the place where the Traveller has asked to be picked up from. In case of
                                airport/port pickup, the Company notifies You via the booking confirmation email the exact pickup location where
                                the Driver will be waiting for You (i.e. Arrivals hall, Departure hall, etc.).
      </p>
                            <p className="simple-text">
                                In the occasions where the Traveller fails to appear at the pickup location by the end of their complementary
                                waiting time, or the end of the extra waiting time, or if an “Extra Waiting Time” request has not been able to
                                be accommodated by the Driver, the Transfer is marked as a “Traveller No Show” (“TNS”). In this case no amount
                                is refunded to the Traveller. If an extra waiting time was agreed with the Traveller as per the Extra Waiting
                                Time Policy, the Traveller must pay the extra waiting fee as well.
      </p>
                            <p className="simple-text">
                                If You are not at the pickup location at the scheduled pickup time, and the Pick up place is an Airport, Port,
                                Train Station or Bus Station, the assigned Driver will try to contact You via text/Whatsapp/Viber or he will try
                                to call You on Your Mobile. By booking a Transfer You consent to Us providing Your mobile phone number to Your
                                Welcomer.
      </p>
                            <p className="simple-text">
                                A possible TNS will be reported by Your Welcomer to the Company, at the following times:
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    For airport pickups by 45 minutes of a Traveller’s delay to appear at the pickup location after the scheduled
                                    pickup time
        </li>
                                <li className="point-marker-list-item">
                                    For Port, Train Station, Bus Station pickups by 15 minutes of a Traveller’s delay to appear at the pickup
                                    location after the scheduled pickup time
        </li>
                                <li className="point-marker-list-item">
                                    For Sightseeing Rides and other Transport Services by 5 minutes of a Traveller’s delay to appear at the pickup
                                    location after the scheduled pickup time
        </li>
                            </ul>
                            <p className="simple-text">
                                Upon a Driver’s notification of a possible TNS, the Company will try to contact the You (via call and/or
                                text/Whatsapp/Viber and/or email) in an effort to locate You. These efforts will be carried out until the end of
                                the Your complimentary waiting time as per these present Terms of Use.
      </p>
                            <p className="simple-text">By the end of Your complimentary waiting time, the following will happen:</p>

                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    If You have not been reached (have not replied) , We must “release” the Driver. In this occasion, the Transfer
                                    is marked as a “TNS” .
        </li>
                                <li className="point-marker-list-item">
                                    If You have been reached and have communicated that You will be at the pickup location by the end of the
                                    complimentary waiting time, We must inform the Driver to wait. If You fails to appear by the end of the
                                    complimentary waiting time, We will make one final effort to reach out to You, and depending on the outcome,
                                    either release the driver immediately, or follow Company’s Extra Waiting Time Policy accordingly.
        </li>
                                <li className="point-marker-list-item">
                                    If You have been reached and You have asked for extra waiting time, We will contact the Driver whereas the
                                    Extra Waiting Time Policy will apply accordingly.
        </li>
                            </ul>
                            <p className="simple-text">
                                You have 24 hours to dispute a TNS by contacting Welcome’s Customer Support. In the occasion You dispute a TNS,
                                if the Driver was at the pickup location between the scheduled pick up time and the end of the complimentary
                                waiting time (or extra waiting time if applicable), there is sufficient evidence to assume the Driver was at the
                                pickup location as per Company’s policies. If there is sufficient evidence to assume that both You and the
                                Driver were at the pickup location but failed to meet each other and the Transfer was not operated, You will be
                                refunded 80% of the Transfer price, whereas the 20% will be kept by Us in order to cover partially Driver’s fee,
                                since the Company is responsible to pay them their fee.
      </p>

                            <h2>3.7 Driver No-Show Policy</h2>
                            <p className="simple-text">
                                The Driver must be at the pickup location by the scheduled pickup time. If the Driver is to be up to 10 minutes
                                late, they will contact and notify the Traveller (via call and text/Whatsapp) as much in advance as possible,
                                and the latest by the scheduled pickup time. If the Driver is to be more than 10 minutes late, they will contact
                                and notify Company ’s CS as much in advance as possible, and the latest by the scheduled pickup time. Company ’s
                                CS will contact the Traveller and notify them of the expected Driver delay.
      </p>

                            <h2>3.8 Baggage Allowance Policy</h2>
                            <p className="simple-text">
                                When booking the Transfer, You are requested to declare the number of pieces of luggage You will be carrying
                                with them during the Transfer.
      </p>
                            <p className="simple-text">One piece of luggage is considered to be</p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">A medium to large sized backpack</li>
                                <li className="point-marker-list-item">
                                    An average suitcase, whether it’s a carry-on or checked-in type (volume not to exceed 100lt)
        </li>
                            </ul>
                            <p className="simple-text">
                                Should You carry additional items such as baby carriages, pet carriers, sports equipment, foldable wheelchairs,
                                musical instruments, etc. You must declare one piece of luggage for each one of those items and add a note to
                                the booking describing them. The Company might contact You for clarifications and adjust the booking details
                                accordingly in order to ensure the proper car-type is assigned to operate the Transfer.
      </p>
                            <p className="simple-text">
                                The Transfer price and the car-type assigned to operate the booked transfer are calculated amongst other factors
                                based on the number of Travellers and pieces of luggage declared. If You appears with more travellers and/or
                                carrying more pieces of luggage than declared, and the assigned Driver cannot safely and legally accommodate
                                these, the Driver will contact Company’s CS before the start of the Transfer explaining the situation, and
                                assist You in hailing another Vehicle. In that occasion the Driver will only carry the number of Travellers and
                                pieces of luggage declared in the Transfer booking.
      </p>
                            <p className="simple-text">
                                If You appear with more Travellers and/or carrying more pieces of luggage than declared, and the assigned Driver
                                can safely and legally accommodate these (i.e. in the case where a Minivan car-type accepted a Sedan car-type
                                Transfer), the Driver must contact the Company’ before the start of the Transfer explaining the situation. If
                                the additional number of Travellers and/or luggage combination yield a different Transfer price, the Company
                                will recalculate this and notify You accordingly. If You consent to paying the additional charges, the Driver
                                will accommodate the additional passengers and/or pieces of luggage. Otherwise, the Driver will kindly assist
                                You in hailing another vehicle and carry the number of Travellers and pieces of luggage declared in the Transfer
                                booking.
      </p>
                            <p className="simple-text">
                                The Company will not bare the cost of any other means of transportation used by You.
      </p>

                            <h2>3.9 Traveller Refund Policy</h2>
                            <p className="simple-text">
                                Transfer fee paid by a Traveller will be offered as an open date coupon (as mentioned in article 3.2 Traveller
                                Cancellation Request Policy) in the following occasions:
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    If the booked Transfer is canceled by the Company for operational capacity reasons
        </li>
                                <li className="point-marker-list-item">
                                    If the booked Transfer is canceled due to a force majeure incident (strike, demonstration, natural disaster,
                                    accident, etc.)
        </li>
                                <li className="point-marker-list-item">
                                    If the Transfer is canceled due to a flight/ship delay over 8 hours as per the Complimentary Waiting Time
                                    Policy
        </li>
                            </ul>
                            <p className="simple-text">Other cases:</p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    If the Company receives a Traveller cancellation request up to 24 hours prior to the scheduled pickup time, as
                                    per the Traveller Cancellation Request Policy, there is a cancellation fee of 20% of the amount paid which
                                    will be held by Us. The rest 80% is refunded.
        </li>
                                <li className="point-marker-list-item">
                                    If the Company receives a Traveller cancellation request within less than 24 hours prior to the scheduled
                                    pickup time, as per the Traveller Cancellation Request Policy, it is considered as a last minute and non
                                    refundable cancellation, resulting in a 100% cancellation fee and NO refund to the Traveller.
        </li>
                                <li className="point-marker-list-item">
                                    In the occasion of a Traveller-disputed “Traveller No-Show”, if there is sufficient evidence to assume that
                                    both the Traveller and Driver were at the pickup location but failed to meet each other and the Transfer was
                                    not operated, the Traveller will be refunded 80% of the Transfer price as per the Traveller No-Show Policy.
        </li>
                            </ul>
                            <h2>4. Travellers’ Rights and Obligations – Representations – Warranties</h2>
                            <h3 className="subtitle">4.1 TRAVELLERS’ REPRESENTATIONS-WARRANTIES</h3>
                            <p className="simple-text">By using the Platform, You agree:</p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    to provide true, accurate, complete and updated information about yourself at the booking form, and update
                                    them whenever necessary. The Company is not obligated to monitor or control the accuracy of information
                                    provided by You. If any information provided by You is not in accordance with these provisions or if the
                                    Company has reasonable grounds to suspect that such pieces of information do not agree, the Company has the
                                    right to refuse Your registration, and suspend or terminate immediately, regardless of notice, the
                                    registration of Your account and refuse any and every use, present or future, of the Services that require
                                    registration. In this case, You shall not be entitled to any indemnity or compensation for the refusal,
                                    suspension or cancellation of Your registration.
        </li>
                                <li className="point-marker-list-item">
                                    that You will not use the Platform: for any unlawful purpose; in any way that interrupts, damages, impairs or
                                    renders the Company less efficient; to transfer files that contain viruses, trojans or other harmful programs;
                                    to access or attempt to access the accounts of other users or to penetrate or attempt to penetrate any
                                    security measures; to disseminate any content which is defamatory, obscene, or may have the effect of being
                                    harassing, threatening or abusive to an individual or group of individuals on the basis of religion, gender,
                                    sexual orientation, race, ethnicity, age or disability or otherwise; to advertise or promote third party or
                                    Your own products or services;
        </li>
                                <li className="point-marker-list-item">
                                    to refrain from doing anything which we reasonably believe to be disreputable or capable of damaging our
                                    reputation
        </li>
                                <li className="point-marker-list-item">
                                    and acknowledge that we have limited control over the nature and content of information received by You or
                                    other users of the Platform. Although we reserve the right to do so, we do not monitor such content in the
                                    usual course of business and will not be liable for any such content. If You have a complaint about another
                                    user please contact us via the Platform;
        </li>
                                <li className="point-marker-list-item">
                                    to comply with all applicable laws from Your home nation, the country, state and city in which You are present
                                    while using the software or Service;
        </li>
                                <li className="point-marker-list-item">
                                    to treat Drivers introduced to You through the Platform with respect and not to cause damage to their Vehicle;
        </li>
                                <li className="point-marker-list-item">
                                    to compensate and defend us fully against any claims or legal proceedings brought against us by any other
                                    person as a result of Your breach of this agreement.
        </li>
                                <li className="point-marker-list-item">
                                    that the email (e.g xxx@yahoo.com) that You use to register on the Platform shall not resemble to Company’s
                                    company name. Also log in passwords deemed offensive may be deleted, as well as containing a URL or email
                                    address.
        </li>
                                <li className="point-marker-list-item">
                                    that You shall not use the Service or software to cause nuisance, annoyance or inconvenience
        </li>
                                <li className="point-marker-list-item">
                                    that by accessing and using the Services via mobile devices, or by using certain mobile features, You may
                                    incur fees from the provider or carrier of the mobile services that You use (“Carrier”) and You are solely
                                    responsible for the payment of such fees.
        </li>
                            </ul>
                            <p className="simple-text">
                                4.2 You acknowledge and agree that You must avoid any action that may cause traffic safety problems.
                                Particularly, You warrant and represent that You will:
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    not in any way hinder the Driver from driving safely during Your transfer,
        </li>
                                <li className="point-marker-list-item">not attempt to disembark or board the vehicle when it is moving,</li>
                                <li className="point-marker-list-item">not smoke,</li>
                                <li className="point-marker-list-item">not open windows and doors without the Driver’s consent;</li>
                                <li className="point-marker-list-item">
                                    be decent, courteous and not display inappropriate, abusive, provocative or threatening behavior in any way
                                    during the Transfer;
        </li>
                                <li className="point-marker-list-item">not take any action that may cause damage to the Driver’s Vehicle,</li>
                                <li className="point-marker-list-item">
                                    take care that the children, animals and objects to be transferred are done so with responsibility,
        </li>
                                <li className="point-marker-list-item">
                                    Not throw objects inside or outside the Vehicles or pollute them in any way,
        </li>
                                <li className="point-marker-list-item">
                                    Carefully observe the instructions for traveling with young children and for the transport of infant and kid
                                    strollers, luggage, bicycles and pets,
        </li>
                                <li className="point-marker-list-item">
                                    not consume food and drinks throughout Your Transfer and until the end of the Transfer.
        </li>
                                <li className="point-marker-list-item">not embark on a Vehicle with dirty or contaminated clothing</li>
                            </ul>
                            <p className="simple-text">
                                4.3 In cases where You carry animals and/or objects during the Transfer and the Vehicle is damaged/destroyed or
                                gets dirty somehow due to them, You undertake the obligation to pay for any such damages.
      </p>
                            <p className="simple-text">
                                4.4. You also warrant that You have fully read and understood these Terms of Use, the Policy of Acceptable Uses,
                                the Privacy Policy and accepts them fully and unconditionally and that You meet all the legal requirements for
                                the acceptance of these Terms.
      </p>
                            <p className="simple-text">
                                4.5 You warrant and represent that at Your own responsibility and expense, You shall immediately take all
                                necessary measures and in any case fully compensate, indemnify and hold harmless the Company, its Affiliates and
                                its Partners and any third party that derives rights therefrom (partners, employees, administrators, suppliers,
                                agents, representatives of the same and its associates) for any claim, loss, damage, cost, expenses,
                                liabilities, deviations, penalties, fines, lawyer fees We have suffered as a result of (a) any violation by You
                                of these Terms of Use and / or the Policy of Acceptable Uses, of Your representations and warranties under this
                                Agreement, of intellectual property or other rights of the Company; (b) any other action or omission that is
                                illegal while using the Service; (c) violation of applicable law on Your behalf.
      </p>

                            <h2>5. Company’s Rights</h2>
                            <h3 className="subtitle">5.1 Definite deactivation (deletion) of the Platform:</h3>
                            <p className="simple-text">
                                Save as provided in these General Terms the Company expressly reserves the right to delete the Platform and the
                                Service respectively, in the following cases at the Company’s absolute discretion: a) in case You violate the
                                Acceptable Use Policy of the Platform, and / or b) violates any term or provision mentioned and at the Company’s
                                absolute discretion this violation causes immediate risk-loss to the Company and / or to any other User of the
                                Platform c) has committed, at the Company’s absolute discretion, a penal or other offence, during the use of the
                                Platform and the Service and / or the Transport Services and/or d) for any other important reason referred to
                                herein.
      </p>
                            <p className="simple-text">
                                5.2 In the above cases, the Platform/Service is deactivated immediately and automatically, without the Company
                                providing to the Traveller the right to remove the violation, expressly reserving all its legal rights,
                                including the right to compensation for any damage that may have been suffered by the acts and / or omissions of
                                its Users according to the above mentioned.
      </p>
                            <h3 className="subtitle">5.3 Temporary de-activation (deletion) of the Platform :</h3>
                            <p className="simple-text">
                                Save as provided in these General Terms regarding the deactivation (deletion) of the Platform, the Company
                                expressly reserves its right at its sole discretion to temporarily deactivate the Platform/Service, directly and
                                without any penalty in the event that it considers that the Traveller has breached or has violated any
                                applicable law and until the Traveller removes the breach within the time period drawn by the Company, at its
                                sole discretion. In the event of the Traveller’s non-compliance, the Company may definitively deactivate the
                                Platform for the Traveller, expressly reserving all its lawful rights, including the right for compensation of
                                any damage suffered by the User’s actions and / or omissions in accordance with the above mentioned.
      </p>
                            <p className="simple-text">
                                5.4 It is expressly agreed that in the event of a permanent or temporary deactivation (deletion) of the Platform
                                as per articles 5.1 and 5.2 above is based on automated means, the user is not entitled to claim compensation of
                                any damage caused by the deactivation of the Platform/Service, in accordance with the above provisions, even if
                                the evidence on which the Company was based for the deletion of the Platform was incorrect since the user
                                acknowledges and accepts that the automated means used by the Company to identify any illegal or contract
                                breaching actions of users are based either on generalised / automated electronic commands of a software program
                                that may include and / or make errors or on complaints by other Users.
      </p>
                            <h3 className="subtitle">5.5 Termination of the Platform s Function:</h3>
                            <p className="simple-text">
                                The Company reserves the right, for technical reasons, maintenance and / or upgrade of the Platform, to
                                discontinue the function of the Platform/Service temporarily and at regular intervals. In the event that this
                                interruption lasts more than a reasonable period of time at the absolute discretion of the Company or such
                                interruption has already been scheduled by the Company, the latter will notify its Users. It is expressly agreed
                                that Users may not raise any claim against the Company for any loss or damage whatsoever arising out of this
                                reason even if the Company, at its sole discretion, does not give notice of such interruption.
      </p>
                            <p className="simple-text">
                                5.6 Except as expressly set forth herein, the Company may at any time terminate, change, suspend or discontinue
                                any particular function of the Platform, including the availability of the Service itself, provided that it
                                notify Travellers in writing within reasonable notice period before the termination date as per this article.
      </p>
                            <h3 className="subtitle">5.7 Limitation of warranties:</h3>
                            <p className="simple-text">
                                The Company, its directors, shareholders, employees, providers, partners always act in good faith in the context
                                of the law and the present General Terms of Use. Hence it has take all technical and other measures and uses its
                                best endeavours to ensure that (a) the Platform operates continuously and properly without any problems,
                                interruptions, delays, errors or mistakes; (b) defects and/or mistakes are corrected, (c ) data/information
                                transferred through the Platform (d) the technology used by the Company or the Servers, through which the
                                Service is made available to the Users, does not contain viruses or other harmful components, however THE
                                COMPANY DOES NOT PROVIDE ANY RESPECTIVE WARRANTIES for all of the above mentioned.
      </p>
                            <p className="simple-text">
                                5.8 In addition, the Company does not provide any warranty for (a) the appropriate, effective, and efficient
                                function of the Platform with regard to the purpose for which the Traveller uses it; (b) the compatibility of
                                the Platform with third party software that Driver may use, unless these (software) are provided by the Company
                                and only to the extent the Company has guaranteed for them in the relevant licenses provided to the Travellers
                                (c) the proper and efficient execution of the Drivers’ transaction Obligation.
      </p>
                            <h3 className="subtitle">5.9 Limitation of Obligations:</h3>
                            <p className="simple-text">
                                The Platform and the Service is provided “as it is and is made available” and in a fully automated manner and
                                does not include the importation by the Company of any element in the Service’s system nor any customisations on
                                an ad hoc basis. The Application may in the future be parameterised, at the sole discretion of the Company.
      </p>
                            <p className="simple-text">
                                5.10 The Company shall take all necessary technical and other measures to ensure that Travellers comply with the
                                present Terms of Use, however, as a Provider of Information Society Services, it has no general nor statutory
                                obligation to (a) control, (b) actively seek for facts or circumstances that indicate that Traveller has engaged
                                in an unlawful or contract breaching activity while using the Platform/Service, (c) provision of technical
                                support and/or education of the Travellers.
      </p>
                            <h3 className="subtitle">5.11 Limitation of Liability.</h3>
                            <p className="simple-text">
                                In addition to what is expressly stated herein, the Company has no civil, penal and bears no liability towards
                                the Traveller, any Authorised Users, their Affiliates, Employer or employee and / or any third party rendering
                                rights whatsoever, in case that any of these persons, during using the Service, suffer any damage, loss, direct
                                or indirect, incidental or damage for loss of profits, business, income, reputation, sales of products and
                                frequency of services’ provision, or any other direct, indirect or repayable, economic or otherwise, loss of
                                profits, due to:
      </p>
                            <p className="simple-text">
                                5.11.1 Delayed or inappropriate sending, transmission, maintenance, retention, downloading of any data,
                                information or /and Content and / or loss and destruction of such data due to mistakes, omissions, malfunctions
                                or dysfunctions of telecommunication Networks, the Internet, the website, Internet Service Providers.
      </p>
                            <p className="simple-text">
                                5.11.2 Any permanent or temporary disabling of the Service, any termination of the Service or its individual
                                functions, or any technical collision of the Service in accordance with the terms herein.
      </p>
                            <p className="simple-text">
                                5.11.3 Events, situations, acts, actions and / or omissions by the Company or third parties including the
                                Users/Authorised Users for which the Company does not provide any warranties and is not bound by the terms
                                herein.
      </p>
                            <p className="simple-text">
                                5.11.4 Use by the Drivers or third parties of Travellers’ data (including any personal data) for purposes other
                                than the use of the Service
      </p>
                            <p className="simple-text">
                                5.11.5 Infringement of applicable law on the protection of personal data or other applicable legislation which
                                relates, for example, to the use of the Service
      </p>
                            <p className="simple-text">
                                5.11.6 Infringement of the ACCEPTABLE USE OF THE PLATFORM POLICY , the present Terms of Use by the Travellers or
                                other users of the Application.
      </p>
                            <p className="simple-text">
                                5.11.7 Any discrepancy between the time system of the Service and an officially defined time system.
      </p>
                            <p className="simple-text">5.11.8 Events of force majeure.</p>
                            <p className="simple-text">
                                5.11.9 Drivers mis conduct and/or breach of any laws on their behalf. Drivers non show, or delays other than as
                                assumens herein.
      </p>
                            <p className="simple-text">5.11.10 Loss of staff during the transfer or any accident.</p>
                            <p className="simple-text">
                                5.12T HE COMPANY ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES THE TRAVELLER MAY SUFFER INCLUDING BUT NOT LIMITED TO
                                LOSS OF DATA FROM DELAYS, NON-DELIVERIES, ERRORS, SYSTEM DOWN TIME, INCORRECT DELIVERY, NETWORK OR SYSTEM,
                                DAMAGE FILES, INTERRUPTION OF SERVICE CAUSED BY NEGLIGENCE , FROM ERRORS AND / OR OMISSIONS OF THE USERS .
      </p>
                            <p className="simple-text">
                                5.13 COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS, FAILURES DELIVERY OR ANY OTHER LOSS OR DAMAGE ARISING FROM DATA
                                TRANSFER VIA NETWORK AND INSTALLATION COMMUNICATIONS INCLUDING INTERNET WHILE USER – TRAVELLERS RECOGNIZES THAT
                                THE SERVICE AND ANT DATA SENT BY HIM/HER ARE SUBJECT TO LIMITATIONS OF DELAYS AND ANY OTHER PROBLEMS WHICH ARE
                                RELATED TO THE USE OF THESE MEDIA COMMUNICATIONS.
      </p>
                            <p className="simple-text">
                                5.14 In any case, it is expressly agreed that the Company is liable only for direct damages that result from
                                deception or gross negligence on its part. Subject to mandatory legal provisions, the liability of the Company
                                for direct loss due to gross negligence irrespective of legal reason is expressly excluded. The Company’s
                                liability for indirect or consequential damages, irrespective of reason, is completely and explicitly excluded.
                                Subject to the limitations set forth herein and to the extent permitted by law, any liability of the Company is
                                limited to the total amount corresponding to the total cost of the Transport Service.
      </p>
                            <p className="simple-text">
                                5.15 LIMITATIONS AND RESTRICTIONS SET HEREIN WITH REGARD TO DAMAGE COMPENSATION SHALL APPLY EVEN IF THE PRESENT
                                TERMS AND CONDITIONS ARE NOT MET AND SATISFIED OR EVEN IF THEY ARE CONSIDERED VOID OR UNENFORCEABLE AND THE USER
                                ACKNOWLEDGES THAT THESE LIMITATIONS ARE JUST AND CONSTITUTE THE BASIS OF THIS DOCUMENT AND THAT WITHOUT THEM THE
                                COMPANY WOULD NOT PROCEED.
      </p>
                            <p className="simple-text">
                                5.16 Provision of Information : The Company, if so requested by a competent prosecution, supervisory, judicial
                                or other Authority, is obliged to transmit the data of the Users and/or of Third Parties, including any personal
                                data of Authorised Users provided to the Company in accordance with the present General Terms, as well as any
                                other data and any other information provided by them without their prior notice or consent, on the grounds of
                                necessary protection measures against risks to state and public security as well as for prosecution reasons,
                                including tax crimes and offences.
      </p>
                            <h2>6. Charges and Payment Policy</h2>
                            <p className="simple-text">
                                The Company facilitates pre-payment via PayPal, Checkout or Shopify, Checkout or Shopify of a
                                Traveller’s Transfer, The cost of a Transfer (“Transfer Cost”) is the amount shown to You when You get a quote
                                via the Platform or the amount shown to You before payment. Transfer cost includes Driver’s fee and Company’s
                                revenue. The Company’s Revenue is calculated on a percentage basis or may be a fixed fee and its calculation may
                                change without any previous notice to You and according to the Company’s will and as it is deemed necessary for
                                Company’s business.
      </p>
                            <p className="simple-text">
                                The Driver is solely responsible for providing a printed receipt to the Traveller and abiding with local tax
                                regulations on the income generated by the Transfer. We do not hold any responsibility whatsoever for tax
                                compliance of the Driver and/or Traveller with local tax authorities.
      </p>
                            <p className="simple-text">
                                Refunds: The full amount of the Transfer Cost (including the Company’s Revenue), is charged immediately
                                following completion of such election to the Traveller’s authorized PayPal, Checkout or Shopify
                                account and transferred (less the Company’s Revenue) to such Driver’s account.
      </p>
                            <p className="simple-text">About the Refund Policy please see section II. 3 -Policies of Transport Services.</p>
                            <p className="simple-text">
                                Promotional Offers: The Company, at its sole discretion, may make available promotional offers with different
                                features to any of Travellers.
      </p>
                            <p className="simple-text">
                                Facilitation of Payments: All Donations or Charges, as applicable, shall be facilitated through PayPal or
                                Checkout or Shopify, Company’s third-party payment processing service.
      </p>

                            <h2>7. Traveler Opinions</h2>
                            <p className="simple-text">
                                7.1 Only the Travellers who have received the Transport Service may rate / comment the Transport Service, the
                                Drivers, their vehicles or whatever else they deem appropriate and worthwhile of annotation or of positive or
                                negative remark.
      </p>
                            <p className="simple-text">
                                7.2 In particular, the Company, upon completion of the Transport, sends an email to the Traveler in order to
                                invite them to rate the Driver who carried out the Transport, and the Company. The Traveler rates the Driver by
                                setting a score from one (1) up to five (5) stars. For a score of three or less stars, the Traveler should
                                choose one of the reasons presented to them to justify their negative feedback. In any case the Traveler has the
                                right to send to the Company comments regarding the Driver, the Company, the Transport and / or anything else.
      </p>
                            <p className="simple-text">
                                7.4 The Company does not indemnify or reward Travelers who have sent their rating / feedback. Travelers’ review,
                                rating and comments may be used by the Company at its absolute discretion to inform Travelers for marketing
                                purposes and for purposes of its own evaluation of the Drivers.
      </p>
                            <p className="simple-text">
                                7.5 Travelers have the right to publish ratings / comments in public media, for example, social media or
                                websites such as FB, TRIPADVISOR and it is at the discretion of the Company whether to consider these ratings /
                                comments too, as part of Your evaluation by the Company and how these are managed in general.
      </p>

                            <h2>8. DISCLAIMER</h2>
                            <p className="simple-text">
                                By entering into this agreement and using the software, the Platform or Service, to the extent permitted by law,
                                You agree that You shall defend, indemnify and hold the company, its licensors and each such party’s parent
                                organizations, subsidiaries, affiliated companies, distributors, affiliate (distribution) partners,
                                shareholders, officers, directors, employees, representatives, members, attorneys, licensees, agents, or others
                                involved in creating, sponsoring, promoting, or otherwise making available the software and its contents
                                harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including
                                attorneys’ fees and costs) arising out of or in connection with: (i) any punitive, special, indirect or
                                consequential loss or damages, any loss of production, loss of profit, loss of revenue, loss of contract, loss
                                of or damage to goodwill or reputation, loss of claim, (ii) any inaccuracy relating to the (descriptive)
                                information (including personal data, comments, and ratings) of the users as made available on our software,
                                (iii) the services rendered by the driver, (iv) any (direct, indirect, consequential or punitive) damages,
                                losses or costs suffered, incurred or paid by You (including attorneys’ fees and costs), pursuant to, arising
                                out of or in connection with the use, inability to use or delay or virus of our software and/or the server that
                                the software is hosted, or (v) for any (personal) injury, death, property damage, or other (direct, indirect,
                                special, consequential or punitive) damages, losses or costs suffered, incurred or paid by You, whether due to
                                (legal) acts, errors, breaches, (gross) negligence, willful misconduct, omissions, non-performance,
                                misrepresentations, tort or strict liability by or (wholly or partly) attributable to the user (its employees,
                                directors, officers, agents, representatives or affiliated companies)(including attorneys’ fees and costs),
                                including any (partial) cancellation, double-booking, strike, force majeure or any other event beyond our
                                control (vi) any information on this software sometimes linked to external sites over which our services have no
                                control and for which we assume no responsibility (vii) any user violation or breach of any term of this
                                agreement or any applicable law or regulation, whether or not referenced herein.
      </p>

                            <h2>PRIVACY POLICY</h2>
                            <h2>I. INTRODUCTION</h2>
                            <h2>1. General</h2>
                            <p className="simple-text">
                                We take privacy seriously and We respect and protect Your personal data. We are “W ELCOME TRAVEL TECHNOLOGIES
                                HOLDINGS LTD” , with no 10879236, headquartered in c/o Harrison Beale & Owen Seven Stars House, 1 Wheler Road,
                                Coventry CV3 4LB, UK with VAT np: 289 0786 46, with the following contact details: email:
                                privacy@welcomepickups.com (hereinafter the “ Company ”, “ We” , “ Us, ” “ Our ”) and We are the controllers of
                                Your Personal Data.
      </p>
                            <p className="simple-text">
                                This Privacy Notice tells Travellers (hereinafter the “Traveller(s)”, “You”, “Yours”, “User”) what information
                                about You We collect, what We do with it and what rights You have in relation to any of Your personal data being
                                processed by Us. . Click on “f ind out more” in each section for further information.
      </p>
                            <p className="simple-text" />

                            <h2>2. Amendments</h2>
                            <p className="simple-text">
                                This is the first version of Our Privacy Policy and is in force since the 25t h of May 2018. This Privacy Policy
                                is in accordance with the Regulation 2016/697 for the protection of individuals from the processing of personal
                                data and any other European Community law relating to the protection of Your personal data. Any future
                                amendments to the applicable regulatory framework will be incorporated in this Policy. Therefore, we reserve the
                                right to update this Privacy and any supplemental privacy notice at Our sole discretion, from time to time. We
                                will notify you of the changes where required by law to do so. Should You not agree with the changes, or have
                                any dispute, reservation or question about them (changes), You can contact Us at _p rivacy@welcomepickups.com.
                                Please note that any information / clarification given to you in connection with any changes to this Policy does
                                not constitute a substitution, or any modification of this Policy.
      </p>
                            <p className="simple-text">
                                By continuing to use Our Service by any means, You accept automatically and unconditionally the modified terms
                                of this Policy. In the event that You do not agree with the modifications, You should not make any action or use
                                of the Platform, and provide us Your personal data and you are entitled to terminate the contracts between us
                                and request the deletion of Your Account and Your data, to the extent permitted by applicable law. For any
                                information or clarification, You can contact us and in any case You have the right to exercise Your rights as
                                detailed in Section V below.
      </p>

                            <h2>3. Content of the Privacy Policy</h2>
                            <p className="simple-text">Our Privacy Policy includes:</p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    information about the Data Controller information on how to conduct Us for any issue regarding Your personal
                                    data.
        </li>
                                <li className="point-marker-list-item">
                                    the type of personal data that we collect for You and methods of collection
        </li>
                                <li className="point-marker-list-item">
                                    the purpose of collection, the processing activities of Your personal data and the legal ground for the
                                    processing;
        </li>
                                <li className="point-marker-list-item">
                                    security measures that we undertake for the protection of Your personal data and Our data retention policy;
        </li>
                                <li className="point-marker-list-item">
                                    Your rights and the ways to exercise them and any options You may have regarding processing of Your personal
                                    data
        </li>
                                <li className="point-marker-list-item">
                                    information about the protection and storage of Your personal data by our Company
        </li>
                            </ul>

                            <h2>II. DATA CONTROLLER AND DATA PROTECTION OFFICER</h2>
                            <h2>1. Who is the Data Controller?</h2>
                            <p className="simple-text">
                                Data Controller of Your personal data is the company under the name “W ELCOME TRAVEL TECHNOLOGIES HOLDINGS LTD”
                                , with no 10879236, headquartered in c/o Harrison Beale & Owen Seven Stars House, 1 Wheler Road, Coventry CV3
                                4LB, UK with VAT np: 289 0786 46 (“ Company ”, “ us, ” “ our ,” and “ we ”).
      </p>
                            <h2>2. Questions and Comments</h2>
                            <p className="simple-text">
                                If You have any questions, or wish to exercise any of Your rights, You can conduct Us at the following contact
                                details: email:p rivacy@welcomepickups.com. If Your country has a data protection authority, You have a right to
                                contact it with any questions or concerns. If We cannot resolve Your questions or concerns, You also have the
                                right to seek judicial remedy before a national court.
      </p>

                            <h2>III. COLLECTION AND PROCESSING OF PERSONAL DATA</h2>
                            <h2>1. WHAT personal data do we collect and HOW we collect them</h2>
                            <p className="simple-text">
                                We collect some of Your personal data when You use the Service and book Your transfer, or buy a product from or
                                through Us. We collect different types of personal data either directly from You or from third parties through
                                automatic means such us (indicatively):
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    Identity data: You grant us when You get a price quote i.e Your pick up address, when you book a transfer,
                                    Your name, surname, email, telephonel etc.
        </li>
                                <li className="point-marker-list-item">
                                    Communication Data: You provide Us with Your mobile phone number and Your email, a means to either Us or the
                                    Driver who picks You up, to communicate with You about the Transfer.
        </li>
                                <li className="point-marker-list-item">
                                    Payment Data You provide Us with Your bank card number, the expiration date of the card, the CVV etc. in order
                                    to pay for the Transfer and Your email and/or full name when You pay via Paypal
        </li>
                                <li className="point-marker-list-item">
                                    Transport Data (History): The total number of Transfers You have made through Us in cases You have an Account
                                    with Us.
        </li>
                                <li className="point-marker-list-item">
                                    Technical / Device Data: The type of software used when entering the Platform, when using it, etc, IP address,
                                    login information, browser type and version, operating system and platform and other technology on the devices
                                    You use to access our Platform, etc.
        </li>
                                <li className="point-marker-list-item">
                                    Login Data, when You log in Your account in the Platform which is Your email and password.
        </li>
                                <li className="point-marker-list-item">
                                    Profile Data created from the information You provide to us when You make a booking, from data regarding Your
                                    preferences and provided to Us optionally, from Your interaction with the Newsletters (e.g., how many and what
                                    newsletters You open and when), from Your purchase habits deriving from products You may buy from Us, from
                                    information derived from Your browsing (kind, time, cost), from Your feedback on the market research that our
                                    Company may conduct from time to time, from Your feedback and Your questions to us or any complaints from Your
                                    interaction with Social Media, or directly from Our Customer care, from the information You voluntarily upload
                                    to Social Media, from information collected from cookies we use, from data that You grant us You when You
                                    complete questionnaire that we send to You following the payment etc (such as nationality, consumer
                                    preferences etc).
        </li>
                                <li className="point-marker-list-item">Demographic data : age, city, Postal Code etc.</li>
                                <li className="point-marker-list-item">
                                    Cookie data: number of visits to the Platform, visitor interaction with the Platform (see analytically Cookies
                                    Policy ).
        </li>
                                <li className="point-marker-list-item">
                                    Customer service data: when You call Customer Support Team, we collect Your full name, voice data etc.
        </li>
                            </ul>
                            <p className="simple-text">
                                Platform and its services are provided exclusively to people who are at least 18 years of age or older. If You
                                are under 18, please do not use or provide us with any personal data, and do not use the Platform and the
                                Service.
      </p>

                            <h2>Learn more…..</h2>
                            <p className="simple-text">We collect directly from You the following personal data:</p>
                            <p className="simple-text">
                                – When You open an Account with Us and also book a Transfer, You provide us with identity data, contact data,
                                payment data etc
      </p>
                            <p className="simple-text">
                                – When You communicate with us, we collect identity data and communication data, and customer service data if
                                You contact Customer Support Team etc.
      </p>
                            <p className="simple-text">We collect the following data and information for You automatically.</p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">
                                    By using cookies and other related technologies, we collect and / or create data about Your preferences, the
                                    type of newsletter You open or not, its content and your interaction with us after each newsletter, the type
                                    of Transfer You booking, the search terms You’ve entered or the links You clicked on the Platform etc,
        </li>
                                <li className="point-marker-list-item">
                                    From Your bookings, we create data about Your bookings, and booking history, Your preferences etc.
        </li>
                                <li className="point-marker-list-item">
                                    Data about the devices through which You visit our Platform, such as IP address, login information, browser
                                    type and version, operating system and platform, and other technology on the devices You use to access our
                                    Platform, etc.
        </li>
                                <li className="point-marker-list-item">
                                    Data about the page from which You log in and the page You visit when You leave the Platform (i.e. your
                                    Browsing history)
        </li>
                            </ul>
                            <p className="simple-text">We collect f rom third parties the following data and information about You:</p>
                            <p className="simple-text">– from Drivers when they are ask to rate a ride</p>
                            <p className="simple-text">– from Hotels when a transfer is booked through Your Hotels Platform.</p>

                            <h2>· 2. HOW and WHY do we use Your personal data.</h2>
                            <p className="simple-text">
                                We use Your data for the legal purposes referred below. However, where the European laws restrict or prohibit
                                certain activities described in this notice, we will not use information about You for those purposes.
      </p>
                            <p className="simple-text">Subject to the above, We may use information about You for the following purposes :</p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">To subscribe You to the Platform and open your Account.</li>
                                <li className="point-marker-list-item">To identify You every time You log in.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">To provide you with the Service.</li>
                                <li className="point-marker-list-item">
                                    To handle / process Your requests, such as change of a Transfer that You have booked, change Your personal
                                    details, exercise Your rights.
        </li>
                                <li className="point-marker-list-item">
                                    To transmit documents and information necessary for the use of the Platform and the Service.
        </li>
                                <li className="point-marker-list-item">For marketing purposes.</li>
                                <li className="point-marker-list-item">For security reasons for the transactions.</li>
                                <li className="point-marker-list-item">
                                    for business analysis and enhancements, such as making our Services available and optimized, optimizing Your
                                    experience and service within the Platform, and customizing Your experience in Platform
        </li>
                                <li className="point-marker-list-item">For statistical analysis.</li>
                                <li className="point-marker-list-item">To comply with regulatory obligations.</li>
                                <li className="point-marker-list-item">
                                    For other purposes that we will notify You, or identify to You on a case-by-case basis, at the point where
                                    Your information is originally collected.
        </li>
                            </ul>
                            <p className="simple-text">
                                The legal basis for our use of information about You is one of the following (which we explain in more detail in
                                the “find out more” section):
      </p>
                            <ul className="point-marker-list-point">
                                <li className="point-marker-list-item">compliance with a legal obligation to which we are subject;</li>
                                <li className="point-marker-list-item">the performance of a contract to which You are a party;</li>
                                <li className="point-marker-list-item">
                                    a legitimate business interest that is not overridden interests You have to protect the ·information;
        </li>
                                <li className="point-marker-list-item">
                                    where none of the above applies, Your consent (which we will ask for before we process the information).
        </li>
                            </ul>

                            <h2>·find out more…</h2>
                            <p className="simple-text">
                                Purpose of processing Data that we processfor the purpose Legal basis For Your Subscription to the Platform and
                                opening of Your Account.
      </p>
                            <p className="simple-text">We process Your data</p>
                            <p className="simple-text">
                                – for Your identification
        <br />
        – to recognize You as a Platform user.
        <br />
        – To give You access to the features and service of the Platform.
        <br />– to use the Platform Service/ Features- Identity data- Communicationdata- Log in DataLegal basis is:
      </p>
                            <p className="simple-text">
                                a) your consent upon Your subscription to the Platform.
        <br />
        b) our legitimate interest to identify You if necessary, for the avoidance of any fraud or security incident.
        <br />
        c) fulfilling of our contractual obligations
      </p>
                            <p className="simple-text">
                                a) to give You access to the features and service of the Platform in order to use it b) for
                                creation/administration of Your account in the Platform. For booking a transfer & for the provision of our
                                Service We process Your data:
        <br />
        – for booking a transfer
        <br />
        – filing of a transfer order
        <br />
        – To send a request to Driver for Your pick Up.
        <br />
        – for identification
        <br />
        – to contact You for any case regarding Your Transfer
        <br />
        – to contact You for any case regarding the Platform and the Service.
        <br />
        – to process any payment and generally to proceed to any transaction with You regarding the Trans
        <br />
        – to activate mechanisms to prevent fraud against You or against us
        <br />
        – Identity data
        <br />
        – Communication data
        <br />
        – Payment data
        <br />
        Legal basis is: a) contractual obligation to fulfill Your transfer booking.
        <br />
        b) legal obligation of our Company to comply with tax law,
        <br />
        c) legitimate interest to collect payments, detect any fraud.
        <br />
        d) consent in specific processing such us storage of data for future purposes etc. Support, handling of Your
        requests- Communication regarding Transfer & the Platform.
      </p>
                            <p className="simple-text">
                                We process Your data for:
        <br />
        – Contacting You regarding Transport Services
        <br />
        – Your support in general on issues related to the Company, its services, Your account in the Platform
        <br />
        – Complaints or clarifications
        <br />
        – Generally management and optimization of user experience in the Platform.
        <br />
        – Customer care for issues related to the Company, Platform, transfers, services, bookings etc.
        <br />
        – Complaints or clarifications
        <br />
        – Exercise of Your rights
        <br />
        – Identity data
        <br />
        – Communication Data
        <br />
        – Information that You provide us related to Your request
        <br />– And data that we create for You. In case a call is recorded, You will be notified beforehand by a
        relevant audio message.
      </p>
                            <p className="simple-text">
                                Legal basis is :<br />
        a) legal obligation of our Company to have means for customer care before and after booking, to reply to Your
        inquiries about exercising of Your rights.
        <br />
        b) legitimate interest of our Company to reply to Your requests, to optimize customer care services and
        communication with Travellers, and also to handle any case in the best way to the benefit of data subject and
        that is reasonably expected by data subjects.
        <br />
        c) contractual obligation if You contact us about Your Transfer Αdvertising & Marketing
        <br />
        – to send newsletters generic and personalized.
        <br />
        – to send marketing communication via email, or multimedia, push notifications etc.
        <br />
        – to run promotions, loyalty programmes, etc.
        <br />
        – Customer’s satisfaction with Company’s services.
        <br />
        – Personalized marketing communication via personalized messages based on Traveller’s preferences.
        <br />
        – Identity data
        <br />
        – Communication data,
        <br />
        – Profiling data,
        <br />
        – Demographic data,
        <br />
        – Cookies data
        <br />
        – orcombination of all the above data.
        <br />
        a) the legitimate interest of our Company to process Traveller’s data provided to us:
        <br />
        i) for the Transfer or other transaction
        <br />
        ii) for the purpose of direct commercial communication for related services or purposes. In these cases, you may
        request to opt-out with a click on the unsubscribe button in the email or by contacting with us. In addition,
        the Company has the legitimate interest to make Your profile based on all information that we have for You (e.g.
        transfer history, Your preferences) and also based on Your purchases and Your interaction with newsletters, Your
        place of residence, in order to enhance your experience. b) Your consent you provide us voluntarily to send you
        newsletters and personalized information via email, and other multimedia and in case you agree to send you push
        notifications. Business analysis and improvements for :<br />
        – Unceasing Platform operation
        <br />
        – technical excellence and security of transactions
        <br />
        – optimization of technical systems
        <br />
        – addressing technical issues
        <br />
        – compiling reports and keeping data
        <br />
        – Log in data
        <br />
        – Identity data
        <br />
        a) the legal obligation to secure information and confidentiality
        <br />
        b) the legitimate interest for security of networks, avoidance of any fraud and unauthorized access to data, for
        our business continuity, upgrading our systems and our partners’ system, for our business development,
        optimization of our technical systems and processes, any data process that prevails rights and freedoms of data
        subjects that data subjects reasonably expect to be processed for this purpose. You have the right to oppose the
        above processing per data processing case. It should be noted, however, that the Company is entitled to
        demonstrate legal reasons for the processing to prevail interests, rights and freedoms of data subjects.
        Statistical analysis to rate and improve Platform services and procedures that includes :<br />
        – Rating and improvement of business processes
        <br />
        – Responsiveness and management of Your requests
        <br />
        – Responsiveness and management of the Platform
        <br />
        – Research and / or analysis to understand needs of users
        <br />
        – Adoption of new business and business models,programs and partnerships.
        <br />
        – Improvement of Your experience
        <br />
        – Delivery of related content
        <br />
        – Quality surveys
        <br />
        – Statistical analysis
        <br />
        – Financial data
        <br />
        – Transfer history data
        <br />– Profile data
      </p>
                            <p className="simple-text">
                                a) the legitimate interest of the Company for the further processing of aggregated data for the purpose of
                                statistical analysis to improve its products and services while complying with all the necessary safeguards to
                                collect and process data that do not identify a particular data subject and do not affect the rights and
                                freedoms of data subjects.
        <br />
        b) the legitimate interest to, analyze usability and functionality of the Platform, to improve our business
        performance for our users, to improve our partnerships, always respecting Your rights, freedoms and interests
        for Your personal data. Provision of personalized services, personalized Traveler experience:
        <br />
        – transfer data to Your personal Driver in order to welcome You and provide You a personalized experience during
        Your transfer
        <br />
        – Share data with the partner hotel in case You have booked a transfer with our Company though hotel’s page in
        order to provide You a personalized experience during Your accomodation at the hotel .<br />
        – Identity data
        <br />
        – Profile data
        <br />
        Your consent to provide us voluntarily personal data in order for Us or Our partners to provide You with
        personalized services/experiences.
        <br />
        3. WHO do we share Your information with and for what purposes?
        <br />
        For the operation of the Platform, fulfillment of contractual obligations and for Your best support as user, our
        Company reserves the right to cooperate with third-party service providers that provide us with their services
        and access only those data that are absolutely necessary for the service they offer us (e.g. subscribing to the
        Platform and managing Your account, execute our contract between us, optimization of our services, etc.). These
        third service providers are bound by contract not to use Your information for any other purposes.
        <br />
        More specifically, our Company cooperates with:
        <br />
        (a) a third company acting as processor and hosts data that You provide us through the Platform
        <br />
        (b) Data analysis companies
        <br />
        (c) Research companies
        <br />
        (d) third-party email marketing companies acting in the name and on our behalf to send You the newsletter.
        <br />
        (e) advertising and marketing companies
        <br />
        (f) Data analysis companies
        <br />
        (g) Research companies
        <br />
        (h) Fraud Detection and Prevention Organizations,
        <br />
        (i) Providers of technology services, We reserve the right to disclose Your personal data to a third party that
        we choose to transfer all or part of our business. In addition, in the event of a merge or redemption or other
        change in our business, the new owners, etc. have the right to use Your personal data in the same way according
        to this Privacy Policy. Your data may be communicated to competent judicial, police and other administrative
        authorities upon their request and in accordance with the applicable laws. Moreover, in case of a statutory
        provision, a service order or a formal preliminary examination, our Company has the right to place the relevant
        information at the disposal of the respective authority.
        <br />
        IV. PROTECTION AND MANAGEMENT OF YOUR PERSONAL DATA
        <br />
        1. SECURITY of Your personal data We take appropriate technical and organizational measures to protect Your
        personal data from unauthorized disclosure, use, conversion or destruction. Where appropriate, we use encryption
        and other technologies that can help to secure any information You provide us. We also ask our service providers
        to comply with privacy and data protection requirements.
      </p>

                            <h2>·find out more…</h2>
                            <p className="simple-text">
                                More specifically, Your personal data are managed exclusively by specially authorized personnel of the Company
                                under our control. In order to conduct the processing, the Company selects individuals or third parties with
                                corresponding professional qualifications that provide sufficient guarantees in terms of technical knowledge and
                                personal integrity to maintain confidentiality. The Company, through its respective contractual commitments and
                                its partners, takes all necessary security measures to protect and secure confidentiality and integrity of
                                personal data. In any case, the security is subject to reasons beyond Company’s influence, as well as to reasons
                                resulting from technical problems of the network that are not controlled by the Company or reasons of force
                                majeure events. You should not disclose the data / passwords You have for Your account, which are personal and
                                non-transferable. For personalized communication, our Company also uses appropriate mathematical and / or
                                statistical processes to compile the profile and performs regular quality and security checks on the systems and
                                algorithms it uses to correct the factors that lead to inaccuracies in the data.
      </p>
                            <p className="simple-text">2. HOW LONG will information about You be kept</p>
                            <p className="simple-text">
                                We will retain information about You for the period necessary to fulfill the purposes for which the information
                                was collected. After that, Your account will be deleted. Such period will vary depending on the purposes for
                                which the information was collected. Please note that in some circumstances, You have the right to request a
                                deletion of the information. Furthermore, we are sometimes legally obliged to retain the information, for
                                example, for tax and accounting purposes. We may also keep it until You request its deletion, or in case of data
                                based on Your consent, until You withdraw Your consent, or until You opt out for the processing for which we
                                have legitimate interest. To determine data retention time of Your personal data, we take into account the
                                nature of Your data, the quantity, purpose, security, etc. You have the right to request from us to delete Your
                                data. To exercise Your right, please visit the relevant Section in this Privacy Policy. We reserve the right in
                                certain circumstances to anonymize Your data for research or statistical purposes, so that it cannot be
                                associated with an identifiable person, therefore we reserve the right to use this information for an indefinite
                                period of time. In any case, Your data is stored with safety.
      </p>

                            <h2>·find out more…</h2>
                            <p className="simple-text">
                                Management of Your account in the Platform Until Your account is deleted or after 2 years of the last action.
                                Transfer Orders We will process Your data for the time required to manage a Transfer and/or products or services
                                You have ordered, including any necessary time to refund, handling complaints or claims relating to the Transfer
                                or service. We may retain some of Your information that You may provide us for more time, until You ask us to
                                stop storing it. Transfers If You book a Transfer, we will retain details of this for so long as required to
                                complete the Transfer and to comply with any legal obligations (for example, for tax and accounting
                                record-keeping purposes). We may only store aggregated data for the Transfer for business analytic purposes.
                                Customer care If You contact the Customer Support team of the Company, we will make a record of the matter
                                (including details of Your enquiry and our response) and retain it while it remains relevant to our
                                relationship. Temporary records may be relevant only until more permanent records are made, and will be retained
                                only temporarily. Usability and quality analysis We will process Your data during the period we will perform an
                                action or a specific quality survey or until we have anonymized Your browsing data Marketing Until You
                                unsubscribe from our newsletter recipients list, or until You withdraw Your consent, or until You oppose (opt
                                out) processing, otherwise we will retain Your data for five (5) years maximum. However, some elements of Your
                                profile, such as files about how we interact with You, may stop to be in force after a period of time, so we
                                automatically delete them after a specified period (usually 3 years) depending on the purpose for which we have
                                collected them. if we do not have communication with You for a long time (usually 3 years), we will stop sending
                                You promotional messages and we will delete history of Your responses. This will happen, for example, if You
                                never click on our Newsletter, if You never log in to a digital contact point or if You never contact us or do
                                not book a transfer, or contact customer service. The reason is that, in these cases, we assume that You prefer
                                not to receive any message from us. Business analytics Business analytics data is typically collected
                                automatically when You use Company’s touchpoints and becomes anonymised/ aggregated shortly afterwards.
      </p>
                            <p className="simple-text">3. TRANSFER of Your personal data to third countries</p>
                            <p className="simple-text">
                                The Company generally keeps Your personal data within the European Economic Area. When Your data is to be
                                transmitted to third countries outside the European Economic Area or International Organizations for which no
                                European Commission decision is available, all the appropriate safeguards provided for in the applicable data
                                protection legislation on the transfers to third countries.
      </p>
                            <p className="simple-text">4. YOUR RIGHTS AND YOUR OPTIONS</p>
                            <p className="simple-text">
                                You may have some or all of the following rights in respect of information that we hold about you:
        <br />
        · request us to give You access to it;
        <br />
        · request us to rectify it, update it, or erase it;
        <br />
        · request us to restrict our use of it, in certain circumstances;
        <br />
        · object to our using it, in certain circumstances;
        <br />
        · withdraw Your consent to our using it;
        <br />
        · data portability, in certain circumstances;
        <br />
        · opt out from our using it for direct marketing; and
        <br />· lodge a complaint with the supervisory authority in Your country (if there is one). We offer You easy
        ways to exercise these rights, by calling at Customer Support Team by sending email at
        Privacy@welcomepickups.com. Some mobile applications we offer might also send You push messages, for instance
        about new products or services. You can disable these messages through the settings in Your phone or the
        Platform. It may be necessary, for the security of Your information, to ask for some information about You for
        the purposes of Your identification. Your right is exercised free of charge, however, when your right is
        abusively used, we may ask You a fee in accordance with the conditions set by law. In any case, we respond to
        Your requests within one month, except in exceptional cases where our response time to a request may be longer.
      </p>

                            <h2>·find out more…</h2>
                            <p className="simple-text">
                                Right in respect of the information about You that we hold Further detail (note: certain legal limits to all
                                these rights apply)
        <br />
        · to request us to give You access to it (article 15)
        <br />
        This is confirmation of:
        <br />
        · whether or not we process information about You;
        <br />
        · our name and contact details;
        <br />
        · the purpose of the processing;
        <br />
        · the categories of information concerned;
        <br />
        · the categories of persons with whom we share the information
        <br />
        · (if we have it) the source of the information, if we did not collect it from You;
        <br />
        · (to the extent we do any, which will have been brought to Your attention) the existence of automated
        decision-making, including profiling, that produces legal effects concerning You, or significantly affects You
        in a similar way, and information about the logic involved, as well as the significance and the envisaged
        consequences of such processing for You; and
        <br />
        · the criteria for determining the period for which we will store the information. On Your request we will
        provide You with a copy of the information about You that we use (provided this does not affect the rights and
        freedoms of others).
        <br />
        · to request us to rectify or update it (article 16) This applies if the information we hold is inaccurate or
        incomplete.
        <br />
        · to request us to erase it (article 17)
        <br />
                            </p>
                            <p className="simple-text">
                                This applies if:
        <br />
        · the information we hold is no longer necessary in relation to the purposes for which we use it;
        <br />
        · we use the information on the basis of Your consent and You withdraw Your consent (in this case, we will
        remember not to contact You again, unless You tell us You want us to delete all information about You in which
        case we will respect Your wishes);
        <br />
        · we use the information on the basis of legitimate interest and we find that, following Your objection, we do
        not have an overriding interest in continuing to use it;
        <br />
        · the information was unlawfully obtained or used; or
        <br />
        · to comply with a legal obligation.
        <br />
        · to request us to restrict our processing of it (article 18) This right applies, temporarily while we look into
        Your case, if
        <br />
        You:
        <br />
        · contest the accuracy of the information we use; or · have objected to our using the information on the basis
        of legitimate interest (if You make use of Your right in these cases, we will notify You before we use the
        information again).
        <br />
        This right applies also if:
        <br />
        · our use is unlawful and You oppose the erasure of the data; or
        <br />
        · we no longer need the data, but You require it to establish a legal case.
        <br />
        · to object to our processing it (article 21) You may at any time object to any processing of Your personal
        data, which are processed under the legitimate interest of the Company or the fulfillment of a duty performed in
        the public interest.
        <br />
        · to withdraw Your consent to our using it (opt- out) This applies if the legal basis on which we use the
        information about You is consent. These cases will be clear from the context.
        <br />
        · to data portability (article 20)
        <br />
        If:
        <br />
        (i) You have provided data to us; and
        <br />
        (ii) we use that data, by automated means, and on the basis either of Your consent, or on the basis of
        discharging our contractual obligations to You, then You have the right to receive the data back from us in a
        commonly used format, and the right to require us to transmit the data to someone else if it is technically
        feasible for us to do so.
        <br />
        · to lodge a complaint with the supervisory authority You can lodge a complaint with the supervisory competent
        authority in Your country.
        <br />
        · Identity card We take into account the confidentiality of all files that contain personal data and we reserve
        the right to ask You for proof of Your identity if You make a claim about those files.
        <br />
        · Costs We will not charge You for the exercise of Your rights in relation to Your personal data unless, as
        required by law, Your request for access to information is unreasonable or excessive, so we may charge a
        reasonable fee under these conditions. We will notify You of any charges before fulfilling Your request.
        <br />· Timelines We aim to respond to any valid requests within one (1) month of receipt, unless it is very
        complex or You have made a number of requests, so we aim to respond within three months. We’ll let You know if
        we’ll need more than one (1) month for the reasons outlined above. We may ask You if You can tell us exactly
        what You want to receive or what exactly is Your concern. This will help us to act faster for Your request. In
        any case, You should provide us with specific and truthful facts and / or facts in order to be able to answer
        and / or satisfy Your request, otherwise we reserve the right to make any errors that are out of our control. In
        addition, our Company may refuse requests that are unjustified or excessive or abusive or inaccurate or
        generally illegal according the provisions of the law.
      </p>
                            <p className="simple-text">
                                IV. GENERAL LEGAL TERMS
        <br />
        1. Copyright
        <br />
        1.1 The Company is the proprietary and lawful owner of all Intellectual Property Rights in the Platform and the
        Service and grants Travellers the right to access the Platform and use the Service under the present Terms of
        Use (“License”). Limitations of the License are referred to the ACCEPTABLE USE OF THE PLATFORM POLICY of the
        Platform .<br />
        1.2 The Company is the owner and retains in its possession and ownership all rights, titles and interests
        regarding the Platform and the Service, its functions and features (indicatively but not restrictively: its
        Software, its programs, philosophy, look and feel, methodology and technique by which it has been designed, its
        model, algorithms, information and materials, know-how regarding the Platform, the Service and Software, as well
        as any modifications, notifications, improvements,parameterizations, derivative functions, manuals and other
        documents related to the Implementation and operation of the Platform.
        <br />
        1.3 All the content of the Platform /Service, such as texts, graphics, logos, icons, images, etc, is the
        property of the Company and are protected by the Legislation of England and Wales, European Law and
        International Conventions and therefore the Traveller has no right to the Platform/Service other than those
        granted to him under the License under the present Terms of Use.
        <br />
        1.4 The names, images, logos and distinctive features representing Our Company and its products / services, such
        as the brand name “WELCOME”, “WELCOME PICKUPS” and the products / services provided under this brand name and/or
        any of its derivative, or composite, homonymous or similar names, and/or any other logos, trademarks and
        distinctions, trade secrets, patents and any other intellectual property rights in respect of the Platform/
        Service and the functions that become known to the Traveller through the Service, are the exclusive trademarks
        and distinctive features of the Company and are protected by UK, European and international trademark laws, and
        industrial and intellectual property and competition law. In any case, their exposure to the Platform shall in
        no way grant You and/or any third party a license or right to use them.
        <br />
        1.5 With these General Terms of Use, We grant You a limited and non transferable license to access and use the
        Platform and Service, but not permission to download the Content and the Code of the Platform in its entirety or
        in part, unless We give You our express written permission. This license does not allow any resale or commercial
        use of the Platform/ Service or its content, collection and use of our catalogs, our products, our commercial
        policy, data mining, etc. You are entitled to use the above only for Your personal use.
        <br />
        1.6 You may not use any “post-tags” or any other “hidden text” based on our Company’s brand names or trademarks
        without our express written consent. In the event of an unauthorized use, the License granted shall cease to be
        valid. The same applies to any use of our Company’s logos and trademarks. Any copying, analog / digital
        recording and mechanical reproduction, distribution, transmission, downloading, processing, resale, of part or
        of all the Platform Content for any purpose other than strictly personal use is forbidden, unless we give You
        our writing consent.
        <br />
        2. Technical Support – Enhancements of Platform The Company has no obligation and is not currently bound to
        provide technical support, improvements, customizations and/or renewals to the Platform and/or the Service. Only
        occasionally and at its sole discretion it may upgrade the Platform and the Platform. You may also need to make
        software updates, otherwise the Company has no responsibility for any incompatible features of the version of
        the Platform You are using. In any case, You acknowledge and agree that these General Terms govern any current
        version of the Platform and the Service.
        <br />
        3. External Links
        <br />
        The Platform may contain links to third party websites whose information and data protection practices are
        different to those of the Company. The Company is not liable for the information or data protection practices
        used by third parties on their websites. It is recommended, before using other websites, to read and understand
        the terms of use and their privacy policy. The Company does not check the availability, content, privacy policy,
        quality and completeness of other web sites that the Platform may refer to through “links”, hyperlinks, or
        banners. The Company shall in no case be deemed to embrace or accept the content or services of the websites and
        pages to which it refers to or that it is in any way affiliated to them.
        <br />
        4. Contact
        <br />
        By using the Platform/ Service, You agree to receive electronic communications from us and You unreservedly
        accept that they meet all legitimate written communication requirements as to the reason they are created. For
        any complaints, comments, suggestions, etc. You want to submit to us, please contact Our Customer Service at
        hello@welcomepickups.com.
        <br />
        5. Final Terms
        <br />
        The present General Terms are the definite and unique terms in force, regarding the provision of the Service by
        the Company to the User and any prior terms, prior agreements and arrangements, written or oral between the
        Company and the User regarding the use of the Service, are hereby repealed.
        <br />
        6. Waiver
        <br />
        Any delay, negligence or tolerance by the Company in enforcing the User’s adherence to any of the present terms
        shall not constitute a waiver or a detriment to any of the Company’s rights. In case any term of the present
        document is held as void by any competent Court or Authority and therefore inapplicable, then the said term will
        not invalidate the remaining terms herein, which all of them will remain in full force and effect. 7. Invalidity
        of Terms
        <br />
        In the event that any part of the present document is held invalid or void by a court decision, such invalidity
        shall not affect the validity of the remaining part of these General Terms, which shall remain valid as if the
        invalid part were deleted. The Company will seek to replace any invalid term with a new valid one, the effect of
        which will be the as similar as possible to the one of the one canceled.
        <br />
        8. Assignment
        <br />
        This Agreement may not be assigned by You without the prior written approval of the Company but may be assigned
        without Your consent by the Company to (i) a parent or subsidiary, (ii) a purchaser of assets, or (iii) a
        successor to a merger . Any attempted assignment of the Agreement, in violation of this article, is void. 9.
        Applicable Law – Jurisdiction Any dispute between the parties concerning the Platform, interpretation, nullity
        of the terms of the contract, the existence or non-existence of rights and obligations of the contracting
        parties under contract or even tort, shall be interpreted in accordance with laws of England and Wales and shall
        be subject to the exclusive jurisdiction of the competent courts of the city of London, under the jurisdiction
        of which the parties are voluntarily submitted.
        <br />
        V. ACCEPTABLE USE OF THE PLATFORM POLICY
        <br />
        1. The Users and their Authorised Users are required to use the Platform in accordance with the present General
        Terms and all applicable laws and regulations as well as pursuant the principles of good faith and morality and
        the business ethics.
        <br />
        2. To the aforementioned persons, the following are prohibited:
        <br />
        2.1 Any use of the Platform for other purpose than the purpose of using the Service commercial purposes.
        <br />
        2.2 Any access or attempt to access information and data (including personal data) that are transmitted through
        the Platform, for which they have no authorisation or authority to use
        <br />
        2.3 Access to the Platform in order to create or produce a product or service that is competitive to the
        Platform and the Software of the Platform.
        <br />
        2.4 Use of the Platform for the purpose of providing services to third parties other than those permitted under
        the General Terms.
        <br />
        2.5 The sale, lease, disposal, transfer, assignment, concession, presentation, transmission and, in general,
        commercial exploitation of the access License and use the Service and the rights granted to them under the
        License in accordance with the General Terms of Use .<br />
        2.6 Facilitating in any way and by any means third parties to access the Platform and any data (including
        personal data) and information for non-legitimate or non authorised purposes.
        <br />
        2.7 The transmission of viruses, the transmission of documents and Information which are illegal, harmful,
        offensive, threatening, defamatory or dangerous to the security of the Platform and for the reputation of the
        Company, its Affiliates and Associated Companies and other Users of the Platform.
        <br />
        2.8. The posting of messages, Information, Documents and other data as well as the granting of any data
        (including personal data) and information that may contain abusive, threatening, offensive, defamatory and
        generally illegal content.
        <br />
        2.9 Any action which is or is considered to be detrimental to the interests of the Company, its Affiliates and /
        or Associated Companies and other Users of the Platform, or which may prevent any third party from using the
        Service.
        <br />
        2.10 Selling, conceding, renting, transmitting, processing to any third party and for any purpose any data that
        are connected with any use whatsoever of the Platform and the Service in general.
        <br />
        2.11 Publishing or transmitting content which is improper, illegal, abusive, harmful, threatening, offensive,
        libelous, defamatory, vulgar, obscene, pornographic, blasphemous, and in any way contrary to morality or which
        constitutes a violation of someone else’s privacy or which is confidential, shows empathy, may cause discomfort,
        inconvenience or unnecessary stress to third parties, expresses racial, religious, national and other
        distinctions, can cause harm to minors in any way, violates spiritually rights or other proprietary rights of
        others, is used to collect or retain personal data of others, promotes alcohol, drugs, cigarettes, gambling and
        betting, weapons, explosives, launches pyramid shapes, illegal activities, or violates in any way the law or the
        Terms of Use, is advertising, promotes, recommends or encourages conduct that could be considered as a criminal
        act that would incur civil liability or would violate the law or violate the rights of any third party in any
        country worldwide, or contains software viruses or any other codes, files, or programs designed to interrupt,
        damage, prevent or destroy any software, equipment or computer hardware, or could be considered inappropriate in
        the sole judgment of the Company.
        <br />
        2.12 Any form of Software piracy, hacking and / or interception of data (including personal data) and
        information.
        <br />
        2.13 Importing to the Platform, sending and forwarding of information or software programs that may contain
        viruses or other harmful features that may either cause the Platform to malfunction or cause traffic to the
        Service which may make it difficult for users to use it.
        <br />
        2.14 The use of the Platform in any way, which, irrespectively of the purpose (fraudulence or negligence), could
        cause the Service to malfunction.
        <br />
        2.15 Licensing, resale, leasing, assignment, subcontracting, and in general any disposal of the Service to third
        parties for any use and purpose other than the ones expressly permitted herein
        <br />
        2.16 Decompilation, duplication, reproduction, falsification, distribution, presentation, software included in
        the Service or part thereof and / or any processing of the source code.
        <br />
        2.17 Linking the Platform with any Product / Service of the Users without the express and written consent of the
        Company.
        <br />
        2.18 Any action which may affect the ability of other Users to make use of the Service, or circumvent the rights
        of other Users, such as, for example, their intellectual property rights, personal data, etc.
        <br />
        2.19 The use of mechanisms, software or other actions that impede the normal operation of the Platform.
        <br />
        2.20 Any copying, analogue / digital recording and mechanical reproduction, distribution, transfer, downloading,
        processing, resale, creation of derivative work of the Platform and its components described herein.
        <br />
        3. In the event that the above persons have doubts as to whether an action or omission constitutes a violation
        of this Acceptable Use of Platform Policy and / or, if they wish to report to the Company, any violation of this
        Policy which has come to their notice, they may contact the Company.
        <br />
                            </p>
                        </StyledCard>
                    </DesktopRightContainer>
                </DesktopFlexContainer>
            </StyledContent>
        </StyledPage>
    );
};

export default withTranslation()(withRouter(TermsAndConditionsEnglish));

import React from "react";
import styled from "styled-components";
import promoImage from "../../../assets/branding/install_app.jpg";
import promoImage2X from "../../../assets/branding/install_app.jpg";
import { Heading4, Text2 } from "../../../components/styled/Typography";
import { IonImg, IonModal } from "@ionic/react";
import theme from "../../../theme";
import { isPlatform } from '@ionic/react';

import AppsBlock from "./AppsBlock";
import closeIcon from "../../../assets/icons/close-delete-icon.svg";
import smsIcon from "../../../assets/icons/sms-icons.svg";
import { withTranslation } from "react-i18next";
const isMobileWeb = isPlatform('mobileweb')

const PromoPopup = ({ open, onChangeVisibility, t }) => (
	<StyledModal className="promo-modal" isOpen={open}>
		<CloseIconWrapper
			className="close-icon"
			onClick={() => onChangeVisibility()}
		>
			<CloseIcon role="button" />
		</CloseIconWrapper>
		<PromoImage
			srcset={`${promoImage}, ${promoImage} 2x`}
			src={promoImage2X}
			alt="Promo Image"
		/>
		<PromoFooterBlock>
			<HeaderBlock>{t("promo.header_alt")}</HeaderBlock>
			<Text2>{t("promo.text_alt")}</Text2>
			{!isMobileWeb && <SmsPanel>
				<SmsIcon /> {t("promo.sms_text")}
			</SmsPanel>}
      {isMobileWeb && <AppsBlock />}
		</PromoFooterBlock>
	</StyledModal>
);

export default withTranslation()(PromoPopup);

const CloseIconWrapper = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	border-radius: 20px;
	background-color: ${theme.colors.base.white};
	position: absolute;
	z-index: 1000;
	right: 10px;
	top: 10px;
`;

const SmsPanel = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 14px 20px 14px 60px;
	color: #00b579;
	position: relative;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 24px;
	margin-top: 32px;
	border-radius: 4px;
	background-color: #d7f7e9;
`;

const SmsIcon = styled.div`
	background-image: url(${smsIcon});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 20px;
`;

const CloseIcon = styled.div`
	background-image: url(${closeIcon});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 20px;
	height: 20px;
`;

const HeaderBlock = styled(Heading4)`
	margin-top: 0px;
	margin-bottom: 16px;
	font-size: 24px;
	line-height: 32px;
`;

const PromoImage = styled(IonImg)`
	width: 100%;
	border-radius: 4px;
	box-shadow: inset 0 0 34px 0 rgba(255, 255, 255, 0.4);
	height: 380px;
	object-fit: cover;
	@media (max-width: 560px) {
		position: static;
		height: 260px;
  }
  @media (max-width: 340px) {
		position: static;
		height: 240px;
	}
`;

const PromoFooterBlock = styled.div`
	border-radius: 4px;
	width: 100%;
	background-color: ${theme.colors.base.white};

	padding: 32px 40px;
	display: flex;
	flex-direction: column;
	line-height: 24px;
	position: relative;
	bottom: 0;
	top: -6px;
	font-size: 16px;
	line-height: 24px;

	@media (max-width: 560px) {
		padding: 24px 24px 24px 24px;
  }
  @media (max-width: 340px) {
		padding: 24px 12px 24px 12px;
	}
`;

const StyledModal = styled(IonModal)`
--ion-backdrop-opacity:1;
--border-radius:4px;
--ion-backdrop-color:rgba(45,59,78,0.95);
	.modal-wrapper {
		height: 680px;
		max-width: 520px;
		width: 100%;
	}

	@media (max-width: 560px) {
		.modal-wrapper {
      height: 100%;
      max-height:85%;
      align-self:flex-end;
			border-radius: 4px;

			.ion-page {
				justify-content: flex-start;
			}
		}
	}
`;

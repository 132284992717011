/**
 * @file This file holds the implementation that sends custom interactions for tracking purposes through the Google Tag Manager system.
 */

/**
 * @description This function is using a format specified on Welcome's GTM containers that tracks a Google Analytics event.
 * @param {string} eventCategory GA event category
 * @param {string} eventAction GA event action
 * @param {string} eventLabel GA event label
 * @param {boolean} [eventNonInteraction=false] Signifies the event to be interactive or not
 */
const trackEvent = (eventCategory, eventAction, eventLabel, eventNonInteraction = false) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: "eventTracking",
		eventCategory,
		eventAction,
		eventLabel,
		eventNonInteraction
	})
}

export {
	trackEvent
}
